import baseApi from "./base-api";

function getCurrencies() {
    return baseApi.get("admin/currency/currency");
}

function getCurrencyById(id) {
    return baseApi.get(`admin/currency/currency/${id}`);
}

function updateCurrencyById(id, data) {
    return baseApi.put(`admin/currency/currency/${id}`, data);
}

const currencyService = {
    getCurrencies: getCurrencies,
    getCurrencyById: getCurrencyById,
    updateCurrencyById: updateCurrencyById
};

export default currencyService;
