// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-text-with-label {
    margin-bottom: 0.5rem;
}

.h-text-with-label .h-label {
    color: #93969F;
    font-weight: 600;
    margin-right: 4px;
}

.h-text-with-label .h-text {
    display: inline-block;
    color: #93969F;
    margin: 0;
}

.h-text-with-label .h-text.is-multiline {
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/TextWithLabel/TextWithLabel.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,SAAS;AACb;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".h-text-with-label {\n    margin-bottom: 0.5rem;\n}\n\n.h-text-with-label .h-label {\n    color: #93969F;\n    font-weight: 600;\n    margin-right: 4px;\n}\n\n.h-text-with-label .h-text {\n    display: inline-block;\n    color: #93969F;\n    margin: 0;\n}\n\n.h-text-with-label .h-text.is-multiline {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
