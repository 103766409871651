import baseApi from "./base-api";

function getOrganizationGroups({ search, pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("v2/webadmin/organization-groups", {
        search: search,
        page: pageNumber,
        limit: pageSize,
    });
}

function getOrganizationGroupById(organizationGroupId, params = {}) {
    return baseApi.getWithParams(`v2/webadmin/organization-groups/${organizationGroupId}`, params);
}

function deleteOrganizationGroupById(organizationGroupId, params = {}) {
    return baseApi.deleteWithParams(`v2/webadmin/organization-groups/${organizationGroupId}`, params);
}

function updateOrganizationGroupById(organizationGroupId, organizationGroup) {
    return baseApi.put(`v2/webadmin/organization-groups/${organizationGroupId}`, organizationGroup);
}

function createOrganizationGroup(organizationGroup) {
    return baseApi.post(`v2/webadmin/organization-groups`, organizationGroup);
}

function deleteOrganizationFromGroupByGroupById(organizationGroupId, organizationId) {
    return baseApi.delete(`v2/webadmin/organization-groups/${organizationGroupId}/organizations/${organizationId}`);
}

const organizationGroupService = {
    getOrganizationGroups: getOrganizationGroups,
    getOrganizationGroupById: getOrganizationGroupById,
    createOrganizationGroup: createOrganizationGroup,
    deleteOrganizationGroupById: deleteOrganizationGroupById,
    updateOrganizationGroupById: updateOrganizationGroupById,
    deleteOrganizationFromGroupByGroupById: deleteOrganizationFromGroupByGroupById
};

export default organizationGroupService;
