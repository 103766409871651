import { create } from "zustand";

export const useBreadcrumbStore = create((set) => ({
    /** @type {import('../typedefs').BreadcrumbItem[]} */
    breadcrumbs: [],
    addBreadcrumb: (breadcrumb) => {
        set((state) => ({
            breadcrumbs: [...state.breadcrumbs, breadcrumb],
        }));
    },
    setBreadcrumbs: (breadcrumbs) => {
        set(() => ({
            breadcrumbs: breadcrumbs,
        }));
    },
    clearBreadcrumbs: () => {
        set(() => ({
            breadcrumbs: [],
        }));
    },
}));
