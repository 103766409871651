import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "./Gift.css";
import { formatDateForTable } from "../../common/utils/date.util";
import { GiftUtils } from "../../common/utils/gift.util";
import { LabelHelper } from "../../common/utils/label.helper";
import giftService from "../../Services/gift.service";
import { GiftProcessStateValues } from "../../features/gift/models/gift-process-state.enum";
import { TextWithLabel } from "../../shared/components/TextWithLabel/TextWithLabel";
import { QUERY_KEYS } from "../../common/query-keys";
import { useGetGiftById } from "../../hooks/gift.hook";

/**
 * @param {Object} props
 * @param {string} props.giftId
 */
export function ViewGift({ giftId }) {
  const queryClient = useQueryClient();
  const { data: gift } = useGetGiftById(giftId);
  const [giftProcessState, setGiftProcessState] = useState("");
  const [giftAdminNotes, setGiftAdminNotes] = useState("");
  const senderDetails = GiftUtils.getSenderDetails(gift);

  useEffect(() => {
    setGiftProcessState(gift?.processState ?? "");
    setGiftAdminNotes(gift?.giftAdminNotes ?? "");
  }, [gift]);

  function onSubmit(e) {
    e.preventDefault();

    if (!giftProcessState || !giftAdminNotes) {
      toast.error("Please fill process state and admin notes");
      return;
    }

    updateGift();
  }

  async function updateGift() {
    try {
      const response = await giftService.updateGiftProcessState(
        gift?._id,
        giftProcessState,
        giftAdminNotes
      );

      if (response.status === 200) {
        toast.success("Successfully updated gift");
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GIFT_BY_ID, gift?._id],
        });
        return;
      }

      toast.error("Couldn't update gift");
    } catch (err) {
      console.log(err);
      toast.error("Couldn't update gift");
    }
  }

  return (
    <div className="view-gift-container">
      <div className="card border-0 shadow rounded mb-4 p-2">
        <div>
          <TextWithLabel label="Message" text={gift?.message} isMultiline />
          <TextWithLabel
            label="Reply"
            text={gift?.replies?.[0]?.message}
            isMultiline
          />
          <TextWithLabel
            label="Auto Reply"
            text={gift?.autoReply?.[0]?.replyMessage}
            isMultiline
          />
          <TextWithLabel label="Sender Name" text={senderDetails?.firstName} />
          <TextWithLabel label="Sender Email" text={senderDetails?.email} />
          <TextWithLabel
            label="Sender Phone"
            text={senderDetails?.phoneNumber}
          />
          <TextWithLabel
            label="Receiver Name"
            text={gift?.users?.[0]?.firstName}
          />
          <TextWithLabel
            label="Receiver Email"
            text={gift?.users?.[0]?.email}
          />
          <TextWithLabel
            label="Receiver Phone"
            text={gift?.users?.[0]?.phoneNumber}
          />
          <TextWithLabel label="Type" text={gift?.giftCard?.type} />
          <TextWithLabel
            label="Platform"
            text={LabelHelper.getPlatformFlowLabel(gift?.flow)}
          />
          <TextWithLabel
            label="Date"
            text={gift?.createdAt ? formatDateForTable(gift?.createdAt) : null}
          />
        </div>

        <form onSubmit={onSubmit}>
          <div className="mb-2 w-100">
            <label className="cstm-label">Gift Process State</label>
            <select
              className="form-select cstm-category"
              value={giftProcessState}
              onChange={(e) => setGiftProcessState(e.target.value)}
            >
              <option value="" disabled>
                Select State
              </option>

              {GiftProcessStateValues.map((processState) => (
                <option key={processState} value={processState}>
                  {processState}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2 w-100">
            <label className="cstm-label">Notes</label>
            <textarea
              rows="5"
              value={giftAdminNotes}
              onChange={(e) => setGiftAdminNotes(e.target.value)}
              className="cstm-textarea"
              placeholder="Note"
            ></textarea>
          </div>

          <div className="submit-btnMain cstm-loader">
            <button
              type="submit"
              className="btn-submit-login-form cstm-submitbutton"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
