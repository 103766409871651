import { createPortal } from "react-dom";

import './Modal.css';

export function Modal({ show, children, onClose }) {
    if (!show) {
        return null;
    }

    return createPortal(
        <div className="custom-modal-container">
            <div className="custom-modal-backdrop"></div>
            <div className="custom-modal">
                <div className="custom-modal-inner">
                    <div className="d-flex justify-content-end mb-4">
                        <button
                            type="button"
                            className="modal-close-icon-btn"
                            onClick={() => onClose()}
                        >
                            <i className="fi fi-rr-cross-small"></i>
                        </button>
                    </div>

                    <div className="custom-modal-content">{children}</div>
                </div>
            </div>
        </div>,
        document.getElementById("modal-portal")
    );
}
