import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import swal from "sweetalert";
import loader from "../../assets/Images/loder.gif";



//datatable
import { useTable, usePagination, useGlobalFilter } from "react-table";

function Table({ columns, data }) {
  const history = useNavigate();
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  }, [])

  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  console.log(props);
  React.useEffect(() => {
    // console.log(globalFilter);
  }, [globalFilter]);
  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  return (
    <>
      <main class="page-content bg-light">
        <Header main_title="Organization" title="Unregistered Organization List" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">

              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="row justify-content-between align-items-center p-3">
                    <div class="col-md-4">
                      <h4 class="mb-0">Unregistered Organization List</h4>
                    </div>{" "}
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="table-responsive bg-white border-top rounded-0">
                      <table
                        {...getTableProps()}
                        class="table mb-0 table-center cstm-cl-mn2"
                      >
                        <thead>
                          {" "}
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  class="border-bottom"
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                  {/* Render the columns filter UI */}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {
                            page.length > 0 && page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                  {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>

                      </table>
                      {!showLoader ? page.length === 0 &&
                        <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-12 mt-4">
                <div class="d-md-flex align-items-center text-center justify-content-between">
                  <span class="text-muted me-3">
                    {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}

                  </span>
                  <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">

                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        href="javascript:void(0)"
                      >
                        Prev
                      </a>
                    </li>}
                    {

                      pageOptions.map(pgnumber => {
                        {/* console.log(pgnumber, "pgnumber") */ }
                        return (
                          pgnumber !== pageCount && pageCount !== 1 &&
                          <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                        {/* return( pgnumber) */ }
                      })
                    }

                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        href="javascript:void(0)"
                      >
                        Next
                      </a>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function UnRegisteredOrganization() {
  const history = useNavigate();

  const [orgData, setOrgData] = useState([]);


  orgData.forEach((orgData_id, index) => {
    orgData_id.serial = index + 1;
  });

  var DataArray = [];
  useEffect((data, e) => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    getData(1);
  }, []);

  const getData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 10
    };

    Services.getUnregisteredOrganization(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.organization_data.length !== 0) {
            var data = response.data.data.organization_data;
            data.map((data1) => {
              DataArray = [...DataArray, data1]
            })
            if (response.data.data.state) {
              getData(response.data.data.state.page + 1);
            }
          }
          setOrgData(DataArray);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }



  const columns = [
    {
      Header: "No",
      accessor: "serial",
    },

    {
      Header: "Organization Name",
      accessor: "name",
    },

    {
      Header: "Actions",

      Cell: (row) => {
        return (
          <div>

            <Link

              to={"/unregistered-organization/users?id=" + row.cell.row.original._id}
              class="cstm-eye"
            >
              <i class="fi fi-rr-eye"></i>
            </Link>
          </div>
        );
      },
    },
  ];


  return (
    <>
      <Table columns={columns} data={orgData} />
      <div
        class="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
      </div>
    </>
  );
}

export default UnRegisteredOrganization;
