import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import Services from '../../../Services/auth.service';
import userService from '../../../Services/user.service';
import { categoryType, S3_BASEURL } from '../../../Constants/app.constants';
import { Pagination } from '../../shared/Pagination';
import { useDebounceSearchTextFn } from '../../../common/hooks/use-debounce.hook';
import { formatDateForTable } from '../../../common/utils/date.util';
import { downloadCsvFromData, generateCsvFileName } from '../../../common/utils/csv.util';
import { useLoaderStore } from '../../../stores/loader.store';
import { Modal } from '../../../shared/components/Modal/Modal';
import { UserListFilters } from './UserListFilters';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';
import { APP_ROUTES } from '../../../common/app-routes';
import { createBreadcrumb, useSetBreadcrumbs } from '../../../shared/hooks/use-set-breadcrumbs.hook';

export function UserList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getUserListStateFromSearchParams(searchParams), [searchParams]);
    const filters = state.filters;
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [searchText, setSearchText] = useState(state.searchText);
    const [showFilters, setShowFilters] = useState(false);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        if (debouncedSearchText !== state.searchText) {
            setSearchParams(createUserListSearchParams(filters, debouncedSearchText));
        }
    });

    useSetBreadcrumbs([createBreadcrumb(APP_ROUTES.USER_LIST)]);

    useEffect(() => {
        getUsers();
    }, [searchParams]);

    async function getUsers() {
        setShowLoader(true);

        try {
            const res = await userService.getUsers({
                search: state.searchText ? state.searchText.trim() : '',
                page: pagination.pageNumber,
                limit: pagination.pageSize,
                organizationIds: filters.organizationId ? [filters.organizationId] : [],
                teamIds: filters.teamIds,
                isActive: true,
                nullFields: filters.nullField ? [filters.nullField] : [],
                dateRangeStart: filters.dateFilterType === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.dateFilterType === "range" ? filters.dateRangeEnd : undefined,
            });

            if (res.status === 200) {
                setUsers(res.data.results);
                setResultsCount(res.data.count);

                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    async function downloadUserQRCode(user) {
        if (user.qrCodePath) {
            let qrImageUrl = S3_BASEURL + user.qrCodePath;

            return window.open(qrImageUrl, '_blank');
        }

        try {
            let requestParams = {
                id: user._id,
                name: user.firstName,
                category: categoryType.User,
            };

            const response = await Services.generateQrCodeAtAdmin(requestParams);

            if (response.status === 200) {
                let orgData = response.data.data;
                let qrImageUrl = S3_BASEURL + orgData.data.qrCodePath;

                return window.open(qrImageUrl, '_blank');
            }

            toast.error("Couldn't generate user QR");
        } catch (err) {
            toast.error("Couldn't generate user QR");
        }
    }

    async function downloadAsCsv() {
        setShowLoader(true);

        try {
            const res = await userService.downloadAsCsv({
                searchText: state.searchText ? state.searchText.trim() : '',
                organizationIds: filters.organizationId ? [filters.organizationId] : [],
                teamIds: filters.teamIds,
                nullFields: filters.nullField ? [filters.nullField] : [],
                dateRangeStart: filters.dateFilterType === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.dateFilterType === "range" ? filters.dateRangeEnd : undefined,
            });

            const csvName = generateCsvFileName('users');
            downloadCsvFromData(res, csvName);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download csv");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createUserListSearchParams(filters, state.searchText, pageNumber));
    }

    function onApplyFilters(newFilters) {
        setShowFilters(false);
        setSearchParams(createUserListSearchParams(newFilters, state.searchText));
    }

    return (
        <SidebarLayoutContent headerTitle='User List'>
            <div className="card border-0 shadow rounded">
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">User Details</h4>
                        <div className='d-flex'>
                            <div className="mr-2">
                                <Link
                                    to="/create-user"
                                >
                                    <button className="cstm-btn">Add User</button>
                                </Link>
                            </div>
                            <div className="">
                                <button
                                    type='button'
                                    className="cstm-eye m-0"
                                    onClick={downloadAsCsv}
                                >
                                    <i className="fi fi-rr-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-2">
                        <div className="mr-2">
                            <i className="fi fi-rr-search cstm-search-ro"></i>
                            <input
                                name="search-user"
                                id="search-user"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                className="cstm-input-seacrh"
                                placeholder="Search Users"
                            />
                        </div>

                        <button type='button' className='custom-filter-icon-btn' onClick={() => setShowFilters(true)}>
                            <i className="fi fi-rr-settings-sliders"></i>
                        </button>
                    </div>
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Name</th>
                                <th className="border-bottom w-4 cstm-userheading">Contact</th>
                                <th className="border-bottom w-4 cstm-userheading">Email</th>
                                <th className="border-bottom w-4 cstm-userheading">Joined Date</th>
                                <th className="border-bottom w-4 cstm-userheading">QR Code</th>
                                <th className="border-bottom w-4 cstm-userheading">Status</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {users?.map((user) => (
                                <tr
                                    key={user._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{user.firstName}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{user.email}</td>
                                    <td>{user.createdAt ? formatDateForTable(user.createdAt) : null}</td>
                                    <td>
                                        <div>
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip id="button-tooltip-2">Download QR</Tooltip>}
                                            >
                                                <Link
                                                    onClick={() => downloadUserQRCode(user)}
                                                >
                                                    <i className="fi fi-rr-download"></i>
                                                </Link>
                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                    <td>{user.isBlock ? 'Blocked' : 'Active'}</td>
                                    <td>
                                        <div>
                                            <Link
                                                to={`/view-user/${user?._id}`}
                                                className="cstm-eye"
                                                state={{ breadcrumbPath: location.pathname + location.search }}
                                            >
                                                <i className="fi fi-rr-eye"></i>
                                            </Link>
                                            <Link
                                                to={"/edit-user/?id=" + user?._id}
                                                className="cstm-eye"
                                                state={{ breadcrumbPath: location.pathname + location.search }}
                                            >
                                                <i className="fi fi-rr-pencil"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {!users?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <UserListFilters
                    filters={filters}
                    onApplyFilters={onApplyFilters}
                />
            </Modal>
        </SidebarLayoutContent>
    );
}

const singleFilters = ['organizationId', 'nullField', 'dateFilterType', 'dateRangeStart', 'dateRangeEnd'];
const multiFilters = ['teamIds'];

function createUserListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber, singleFilters, multiFilters);
}

function getUserListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams, singleFilters, multiFilters);
}
