import React, { useEffect, useState } from 'react'
import Header from '../NavMenuBar/Header';
import Services from "../../Services/auth.service";
import { useLocation, useNavigate, Link } from "react-router-dom";
import swal from 'sweetalert';
import $ from 'jquery';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Col, Container, Row } from "react-bootstrap";



function EditGift() {

	//constant added according to selected country by Rajkumari
	const [selectedCountriesPrice, setSelectedCountriesPrice] = useState([]);
	const [countryData, setCountryData] = useState([]);

	setTimeout(() => {
		$(".gift-price").keypress(function (e) {
			var charCode = (e.which) ? e.which : e.keyCode;
			console.log(charCode, "charcode");
			if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
				return false;
			}
			return true;

		});

	}, 500);

	const history = useNavigate();
	const getid = useLocation().search;
	const giftID = new URLSearchParams(getid).get('id');
	useEffect(() => {

		const loggedInUser = localStorage.getItem("token");
		!loggedInUser && history("/");
		getData();

	}, [])
	const [priceField, setPriceField] = useState([{ price: "" }])

	const [formFields, setFormFields] = useState({
		_id: "",
		type: "",
		giftName: "",
		platformFee: "0",
		title: "",
		sub_title: "",
		message: "",
		status: "",
		banner: "",
		image_video: [],


	});
	const [err, seterr] = useState({
		type: "",
		giftName: "",
		title: "",
		sub_title: "",
		message: "",
		price: "",
		status: "",
		banner: "",
		image_video: "",


	});
	var priceArray = [];
	///image and videos
	var imgExtArray = [];
	var imgArray = [];
	var imgExtArray = [];
	var videoExtArray = [];
	var videoArray = [];
	const arrayImages = [];

	const [videoPrev, setVideoPrev] = useState([]);
	const [imagesPrev, setImagesPrev] = useState([]);
	const [finalImages, setFinalImages] = useState([]);
	const [multiData, setMultiData] = useState([]);
	const [getMultiFile, setMultiFile] = useState([]);
	const [arrayOfGetData, setArrayOfGetData] = useState([]);


	const getData = () => {	//get data
		Services.getGiftById(giftID)
			.then((response) => {
				console.log(response, "response");
				// return false;
				if (response.data.status === 200) {
					var gatPriceArray = response.data.data.price;

					var arrayImages = response.data.data.image_video;
					arrayImages.map((multifile) => {
						var filesplit = multifile.split(".").pop();
						var imageExtension = [
							"png",
							"jpg",
							"jpeg",
							"apng",
							".avif",
							"jfif",
							"pjpeg",
							"pjp",
							"svg",
							"webp",
						];
						var videoExtension = [
							"mp4",
							"mov",
							"wmv",
							"avi",
							"avchd",
							"flv",
							"f4v",
							"swf",
							"mkv",
							"webm",
							"html5",
							"mpeg-2",
						];
						imageExtension.includes(filesplit) &&
							videoExtArray.push({ url: multifile, type: "image" });
						videoExtension.includes(filesplit) &&
							videoExtArray.push({ url: multifile, type: "video" });
					});

					setFormFields({
						_id: response.data.data._id,
						type: response.data.data.type,
						giftName: response.data.data.giftName,
						title: response.data.data.title,
						image_video: arrayImages,
						platformFee: response.data?.data?.platformFee,
						status: response.data.data.status,
						sub_title: response.data.data.sub_title,
						message: response.data.data.message
					});
					setVideoPrev([...videoPrev, ...videoExtArray]);
					setMultiFile([...arrayImages]);
					console.log(gatPriceArray, "gatPriceArray");

					gatPriceArray.map(p => {
						priceArray.push({ price: p });
					})

					setPriceField(priceArray);

					//gifting price for each country added by Rajkumari
					let priceWithCurrency = response.data.data.priceWithCurrency;
					setSelectedCountriesPrice(priceWithCurrency);

				} else {
					console.log("error");
				}


				// }
			})
			.catch(function (err) {
				console.log(err, "errr");
				swal("Failed", err.response.data.message, "error");
			});
	}
	//image onchange
	function deleteImagesGet(e) {
		const vp = videoPrev.filter((item, index) => index !== e);
		const fs = finalImages.filter((item, index) => index !== e);

		setVideoPrev([...vp]);
	}

	function deleteImages(e) {
		const s = imagesPrev.filter((item, index) => index !== e);
		const fs = finalImages.filter((item, index) => index !== e);

		setFinalImages([...fs]);
		setImagesPrev([...s]);
	}


	//image onchange 

	const handleImageChange = (e) => {
		e.preventDefault();
		err.image_video = e.target.value.length > 0 ? "" : "Select image or video";

		var files = e.target.files;
		var filesArr = Array.prototype.slice.call(files);
		filesArr.forEach(function (f, index) {
			imgArray = [...imgArray, f];
		});
		setFinalImages([...finalImages, ...imgArray]);
		const arr = [];
		imgArray.forEach(function (f, index) {
			var u = URL.createObjectURL(f);
			arr.push(u);
			var filesplit = f.name.split(".").pop();
			var imageExtension = [
				"png",
				"jpg",
				"jpeg",
				"apng",
				".avif",
				"jfif",
				"pjpeg",
				"pjp",
				"svg",
				"webp",
			];
			var videoExtension = [
				"mp4",
				"mov",
				"wmv",
				"avi",
				"avchd",
				"flv",
				"f4v",
				"swf",
				"mkv",
				"webm",
				"html5",
				"mpeg-2",
			];
			imageExtension.includes(filesplit) &&
				imgExtArray.push({ url: u, type: "image" });
			videoExtension.includes(filesplit) &&
				imgExtArray.push({ url: u, type: "video" });
		});

		setImagesPrev([...imagesPrev, ...imgExtArray]);
		setMultiData([...multiData, ...arr]);
	};

	const [file, setFile] = useState();
	// const handleImageChange = (e) => {
	// 	e.preventDefault();
	// 	setFile(URL.createObjectURL(e.target.files[0]));
	// 	var img = document.getElementById("imageB");
	// 	img.style.display = "none";
	// 	let charImage = e.target.files[0];
	// 	var fileExt = charImage.name.split('.').pop();

	// 	var ExtArray = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2", "png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

	// 	ExtArray.includes(fileExt) ? (err.banner = "") :
	// 		(
	// 			err.banner = "Please upload image or video only",
	// 			formFields.banner = ""
	// 		);

	// 	var videoDisplay = document.getElementById("video-uploaded");

	// 	var imageDisplay = document.getElementById("b-img");

	// 	var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];

	// 	var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

	// 	videoExtension.includes(fileExt) ?
	// 		(
	// 			videoDisplay.style.display = "block",
	// 			imageDisplay.style.display = "none"
	// 		) :
	// 		(videoDisplay.style.display = "none"
	// 			// imageDisplay.style.display = "block"
	// 		);

	// 	seterr({ ...err });

	// 	if (err.banner === "") {
	// 		let formData = new FormData();
	// 		formData.append("image", e.target.files[0]);

	// 		Services.charimageGet(formData)
	// 			.then((response) => {
	// 				if (response.status === 200) {
	// 					formFields.banner = response.data.data.images[0].location;
	// 				}
	// 			})
	// 			.catch(function (err) {
	// 				console.log(err, "err");
	// 			});
	// 	}
	// };
	//repeater field add
	const handleOnchangePrice = (i, e) => {
		const { value, name } = e.target;

		if (name === "price") {
			err.price = value.length > 0 ? "" : "Enter price";
		}
		seterr({ ...err })
		let newFormValues = [...priceField];
		newFormValues[i][e.target.name] = e.target.value;
		setPriceField(newFormValues);
		// console.log(newFormValues, "newFormValues");
	}

	const addPriceFields = () => {
		setPriceField([...priceField, { price: "" }])
	}

	const removeFormFields = (i) => {
		let newFormValues = [...priceField];
		newFormValues.splice(i, 1);
		setPriceField(newFormValues)
	}

	var pArray = [];
	priceField.map(p => {
		pArray.push(p.price)
	})

	const handleOnchange = (e) => {
		const { value, name } = e.target;
		switch (name) {
			case "title":
				err.title = value.length > 0 ? "" : "Enter media title";
				break;
			case "type":
				err.type = value.length > 0 ? "" : "Enter gift type";
				break;
			case "giftName":
				err.giftName = value.length > 0 ? "" : "Enter gift name";
				break;
			case "status":
				err.status = value.length > 0 ? "" : "Select status";
				break;
			case "sub_title":
				err.sub_title = value.length > 0 ? "" : "Enter gift slogan";
				break;
			case "message":
				err.message = value.length > 0 ? "" : "Enter description";
				break;
			default:
				break;
		}

		setFormFields({ ...formFields, [name]: value });
		seterr({ ...err });
	};
	const handleOnSubmit = (e) => {
		e.preventDefault();

		if (formFields.title === "") {
			err.title = "Enter media title";
		} else {
			err.title = "";
		}
		if (formFields.type === "") {
			err.type = "Enter gift type";
		} else {
			err.type = "";
		}
		if (formFields.giftName === "") {
			err.type = "Enter gift name";
		} else {
			err.type = "";
		}
		if (formFields.sub_title === "") {
			err.sub_title = "Enter gift slogan";
		} else {
			err.sub_title = "";
		}
		if (formFields.message === "") {
			err.message = "Enter description";
		} else {
			err.message = "";
		}
		if (formFields.status === "") {
			err.status = "Select status";
		} else {
			err.status = "";
		}
		// if (formFields.banner === "") {
		// 	err.banner = "Select banner image";
		// } else {
		// 	err.banner = "";
		// }
		// if (finalImages.length === 0) {
		// 	err.image_video = "Select image or video";
		// } else if (newsData.image_video === "") {
		// 	err.image_video = "Select image or video";

		// } else {
		// 	err.image_video = "";
		// }
		if (priceField[0].price === "") {
			err.price = "Enter price"

		} else {
			err.price = "";
		}

		seterr({ ...err });
		console.log(err, "err");

		if (videoPrev) {

			videoPrev.map(data => {
				videoArray.push(data.url);
			})
			setArrayOfGetData(videoArray)
		}

		if (
			err.title === "" &&
			err.type === "" &&
			err.giftName === "" &&
			err.sub_title === "" &&
			err.message === "" &&
			err.image_video === "" &&
			// err.banner === "" &&
			err.status === ""
			&& err.price === ""

		) {
			$(".loader-main").show();
			if (finalImages.length > 0) {
				const fileData = new FormData();
				finalImages.forEach((file) => fileData.append('image', file));
				// images and videos multiple uploaded
				Services.charimageGet(fileData)
					.then((response) => {

						if (response.status === 200) {
							const ImagesDataArrar = response.data.data.images;
							ImagesDataArrar.map((image) => {
								arrayImages.push(image.location);
							})
							var finalImagesData = [];
							finalImagesData = [...arrayImages, ...videoArray]

							EditGiftData(finalImagesData);
						}

					})
					.catch(function (err) {
						console.log(err, "err");

					});
			} else {
				EditGiftData(videoArray);
			}

			function EditGiftData(finalImagesData) {
				let giftingPrice = selectedCountriesPrice.filter((o) => {
					return o.hasOwnProperty("price");
				});
				var editGift = {
					id: formFields._id,
					type: formFields.type,
					giftName: formFields.giftName,
					title: formFields.title,
					sub_title: formFields.sub_title,
					message: formFields.message,
					image_video: finalImagesData,
					platformFee: formFields.platformFee,
					status: formFields.status,
					price: pArray,
					priceWithCurrency: giftingPrice, //added new gifting price according to country

				};
				Services.editGift(editGift)
					.then((response) => {
						if (response.data.status == 200) {
							swal("Success", response.data.message, "success");
						} else {
							swal("Failed", response.data.message, "error");
						}
					})
					.catch((err) => {
						swal("Failed", err.response.data.message, "error");
					});
			}
		}
	};


	/************************Start Of Gifting Currency According To Country
	 * @author Rajkumari Parihar
	 * @CreatedAt 6th Dec 2020
	 * **************************/

	useEffect(() => {
		Services.countryDataList()
			.then((response) => {
				if (response.status === 200) {
					const optionsArray = response.data.data;
					let countries = [{ label: "All", value: "*" }];
					for (var i in optionsArray) {
						var item = optionsArray[i];
						countries.push({
							label: item.countryName,
							value: item.currencyCode,
						});
						setCountryData(countries);
					}
				}
			})
			.catch(function (err) {
				console.log(err, "err");
			});
	}, []);

	const onCountryChange = (value, event) => {

		if (event.action === "select-option" && event.option.value === "*") {
			setSelectedCountriesPrice(countryData);
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "*"
		) {
			setSelectedCountriesPrice([]);
			//  setCurrencyResponse([]);
		} else if (event.action === "deselect-option") {
			setSelectedCountriesPrice(value.filter((o) => o.value !== "*"));
		} else if (value.length === countryData.length - 1) {
			setSelectedCountriesPrice(countryData);
		} else if (countryData === "" && value !== "") {
			setSelectedCountriesPrice(value);
		} else {

			setSelectedCountriesPrice(value);
		}
	};
	const countryDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
		if (value && value.some((o) => o.value === "*")) {
			return `${placeholderButtonLabel}: All Countries Selected`;
		} else {
			return `${placeholderButtonLabel}: ${value ? value.length : 0} Countries Selected`;
		}
	};

	let countryPrice = [];
	const handleOnCountriesPriceChange = (item, e) => {
		const { value, name } = e.target;
		if (name === "price") {
			err.price = value.length > 0 ? "" : "Enter price";
		}
		let index = selectedCountriesPrice.findIndex((x) => x.value === item.value);
		if (index !== -1) {
			if (value !== "" || value.length !== 0) {
				// update
				countryPrice[0] = value;
				selectedCountriesPrice[index]["price"] = countryPrice;
				setSelectedCountriesPrice(selectedCountriesPrice);
			}
		}

	};

	const deleteGiftingCountry = (deleteCountry) => {
		let removeCountryGifting = selectedCountriesPrice.filter(data => data.value !== deleteCountry.value);
		setSelectedCountriesPrice(removeCountryGifting);

	}
	return (
		<>
			<main class="page-content bg-light">
				<Header main_title="Gifting" title_link="gift" title="Gifting List" title1="Edit Gift" />
				<div class="container-fluid">
					<div class="layout-specing">

						<div class="row">
							<div class="col-md-12">
								<div class="card border-0 shadow rounded">
									<div class="d-flex justify-content-between align-items-center p-3 border-bottom">
										<h4 class="mb-0">Edit Gift</h4>
									</div>
									<div class="p-3 col-md-10 col-lg-7">
										<form id="add-gift" onSubmit={handleOnSubmit}>
											<div class="mb-4 ">
												<label class="cstm-label">Upload Image or Video</label><br />


												<div className='cstm-video-image-upload'>
													<label for="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

													<div className='cstm-vi-in'>
														{imagesPrev.map((data, index) =>

															data.type === 'image' ?

																(<>                 <div className='cstm-vi-box'>
																	<span className="cstm-vi-bx">  <img class="multi-images" controls src={data.url} alt="..." /></span>
																	<button type="button" onClick={() => deleteImages(index)}>
																		✖
																	</button></div>
																</>
																) : (
																	<>                 <div className='cstm-vi-box'>
																		<span className="cstm-vi-bx">  <video class="multi-videos" controls src={data.url} alt="..." /></span>
																		<button type="button" onClick={() => deleteImages(index)}>
																			✖
																		</button>
																	</div>
																	</>
																)

														)}
														{/* </div>

                              <div className='cstm-vi-in'> */}
														{videoPrev.map((data, index) =>

															data.type === 'image' ?

																(<> <div className='cstm-vi-box'>
																	<span className="cstm-vi-bx">  <img class="multi-images getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
																	<button type="button" onClick={() => deleteImagesGet(index)}>
																		✖
																	</button>
																</div>
																</>
																) : (
																	<> <div className='cstm-vi-box'>
																		<span className="cstm-vi-bx">  <video class="multi-videos getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
																		<button type="button" onClick={() => deleteImagesGet(index)}>
																			✖
																		</button>
																	</div>
																	</>
																)

														)}
													</div>


													<input
														// accept=".mov,.mp4"
														multiple
														type="file"
														name="image_video"
														id="org-image"
														className='cstm-uploadHide'
														// value={editcharityVal.image_video}
														// style={{ opacity: 0, cursor: 'pointer' }}
														onChange={handleImageChange}
													/>
													{err.image_video !== "" && (
														<span style={{ color: "red" }} className="ml-3">
															{err.image_video}
														</span>
													)}
												</div>
											</div>

											<div class="row">
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Gift Type</label>
														<input
															name="type"
															disabled="true"
															contentEditable="false"
															id="type"
															value={formFields.type}
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter gift type"
														/>
														{err.type !== "" && (
															<span style={{ color: "red" }}>
																{err.type}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Gift Name</label>
														<input
															name="giftName"
															id="giftName"
															value={formFields.giftName}
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter gift name"
														/>
														{err.giftName !== "" && (
															<span style={{ color: "red" }}>
																{err.giftName}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Media title</label>
														<input
															name="title"
															id="title"
															value={formFields.title}
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter media title"
														/>
														{err.title !== "" && (
															<span style={{ color: "red" }}>
																{err.title}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-6">
													<div class="mb-3">
														<label class="cstm-label">Gift Slogan</label>
														<input
															name="sub_title"
															id="sub_title"
															onChange={handleOnchange}
															value={formFields.sub_title}

															type="text"
															class="cstm-input"
															placeholder="Enter gift slogan"
														/>
														{err.sub_title !== "" && (
															<span style={{ color: "red" }}>
																{err.sub_title}
															</span>
														)
														}
													</div>
												</div>
												<div class="mb-4">
													{/* <div class="mb-6"> */}
													<label class="cstm-label">Description</label>
													<textarea
														name="message"
														id="message"
														rows="5"
														value={formFields.message}
														onChange={handleOnchange}
														type="text"
														class="cstm-input cstm-textarea"
														placeholder="Enter description"
													/>
													{err.message !== "" && (
														<span style={{ color: "red" }}>
															{err.message}
														</span>
													)
													}
													{/* </div> */}
												</div>

												{/* repeter field add  */}
												{priceField.map((element, index) => (
													<div className="form-inline cstm-gift-price-main" key={index}>
														<label class="cstm-label">Default Gift Price In USD</label>
														<input
															name="price"
															id={`price${index}`}

															value={element !== "" ? element.price : ''}
															onChange={(e) => handleOnchangePrice(index, e)}
															type="text"
															class="cstm-input gift-price"
															placeholder="Enter gift price"
														/>

														{
															index ?

																<button class="cstm-gp-btn" type="button" onClick={() => removeFormFields(index)}><i class="fi fi-rr-trash"></i></button>

																: null
														}
													</div>
												))}


												{/* Commented after discussion with Don By Rajkumari
                        
                        <div class="col-md-12">
													<div class="mb-2 mb-4">
														<button class="mr-3 cstm-btn" type="button" onClick={() => addPriceFields()}>Add</button>
													</div>
												</div> */}
												{err.price !== "" && (
													<span style={{ color: "red" }}>
														{err.price}
													</span>
												)
												}

												<div class="col-md-6">
													<div class="mb-3">
														<label class="cstm-label">Platform Fee</label>
														<input
															name="platformFee"
															id="platformFee"
															value={formFields.platformFee}
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter platform fee"
														/>
													</div>
												</div>

												{/***Gifting according to country***/}
												<div class="mb-4">
													<label class="cstm-label">Select Countries</label>
													<ReactMultiSelectCheckboxes
														options={countryData}
														placeholderButtonLabel="Choose Country Specific Gift Pricing"
														value={selectedCountriesPrice}
														onChange={onCountryChange}
														getDropdownButtonLabel={countryDropdownButtonLabel}
													/>
												</div>
												<span style={{ color: "red", marginBottom: 20, fontSize: 13 }}>Note: Country with 0 and blank price will not add in Gifting.</span>

												{selectedCountriesPrice && selectedCountriesPrice.map((item, index) => (
													<>
														{
															item.value !== "*" &&
															<Container key={index}>
																<Row>
																	<label
																		style={{ marginBottom: -5 }}
																		class="cstm-label">
																		Gift Price
																	</label>
																	<Col
																		xs={3}
																		style={{ marginTop: 20, fontSize: 14 }}>
																		{item.label} - {item.value}
																	</Col>

																	<Col xs={6}>
																		<div
																			className="form-inline cstm-gift-price-main"
																			key={index}>
																			<input
																				name="price"
																				id={`price${index}`}
																				defaultValue={
																					item.price && item.price.length > 0
																						? item.price[0]
																						: 0
																				}
																				onChange={(e) =>
																					handleOnCountriesPriceChange(item, e)
																				}
																				type="text"
																				class="cstm-input gift-price"
																				placeholder="Enter Gift Price"
																			/>
																		</div>
																	</Col>

																	<div
																		onClick={() => {
																			deleteGiftingCountry(item);
																		}}
																		class="cstm-delete mrn-rt"
																	>
																		<i class="fi fi-rr-trash"></i>
																	</div>
																</Row>
															</Container>
														}
													</>

												))}

												<div class="mb-4">
													<label class="cstm-label">Role</label>
													<select
														name="status"
														class="form-select cstm-category"
														aria-label="Default select example"
														value={formFields.status}
														onChange={handleOnchange}
													>
														<option value="" selected>
															Select status
														</option>
														<option value="true">Active</option>
														<option value="false">Deactive</option>

													</select>
													{err.status !== "" && (
														<span style={{ color: "red" }}>
															{err.status}
														</span>
													)
													}
												</div>


												<div class="col-md-12">
													<div class="mb-2 mt-1">
														<button class="mr-3 cstm-btn">Save Changes</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</main>
		</>
	)
}

export default EditGift;

