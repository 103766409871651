import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import loader from "../../assets/Images/loder.gif";
import Header from "../NavMenuBar/Header";
import { mainSpinner } from "../../utils/spinner.util";
import partiallyRegisteredUserService from "../../Services/partially-registered-user.service";
import { formatDateWithTime } from "../../common/utils/date.util";

export function EditPartiallyRegisteredUser() {
    const [searchParams] = useSearchParams();
    const countryCode = searchParams.get('countryCode');
    const phoneNumber = searchParams.get('phoneNumber');
    const [tempUser, setTempUser] = useState({});
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            // mainSpinner.show();
            // await getTempUserDetails();
            await getUserDetails();
            await getTempUserDetails();
            // mainSpinner.hide();
        }
        catch (err) {
            // mainSpinner.hide();
        }
    }

    async function getTempUserDetails() {

        try {
            const res = await partiallyRegisteredUserService.getTempUserDataByPhoneNumber(countryCode, phoneNumber);

            if (res.status == 200) {
                setTempUser(res.data.tempUser || {});
                return;
            }

            toast.error("Couldn't get user temp details");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get user temp details");
        } finally {
            return;
        }
    }

    async function getUserDetails() {
        try {
            const res = await partiallyRegisteredUserService.getNotesByPhoneNumber(countryCode, phoneNumber);

            if (res.status == 200) {
                setNotes(res.data?.notes || []);

                return;
            }

            toast.error("Couldn't get user details");
            return;
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get user details");
        } finally {
            return;
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        addNote();
    };

    async function addNote() {
        mainSpinner.show();

        try {
            const res = await partiallyRegisteredUserService.addNotesByPhoneNumber(countryCode, phoneNumber, newNote);

            if (res.status == 200) {
                toast.success("Successfully added note for user");
                setNewNote('');
                getUserDetails();
                return;
            }

            toast.error("Couldn't add note for user");
        } catch (err) {
            toast.error("Couldn't add note for user");
        } finally {
            mainSpinner.hide();
        }
    }

    return (
        <main className="page-content bg-light">
            <Header
                title1="Edit Partially Registered User"
                title="Partially Registered Users"
                main_title="Partially Registered Users"
                title_link="partially-registered-users"
            />
            <div className="container-fluid">
                <div className="layout-specing">
                    <h4 className="mb-4">Edit Partially Registered User</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 shadow rounded mb-4 p-2">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="cstm-label">Country Code</label>
                                        <input
                                            name="countryCode"
                                            type="number"
                                            className="cstm-input"
                                            value={countryCode}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Contact No</label>
                                        <input
                                            name="phoneNumber"
                                            type="number"
                                            className="cstm-input"
                                            value={phoneNumber}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">First name</label>
                                        <input
                                            name="firstname"
                                            type="text"
                                            className="cstm-input"
                                            value={tempUser.firstName ? tempUser.firstName : 'N/A'}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Email</label>
                                        <input
                                            name="email"
                                            type="text"
                                            className="cstm-input"
                                            value={tempUser.email ? tempUser.email : 'N/A'}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Organization</label>
                                        <input
                                            name="organization"
                                            type="text"
                                            className="cstm-input"
                                            value={(tempUser.companyData && tempUser.companyData[0]?.name) ? tempUser.companyData[0].name : 'N/A'}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Occupation</label>
                                        <input
                                            name="occupation"
                                            type="text"
                                            className="cstm-input"
                                            value={tempUser.occupation ? tempUser.occupation : 'N/A'}
                                            disabled
                                        />
                                    </div>

                                    <div>
                                        <h4>Notes</h4>

                                        <div className="partially-registered-user-notes">
                                            {notes?.map((note, index) => (
                                                <div key={index}>
                                                    <span>Date: {formatDateWithTime(note.createdAt)}</span>
                                                    <p>{note.note}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Add new note</label>
                                        <textarea
                                            rows="5"
                                            value={newNote}
                                            onChange={(e) => setNewNote(e.target.value)}
                                            className="cstm-textarea"
                                            placeholder="Note"
                                        ></textarea>
                                    </div>

                                    <div className="submit-btnMain cstm-loader">
                                        <button type="submit" className="btn-submit-login-form cstm-submitbutton">Add Note</button>
                                        <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                            <img src={loader} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
