// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-editable-info-icon {
    color: #00CCCC;
    cursor: pointer;
}

.info-editable-tooltip-container {
    min-width: 200px;
}

.info-editable-tooltip-edit-btn {
    background: none;
    border: none;
}

.info-editable-tooltip-edit-btn i {
    color: #00CCCC;
}

.info-editable-tooltip-content {
    white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".info-editable-info-icon {\n    color: #00CCCC;\n    cursor: pointer;\n}\n\n.info-editable-tooltip-container {\n    min-width: 200px;\n}\n\n.info-editable-tooltip-edit-btn {\n    background: none;\n    border: none;\n}\n\n.info-editable-tooltip-edit-btn i {\n    color: #00CCCC;\n}\n\n.info-editable-tooltip-content {\n    white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
