import $ from "jquery";

function showMainSpinner() {
    $(".loader-main").show();
}

function hideMainSpinner() {
    $(".loader-main").hide();
}

export const mainSpinner = {
    show: showMainSpinner,
    hide: hideMainSpinner,
};
