import baseApi from "./base-api";

function shortUrlRequest(data) {
    return baseApi.post("admin/sms/short-url", {
        "url": data
    });
}

function sendSms(phoneNumbers, message) {
    return baseApi.post("admin/sms/send", {
        "phoneNumbers": phoneNumbers,
        "message": message
    });
}

const smsService = {
    shortUrlRequest: shortUrlRequest,
    sendSms: sendSms
};

export default smsService;