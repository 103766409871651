import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Header from '../../NavMenuBar/Header';
import loader from '../../../assets/Images/loder.gif';
import storageService from '../../../Services/storage.service';
import videoQuickTipService from '../../../Services/video-quick-tip.service';
import { useLoaderStore } from '../../../stores/loader.store';
import { async } from 'radar-sdk-js';

export function AddNewVideoQuickTip() {
    const [searchParams] = useSearchParams();
    const [finalVideo, setFinalVideo] = useState({});
    const navigate = useNavigate();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [blobVideo, setBlobVideo] = useState(null);
    const [progress, setProgress] = useState(0);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const videoQuickTipId = searchParams.get('id');

    useEffect(() => {
        if (videoQuickTipId) {
            getVideoQuickTipById();
        }
    }, []);

    const [formFields, setFormFields] = useState({
        video_title: ""
    });

    const [err, seterr] = useState({
        title: "",
        video: "",
    });


    const getVideoQuickTipById = async () => {
        try {
            const response = await videoQuickTipService.getVideoQuickTipById(videoQuickTipId);

            if (response.status === 200) {
                let data = response.data;

                setFormFields({ ...formFields, ['video_title']: data.videoTitle });

                setVideoUrl(data.videoLink);
                return;
            }

            toast.error("Couldn't fetch video quick tip");
        } catch (err) {
            toast.error("Couldn't fetch video quick tip");
        }
    }

    const handleVideoChange = (e) => {
        e.preventDefault();
        err.image_video = e.target.value.length > 0 ? "" : "Select video";
        let files = e.target.files;
        setFinalVideo(files);

        if (videoQuickTipId) {
            setVideoUrl(null);
        }

        let reader = new FileReader();

        //if reading completed
        reader.onload = e => {
            let blobData = e.target.result; //blob data
            setBlobVideo(blobData);
        };

        //read the file
        reader.readAsDataURL(files[0]);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            if (videoQuickTipId) {
                await handleEdit();
                return;
            }
            if (formFields.video_title === "") {
                err.video_title = "Enter video  title ";
            } else {
                err.video_title = "";
            }


            if (finalVideo.length === 0) {
                err.video = "Select video";
            } else {
                err.video = "";
            }


            setSubmitStatus(true);
            setShowLoader(true);

            let signedUrl = await getSignedUrlForUpload();
            await handleUpload(signedUrl.signedUrl);

            await transcodeVideo(signedUrl.inputPath);


            let fileName = finalVideo[0].name;
            let environment = process.env.REACT_APP_ENVIRONMENT;
            const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
            let title = getTextCamelCase(formFields.video_title);
            let encodedVideoPath = `quickTipVideo/${environment}/${title}/transcode480p.${extension}`;

            await addVideoQuickTip(signedUrl.inputPath, encodedVideoPath);

            toast.success('Successfully added the video quick tip');
            return navigate('/how-to-list');
        }
        catch (err) {
            toast.error(err);

        } finally {
            setShowLoader(false);
        }
    }

    const handleEdit = async () => {
        try {

            if (formFields.video_title === "") {
                err.video_title = "Enter video  title ";
            } else {
                err.video_title = "";
            }


            if (!videoUrl || finalVideo.length === 0) {
                err.video = "Select video";
            } else {
                err.video = "";
            }

            setSubmitStatus(true);
            setShowLoader(true);

            let inputPath, encodedPath = '';

            if (videoUrl == null) {
                await deleteVideoOnlyQuickTip(videoQuickTipId);

                let signedUrl = await getSignedUrlForUpload();
                await handleUpload(signedUrl.signedUrl);

                await transcodeVideo(signedUrl.inputPath);

                let fileName = finalVideo[0].name;
                let environment = process.env.REACT_APP_ENVIRONMENT;
                const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                let title = getTextCamelCase(formFields.video_title);
                let encodedVideoPath = `quickTipVideo/${environment}/${title}/transcode480p.${extension}`;

                inputPath = signedUrl.inputPath;
                encodedPath = encodedVideoPath;
            }

            await updateVideoQuickTip(inputPath, encodedPath);

            toast.success('Successfully updated the video quick tip'); 
            return navigate('/how-to-list');

        }
        catch (err) {
            toast.error(err);

        } finally {
            setShowLoader(false);
        }
    }

    const handleOnchange = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case "video_title":
                err.video_title = value.length > 0 ? "" : "Enter video title";
                break;

            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        seterr({ ...err });
    };

    // api
    const updateVideoQuickTip = async (sourcePath, encodedVideoPath) => {
        try {
            const res = await videoQuickTipService.updateVideoQuickTip(formFields.video_title, videoQuickTipId, sourcePath, encodedVideoPath);

            if (res.status === 200) {
                return res.data;
            }
        }
        catch (err) {
            toast.error("Couldn't update video quick tip");
            throw err;
        }
    }

    const deleteVideoOnlyQuickTip = async () => {
        try {
            const res = await videoQuickTipService.deleteVideoOnlyQuickTip(videoQuickTipId);

            if (res.status === 200) {
                return res.data;
            }
        }
        catch (err) {
            toast.error("Couldn't delete video quick tip");
            throw err;
        }
    }

    const addVideoQuickTip = async (sourcePath, encodedVideoPath) => {
        try {
            const res = await videoQuickTipService.createVideoQuickTip(formFields.video_title, sourcePath, encodedVideoPath);

            if (res.status === 200) {
                return res.data;
            }
        }
        catch (err) {
            toast.error("Couldn't add video quick tip");
            throw err;
        }
    }

    const handleUpload = async (signedUrl) => {
        try {
            await axios.put(signedUrl, finalVideo[0], {
                headers: {
                    "Content-Type": finalVideo[0].type
                },
                onUploadProgress: (e) => {
                    //  Show progress
                    let percentCompleted = Math.round((e.loaded * 100) / e.total);
                    setProgress(percentCompleted);

                    if (percentCompleted == 100) {
                        return;
                    }
                },
            });
        }
        catch (err) {
            toast.error("Couldn't upload video");
            throw err;
        }
    };

    const transcodeVideo = async (inputPath) => {
        try {
            let environment = process.env.REACT_APP_ENVIRONMENT;
            let title = getTextCamelCase(formFields.video_title);
            let outputPath = `quickTipVideo/${environment}/${title}/transcode`;
            const res = await storageService.intitiateVideoTranscode(inputPath, outputPath);

            if (res.status === 200) {
                console.log(res.data);
                return res.data;
            }

            toast.error("Couldn't transcode video");
            throw `Couldn't transcode video`;
        } catch (err) {
            toast.error("Couldn't transcode video");
            throw err;
        } finally {
        }
    }

    const getTextCamelCase = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }


    const getSignedUrlForUpload = async () => {
        try {
            let environment = process.env.REACT_APP_ENVIRONMENT;
            let title = getTextCamelCase(formFields.video_title);
            let path = `quickTipVideo/${environment}/${title}/${finalVideo[0].name}`

            let type = finalVideo[0].type;
            const res = await storageService.getSignedUrlForUpload(path, type);

            if (res.status === 200) {

                return {
                    'signedUrl': res.data.signedUrl,
                    'inputPath': path
                }
            }

            throw 'Failed';
        } catch (err) {
            toast.error("Couldn't get signed url");
            throw err;
        } finally {
        }
    }

    return (
        <>
            <main class="page-content bg-light">
                <Header
                    title1="Create New How To"
                    title="Video Quick Tip"
                    main_title="Help Center"
                    title_link=""
                />

                <div class="container-fluid">
                    <div class="layout-specing">
                        <h4 class="mb-4">{videoQuickTipId ? 'Edit' : 'Add New'} How to...</h4>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card border-0 shadow rounded mb-4 ">
                                    <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-body p-4">
                                                <form id="add-news" onSubmit={handleOnSubmit}>
                                                    {!videoQuickTipId && (<div class="mb-4">
                                                        <label class="cstm-label">Upload Quick Tip Video</label><br />

                                                        <div className='cstm-video-image-upload'>
                                                            <label for="o-video" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                                                        </div>
                                                    </div>)
                                                    }

                                                    <div class="mb-4">
                                                        <label class="cstm-label">Video Title</label>
                                                        <input
                                                            name="video_title"
                                                            id="char-name"
                                                            value={formFields.video_title}
                                                            type="text"
                                                            class="cstm-input"
                                                            placeholder="Enter Video Title"
                                                            onChange={handleOnchange}
                                                        />
                                                        {err.video_title !== "" && (
                                                            <span style={{ color: "red" }}>
                                                                {err.video_title}
                                                            </span>
                                                        )}
                                                    </div>

                                                    <input
                                                        accept="video/*"
                                                        type="file"
                                                        name="video"
                                                        id="o-video"
                                                        className='cstm-uploadHide'
                                                        onChange={handleVideoChange}
                                                    />

                                                    {videoQuickTipId && (

                                                        <div className='cstm-video-image-upload'>
                                                            <label for="o-video" className='cstm-uploadbtn cstm-btn'>Change Video</label>
                                                        </div>
                                                    )
                                                    }

                                                    {blobVideo && finalVideo[0]?.type && submitStatus && (
                                                        <div className="progress mb-4 row">
                                                            <div
                                                                className="progress-bar progress-bar-info progress-bar-striped"
                                                                role="progressbar"
                                                                aria-valuenow={progress}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                                style={{ width: progress + "%" }}
                                                            >
                                                                {progress}%
                                                            </div>
                                                        </div>
                                                    )}

                                                    {blobVideo && finalVideo[0]?.type && (
                                                        <video width="750" height="500" controls >
                                                            <source src={blobVideo} type={finalVideo[0]?.type} />
                                                        </video>)}

                                                    {videoUrl && videoUrl.encoded480p && (
                                                        <video width="750" height="500" controls >
                                                            <source src={videoUrl.encoded480p} />
                                                        </video>)}

                                                    <div className="submit-btnMain cstm-loader">
                                                        <button
                                                            type="submit"
                                                            className="btn-submit-login-form cstm-submitbutton"
                                                        >
                                                            {" "}
                                                            Save
                                                        </button>
                                                        <div
                                                            id="login-loader"
                                                            className="loader-main"
                                                            style={{ display: "none" }}
                                                        >
                                                            <img src={loader} />
                                                        </div>
                                                    </div>

                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}