import { AppPlatform } from "../app.enum";

export class GiftUtils {
  /**
   * @param {import('../../typedefs').Gift} gift
   * @returns {{ firstName: string, email: string, phoneNumber: string }}
   */
  static getSenderDetails(gift) {
    const senderDetails = { firstName: "", email: "", phoneNumber: "" };

    if (gift?.platform === AppPlatform.Mobile) {
      senderDetails.firstName = gift?.createdBy?.firstName;
      senderDetails.email = gift?.createdBy?.email;
      senderDetails.phoneNumber = gift?.createdBy?.phoneNumber;
    } else if (gift?.platform === AppPlatform.Web) {
      senderDetails.firstName = gift?.createdUserData?.firstName;
      senderDetails.email = gift?.createdUserData?.email;
      senderDetails.phoneNumber = gift?.createdUserData?.phoneNumber;
    }

    return senderDetails;
  }

  /**
   * @param {import('../../typedefs').Gift} gift
   * @returns {{ reply: string, isAutoReply: boolean }}
   */
  static getReplyDetails(gift) {
    let reply = "";
    let isAutoReply = false;

    if (gift?.replies?.[0]?.message) {
      reply = gift?.replies?.[0]?.message;
    } else if (gift?.autoReply?.[0]?.replyMessage) {
      reply = gift?.autoReply?.[0]?.replyMessage;
      isAutoReply = true;
    }

    return {
      reply: reply,
      isAutoReply: isAutoReply,
    };
  }
}
