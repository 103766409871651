import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/query-keys";
import thankYouService from "../Services/thank-you.service";

/**
 * @param {string} userId
 */
export function useGetThankyousByUserId(userId, params = {}) {
  const { data: thankYousRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.THANKYOUS, { userId: userId, ...params }],
    queryFn: () =>
      thankYouService.getThankyous({
        userIds: [userId],
        ...params,
      }),
    enabled: !!userId,
    staleTime: Infinity,
  });

  return {
    data: thankYousRes?.data,
    isLoading: isLoading,
  };
}

/**
 * @param {string} thankYouId
 */
export function useGetThankYouById(thankYouId) {
  const { data: thankYouRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.THANKYOU_BY_ID, thankYouId],
    queryFn: () => thankYouService.getThankYouById(thankYouId),
    enabled: !!thankYouId,
    staleTime: Infinity,
  });

  return {
    data: thankYouRes?.data,
    isLoading: isLoading,
  };
}
