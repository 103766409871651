export function highlightThankyouMessageMatchInRed(message, contactDetails, failedReason) {
    for (let i = 0; i < failedReason.length; i++) {
        let item = failedReason[i];
        if (item.field == 'message') {
            message = checkInMessageReasons(item.reasons, message);
        }
        if (item.field == 'message') {
            contactDetails.firstName = checkInMessageReasons(item.reasons, contactDetails.firstName, false);
        }
        if (item.field == 'contact') {
            contactDetails.email = checkInContactReasons(item.reasons, contactDetails.email);
        }
    }

    return {
        message: message,
        contact: contactDetails
    }

}

function checkInMessageReasons(reasons, message, isSentimentValid = true) {
    reasons.forEach(reason => {
        reason.matches.forEach(match => {
            const regex = new RegExp(`(\\.${match})`, 'gi');
            message = message.replace(regex, `<span style='color: red;'>$1</span>`);
        });

        if (reason.matches.length == 0 && reason.reason == 'sentimental' && isSentimentValid) {
            message = message.replace(message, `<span style='color: red;'>${message}</span>`);
        }
    });

    return message;
}

function checkInContactReasons(reasons, email) {
    reasons.forEach(reason => {
        reason.matches.forEach(match => {
            const regex = new RegExp(`(\\.${match})`, 'gi');
            email = email.replace(regex, `<span style='color: red;'>$1</span>`);
        });
    });

    return email;
}


export function highlightUnregisteredUserThankyouMessageMatchInRed(message, contactDetails, failedReason, toStaffName, toStaffLastName) {
    for (let i = 0; i < failedReason.length; i++) {
        let item = failedReason[i];
        if (item.field == 'message') {
            message = checkInMessageReasons(item.reasons, message);
        }
        if (item.field == 'message' && contactDetails.firstName) {
            contactDetails.firstName = checkInMessageReasons(item.reasons, contactDetails.firstName, false);
        }
        if (item.field == 'message') {
            toStaffName = checkInMessageReasons(item.reasons, toStaffName, false);
        }
        if (item.field == 'message' && toStaffLastName) {
            toStaffLastName = checkInMessageReasons(item.reasons, toStaffLastName, false);
        }
        if (item.field == 'contact') {
            contactDetails.email = checkInContactReasons(item.reasons, contactDetails.email);
        }
    }

    return {
        message: message,
        contact: contactDetails,
        toStaffName: toStaffName,
        toStaffLastName: toStaffLastName
    }

}
