import React, { useState, useEffect } from 'react';
import BootStrap from 'bootstrap';
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import SidebarLayout from './Components/NavMenuBar/SidebarLayout';
import Gift from './Components/Gift/Index';
import AddGift from './Components/Gift/AddGift';
import EditGift from './Components/Gift/EditGift';
import Profile from './Components/Dashboard/Profile';
import AccountSetting from './Components/Dashboard/Account-setting';
import UnRegisteredOrganization from './Components/UnregisteredOrganization/index';
import RequestedOrganinzation from './Components/Organization/requested-organinzation';
import Charity from './Components/Charity/Index';
import AddCharity from './Components/Charity/add-charity';
import EditCharity from './Components/Charity/edit-charity';
import CharityCategories from './Components/Charity/charity-categories';
import RequestedCharity from './Components/Charity/requested-charity';
import ArchivedCharity from './Components/Charity/archived-charity';
import Event from './Components/Event/Index'
import EventCategories from './Components/Event/event-categories';
import RequestedCharityDetail from './Components/Charity/requested-charity-detail';
import LocationGreetings from './Components/Location/location-greetings';
import Login from './Components/Dashboard/Login';
import Quicktip from './Components/Announcements/QuickTip';
import QuicktipView from './Components/Announcements/ViewQuickTip';
import TermsCondition from './Components/TermsCondition/Index';
import ArchivedEvent from './Components/Event/archived-event';
import NewsAndStories from './Components/News and Stories/index';
import EditNews from './Components/News and Stories/edit-news';
import ViewNews from './Components/News and Stories/view-news'
import AddNews from './Components/News and Stories/add-news';
import Faq from './Components/Faq/Index';
import ViewCharity from './Components/Charity/view-charity';
import UnRegisteredOrganizationUsers from './Components/UnregisteredOrganization/unregistered-user';
import BlockList from './Components/Community/block-list';
import AcceptablePolicy from './Components/AcceptablePolicy/Index';
import PrivacyPolicy from './Components/PrivacyPolicy/Index';
import PrivacySetting from './Components/PrivacySetting/Index';
import AddEvent from './Components/Event/add-event';
import EditEvent from './Components/Event/edit-event';
import ViewEvent from './Components/Event/view-event';
import GiftPartner from './Components/GiftPartner/Index';
import AddGiftPartner from './Components/GiftPartner/add-giftpartner';
import EditGiftPartner from './Components/GiftPartner/edit-giftpartner';
import ViewGiftPartner from './Components/GiftPartner/view-giftpartner';
import UserChangePassword from './Components/User/ResetPassword/Index';
import AdminSettings from './Components/AdminSettings/Index'
import TicketsSupport from './Components/TicketsSupport/Index';
import AddAdminUser from './Components/AdminSettings/add-admin-user';
import EditAdminUser from './Components/AdminSettings/edit-admin-user';
import { EditUser } from './Components/Admin/User/EditUser';
import { CreateUser } from './Components/Admin/User/CreateUser';
import { ViewUser } from './Components/User/ViewUser';
import Orders from './Components/Orders';
import AppQRCode from './Components/AppQRCode';
import { UserList } from './Components/Admin/User/UserList';
import { ThankYouList } from './Components/ThankYou/ThankYouList';
import { GiftList } from './Components/Gift-List/GiftList';
import { OrganizationList } from './Components/Organization/OrganizationList';
import { Dashboard } from './Components/Dashboard/Dashboard';
import { PartiallyRegisteredUserList } from './Components/User/PartiallyRegisteredUserList';
import { EditPartiallyRegisteredUser } from './Components/User/EditPartiallyRegisteredUser';
import { UserSignupRequestList } from './Components/User/UserSignupRequestList';
import { PendingEmailVerificationUserList } from './Components/User/PendingEmailVerificationUserList';
import { LOCAL_STORAGE_KEYS } from './common/constants/local-storage.constant';
import { AddOrganization } from './Components/Organization/AddOrganization';
import { EditOrganization } from './Components/Organization/EditOrganization';
import { ViewGiftDetails } from './Components/Gift/ViewGiftDetails';
import { FullScreenLoader } from './shared/components/Loader/FullScreenLoader';
import { DraftThankYouList } from './Components/Moderation/DraftThankyou/DraftThankYouList';
import { ModerationFailedThankYouList } from './Components/Moderation/ModerationFailedThankYouList/ModerationFailedThankYouList';
import { ModerationFailedGiftList } from './Components/Moderation/ModerationFailedGiftList/ModerationFailedGiftList';
import { AddNewVideoQuickTip } from './Components/HelpCenter/VideoQuickTip/AddNewVideoQuickTip';
import { VideoQuickTipsList } from './Components/HelpCenter/VideoQuickTip/VideoQuickTipsList';
import { SendThankyouToUser } from './Components/SendThankyouToUser/SendThankyouToUser';
import { TeamJoinRequestList } from './Components/Organization/TeamJoinRequestList';
import { WelcomeEmailSetting } from './Components/AdminSettings/EmailTemplateSettings/WelcomeEmailSetting';
import { OrganizationTeamList } from './Components/Organization/OrganizationTeamList';
import { AddTeamToOrganization } from './Components/Organization/AddTeamToOrganization';
import { ReportedMessagesList } from './Components/Moderation/ReportedMessages/ReportedMessagesList';
import { EditTeam } from './Components/Team/EditTeam';
import { SendSmsToUsers } from './Components/Admin/User/SendSmsToUsers';
import { CurrencySetting } from './Components/CurrencySettings/CurrencySettings';
import { EditCurrency } from './Components/CurrencySettings/EditCurrency';
import { ViewOrganization } from './Components/Organization/ViewOrganization';
import { OrganizationTeamNotification } from "./Components/Organization/OrganizationTeamsNotification";
import { NonUserThankYouList } from "./Components/NonUserThankyou/NonUserThankyouList/NonUserThankyouList";
import { NonUserModerationFailedThankYouList } from "./Components/NonUserThankyou/NonUserModerationFailedThankyou/NonUserModerationFailedThankyouList";
import { NonUserNoContactThankYouList } from "./Components/NonUserThankyou/NoContactThankyouList/NoContactThankyouList";
import { UnregisteredUserBlacklistWordsSettings } from "./Components/AdminSettings/ModerationSettings/UnregisteredUserBlackListWordsSettings";
import { CrowdFundingSettings } from "./Components/AdminSettings/CrowFundSettings/CrowdFundingSettings"; 
import { AddEditCrowdFundSetting } from "./Components/AdminSettings/CrowFundSettings/AddEditCrowdFundSettings";
import { OrganizationGroupList } from "./Components/OrganizationGroup/OrganizationGroupList/OrganizationGroupList";
import { AddEditOrganizationGroup } from "./Components/OrganizationGroup/AddEditOrganizationGroup/AddEditOrganizationGroup";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  var loggedInUser = false;

  useEffect(() => {
    loggedInUser = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    if (loggedInUser) {
      setisLoggedIn(true);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="page-wrapper doctris-theme toggled">
        <Routes>
          <Route exact path="user-resetpassword" element={<UserChangePassword />} />
          <Route exact path='/' element={loggedInUser ? <Navigate to="/dashboard" /> : <Login />} />

          <Route element={<SidebarLayout />}>
            <Route exact path='dashboard' element={<Dashboard />} />

            <Route exact path='user' element={<UserList />} />
            <Route exact path='user-thankyou-share' element={<SendThankyouToUser />} />
            <Route exact path='create-user' element={<CreateUser />} />
            <Route exact path='edit-user' element={<EditUser />} />
            <Route exact path='view-user/:userId' element={<ViewUser />} />
            <Route exact path='partially-registered-users' element={<PartiallyRegisteredUserList />} />
            <Route exact path='edit-partially-registered-user' element={<EditPartiallyRegisteredUser />} />
            <Route exact path='user-signup-request-list' element={<UserSignupRequestList />} />
            <Route exact path='pending-email-verification-users' element={<PendingEmailVerificationUserList />} />
            <Route exact path='send-sms-user' element={<SendSmsToUsers />} />

            <Route exact path='thank-you-list' element={<ThankYouList />} />
            <Route exact path='gift-list' element={<GiftList />} />
            <Route exact path='view-gift-details' element={<ViewGiftDetails />} />
            <Route exact path='profile' element={<Profile />} />
            <Route exact path='account-setting' element={<AccountSetting />} />
            <Route exact path='organization' element={<OrganizationList />} />
            <Route exact path='unregistered-organization' element={<UnRegisteredOrganization />} />
            <Route exact path='team-join-request' element={<TeamJoinRequestList />} />
            <Route exact path='add-organization' element={<AddOrganization />} />
            <Route exact path='requested-organization' element={<RequestedOrganinzation />} />
            <Route exact path='charity' element={<Charity />} />
            <Route exact path='add-charity' element={<AddCharity />} />
            <Route exact path='edit-charity' element={<EditCharity />} />

            <Route exact path='charity-categories' element={<CharityCategories />} />
            <Route exact path='requested-charity' element={<RequestedCharity />} />
            <Route exact path='archived-charity' element={<ArchivedCharity />} />
            <Route exact path='requested-charity-detail' element={<RequestedCharityDetail />} />
            <Route exact path='event' element={<Event />} />
            <Route exact path='add-event' element={<AddEvent />} />
            <Route exact path='edit-event' element={<EditEvent />} />
            <Route exact path='view-event' element={<ViewEvent />} />

            <Route exact path='event-categories' element={<EventCategories />} />
            <Route exact path='gift' element={<Gift />} />
            <Route exact path='add-gift' element={<AddGift />} />
            <Route exact path='edit-gift' element={<EditGift />} />

            <Route exact path='gift-partner' element={<GiftPartner />} />
            <Route exact path='add-giftpartner' element={<AddGiftPartner />} />
            <Route exact path='edit-giftpartner' element={<EditGiftPartner />} />
            <Route exact path='view-giftpartner' element={<ViewGiftPartner />} />

            <Route exact path='organization/:organizationId/teams' element={<OrganizationTeamList />} />
            <Route exact path='organization/:organizationId/teams/notification' element={<OrganizationTeamNotification />} />
            <Route exact path='organization/:organizationId/teams/add' element={<AddTeamToOrganization />} />
            <Route exact path='teams/:teamId/edit' element={<EditTeam />} />
            <Route exact path='archived-event' element={<ArchivedEvent />} />

            <Route exact path='news-stories' element={<NewsAndStories />} />
            <Route exact path='add-news' element={<AddNews />} />
            <Route exact path='edit-news' element={<EditNews />} />
            <Route exact path='view-news' element={<ViewNews />} />

            <Route exact path="location-greetings" element={<LocationGreetings />} />
            <Route exact path='quicktip' element={<Quicktip />} />
            <Route exact path='view-quicktip' element={<QuicktipView />} />
            <Route exact path='terms-of-service' element={<TermsCondition />} />
            <Route exact path='faq' element={<Faq />} />
            <Route exact path='admin-settings' element={<AdminSettings />} />
            <Route exact path='unregistered-user-blacklist-word-admin-settings' element={<UnregisteredUserBlacklistWordsSettings />} />
            <Route exact path='tickets-support' element={<TicketsSupport />} />
            <Route exact path='view-charity' element={<ViewCharity />} />
            <Route exact path='view-organization/:organizationId' element={<ViewOrganization />} />
            <Route exact path='edit-organization' element={<EditOrganization />} />
            <Route exact path='unregistered-organization/users' element={<UnRegisteredOrganizationUsers />} />

            <Route exact path='add-adminuser' element={<AddAdminUser />} />
            <Route exact path='edit-adminuser' element={<EditAdminUser />} />
            <Route exact path='block-list' element={<BlockList />} />

            <Route exact path='acceptable-policy' element={<AcceptablePolicy />} />
            <Route exact path='privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='privacy-setting' element={<PrivacySetting />} />

            <Route exact path='currency-settings' element={<CurrencySetting />} />
            <Route exact path='currency-details' element={<EditCurrency />} />

            <Route exact path='orders' element={<Orders />} />
            <Route exact path='no-contact-thankyou-list' element={<DraftThankYouList />} />
            <Route exact path='moderation-failed-thankyou-list' element={<ModerationFailedThankYouList />} />
            <Route exact path='moderation-failed-gift-list' element={<ModerationFailedGiftList />} />
            <Route exact path='reported-messages' element={<ReportedMessagesList />} />
            <Route exact path='app-qr-code' element={<AppQRCode />} />

            <Route exact path='add-how-to' element={<AddNewVideoQuickTip />} />
            <Route exact path='how-to-list' element={<VideoQuickTipsList />} />
            <Route exact path='edit-how-to' element={<AddNewVideoQuickTip />} />

            <Route exact path='non-user-thankyou-list' element={<NonUserThankYouList />} />
            <Route exact path='non-user-moderation-failed-thankyou-list' element={<NonUserModerationFailedThankYouList />} />
            <Route exact path='non-user-no-contact-thankyou-list' element={<NonUserNoContactThankYouList />} />

            <Route exact path='welcome-email-settings' element={<WelcomeEmailSetting />} />
            <Route exact path='crowd-fund-settings' element={<CrowdFundingSettings />} />
            <Route exact path='add-crowd-fund-settings' element={<AddEditCrowdFundSetting />} />
            <Route exact path='edit-crowd-fund-settings/:id' element={<AddEditCrowdFundSetting />} />

            {/* Organization group */}
            <Route exact path='organization-group-list' element={<OrganizationGroupList />} />
            <Route exact path='add-organization-group' element={<AddEditOrganizationGroup />} />

            <Route path="*" element={<>404 Not Found</>} />
          </Route>
        </Routes>

        <ToastContainer
          autoClose={3000}
        />

        <FullScreenLoader />
      </div>
    </QueryClientProvider>
  );
}

export default App;
