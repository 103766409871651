import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import Services from '../../Services/auth.service';
import { useLoaderStore } from '../../stores/loader.store';
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';

export function TeamJoinRequestList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [teamJoinRequests, setTeamJoinRequests] = useState([]);
    const [selectedTeamJoinRequest, setSelectedTeamJoinRequest] = useState(null);

    useEffect(() => {
        getTeamJoinRequests();
    }, []);

    async function getTeamJoinRequests() {
        setShowLoader(true);

        try {
            const res = await Services.getTeamJoinRequests({});

            if (res.data.status === 200) {
                setTeamJoinRequests(res.data.data.teams_data);
                return;
            }

            toast.error("Couldn't get team join requests");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get team join requests");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleApprove() {
        setShowLoader(true);

        try {
            const res = await Services.addUserToTeam({
                teamId: selectedTeamJoinRequest.teamId._id,
                memberId: selectedTeamJoinRequest.memberId._id,
                status: 1,
            });

            if (res.data.status === 200) {
                toast.success("Successfully approved team join request");
                const updatedList = teamJoinRequests.filter((teamJoinRequest) => teamJoinRequest._id !== selectedTeamJoinRequest._id);
                setTeamJoinRequests(updatedList);
                return;
            }

            toast.error("Couldn't approve team join request");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't approve team join request");
        } finally {
            setShowLoader(false);
            setSelectedTeamJoinRequest(null);
        }
    }

    function onCancel() {
        setSelectedTeamJoinRequest(null);
    }

    return (
        <>
            <main className="page-content bg-light">
                <Header title="Pending team requests" main_title="Pending Team Members Requests" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className="p-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0">Team joining requests</h4>
                                </div>
                            </div>

                            <div className="overflow-auto">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Organization name</th>
                                            <th className="border-bottom w-4 cstm-userheading">Team Name</th>
                                            <th className="border-bottom w-4 cstm-userheading">Name</th>
                                            <th className="border-bottom w-4 cstm-userheading">Email</th>
                                            <th className="border-bottom w-4 cstm-userheading">Country code</th>
                                            <th className="border-bottom w-4 cstm-userheading">Phone Number</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {teamJoinRequests?.map((teamJoinRequest) => (
                                            <tr
                                                key={teamJoinRequest._id}
                                                className="cstm-Tabledesign cstm-usertable-design"
                                            >
                                                <td>{teamJoinRequest.organizationId?.name}</td>
                                                <td>{teamJoinRequest.teamId?.name}</td>
                                                <td>{teamJoinRequest.memberId?.firstName}</td>
                                                <td>{teamJoinRequest.memberId?.email}</td>
                                                <td>{teamJoinRequest.memberId?.countryCode}</td>
                                                <td>{teamJoinRequest.memberId?.phoneNumber}</td>
                                                <td>
                                                    <div>
                                                        <Link
                                                            to={""}
                                                            onClick={() => setSelectedTeamJoinRequest(teamJoinRequest)}
                                                            className="cstm-delete mrn-rt"
                                                        >
                                                            <i className="fi fi-rr-check"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {!teamJoinRequests?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {selectedTeamJoinRequest && (
                <ConfirmationModal
                    text='Are you sure you want to add the user to this team?'
                    confirmBtnText='Add'
                    showImg={false}
                    onConfirm={handleApprove}
                    onCancel={onCancel}
                />
            )}
        </>
    );
}
