import { useState, useEffect } from "react";
import { DEFAULT_DEBOUNCE_TIME, DEFAULT_MIN_SEARCH_CHARACTERS } from "../constants/filter.constant";

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeout);
        }
    }, [value, delay]);

    return debouncedValue;
}

export function useDebounceSearchText(value = "", delay = DEFAULT_DEBOUNCE_TIME, minLength = DEFAULT_MIN_SEARCH_CHARACTERS) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (value.length === 0 || value.length >= minLength) {
                setDebouncedValue(value);
            }
        }, delay);

        return () => {
            clearTimeout(timeout);
        }
    }, [value, delay, minLength]);

    return debouncedValue;
}

export function useDebounceSearchTextFn(value = "", fn, delay = DEFAULT_DEBOUNCE_TIME, minLength = DEFAULT_MIN_SEARCH_CHARACTERS) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (value.length === 0 || value.length >= minLength) {
                fn(value);
            }
        }, delay);

        return () => {
            clearTimeout(timeout);
        }
    }, [value, delay, minLength]);
}
