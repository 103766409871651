export function formatDateForTable(date) {
    return new Date(date).toLocaleDateString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });
}

export function formatDateWithTime(date) {
    return new Date(date).toLocaleString();
}

/**
 * @param {Date} date
 */
export function getDateStrFromDateObj(date) {
    return date.toISOString().split('T')[0];
}
