export const APP_ROUTES = Object.freeze({
    USER_LIST: {
        route: '/user',
        breadcrumb: { label: 'User List' },
    },
    EDIT_USER: {
        route: '/edit-user',
        breadcrumb: { label: 'Edit User' },
    },
    VIEW_USER: {
        route: '/view-user/:userId',
        breadcrumb: { label: 'View User' },
    },
    ORGANIZATION_LIST: {
        route: '/organization',
        breadcrumb: { label: 'Organization List' },
    },
    ADD_ORGANIZATION: {
        route: '/add-organization',
        breadcrumb: { label: 'Add Organization' },
    },
    VIEW_ORGANIZATION: {
        route: '/view-organization/:organizationId',
        breadcrumb: { label: 'View Organization' },
    },
    EDIT_ORGANIZATION: {
        route: '/edit-organization',
        breadcrumb: { label: 'Edit Organization' },
    },
    THANK_YOU_LIST: {
        route: '/thank-you-list',
        breadcrumb: { label: 'Thank you List' },
    },
    GIFT_LIST: {
        route: '/gift-list',
        breadcrumb: { label: 'Gift List' },
    },
    NO_REPLY_DETAILS_LIST: {
        route: '/no-contact-thankyou-list',
        breadcrumb: { label: 'No Reply Details' },
    },
    TEAM_NOTIFICATION: {
        route: '/organization/:organizationId/teams/notification',
        breadcrumb: { label: 'Notifications' },
    },
});
