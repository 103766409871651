import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../../stores/loader.store';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import cloudUpload from "../../../assets/Images/cloud-upload.png";
import nonUserService from "../../../Services/non-user.service";

export function ReleaseModerationFailedThankyou({ moderationFailedThankyou, onReleaseSuccess }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [fromFirstname, setFromFirstname] = useState(moderationFailedThankyou.fromUserData.firstName);
    const [staffFirstname, setStaffFirstname] = useState(moderationFailedThankyou.toStaffName);
    const [staffLastname, setStaffLastname] = useState(moderationFailedThankyou.toStaffLastName);
    const [message, setMessage] = useState(moderationFailedThankyou.message);
    const [showConfirmation, setShowConfirmation] = useState(false);

    async function handleReleaseMessage() {
        setShowLoader(true);

        let data = {
            _id: moderationFailedThankyou._id,
            fromFirstName: fromFirstname,
            staffLastName: staffLastname,
            staffFirstName: staffFirstname,
            message: message
        };
        try {
            const res = await nonUserService.releaseModerationThankyou(data);
            if (res.status === 200) {
                toast.success("Successfully released moderation failed thank you");
                onReleaseSuccess();
                return;
            }

            toast.error("Couldn't release moderation failed thank you");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't release moderation failed thankyou");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <div style={{ minWidth: '600px', maxWidth: '1000px', maxHeight: '1000px', overflowY: "auto" }} className='p-2'>
            <div className="mb-2">
            <div className="mb-2">
                    <label className="cstm-label">To Firstname</label>
                    <input
                        type="text"
                        class="cstm-input"
                        value={staffFirstname}
                        onChange={(e) => setStaffFirstname(e.target.value)}
                    />
                </div>
                <div className="mb-2">
                    <label className="cstm-label">To Lastname</label>
                    <input
                        type="text"
                        class="cstm-input"
                        value={staffLastname}
                        onChange={(e) => setStaffLastname(e.target.value)}
                    />
                </div>
                <div className="mb-2">
                    <label className="cstm-label">From</label>
                    <input
                        type="text"
                        class="cstm-input"
                        value={fromFirstname}
                        onChange={(e) => setFromFirstname(e.target.value)}
                    />
                </div>
                <div className="mb-2">
                    <label className="cstm-label">Edit Message (Optional)</label>
                    <textarea
                        type="text"
                        className="cstm-input-moderation-field"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type='button' className='cstm-submitbutton' onClick={() => setShowConfirmation(true)}>Release</button>
            </div>

            {showConfirmation &&
                <ConfirmationModal
                    text='Are you sure you want to release this thank you message?'
                    confirmBtnText='Release'
                    imgSrc={cloudUpload}
                    onConfirm={handleReleaseMessage}
                    onCancel={() => setShowConfirmation(false)}
                />}
        </div>
    );
}