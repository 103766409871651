import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { SidebarLayoutHeader } from '../../shared/components/SidebarLayoutHeader';
import organizationService from '../../Services/organization.service';
import { FormFieldError } from '../../shared/components/FormFieldError';
import { useLoaderStore } from '../../stores/loader.store';
import { useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';
import { TEAM_CLASSIFICATIONS } from '../../common/data/team.data';

const addTeamSchema = yup.object().shape({
    name: yup.string().required("Team name is required"),
    classification: yup.string().required("Classification is required"),
    role: yup.string().required("Role is required"),
    teamStaffCount: yup.string(),
});

export function AddTeamToOrganization() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(addTeamSchema),
        defaultValues: {
            name: "",
            classification: "",
            role: "",
            teamStaffCount: "",
        },
    });

    useSetBreadcrumbs([
        { label: 'Organization Team List', path: `/organization/${organizationId}/teams` },
        { label: 'Add Team', path: '' },
    ]);

    function onSubmit(data) {
        try {
            const teamData = {
                organizationId: organizationId,
                name: data.name,
                classification: data.classification,
                teamStaffCount: data.teamStaffCount ? parseInt(data.teamStaffCount) : 0,
                role: data.role,
                image: "",
            };
            createTeam(teamData);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't create team");
        }
    }

    async function createTeam(team) {
        setShowLoader(true);
        try {
            const response = await organizationService.addTeamToOrganization(team);

            if (response.status === 200) {
                toast.success("Successfully created team");
                return navigate(`/organization/${organizationId}/teams`);
            }

            toast.error("Couldn't create team");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't create team");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title='Add Team to Organization' />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <h4 className="mb-4">Add Team</h4>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="card border-0 shadow rounded mb-4 p-4"
                        >
                            <div className="mb-4">
                                <label className="cstm-label">Team Name</label>
                                <input
                                    type="text"
                                    className="cstm-input"
                                    placeholder="Team Name"
                                    {...register("name")}
                                />
                                <FormFieldError message={errors.name?.message} />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Classification</label>
                                <select
                                    className="form-select cstm-category"
                                    {...register("classification")}
                                >
                                    <option value="" hidden disabled>Select Classification</option>
                                    {TEAM_CLASSIFICATIONS.map((classification) => (
                                        <option
                                            key={classification.value}
                                            value={classification.value}
                                            title={classification.description}
                                        >{classification.label}</option>
                                    ))}
                                </select>
                                <FormFieldError message={errors.classification?.message} />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Role</label>
                                <select
                                    className="form-select cstm-category"
                                    {...register("role")}
                                >
                                    <option value="" hidden disabled>Your Role</option>
                                    <option value="Team Lead">Team Lead</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Executive">Executive</option>
                                    <option value="Other">Other</option>
                                </select>
                                <FormFieldError message={errors.role?.message} />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Staff count</label>
                                <input
                                    type="number"
                                    className="cstm-input"
                                    placeholder="Staff count"
                                    {...register("teamStaffCount")}
                                />
                                <FormFieldError message={errors.teamStaffCount?.message} />
                            </div>

                            <div className="submit-btnMain cstm-loader">
                                <button type="submit" className="btn-submit-login-form cstm-submitbutton">Add Team</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </>
    );
}
