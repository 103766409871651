import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useLoaderStore } from '../../../stores/loader.store';
import { useDebounceSearchTextFn } from '../../../common/hooks/use-debounce.hook';
import { Pagination } from '../../shared/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import organizationGroupService from "../../../Services/organization-group.service";

const ORGANIZATION_GROUP_ACTION = Object.freeze({
    DELETE: "DELETE"
});

export function OrganizationGroupList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState('');
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getOrganizationGroupListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: 10 };
    const [organizationGroups, setOrganizationGroups] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        if (debouncedSearchText !== state.searchText) {
            setSearchParams(createOrganizationListSearchParams({}, debouncedSearchText, 1));
        }
    });

    useEffect(() => {
        getOrganizationGroupList();
    }, []);

    useEffect(() => {
        getOrganizationGroupList();
    }, [searchParams]);

    async function getOrganizationGroupList() {
        setShowLoader(true);
        try {

            const res = await organizationGroupService.getOrganizationGroups({
                search: state.searchText ? state.searchText.trim() : '',
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize
            });

            if (res.status == 200) {
                setOrganizationGroups(res.data.results);
                setResultsCount(res.data.count);
                return;
            }

            toast.error("Couldn't fetch organization group list");
        }
        catch (err) {
            toast.error("Couldn't fetch organization group list");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function handleDeleteOrganizationGroup() {
        setShowLoader(true);
        try {
            const deleteOrganizationGroup = selectedRow.organizationGroup;
            const res = await organizationGroupService.deleteOrganizationGroupById(deleteOrganizationGroup._id);

            if (res.status === 200) {
                toast.success("Successfully deleted organization group");
                setOrganizationGroups((organizationGroup) => organizationGroup.filter((t) => t._id !== deleteOrganizationGroup._id));
                return;
            }

            toast.error("Couldn't delete organization group");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete organization group");
        } finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createOrganizationListSearchParams({}, '', pageNumber));
    }

    return (
        <SidebarLayoutContent headerTitle='Non User Moderation Failed Thank you List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    <div className="col-md-9">
                        <div className="row row justify-content-end">
                            <div className="col-md-5">
                                <i className="fi fi-rr-search cstm-search-ro"></i>
                                <input
                                    name="serch-organization"
                                    id="serch-organization"
                                    type="text"
                                    className="cstm-input-seacrh"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder="Search organization"
                                />
                            </div>
                            <div className="col-all-auto">
                                <Link
                                    to="/add-organization-group"
                                >
                                    <button className="cstm-btn">Add Organization Group</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Organization Group Name</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {organizationGroups?.map((organizationGroup, index) => (
                                <tr
                                    key={organizationGroup._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{organizationGroup.groupName}</td>

                                    <td>
                                        <div>
                                            <Link
                                                to={"/add-organization-group/?id=" + organizationGroup._id}
                                                className="cstm-eye"
                                            >
                                                <i className="fi fi-rr-pencil"></i>
                                            </Link>

                                            <Link
                                                to={""}
                                                onClick={() => setSelectedRow({ organizationGroup: organizationGroup, action: ORGANIZATION_GROUP_ACTION.DELETE })}
                                                class="cstm-delete mrn-rt"
                                            >
                                                <i class="fi fi-rr-trash"></i>
                                            </Link>

                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    {!organizationGroups?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>

                <Pagination
                    totalRows={resultsCount}
                    currentPage={pagination.pageNumber}
                    rowsPerPage={pagination.pageSize}
                    onPaginationChange={onPaginationChange}
                />

                {(selectedRow && selectedRow.action === ORGANIZATION_GROUP_ACTION.DELETE) && (
                    <ConfirmationModal
                        text='Are you sure you want to delete this organization group?'
                        confirmBtnText='Delete'
                        type="delete"
                        onConfirm={handleDeleteOrganizationGroup}
                        onCancel={() => setSelectedRow(null)}
                    />
                )}
            </div>
        </SidebarLayoutContent>
    );
}

function createOrganizationListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState({}, searchText, pageNumber, [], []);
}

function getOrganizationGroupListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams, [], []);
}