import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from "../../shared/components/SidebarLayoutContent";
import Services from "../../Services/auth.service";
import deleteImg from "../../assets/Images/delete.png";
import loader from "../../assets/Images/loder.gif";
import { Pagination } from "../shared/Pagination";
import organizationService from "../../Services/organization.service";
import { DEFAULT_DEBOUNCE_TIME } from "../../common/constants/filter.constant";
import { useDebounce } from "../../common/hooks/use-debounce.hook";
import { APP_ROUTES } from "../../common/app-routes";
import { createBreadcrumb, useSetBreadcrumbs } from "../../shared/hooks/use-set-breadcrumbs.hook";

export function OrganizationList() {
    const location = useLocation();
    const [organizations, setOrganizations] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, DEFAULT_DEBOUNCE_TIME);
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [selectedDeleteOrganizationId, setSelectedDeleteOrganizationId] = useState(null);

    useSetBreadcrumbs([createBreadcrumb(APP_ROUTES.ORGANIZATION_LIST)]);

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getOrganizations(pageNumber, pagination.pageSize);
    }, [debouncedSearchText]);

    async function getOrganizations(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await organizationService.getOrganizations({
                search: debouncedSearchText,
                pageNumber: pageNumber,
                pageSize: pageSize,
            });

            if (res.status == 200) {
                setOrganizations(res.data.results);
                setResultsCount(res.data.count);
                return;
            }

            toast.error("Couldn't get organizations");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get organizations");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getOrganizations(pageNumber, pagination.pageSize);
    }

    async function handleDelete() {
        try {
            const response = await Services.deleteOrganization(selectedDeleteOrganizationId);

            if (response.status === 200) {
                toast.success("Successfully deleted organization");
                getOrganizations(pagination.pageNumber, pagination.pageSize);

                document.querySelector('.modal-backdrop').remove();
                document.querySelector('#cancelappointment').classList.remove('show');
                document.querySelector("#cancelappointment .btn-close").click();

                return;
            }

            toast.error("Couldn't delete organization");
        } catch (err) {
            toast.error("Couldn't delete organization");
        }
    }

    function onCancelDelete() {
        setSelectedDeleteOrganizationId(null);
        document.querySelector('#cancelappointment').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
    }

    return (
        <SidebarLayoutContent headerTitle="Organization List">
            <div className="card border-0 shadow rounded">
                <div className="row justify-content-between align-items-center p-3">
                    <div className="col-md-3">
                        <h4 className="mb-0">Organization List</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row row justify-content-end">
                            <div className="col-md-5">
                                <i className="fi fi-rr-search cstm-search-ro"></i>
                                <input
                                    name="serch-organization"
                                    id="serch-organization"
                                    type="text"
                                    className="cstm-input-seacrh"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder="Search organization"
                                />
                            </div>
                            <div className="col-all-auto">
                                <Link
                                    to="/add-organization"
                                >
                                    <button className="cstm-btn">Add Organization</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12">
                    <div className="table-responsive bg-white border-top rounded-0">
                        <table className="table mb-0 table-center cstm-cl-mn2">
                            <thead>
                                <tr>
                                    <th className="border-bottom">Organization Name</th>
                                    <th className="border-bottom">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {organizations?.map((organization) => (
                                    <tr
                                        key={organization._id}
                                        className="cstm-Tabledesign"
                                    >
                                        <td>{organization.name}</td>
                                        <td>
                                            <div>
                                                <Link
                                                    to={"/edit-organization/?id=" + organization._id}
                                                    className="cstm-eye"
                                                    state={{ breadcrumbPath: location.pathname + location.search }}
                                                >
                                                    <i className="fi fi-rr-pencil"></i>
                                                </Link>
                                                <Link
                                                    to={`/view-organization/${organization._id}`}
                                                    className="cstm-eye"
                                                >
                                                    <i className="fi fi-rr-eye"></i>
                                                </Link>
                                                <Link
                                                    to={" "}
                                                    onClick={() => setSelectedDeleteOrganizationId(organization._id)}
                                                    className="cstm-delete mrn-rt"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#cancelappointment"
                                                >
                                                    <i className="fi fi-rr-trash"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {!organizations?.length && (
                            <div className="NoRecord-cstm">No records found!</div>
                        )}

                        {showLoader && (
                            <div className="NoRecord-cstm">
                                <img className="pageloader" alt="" src={loader} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            <div
                className="modal fade"
                id="cancelappointment"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src={deleteImg} alt="" />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are sure you want to delete
                                    <br /> this organization?
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={onCancelDelete}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayoutContent>
    );
}
