import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { SidebarLayoutHeader } from '../../shared/components/SidebarLayoutHeader';
import teamService from "../../Services/team.service";
import Services from "../../Services/auth.service";
import { FormFieldError } from '../../shared/components/FormFieldError';
import { useLoaderStore } from '../../stores/loader.store';
import { useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';
import { ALLOWED_IMAGE_MIME_TYPES } from '../../common/constants/file.constant';
import organizationService from '../../Services/organization.service';
import { TEAM_CLASSIFICATIONS } from '../../common/data/team.data';

const editTeamSchema = yup.object().shape({
    name: yup.string().required("Team name is required"),
    classification: yup.string().required("Classification is required"),
    teamStaffCount: yup.string(),
});

export function EditTeam() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const { teamId } = useParams();
    const [searchParams] = useSearchParams();
    const organizationId = searchParams.get('organizationId');
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editTeamSchema),
        defaultValues: {
            name: "",
            teamStaffCount: "",
        },
    });
    const [imagePath, setImagePath] = useState();
    const [tempImage, setTempImage] = useState();
    const [organizationName, setOrganizationName] = useState('');

    useSetBreadcrumbs([
        { label: 'Organization Team List', path: `/organization/${organizationId}/teams` },
        { label: 'Edit Team', path: '' },
    ]);

    useEffect(() => {
        getTeamDetails();
        getOrganizationDetails();
    }, []);

    async function getTeamDetails() {
        try {
            const res = await teamService.getTeamById(teamId);

            if (res.status === 200) {
                const teamDetails = res.data;
                reset({
                    name: teamDetails.name,
                    classification: teamDetails.classification || "",
                    teamStaffCount: teamDetails.teamStaffCount ? String(teamDetails.teamStaffCount) : "",
                });
                setImagePath(teamDetails.image || null);
                return;
            }

            toast.error("Couldn't get team details");
        } catch (err) {
            toast.error("Couldn't get team details");
        }
    }

    async function getOrganizationDetails() {
        try {
            const res = await organizationService.getOrganizationById(organizationId);

            if (res.status === 200) {
                const organizationData = res.data;
                setOrganizationName(organizationData.name);
                return;
            }

            toast.error("Couldn't get organization details");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get organization details");
        }
    }

    function onSubmit(data) {
        const teamData = {
            name: data.name,
            classification: data.classification,
            teamStaffCount: data.teamStaffCount ? parseInt(data.teamStaffCount) : 0,
            image: imagePath,
        };
        updateTeam(teamData);
    }

    async function updateTeam(teamUpdateData) {
        setShowLoader(true);
        try {
            const res = await teamService.updateTeam(teamId, teamUpdateData);

            if (res.status == 200) {
                toast.success("Successfully updated team details");
                return;
            }

            toast.error("Couldn't update team details");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update team details");
        } finally {
            setShowLoader(false);
        }
    }

    function handleImageChange(e) {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            return;
        }

        setTempImage(URL.createObjectURL(file));

        uploadImage(file);
    }

    async function uploadImage(file) {
        try {
            const formData = new FormData();
            formData.append("image", file);

            const res = await Services.charimageGet(formData);

            if (res.status === 200) {
                setImagePath(res.data.data.images[0].location);
                return;
            }

            toast.error("Couldn't upload image");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't upload image");
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title="Edit Team" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <h4 className="mb-4">Edit Team</h4>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="card border-0 shadow rounded mb-4 p-4"
                        >
                            <div className="mb-4">
                                <label className="cstm-label">Organization</label>
                                <input
                                    type="text"
                                    className="cstm-input"
                                    value={organizationName}
                                    disabled
                                />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Image</label>
                                <br />
                                <div className="cstm-Videoalign">
                                    <label htmlFor="select-team-image" className="cstm-uploadbtn cstm-btn">Choose File</label>

                                    {tempImage && (<img src={tempImage} className="AddOrganization-bannerImg" alt="" />)}

                                    {(!tempImage && imagePath) && (
                                        <img
                                            src={`${process.env.REACT_APP_S3_BASE_URL}/${imagePath}`}
                                            className="AddOrganization-bannerImg"
                                            alt=""
                                        />
                                    )}

                                    <input
                                        id="select-team-image"
                                        type="file"
                                        accept="image/*"
                                        className="cstm-uploadHide"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Team Name</label>
                                <input
                                    type="text"
                                    className="cstm-input"
                                    placeholder="Team Name"
                                    {...register("name")}
                                />
                                <FormFieldError message={errors.name?.message} />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Classification</label>
                                <select
                                    className="form-select cstm-category"
                                    {...register("classification")}
                                >
                                    <option value="" hidden disabled>Select Classification</option>
                                    {TEAM_CLASSIFICATIONS.map((classification) => (
                                        <option
                                            key={classification.value}
                                            value={classification.value}
                                            title={classification.description}
                                        >{classification.label}</option>
                                    ))}
                                </select>
                                <FormFieldError message={errors.classification?.message} />
                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Staff count</label>
                                <input
                                    type="number"
                                    className="cstm-input"
                                    placeholder="Staff count"
                                    {...register("teamStaffCount")}
                                />
                                <FormFieldError message={errors.teamStaffCount?.message} />
                            </div>

                            <div className="submit-btnMain cstm-loader">
                                <button type="submit" className="btn-submit-login-form cstm-submitbutton">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </>
    );
}
