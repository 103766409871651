import React, { useEffect, useState } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import loader from "../../assets/Images/loder.gif";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const EditGiftPartner = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const cahrID = new URLSearchParams(getid).get("id");
  const [selectStatus, setSelectStatus] = useState(false);

  const [editcharityVal, setEditCharityVal] = useState({

    title: "",
    banner: "",
    image_video: [],
    category: "",
    categoryId: "",

    webAddress: "",
    mediaLink: "",
    location: "",
    role: "",
    role_other: "",
    organisationEmail: "",
    charity:"",
    charityId:""
  });
  const [errEditCharityVal, setEditErrCharityVal] = useState({
    title: "",
    banner: "",
    image_video: "",
    category: "",
    mediaLink: "",
    webAddress: "",
    location: "",
    location: "",
    role: "",
    role_other: "",
    organisationEmail: ""
  });


  var imgExtArray = [];
  var imgArray = [];
  var bannimg = "";
  var imgExtArray = [];
  var videoExtArray = [];
  var videoArray = [];
  const DataArrayCat = [];

  var pageno = 1;
  var limit = 20;
  var CharityDataArray = [];
  const arrayImages = [];
  const [giftPartnerCategory, setGiftPartnerCategory] = useState([]);
  const [showGiftPartnerCategoryIds, setShowGiftPartnerCategoryIds] = useState("");

  const [giftCharity, setCharityCategory] = useState([]);
  const [showCharityIds, setShowCharityIds] = useState("");


  // const [showCategoryIds, setShowCategoryIds] = useState("");
  // const [charCategory, setCharCategory] = useState([]);
  const [videoPrev, setVideoPrev] = useState([]);
  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [arrayOfGetData, setArrayOfGetData] = useState([]);
  const [getBannerImg, setGetBannerImg] = useState("");
  const [getMultiFile, setMultiFile] = useState([]);
  const [otherRole, setOtherRole] = useState();
  const [showLatitude, setLatitude] = useState('');
  const [showLongitude, setLongitude] = useState('');


  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    Services.getGiftPartnerById(cahrID)
      .then((response) => {
        if (response.data.status === 200) {
          var arrayImages = response.data.data.image_video;
          arrayImages.map(multifile => {
            var filesplit = multifile.split('.').pop();
            var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
            var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
            imageExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'image' });
            videoExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'video' });
          })

          setEditCharityVal({

            title: response.data.data.title,
            banner: response.data.data.banner ? response.data.data.banner : '',
            image_video: arrayImages,
            webURL: response.data.data.mediaLink,
            location: response.data.data.location,
            categoryId: response.data.data.categoryId,
            webAddress: response.data.data.webAddress,
            organisationEmail: response.data.data.organisationEmail,
            role: response.data.data.role,
            charityId: response.data.data.charityId,
          });

          setLatitude(response.data.data.latitude);
          setLongitude(response.data.data.longitude);

          if(response.data.data.role !== "principle" && response.data.data.role !== "manager"){
            setSelectStatus(true)
            setOtherRole(response.data.data.role);
            
          }else{
            setSelectStatus(false)
          }

          setVideoPrev([...videoPrev, ...videoExtArray]);
          setMultiFile([...arrayImages]);
          // setShowCategoryIds(response.data.data.categoryId);
          setShowCharityIds(response.data.data.charityId);
          setShowGiftPartnerCategoryIds(response.data.data.categoryId);
          setGetBannerImg(response.data.data.banner);
          // getCatData(1);
          // getCharityData(pageno,limit);

        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
    getCatData(1);
    getCharityData(pageno,limit)
  }, []);


  //   get category for add gift partner
  const getCatData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getGift(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.gift_card_data.length !== 0) {
            var data = response.data.data.gift_card_data;
            data.map((data1) => {
              DataArrayCat.push(data1);
            });
          }
          setGiftPartnerCategory(DataArrayCat);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };

  const getCharityData = (pageno, limit) => {
    const queryVar = {
      page: pageno,
      limit: limit,
      isDelete: false,
      isCharityVerified: true,
    };

    Services.getCharity(queryVar)
      .then((response) => {
        console.log(response, "response chariy")
        // return false;
        if (response.data.status === 200) {
          if (response.data.data.charity_count.length !== 0) {
            var data = response.data.data.charity_count;
            data.map((data1) => {
              CharityDataArray.push(data1);

            });
            if (response.data.data.state) {
              pageno = response.data.data.state.page + 1;
              limit = response.data.data.state.data_count
            }
          }
          setCharityCategory(CharityDataArray);
        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  const handleOnchangeRole = (e) => {
   
    if (e.target.value === "") {
      errEditCharityVal.role_other = "Enter your role"
    } else {
      errEditCharityVal.role_other = ""
    }
    setEditErrCharityVal({ ...errEditCharityVal })
    setEditCharityVal({ ...editcharityVal, role: e.target.value })
  }


  const handleOnchangeUpdateCharity =(e)=>{
    console.log(e.target.value,"charity")
    setEditCharityVal({...editcharityVal,['charity']:e.target.value})
    setShowCharityIds(editcharityVal.charityId = e.target.value);
  }
  const handleOnchangeUpdateRole =(e)=>{

      errEditCharityVal.role = e.target.value.length > 0 ? "" : "Enter role";
      if (e.target.value === "other") {
        setSelectStatus(true);
      } else {
        setSelectStatus(false);
      }

    setEditCharityVal({...editcharityVal,['role']:e.target.value})
    setEditErrCharityVal({ ...errEditCharityVal });
    
    // setShowGiftPartnerCategoryIds(editcharityVal.charityId = e.target.value);
  }

  // console.log(editcharityVal, "editcharityVal");
  const handleOnchangeUpdate = (e) => {
    const { value, name } = e.target;
    console.log(e.target.value !== "", "target")

    if (e.target.name == "title") {
      errEditCharityVal.title = value.length > 0 ? "" : "Enter charity name";
    }
    if (e.target.name == "category") {
      errEditCharityVal.category = value.length > 0 ? "" : "Enter category";
    }
    


    if (e.target.name == "webAddress") {
      errEditCharityVal.webAddress =
        value.length > 0 ? "" : "Enter your website address";
      errEditCharityVal.webAddress = !new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      ).test(value)
        ? "Enter a valid website url"
        : "";
    }
    if (e.target.name == "mediaLink") {
      errEditCharityVal.mediaLink =
        value.length > 0 && !new RegExp(
          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
        ).test(value)
          ? "Enter a valid media link"
          : "";
    }

    if (e.target.name == "about") {
      errEditCharityVal.about = value.length > 0 ? "" : "enter about";
    }
    setEditCharityVal({ ...editcharityVal, [name]: value });
    setEditErrCharityVal({ ...errEditCharityVal });
    setShowGiftPartnerCategoryIds(editcharityVal.categoryId = e.target.value);
  };
  //image onchange

  const [file, setFile] = useState();
  const [bannerImg, setBannerImg] = useState("")
  const handleImageChangeBanner = (e) => {
    e.preventDefault();
    errEditCharityVal.banner = e.target.value.length > 0 ? "" : "Select banner image";

    setFile(URL.createObjectURL(e.target.files[0]));
    var charImage = e.target.files[0];
    setBannerImg(charImage);
    console.log(charImage, "charImage");
    setEditCharityVal({ ...editcharityVal, banner: '' })

  }

  function deleteImagesGet(e) {

    const vp = videoPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setVideoPrev([...vp]);

  }

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs])
    setImagesPrev([...s]);

  }

  const handleImageChangeorg = (e) => {
    e.preventDefault();
    errEditCharityVal.image_video = e.target.value.length > 0 ? "" : "Select image or video";


    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];

    })
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    imgArray.forEach(function (f, index) {

      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split('.').pop();
      var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
      var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
      imageExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'image' });
      videoExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'video' });

    })

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);

  };

  const handleChange = address => {
    setEditCharityVal({ ...editcharityVal, ['location']: address });
  };

  const handleSelect = address => {
    setEditCharityVal({ ...editcharityVal, ['location']: address });

    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      console.log('Success', latLng)
      setLatitude(latLng.lat)
      setLongitude(latLng.lng)
    }  
      )
    .catch(error => console.error('Error', error));

  };

  const handleOnSubmitUpdate = async (e) => {
    e.preventDefault();

    if (editcharityVal.title === "") {
      errEditCharityVal.title = "Enter charity name";
    } else {
      errEditCharityVal.title = "";
    }
    if (editcharityVal.categoryId === "") {
      errEditCharityVal.category = "Enter category";
    } else {
      errEditCharityVal.category = "";
    }
    if (editcharityVal.webAddress === "") {
      errEditCharityVal.webAddress = "Enter your website address";
    }
    else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(editcharityVal.webAddress)) {

      errEditCharityVal.webAddress = "Enter a valid website url";
    }
    else {
      errEditCharityVal.webAddress = "";
    }
    setEditErrCharityVal({ ...errEditCharityVal });
    setEditCharityVal({ ...editcharityVal });


    if (videoPrev) {

      videoPrev.map(data => {
        videoArray.push(data.url);
      })
      setArrayOfGetData(videoArray)
    }

    if (
      errEditCharityVal.title === "" &&
      errEditCharityVal.banner === "" &&
      errEditCharityVal.image_video === "" &&
      errEditCharityVal.category === "" &&
      // errEditCharityVal.registrationNumber === "" &&
      errEditCharityVal.webAddress === ""
    ) {
      $(".loader-main").show();

      if (bannerImg !== "")
        await UploadBannerImage()

      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {

            if (response.status === 200) {
              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              var finalImagesData = [];
              finalImagesData = [...arrayImages, ...videoArray]

              // EditCharityData(finalImagesData, getBannerImg);

              if (bannimg) {
                EditCharityData(finalImagesData, bannimg);

              } else {
                EditCharityData(finalImagesData, getBannerImg);

              }


            }

          })
          .catch(function (err) {
            console.log(err, "err");

          });
      } else {

        if (bannimg) {
          EditCharityData(videoArray, bannimg);

        } else {
          EditCharityData(videoArray, getBannerImg);

        }

      }

      function EditCharityData(finalImagesData, getBannerImg) {

        if (editcharityVal) {

          var editGiftPartnerVal = {
            title: editcharityVal.title,
            banner: getBannerImg,
            image_video: finalImagesData,
            webURL: editcharityVal.mediaLink,
            location: editcharityVal.location,
            categoryId: editcharityVal.categoryId,
            webAddress: editcharityVal.webAddress,
            role: editcharityVal.role,
            organisationEmail: editcharityVal.organisationEmail,
            charityId: editcharityVal.charity,
            id: cahrID,
            latitude:showLatitude,
            longitude:showLongitude,
          };
          console.log(editGiftPartnerVal, "editGiftPartnerVal")
          // return false;
          Services.editGiftPartner(editGiftPartnerVal)
            .then((response) => {
              $(".loader-main").hide();

              if (response.data.status == 200) {
                swal("Success", response.data.message, "success");

              } else {
                swal("Failed", response.data.message, "error");
              }
            })
            .catch((err) => {
              swal("Failed", err.response.data.message, "error");
            });
        }
      }

    }
  };


  async function UploadBannerImage() {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("image", bannerImg);
      //banner image upload
      Services.charimageGet(formData)
        .then((response) => {
          console.log(response, "response");
          if (response.status === 200) {
            bannimg = response.data.data.images[0].location;
            editcharityVal.banner = response.data.data.images[0].location;
            resolve("success")
          } else {
            reject("failed")
          }
        })
        .catch(function (err) {
          reject("failed")
          console.log(err, "err");
        });
    })


  }



  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Edit Gift Partner"
          title="Gift Partner List"
          main_title="Gift PArtner"
          title_link="gift-partner"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Edit Gift Partner</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-4">
                  <form id="edit-charity" onSubmit={handleOnSubmitUpdate}>
                    <div class="mb-4 col-md-7">
                      <PlacesAutocomplete
                        value={editcharityVal.location}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <i class="fi fi-rr-search cstm-search-ro"></i>
                            <input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'cstm-input-seacrh location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {errEditCharityVal.location !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.location}
                        </span>
                      )}
                    </div>

                    {/* <div class="mb-4 row">
                            <i class="fi fi-rr-search cstm-search-ro cstm-search-icon"></i>
                            <input
                              name="location"
                              id="location"
                              type="text"
                              class="cstm-input-seacrh cstm-input-screen2"
                              // onChange={handleOnchangeForSearch}
                              ref={ref}
                              placeholder="Find & Select your Organization on Google"
                            />
                            {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                {err.location}
                              </span>
                            )
                            }
                          </div> */}
                    <div class="mb-4">
                      <label class="cstm-label">
                        Upload Banner Image
                      </label><br />
                      <div className="cstm-Videoalign">
                        <label for="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                        {/* <div id="login-loader" className='loader-main uploaded-banner' style={{ display: 'none' }}>
                                <img src={loader} />
                              </div> */}
                        {
                          editcharityVal.banner !== undefined &&
                          editcharityVal.banner !== "" &&
                          <img
                            id="imageB"
                            className="AddOrganization-bannerImg"
                            src={`${process.env.REACT_APP_S3_BASE_URL}/` + editcharityVal.banner}
                          />
                        }
                        {file && file.length > 0 && <img
                          id="b-img"
                          className="AddOrganization-bannerImg"
                          src={file}
                        />}
                        {/* <img
                                id="imageB"
                                className="AddOrganization-bannerImg"
                                src={
                                  editcharityVal.banner !== undefined &&
                                    editcharityVal.banner !== null
                                    ? "https://human-backend-data.s3.eu-west-2.amazonaws.com/" +
                                    editcharityVal.banner
                                    : ''
                                }

                              /> */}
                        {/* {file && errEditCharityVal.banner === "" && (
                                <img
                                  id="b-img"
                                  src={file}
                                  className="AddOrganization-bannerImg"
                                />
                              )} */}

                        <input
                          // accept="image/*"
                          type="file"
                          name="banner"
                          id="banner-image"
                          className='cstm-uploadHide'
                          // value={"https://human-backend-data.s3.eu-west-2.amazonaws.com/"+editcharityVal.banner}

                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeBanner}
                        />
                      </div>
                      {errEditCharityVal.banner !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.banner}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 ">
                      <label class="cstm-label">Upload Image or Video</label><br />


                      <div className='cstm-video-image-upload'>
                        <label for="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                        <div className='cstm-vi-in'>
                          {imagesPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<>                 <div className='cstm-vi-box'>
                                <span className="cstm-vi-bx">  <img class="multi-images" controls src={data.url} alt="..." /></span>
                                <button type="button" onClick={() => deleteImages(index)}>
                                  ✖
                                </button></div>
                              </>
                              ) : (
                                <>                 <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">  <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImages(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )

                          )}
                          {/* </div>

                              <div className='cstm-vi-in'> */}
                          {videoPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<> <div className='cstm-vi-box'>
                                <span className="cstm-vi-bx">  <img class="multi-images getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                <button type="button" onClick={() => deleteImagesGet(index)}>
                                  ✖
                                </button>
                              </div>
                              </>
                              ) : (
                                <> <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">  <video class="multi-videos getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImagesGet(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )

                          )}
                        </div>


                        <input
                          // accept=".mov,.mp4"
                          multiple
                          type="file"
                          name="image_video"
                          id="org-image"
                          className='cstm-uploadHide'
                          // value={editcharityVal.image_video}
                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeorg}
                        />
                        {errEditCharityVal.image_video !== "" && (
                          <span style={{ color: "red" }} className="ml-3">
                            {errEditCharityVal.image_video}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Gift Partner Name
                      </label>
                      <input
                        name="title"
                        id="char-name"
                        type="text"
                        class="cstm-input"
                        placeholder="Gift Partner Name"
                        value={editcharityVal.title}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.title !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.title}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Organisation Email</label>
                      <input
                        name="organisationEmail"
                        id="organisationEmail"
                        type="email"
                        class="cstm-input"
                        value={editcharityVal.organisationEmail}
                        placeholder="Enter Your Organization Email"
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.organisationEmail !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.organisationEmail}
                        </span>
                      )
                      }
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Category</label>
                      <select
                        name="categoryId"
                        class="form-select cstm-category"
                        onChange={handleOnchangeUpdate}
                        value={showGiftPartnerCategoryIds}
                      >
                        {/* <option value="">Category</option> */}

                        {giftPartnerCategory.map((cat) => {
                          return (
                            <option value={cat._id}>{cat.type}</option>
                          );
                          // console.log(cat.title,"single cat")
                        })}
                      </select>

                      {errEditCharityVal.category !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {errEditCharityVal.category}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Role</label>
                      <select
                        name="role"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchangeUpdateRole}
                        value={editcharityVal.role}

                      >
                        <option value="principle">Principle</option>
                        <option value="manager">Manager</option>
                        <option value="other">Other</option>
                      </select>
                      {errEditCharityVal.role !== "" && (
                        <span style={{ color: "red" }}>{errEditCharityVal.role}</span>
                      )}
                    </div>

                    {selectStatus &&
                      <div class="mb-4 col-md-7">
                        <label class="cstm-label">Enter Other Role</label>
                        <input type="text" class="cstm-input" name="role_other" value={otherRole} onChange={handleOnchangeRole} />
                        {errEditCharityVal.role_other !== "" && (
                          <span style={{ color: "red" }}>{errEditCharityVal.role_other}</span>
                        )}
                      </div>
                    }

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Website Address</label>
                      <input
                        name="webAddress"
                        id="webAddress"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        value={editcharityVal.webAddress}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.webAddress !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.webAddress}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Media Link</label>
                      <input
                        name="mediaLink"
                        id="mediaLink"
                        type="text"
                        class="cstm-input"
                        placeholder="Media Link"
                        value={editcharityVal.mediaLink ? errEditCharityVal.mediaLink :""}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.mediaLink !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.mediaLink}
                        </span>
                      )
                      }
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Support Charity(Optional)</label>
                      <select
                        name="charity"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchangeUpdateCharity}
                        value={showCharityIds}
                      >
                        <option value="" selected>
                          Select Charity
                        </option>

                        {giftCharity.map((cat) => {
                          return <option value={cat._id}>{cat.title}</option>;
                          // console.log(cat.title,"single cat")
                        })}
                      </select>
                    </div>
                    <div className="submit-btnMain cstm-loader">
                      <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">UPDATE & SAVE</button>
                      <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                        <img src={loader} />
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditGiftPartner;
