import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { SidebarLayoutHeader } from '../../shared/components/SidebarLayoutHeader';
import { useLoaderStore } from '../../stores/loader.store';
import Services from '../../Services/auth.service';
import { Pagination } from '../shared/Pagination';
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';
import { DEFAULT_PAGE_SIZE } from '../../common/constants/pagination.constant';
import { useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';
import { S3_BASEURL, categoryType } from '../../Constants/app.constants';

const TEAM_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function OrganizationTeamList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const { organizationId } = useParams();
    const location = useLocation();
    const [teams, setTeams] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const pagination = { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE };

    /** @type {[{ team: any, action: string }, React.Dispatch<{ team: any, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useSetBreadcrumbs([
        { label: 'Edit Organization', path: location.state?.breadcrumbPath || '' },
        { label: 'Organization Team List', path: '' },
    ]);

    useEffect(() => {
        getTeams();
    }, []);

    async function getTeams() {
        setShowLoader(true);
        try {
            const res = await Services.getTeamByOrganizationId({
                organizationId: organizationId,
                page: pagination.pageNumber,
                limit: pagination.pageSize,
            });

            if (res.data.status === 200) {
                setTeams(res.data.data.team_data);
                setResultsCount(res.data.data.team_data.length);
                return;
            }

            toast.error("Couldn't get teams");
        } catch (err) {
            toast.error("Couldn't get teams");
        } finally {
            setShowLoader(false);
        }
    }

    function onDownloadTeamQRCodeClick(team) {
        if (team.qrCodePath) {
            downloadTeamQRCode(team.qrCodePath);
        } else {
            generateTeamQRCode(team._id, team.name);
        }
    }

    async function generateTeamQRCode(teamId, teamName) {
        try {
            const requestParams = {
                id: teamId,
                name: teamName,
                category: categoryType.Team,
            };
            const res = await Services.generateQrCodeAtAdmin(requestParams);

            if (res.status === 200) {
                const result = res.data.data;
                downloadTeamQRCode(result.data.qrCodePath);
                return;
            }

            toast.error("Couldn't generate user QR");
        } catch (err) {
            toast.error("Couldn't generate user QR");
        }
    }

    function downloadTeamQRCode(qrCodePath) {
        const imageUrl = S3_BASEURL + qrCodePath;
        window.open(imageUrl, '_blank');
    }

    async function handleDeleteTeam() {
        try {
            const team = selectedRow.team;
            const res = await Services.deleteTeam(team._id);

            if (res.status === 200) {
                toast.success("Successfully deleted team");
                setTeams((currentTeams) => currentTeams.filter((t) => t._id !== team._id));
                return;
            }

            toast.error("Couldn't delete team");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete team");
        } finally {
            setSelectedRow(null);
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title='Organization Team List' />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className="d-flex justify-content-between align-items-center p-3">
                                <h4 className="mb-0">Organization Team List</h4>
                                <div className='d-flex'>
                                    <Link
                                        to={`/organization/${organizationId}/teams/add`}
                                        className="cstm-btn lh-base"
                                    >
                                        Add Team
                                    </Link>
                                </div>
                            </div>

                            <div className="overflow-auto">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">No</th>
                                            <th className="border-bottom w-4 cstm-userheading">Team Name</th>
                                            <th className="border-bottom w-4 cstm-userheading">QR Code</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {teams?.map((team, index) => (
                                            <tr
                                                key={team._id}
                                                className="cstm-Tabledesign cstm-usertable-design"
                                            >
                                                <td>{index + 1}</td>
                                                <td>{team.name}</td>
                                                <td>
                                                    <div>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip id="button-tooltip-2">Download QR</Tooltip>}
                                                        >
                                                            <Link
                                                                onClick={() => onDownloadTeamQRCodeClick(team)}
                                                            >
                                                                <i className="fi fi-rr-download"></i>
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link
                                                            to={`/teams/${team._id}/edit?organizationId=${organizationId}`}
                                                            className="cstm-eye"
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link>
                                                        <Link
                                                            onClick={() => setSelectedRow({ team: team, action: TEAM_ACTION.DELETE })}
                                                            className="cstm-delete mrn-rt"
                                                        >
                                                            <i className="fi fi-rr-trash"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {!teams?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                        />
                    </div>
                </div>
            </main>

            {(selectedRow && selectedRow.action === TEAM_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this team?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteTeam}
                    onCancel={() => setSelectedRow(null)}
                />
            )}
        </>
    );
}
