import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Header from '../../NavMenuBar/Header';
import deleteImg from "../../../assets/Images/delete.png";
import videoQuickTipService from '../../../Services/video-quick-tip.service';
import { Pagination } from '../../shared/Pagination';
import { formatDateForTable } from '../../../common/utils/date.util';
import { useLoaderStore } from '../../../stores/loader.store';

import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    // width: 250
});

export function VideoQuickTipsList() {
    const [videoQuickTips, setVideoQuickTips] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [selectedVideoQuickTipId, setSelectedVideoQuickTipId] = useState(null);

    useEffect(() => {
        let pageNumber = 1;
        getVideoQuickTipList(pageNumber, pagination.pageSize);
    }, []);

    async function getVideoQuickTipList(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await videoQuickTipService.getVideoQuickTips({
                pageNumber: pageNumber,
                pageSize: pageSize,
            });

            if (res.status === 200) {
                setVideoQuickTips(res.data?.results);
                setResultsCount(res.data?.totalCount);

                return;
            }

            toast.error("Couldn't get video quick tips");
        } catch (err) {
            toast.error("Couldn't get video quick tips");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber ? pageNumber : 1,
            pageSize: pagination.pageSize ? pagination.pageSize : 20
        });

        getVideoQuickTipList(pageNumber, pagination.pageSize);
    }

    async function handleDelete() {
        try {
            const response = await videoQuickTipService.deleteVideoQuickTipById(selectedVideoQuickTipId);

            if (response.status === 200) {
                toast.success("Successfully deleted video quick tip");
                getVideoQuickTipList(pagination.pageNumber, pagination.pageSize);

                document.querySelector('.modal-backdrop').remove();
                document.querySelector('#cancelappointment').classList.remove('show');
                document.querySelector("#cancelappointment .btn-close").click();

                return;
            }

            toast.error("Couldn't delete video quick tip");
        } catch (err) {
            toast.error("Couldn't delete video quick tip");
        }
    }

    function onCancelDelete() {
        setSelectedVideoQuickTipId(null);
        document.querySelector('#cancelappointment').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
    }

    // const getItemStyle = (isDragging, draggableStyle) => ({
    //     // some basic styles to make the items look a bit nicer
    //     // userSelect: "none",
    //     // padding: grid * 2,
    //     // margin: `0 0 ${grid}px 0`,

    //     // change background colour if dragging
    //     background: isDragging ? "lightgreen" : "grey",

    //     // styles we need to apply on draggables
    //     ...draggableStyle
    //   });

    async function handleDragEnd(result) {
        try {
            setShowLoader(true);
            const { source, destination } = result;

            let reqData = {
                'destination': {
                    '_id': videoQuickTips[source.index]._id,
                    'order': destination.index + 1
                }
            };

            const response = await videoQuickTipService.changeVideoQuickTipOrder(reqData);

            if (response.status === 200) {
                toast.success("Successfully changes video quick tip order");
                await getVideoQuickTipList();
                return;
            }

            toast.error("Couldn't change video quick tip order");
        } catch (err) {

            toast.error("Couldn't change video quick tip order");

        } finally {
            setShowLoader(false);
        }


    }

    return (
        <>
            <main className="page-content bg-light">
                <Header title="How to Quick tips list" main_title="How to Quick tips" />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className='cstm-table-main'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow rounded">
                                        <div className="row justify-content-between align-items-center p-3">
                                            <div className="col-md-3">
                                                <h4 className="mb-0">How to Quick tips</h4>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="row row justify-content-end">
                                                    <div className="col-all-auto">
                                                        <Link
                                                            to="/add-how-to"
                                                        >
                                                            <button className="cstm-btn">Add New</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12 col-lg-12">
                                            <table className="table mb-0 table-center">
                                                <thead>
                                                    <tr className="border-top">
                                                        <th className="border-bottom w-4 cstm-userheading">No</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Date</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Video Title</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Video Link</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Action</th>
                                                    </tr>
                                                </thead>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {provided => (

                                                            <tbody {...provided.droppableProps} ref={provided.innerRef} >
                                                                {videoQuickTips?.map((videoQuickTip, index) => (
                                                                    <Draggable key={videoQuickTip._id} draggableId={videoQuickTip._id} index={index}>
                                                                        {provided => (
                                                                            <tr
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <td>{index + 1}</td>
                                                                                <td>{videoQuickTip.createdAt ? formatDateForTable(videoQuickTip.createdAt) : null}</td>
                                                                                <td>{videoQuickTip.videoTitle}</td>
                                                                                <td>{videoQuickTip.videoLink?.encoded480p}</td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Link
                                                                                            to={"/edit-how-to/?id=" + videoQuickTip._id}
                                                                                            className="cstm-eye"
                                                                                        >
                                                                                            <i className="fi fi-rr-pencil"></i>
                                                                                        </Link>

                                                                                        <Link
                                                                                            to={" "}
                                                                                            onClick={() => setSelectedVideoQuickTipId(videoQuickTip._id)}
                                                                                            className="cstm-delete mrn-rt"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#cancelappointment"
                                                                                        >
                                                                                            <i className="fi fi-rr-trash"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                            </tbody>

                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </table>

                                            {!videoQuickTips?.length && (
                                                <div className="NoRecord-cstm">No records found!</div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber ? pagination.pageNumber : 1}
                            rowsPerPage={pagination.pageSize ? pagination.pageSize : 20}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="cancelappointment"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body py-5">
                                <div className="text-center">
                                    <div className="d-flex justify-content-center">
                                        <img src={deleteImg} />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ display: "none" }}>
                                    </button>
                                    <h4 className="mt-4">
                                        Are sure you want to delete
                                        <br /> this video quick tip?
                                    </h4>
                                    <div className="mt-4">
                                        <button
                                            className="mr-3 cstm-btn cstn-discard"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                        <button className="cstm-btn2 cstn-discard" onClick={onCancelDelete}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
