// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-details-organization-teams-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.user-details-organization-teams-list-item {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.user-details-organization-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
}

.user-details-organization-card-heading {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/User/User.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".user-details-organization-teams-list {\n  list-style-type: none;\n  padding-left: 0;\n  margin-bottom: 0;\n}\n\n.user-details-organization-teams-list-item {\n  background-color: #e9ecef;\n  border-radius: 0.25rem;\n  padding: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.user-details-organization-card {\n  border: 1px solid #dee2e6;\n  border-radius: 0.25rem;\n  padding: 1rem;\n  margin-bottom: 1rem;\n  background-color: #f8f9fa;\n}\n\n.user-details-organization-card-heading {\n  font-size: 1.25rem;\n  font-weight: 500;\n  margin-bottom: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
