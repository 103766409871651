import React ,{useEffect , useState} from 'react'
import Header from '../NavMenuBar/Header';

import userProfileImg from "../../assets/Images/photo.png";
import deleteImg from '../../assets/Images/delete.png';
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';



function RequestedCharityDetail() {

	const history = useNavigate();
	const getid = useLocation().search;
	const cahrID = new URLSearchParams(getid).get('id');
	const [charData,setCharData]=useState({});
	console.log(charData,"setCharData");
 
	useEffect(() => {
 
	   const loggedInUser = localStorage.getItem("token");
	   !loggedInUser && history("/");
	//    var queryVar = {
	// 	"_id": cahrID
	//    }
 
	   Services.getCharityById(cahrID)
		  .then((response) => {
			  console.log(response,"responce");
			//   return false;
			 if (response.data.status === 200) {
				console.log(response.data.data, "all data");
				setCharData(response.data.data);
 
			 } else {
				console.log("error");
			 }
		  })
		  .catch(function (err) {
			 console.log(err, "erron api");
 
		  });
	}, []);


	const handleApprove = () => {

		Services.charVerify(cahrID)
		   .then((response) => {
			  console.log(response, "response")
			  if (response.data.status === 200) {
				 swal("Success", response.data.message, "success");
  
			  } else {
				 swal("Failed", response.data.message, "error");
  
			  }  
		   })
		   .catch(function (err) {
			  console.log(err, "erron api");
		   });
	 }
 
  return (
    <>
      <main class="page-content bg-light">
            <Header main_title="Charity" title="Requested Charity" title_link="requested-charity" title1="Requested Charity Profile"/>
            <div class="container-fluid">
               <div class="layout-specing">
			   
               <h4 class="mb-4">Charity Profile</h4>
          
                
                  <div class="row">
                     <div class="col-md-12">
					      <div class="card border-0 shadow rounded p-3 mb-4">
                               <h5 class="mb-4">Charity Details</h5>
							   
							   
							   <div class="block">
								   <div class="cstm-cd-photo"><img class="csatm-od-photo" src={userProfileImg} /></div>
								      <div class="cstm-od-details">
										   <h5 class="mb-2">London Bus Service</h5>
										   <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
										   <hr />
										   <div class="title2">Website</div>
										   <div class="cstm-website-link">{charData.webURL}</div>
										   <hr />
										   {/* <div class="title2">Charity Number</div>
										   <div class="mb-3">123456789</div>
										    <hr />
										   <div class="row">
										          <div class="row col-md-4">
													   <div class="title2">Address</div>
													   <div>Lorem Ipsum is simply dummy </div>
										         </div>
												  <div class="row col-md-4">
													   <div class="title2">City</div>
													   <div>{charData.city}</div>
										         </div>
												  <div class="row col-md-4">
													   <div class="title2">Country</div>
													   <div>{charData.country}</div>
										         </div>
										   </div> */}
										    
										   <div class="mt-4 mb-2">
											<button class="cstm-btn cstn-discard mr-3" onClick={handleApprove}>Appove</button>
											<button class="cstm-btn2 cstn-discard">Discard</button> 
                                          </div>
										   
										 
										   
										   
									</div>	   
							   </div>
							   
						  </div>
						
					 </div>
					
				  
                </div>
				  
               </div>
            </div>
         </main>

         <div class="modal fade" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body py-5">
                        <div class="text-center">
                            <div class="d-flex justify-content-center">
                                <img src={deleteImg} />
                            </div>
                             <h4 class="mt-4">Are sure you want to delete<br /> this organization?</h4>
							 <div class="mt-4">
                                <button class="mr-3 cstm-btn cstn-discard">Delete</button>
								<button class="cstm-btn2 cstn-discard">Cancel</button> 
                            </div>
							
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RequestedCharityDetail;
