import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import swal from "sweetalert";
import loader from "../../assets/Images/loder.gif";

import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import {
   useTable,
   useFilters,
   usePagination,
   useGlobalFilter,
   useSortBy,
} from "react-table";


// const EditableNumberCell = props => {
//    const { column, row, cell, updateMyData } = props;
//    const value = cell.value;
//    const rowIndex = row.index;
//    const columnId = column.id;
//    const onChange = e => {
//       updateMyData(rowIndex, columnId, parseInt(e.target.value, 10));
//    };
//    return <input value={value} onChange={onChange} type="number" />;
// };

// const EditableTextCell = props => {
//    const { column, row, cell, updateMyData } = props;
//    const value = cell.value;
//    const rowIndex = row.index;
//    const columnId = column.id;
//    const onChange = e => {
//       updateMyData(rowIndex, columnId, e.target.value);
//    };
//    return <input value={value} onChange={onChange} />;
// };

const Tr = styled.tr`
   background-color: white;
   display: ${({ isDragging }) => (isDragging ? "table" : "")};
 `;



function Table({ columns, data, updateMyData,
   // removeRow,
   // addRow,
   resetData,
   reorderData }) {
   const [showLoader, setLoader] = useState(true);
   useEffect(() => {
      setTimeout(() => {
         setLoader(false);
      }, 1500)
   }, [])
   const props = useTable(
      {
         columns,
         data,

         updateMyData,
         // removeRow,
         // addRow,
         reorderData,

         initialState: { pageIndex: 0, pageSize: 20 },
      },
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
   );
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
      state,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
   } = props;
   console.log(props);
   React.useEffect(() => {
      // console.log(globalFilter);
   }, [globalFilter]);
   let firstRecord = pageIndex * pageSize + 1;
   let lastRecord = firstRecord + pageSize - 1;

   // const handleDragEnd = result => {
   //    const { source, destination } = result;
   //    if (!destination) return;
   //    reorderData(source.index, destination.index);
   //  };

    const handleDragEnd = result => { 
      const { source, destination } = result;
      if (!destination) return;
      reorderData(source.index, destination.index); 
      if (source.index === destination.index) {
          return;
        } 
    
        let items = [...data];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);
    
      //   setData([...items]);
      // reorderData(items);
      console.log(items,"items")
 
      var arr = []; 
      const filtered = items !== undefined && items.filter((employee) => {
         return arr.push({ id: employee._id })
     });

   console.log(arr, "Arrryryryryr")

   var queryValue = {
      "gift_card":  arr
  }

   Services.reorderGifting(queryValue)
   .then((response) => {
      // return false;
      if (response.data.status === 200) { 
         // console.log(DataArray, "NR DataArray")
         // setGiftData(DataArray);
      }
   })
   .catch(function (err) {
      console.log(err, "err")
      swal("Failed", err.response.data.message, "error");
   }); 

  };
      
   return (
      <>
         <main class="page-content bg-light">
            <Header main_title="Gifting" title="Gift List" />
            <div class="container-fluid">
               <div class="layout-specing">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="card border-0 shadow rounded">
                           <div class="row justify-content-between align-items-center p-3">
                              <div class="col-md-3">
                                 <h4 class="mb-0">Gift List</h4>
                              </div>{" "}
                              <div class="col-md-9">
                                 <div class="row row justify-content-end">
                                    <div class="col-md-5">
                                       {" "}
                                       <i class="fi fi-rr-search cstm-search-ro"></i>
                                       <input
                                          name="serch-gift"
                                          id="serch-gift"
                                          type="text"
                                          class="cstm-input-seacrh"
                                          value={globalFilter || ""}
                                          onChange={(e) => setGlobalFilter(e.target.value)}
                                          placeholder="Search gift"
                                       />
                                    </div>
                                    <div class="col-all-auto">
                                       <Link
                                          to="/add-gift"
                                       >
                                          <button class="cstm-btn">Add Gift</button>
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-12 col-lg-12">
                              <div class="table-responsive bg-white border-top rounded-0">
                                 <table
                                    {...getTableProps()}
                                    class="table mb-0 table-center"
                                 >
                                    <thead>
                                       {" "}
                                       {headerGroups.map((headerGroup) => (
                                          <tr {...headerGroup.getHeaderGroupProps()}>
                                             {headerGroup.headers.map((column) => (
                                                <th
                                                   class="border-bottom w-22"
                                                   {...column.getHeaderProps()}
                                                   onClick={() =>
                                                      column.toggleSortBy(!column.isSortedDesc)
                                                   }
                                                >
                                                   {column.render("Header")}

                                                   {/* {console.log(column.isSortedDesc)} */}
                                                   <span>
                                                      {column.isSorted ? (
                                                         column.isSortedDesc ? (
                                                            <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i>
                                                         ) : (
                                                            <i class="fi fi-rr-caret-up cstm-table-up-arrow"></i>
                                                         )
                                                      ) : (
                                                         ""
                                                      )}
                                                   </span>
                                                </th>
                                             ))}
                                          </tr>
                                       ))}
                                    </thead>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="tbody">
                                    {(provided, snapshot) => (


                                        <tbody ref={provided.innerRef} {...provided.droppableProps} >

                                            {page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <Draggable
                                                        draggableId={row.original._id}
                                                        key={row.original._id}
                                                        index={row.index}
                                                    >
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <Tr className="cstm-Tabledesign cstm-usertable-design"
                                                                    {...row.getRowProps()}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                    isDragging={snapshot.isDragging}
                                                                >
                                                                    {row.cells.map(cell => (
                                                                        <td {...cell.getCellProps()}>
                                                                            {cell.render("Cell", {
                                                                                dragHandleProps: provided.dragHandleProps,
                                                                                isSomethingDragging: snapshot.isDraggingOver
                                                                            })}
                                                                        </td>
                                                                    ))}
                                                                </Tr>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                            

                                            {/* {
                                    page.length > 0 && page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="cstm-Tabledesign cstm-usertable-design">
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })} */}
                                        </tbody>

                                    )}
                                </Droppable>
                            </DragDropContext>
                                    {/* <tbody {...getTableBodyProps()}>
                                       {page.length > 0 &&
                                          page.map((row, i) => {
                                             prepareRow(row);
                                             return (
                                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                   {row.cells.map((cell) => {
                                                      return (
                                                         <td {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                         </td>
                                                      );
                                                   })}
                                                </tr>
                                             );
                                          })}
                                    </tbody> */}
                                 </table>
                                 {!showLoader ? page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row text-center">
                     <div class="col-12 mt-4">
                        <div class="d-md-flex align-items-center text-center justify-content-between">
                           <span class="text-muted me-3">
                              {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                           </span>
                           <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                              {pageOptions.length !== 1 && page.length !== 0 && (
                                 <li class="page-item">
                                    <a
                                       class="page-link"
                                       onClick={() => previousPage()}
                                       disabled={!canPreviousPage}
                                       href="javascript:void(0)"
                                    >
                                       Prev
                                    </a>
                                 </li>
                              )}
                              {pageOptions.map((pgnumber) => {
                                 // console.log(pgnumber, "pgnumber")
                                 return (
                                    pgnumber !== pageCount && pageCount !== 1 && (
                                       <li
                                          className={`page-item ${pageIndex == pgnumber ? "active" : ""
                                             }`}
                                       >
                                          <a
                                             className="page-link"
                                             href="javascript:void(0)"
                                             onClick={() => gotoPage(pgnumber)}
                                          >
                                             {" "}
                                             {pgnumber + 1}{" "}
                                          </a>
                                       </li>
                                    )
                                 );

                                 {
                                    /* return( pgnumber) */
                                 }
                              })}

                              {pageOptions.length !== 1 && page.length !== 0 && (
                                 <li class="page-item">
                                    <a
                                       class="page-link"
                                       onClick={() => nextPage()}
                                       disabled={!canNextPage}
                                       href="javascript:void(0)"
                                    >
                                       Next
                                    </a>
                                 </li>
                              )}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      </>
   );
}


const UpDownArrow = props => (
   <span
       {...props.dragHandleProps}
       className='testClass'

       // className={props.className}
       aria-label="move"
       role="img"
   >
      <span className="drag-gift-sp-cstm">
      ::
      </span>
   </span>
);
const StyledUpDownArrow = styled(UpDownArrow)`
   position: absolute;
   top: -15px;
   left: -50px;
   padding: 15px;
   display: none;
   tr:hover & {
     display: ${({ isSomethingDragging }) =>
       isSomethingDragging ? "none" : "inline"};
   }
 `;


function Index() {
   const history = useNavigate();
   var DataArray = [];
   const [giftData, setGiftData] = useState([]);

   giftData.forEach((giftData_id, index) => {
      giftData_id.serial = index + 1;
   });

   const DataArrayCat = [];

   useEffect((data, e) => {
      getData(1);
   }, []);

   const getData = (pageno) => {
      const queryVar = {
         page: pageno,
         limit: 100,
         isCharityVerified: true,
      };

      Services.getGift(queryVar)
         .then((response) => {
            // return false;
            if (response.data.status === 200) {
               if (response.data.data.gift_card_data.length !== 0) {

                  var pricesArray = [];
                  var data = response.data.data.gift_card_data;
                  data.map((data1) => {
                     var pricesArray = data1.price.join(",");
                     var status = "";
                     data1.status == true ? (status =
                        <div className="cstm-switch">
                           <label className="switch">
                              <input type="checkbox" checked disabled></input>
                              <span className="slider round"></span>
                           </label>
                        </div>

                     ) : (
                        status =
                        <div className="cstm-switch">
                           <label className="switch">
                              <input type="checkbox" disabled></input>
                              <span className="slider round"></span>
                           </label>
                        </div>

                     )

                     var reqData = {
                        _id: data1._id,
                        type: data1.type,
                        giftName: data1.giftName,
                        title: data1.title,
                        icon: <img className="gift-icon cstm-gift-icon" src={`${process.env.REACT_APP_S3_BASE_URL}/` + data1.image_video} />,
                        price: pricesArray,
                        status: status,
                        order: data1.order,
                     }
                     DataArray = [...DataArray, reqData]
                  });
                  if (response.data.data.state) {
                     getData(response.data.data.state.page + 1);
                  }
               }

               const numAscending = DataArray.sort((a, b) => a.order - b.order);
               setGiftData(numAscending)

               // console.log(DataArray, "NR DataArray")
               // setGiftData(DataArray);
            }
         })
         .catch(function (err) {
            console.log(err, "err")
            swal("Failed", err.response.data.message, "error");
         });
   };


   //delet---------

   const [selectedCid, setselectedCid] = useState();

   const handleDelete = () => {
      console.log(selectedCid, "selectedCid");

      Services.deleteGift(selectedCid)
         .then((response) => {
            // return false;
            if (response.status === 200) {
               setGiftData(
                  giftData.filter((post) => {
                     return post._id !== selectedCid;
                  })
               );
               swal("Success", response.data.message, "success");
               document.querySelector(".modal-backdrop").remove();
               document.querySelector("#cancelappointment").classList.remove("show");
               document.querySelector("#cancelappointment .btn-close").click();

            } else {
               swal("Failed", response.data.message, "error");
            }
         })
         .catch(function (err) {
            swal("Failed", err.response.data.message, "error");
         });
   };
   const onClickCancel = () => {
      document.querySelector("#cancelappointment").classList.remove("show");
      document.querySelector(".modal-backdrop").remove();
   };


   const Description = styled.span`
   display: flex;
   align-items: center;
   position: relative;
 `;
   const columns =  React.useMemo(() => {
      const DescriptionCell = props => {
        return (
          <Description>
            <StyledUpDownArrow {...props} />
            {/* <EditableTextCell {...props} /> */}
          </Description>
        );
      };

      return  [
         {
            Header:'Reorder',
            Cell: DescriptionCell

    },
      {
         Header: "No",
         accessor: "serial",
      },

      {
         Header: "Gift name",
         accessor: "giftName",
      },
       {
         Header: "Media title",
         accessor: "title",
       },
      
      {
         Header: "Price",
         accessor: "price",
      },
      {
         Header: "Status",
         accessor: "status",
      },


      {
         Header: "Actions",

         Cell: (row) => {
            return (
               <div>
                  {/* <Link
                     to={"/view-gift/?id=" + row.cell.row.original._id}
                     class="cstm-eye"
                  >
                     <i class="fi fi-rr-eye"></i>
                  </Link> */}
                  <Link
                     class="cstm-eye"
                     to={"/edit-gift/?id=" + row.cell.row.original._id}
                  >
                     <i class="fi fi-rr-pencil"></i>
                  </Link>
                  <Link
                     to={" "}
                     onClick={() => setselectedCid(row.cell.row.original._id)}
                     class="cstm-delete mrn-rt"
                     data-bs-toggle="modal"
                     data-bs-target="#cancelappointment"
                  >
                     <i class="fi fi-rr-trash"></i>
                  </Link>
               </div>
            );
         },
      },
   ]
}, []);

const updateMyData = (rowIndex, columnID, newValue) => {
   setGiftData(oldData =>
       // console.log(oldData,"olddata"),
       oldData.map((row, index) => {
           if (index === rowIndex) {
               return {
                   ...oldData[rowIndex],
                   [columnID]: newValue
               };
           }
           // console.log(row,"rowin updated data");
           return row;
       })
   );

// console.log(data,"datataaaa update")

};

// console.log(data,"datataaaa")

const reorderData = (startIndex, endIndex) => {
   const newData = [...giftData];
   const [movedRow] = newData.splice(startIndex, 1);
   newData.splice(endIndex, 0, movedRow);
   setGiftData(newData);

   // console.log(data,"data updates")
};
   return (
      <>
         <Table columns={columns} data={giftData}  updateMyData={updateMyData} reorderData={reorderData} />

         {/* //delet  */}
         <div
            class="modal fade"
            id="cancelappointment"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
         >
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body py-5">
                     <div class="text-center">
                        <div class="d-flex justify-content-center">
                           <img src={deleteImg} />
                        </div>
                        <button
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                           style={{ display: "none" }}>
                        </button>
                        <h4 class="mt-4">
                           Are sure you want to delete
                           <br /> this gift?
                        </h4>
                        <div class="mt-4">
                           <button
                              class="mr-3 cstm-btn cstn-discard"
                              onClick={handleDelete}
                           >
                              Delete
                           </button>
                           <button
                              class="cstm-btn2 cstn-discard"
                              onClick={onClickCancel}
                           >
                              Cancel
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Index;
