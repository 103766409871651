import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';
import { toast } from 'react-toastify';
import Services from '../../Services/auth.service';
import { useLoaderStore } from '../../stores/loader.store';
import userService from "../../Services/user.service";
import nonUserService from "../../Services/non-user.service";


const TEAM_NOTIFICATION_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function OrganizationTeamNotificationCommon({ type = "" }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const { organizationId } = useParams();
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersData, setUsersData] = useState([]);

    const [selectedTeamId, setSelectedTeamId] = useState();
    const [teamNotifications, setTeamNotifications] = useState([]);
    const [userSelectionOptions, setUserSelectionOptions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [showAddUnregisteredUser, setShowAddUnregisteredUser] = useState(false);
    /** @type {[{ team: any, action: string }, React.Dispatch<{ team: any, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        getTeams();
        getNotificationsForTeam();
    }, []);

    useEffect(() => {
        if (selectedUsers) {
            let tempUser = usersData.find((data) => { return data._id == selectedUsers.value });
            if (tempUser) {
                setEmail(tempUser.email);
            }
        }
    }, [selectedUsers]);

    async function getTeams() {
        setShowLoader(true);
        try {
            const res = await Services.getTeamByOrganizationId({
                organizationId: organizationId,
                page: 1,
                limit: 100,
            });

            if (res.data.status === 200) {
                let teams = res.data.data.team_data;
                setTeams(teams);
                if (teams && teams.length > 0) {
                    setSelectedTeamId(teams[0]._id);
                    await getUsersOfTeam(teams[0]._id);
                }
                return;
            }

            toast.error("Couldn't get teams");
        } catch (err) {
            toast.error("Couldn't get teams");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleTeamChange(e) {
        let teamId = e.target.value;
        setSelectedTeamId(teamId);

        await getUsersOfTeam(teamId);
    }

    async function getNotificationsForTeam() {

        try {
            const res = await nonUserService.getTeamNotificationsByOrganizationIdAndType(organizationId, type);

            if (res.status === 200) {
                setTeamNotifications(res.data.teamNotifications);
                // toast.success("successfully fetched notifications");
                return;
            }

            toast.error("Couldn't get notifications");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get notifications");
        } finally {
            setShowLoader(false);
        }
    }

    async function addNotificationNonUser() {
        let data = {
            teamId: selectedTeamId,
            teamLeadName: name,
            organizationId: organizationId,
            email: email,
            allowedNotification: type
        }
        return await addNotificationApi(data);
    }

    async function addNotificationApi(data) {
        try {
            const res = await nonUserService.addNotification(data);

            if (res.status === 200) {
                getNotificationsForTeam();
                toast.success("successfully added notification");
                return;
            }

            if (res.status === 400) {
                toast.error("This record already exists");
            }

            toast.error("Couldn't add notification");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't add notification");
        } finally {
            setShowLoader(false);
        }
    }

    async function addNotification() {
        let data = {
            teamId: selectedTeamId,
            teamLeadId: selectedUsers.value,
            organizationId: organizationId,
            email: email,
            allowedNotification: type
        }
        return await addNotificationApi(data);

    }

    async function handleDeleteTeamNotification() {
        try {
            const team = selectedRow.teamNotification;
            const res = await nonUserService.deleteTeamNotification(team._id);

            if (res.status === 200) {
                toast.success("Successfully deleted team notifcation");
                setTeamNotifications((teamNotifications) => teamNotifications.filter((t) => t._id !== team._id));
                return;
            }

            toast.error("Couldn't delete team notifcation");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete team notifcation");
        } finally {
            setSelectedRow(null);
        }
    }

    function handleSelectedUser(value) {
        console.log(value.length);
        if (value && value.length > 0) {
            // to get last selected
            setSelectedUsers(value[value.length - 1]);
        }
    }

    async function getUsersOfTeam(teamId) {
        setShowLoader(true);

        try {
            const res = await userService.getUsers({
                search: '',
                page: 1,
                limit: 1000,
                organizationIds: [organizationId],
                teamIds: [teamId],
                isActive: true
            });

            if (res.status === 200) {
                let tempUsers = res.data.results;
                setUsersData(tempUsers);
                let userSelectionOptionsData = tempUsers.map((user) => formatUsersForDropdown(user));

                setUsers(userSelectionOptionsData);
                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    function formatUsersForDropdown(user) {
        return {
            value: user._id,
            label: user.firstName,
        };
    }

    return (
        <>
            <div className='p-3'>
                <h4 className="mb-0">{type == "UnregisteredUser" ? "Notification for Unregistered Recipient Messages" : "Notification for Sign-up Approval Requests"}</h4>
            </div>

            <div className="row">
                <div className="d-flex mb-2">
                    <button
                        className={`lh-base ml-2 ${showAddUnregisteredUser ? 'cstm-btn' : 'cstm-btn-click'}`}
                        onClick={(e) => { setShowAddUnregisteredUser(false) }}
                    >
                        Add User
                    </button>
                    <button
                        className={`lh-base ml-2 ${!showAddUnregisteredUser ? 'cstm-btn' : 'cstm-btn-click'}`}
                        onClick={(e) => { setShowAddUnregisteredUser(true) }}
                    >
                        Add Manually
                    </button>
                </div>
            </div>

            {!showAddUnregisteredUser && <div className="row">
                <div className="mb-4 col-md-3">
                    <label className="cstm-label">Team Name</label>
                    <select
                        className="form-select cstm-category"
                        onChange={(e) => { handleTeamChange(e) }}
                    >
                        <option value="" hidden disabled>Select Team</option>
                        {teams && teams.map((data) => {
                            return <option key={data._id} value={data._id}>{data.name}</option>
                        })}
                    </select>
                </div>

                <div className="col-sm">
                    <label className="cstm-label">Team Lead</label>

                    <ReactMultiSelectCheckboxes
                        className="form-select"
                        options={users}
                        placeholderButtonLabel="Select users"
                        value={selectedUsers}
                        onChange={(value, e) => handleSelectedUser(value)}
                        getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${value && value.label ? value.label : ''}`}
                    />
                </div>
                <div className="col-sm">
                    <label className="cstm-label">Email</label>
                    <input
                        name="email"
                        type="text"
                        className="cstm-input"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </div>
                <div className="col-sm">
                    <button
                        className='cstm-btn lh-base ml-2'
                        onClick={addNotification}
                    >
                        Add
                    </button>
                </div>
            </div>}

            {showAddUnregisteredUser && <div className="row">
                <div className="mb-4 col-md-3">
                    <label className="cstm-label">Team Name</label>
                    <select
                        className="form-select cstm-category"
                        onChange={(e) => { handleTeamChange(e) }}
                    >
                        <option value="" hidden disabled>Select Team</option>
                        {teams && teams.map((data) => {
                            return <option key={data._id} value={data._id}>{data.name}</option>
                        })}
                    </select>
                </div>

                <div className="col-sm">
                    <label className="cstm-label">Name</label>
                    <input
                        name="name"
                        type="text"
                        className="cstm-input"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                    />
                </div>
                <div className="col-sm">
                    <label className="cstm-label">Email</label>
                    <input
                        name="email"
                        type="text"
                        className="cstm-input"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </div>
                <div className="col-sm">
                    <button
                        className='cstm-btn lh-base ml-2'
                        onClick={addNotificationNonUser}
                    >
                        Add
                    </button>
                </div>
            </div>}


            <div className="overflow-auto">
                <table className="table mb-0 table-center">
                    <thead>
                        <tr className="border-top">
                            <th className="border-bottom w-4 cstm-userheading">No</th>
                            <th className="border-bottom w-4 cstm-userheading">Team Name</th>
                            <th className="border-bottom w-4 cstm-userheading">Team Lead</th>
                            <th className="border-bottom w-4 cstm-userheading">Email</th>
                            <th className="border-bottom w-4 cstm-userheading">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {teamNotifications?.map((teamNotification, index) => (
                            <tr
                                key={teamNotification._id}
                                className="cstm-Tabledesign cstm-usertable-design"
                            >
                                <td>{index + 1}</td>
                                <td>{teamNotification?.teamId?.name}</td>
                                <td>{teamNotification?.teamLeadId?.firstName ? teamNotification?.teamLeadId?.firstName : teamNotification?.teamLeadName}</td>
                                <td>{teamNotification?.email}</td>
                                <td>
                                    <div>
                                        {/* <Link
                                                            //to={`/teams/${team._id}/edit?organizationId=${organizationId}`}
                                                            className="cstm-eye"
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link> */}
                                        <Link
                                            onClick={() => setSelectedRow({ teamNotification: teamNotification, action: TEAM_NOTIFICATION_ACTION.DELETE })}
                                            className="cstm-delete mrn-rt"
                                        >
                                            <i className="fi fi-rr-trash"></i>
                                        </Link>
                                    </div>

                                </td>
                            </tr>

                        ))}


                    </tbody>
                </table>
            </div>

            {(selectedRow && selectedRow.action === TEAM_NOTIFICATION_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this team?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteTeamNotification}
                    onCancel={() => setSelectedRow(null)}
                />
            )}
        </>
    )
}