import React from 'react'

const ViewGiftPartner = () => {
  return (
    <div>
      
    </div>
  )
}

export default ViewGiftPartner
