import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SidebarLayoutContent } from "../../shared/components/SidebarLayoutContent";
import { APP_ROUTES } from "../../common/app-routes";
import {
  createBreadcrumb,
  useSetBreadcrumbs,
} from "../../shared/hooks/use-set-breadcrumbs.hook";
import { useGetUserById } from "../../hooks/user.hook";
import "./User.css";
import { MONTHS } from "../../common/constants/date.constant";
import { useGetThankyousByUserId } from "../../hooks/thankyou.hook";
import { formatDateForTable } from "../../common/utils/date.util";
import { LabelHelper } from "../../common/utils/label.helper";
import { ThankYouUtils } from "../../common/utils/thankyou.util";
import { useGetGiftsByUserId } from "../../hooks/gift.hook";
import { GiftUtils } from "../../common/utils/gift.util";
import { Modal } from "../../shared/components/Modal/Modal";
import { ViewThankYou } from "../ThankYou/ViewThankYou";
import { ViewGift } from "../Gift/ViewGift";
import { TextWithLabel } from "../../shared/components/TextWithLabel/TextWithLabel";
import { Pagination } from "../shared/Pagination";

const VIEW_USER_TABS = Object.freeze({
  USER_DETAILS: "USER_DETAILS",
  USER_THANKYOUS: "USER_THANKYOUS",
  USER_GIFTS: "USER_GIFTS",
});

export function ViewUser() {
  const { userId } = useParams();
  const location = useLocation();
  const { data: userDetails } = useGetUserById(userId);
  const [selectedTab, setSelectedTab] = useState(VIEW_USER_TABS.USER_DETAILS);

  useSetBreadcrumbs([
    createBreadcrumb(APP_ROUTES.USER_LIST, location),
    createBreadcrumb(APP_ROUTES.VIEW_USER, null, { userId: userId }),
  ]);

  return (
    <SidebarLayoutContent headerTitle="View User Details">
      <div className="single-selection-button-group mb-4">
        <button
          type="button"
          className={`${
            selectedTab === VIEW_USER_TABS.USER_DETAILS ? "active" : ""
          }`}
          onClick={() => setSelectedTab(VIEW_USER_TABS.USER_DETAILS)}
        >
          User Details
        </button>
        <button
          type="button"
          className={`${
            selectedTab === VIEW_USER_TABS.USER_THANKYOUS ? "active" : ""
          }`}
          onClick={() => setSelectedTab(VIEW_USER_TABS.USER_THANKYOUS)}
        >
          Thankyous
        </button>
        <button
          type="button"
          className={`${
            selectedTab === VIEW_USER_TABS.USER_GIFTS ? "active" : ""
          }`}
          onClick={() => setSelectedTab(VIEW_USER_TABS.USER_GIFTS)}
        >
          Gifts
        </button>
      </div>

      {selectedTab === VIEW_USER_TABS.USER_DETAILS && (
        <UserDetails userDetails={userDetails} />
      )}
      {selectedTab === VIEW_USER_TABS.USER_THANKYOUS && (
        <UserThankYous userId={userId} />
      )}
      {selectedTab === VIEW_USER_TABS.USER_GIFTS && (
        <UserGifts userId={userId} />
      )}
    </SidebarLayoutContent>
  );
}

function UserDetails({ userDetails }) {
  return (
    <>
      <h4 className="mb-4">User Details</h4>

      <div className="card border-0 shadow rounded mb-4 p-2">
        <div className="mb-2 d-flex justify-content-center align-items-center">
          {userDetails?.image ? (
            <img
              src={`${process.env.REACT_APP_S3_BASE_URL}/${userDetails.image}`}
              alt="User Profile"
              style={{ width: "120px", borderRadius: "50%" }}
            />
          ) : (
            <div
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                backgroundColor: "#00CCCC",
              }}
            ></div>
          )}
        </div>
        <div>
          <TextWithLabel label="User ID" text={userDetails?._id} />
          <TextWithLabel label="First Name" text={userDetails?.firstName} />
          <TextWithLabel label="Email" text={userDetails?.email} />
          <TextWithLabel label="Contact No" text={userDetails?.phoneNumber} />
          <TextWithLabel
            label="Date of Birth"
            text={`${userDetails?.DOB} ${
              userDetails?.MOB ? MONTHS[parseInt(userDetails?.MOB) - 1] : ""
            }`}
          />
          <TextWithLabel label="Pronouns" text={userDetails?.pronouns} />
          <TextWithLabel label="Bio" text={userDetails?.bio} isMultiline />
        </div>
      </div>

      <div className="card border-0 shadow rounded mb-4 p-2">
        <h4 className="mb-2">Organizations</h4>

        {userDetails?.organizations?.map((userOrg) => {
          const organizationDetails = userDetails?.organizationData?.find(
            (org) => org._id === userOrg.organizationId
          );
          return (
            <div key={userOrg._id} className="user-details-organization-card">
              <h5 className="user-details-organization-card-heading">
                {organizationDetails.name}
              </h5>
              <TextWithLabel label="Job Title" text={userOrg.occupation} />
              <TextWithLabel label="Email" text={userOrg.email} />

              <p className="mb-2">
                <strong>Teams:</strong>
              </p>
              <ul className="user-details-organization-teams-list">
                {userDetails.teams
                  ?.filter(
                    (team) => team.organizationId === userOrg.organizationId
                  )
                  .map((team) => (
                    <li
                      key={team._id}
                      className="user-details-organization-teams-list-item"
                    >
                      {team.name}
                    </li>
                  ))}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
}

function UserThankYous({ userId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const { data: thankYous } = useGetThankyousByUserId(userId, {
    page: pageNumber,
  });

  /** @type {[import('../../typedefs').ThankYou, React.Dispatch<import('../../typedefs').ThankYou>]} */
  const [selectedThankYou, setSelectedThankYou] = useState(null);

  return (
    <>
      <h4 className="mb-4">User Thank yous</h4>

      <div className="overflow-auto">
        <table className="table mb-0 table-center">
          <thead>
            <tr className="border-top">
              <th className="border-bottom w-4 cstm-userheading">Message</th>
              <th className="border-bottom w-4 cstm-userheading">Reply</th>
              <th className="border-bottom w-4 cstm-userheading">From</th>
              <th className="border-bottom w-4 cstm-userheading">Platform</th>
              <th className="border-bottom w-4 cstm-userheading">Date</th>
            </tr>
          </thead>

          <tbody>
            {thankYous?.results?.map((thankYou) => {
              const senderDetails = ThankYouUtils.getSenderDetails(thankYou);
              const replyDetails = ThankYouUtils.getReplyDetails(thankYou);

              return (
                <tr
                  key={thankYou._id}
                  className="cstm-Tabledesign cstm-usertable-design"
                  onClick={() => setSelectedThankYou(thankYou)}
                >
                  <td>{thankYou.description}</td>
                  <td style={{ color: replyDetails.isAutoReply ? "red" : "" }}>
                    {replyDetails.reply}
                  </td>
                  <td>
                    <div>{senderDetails?.firstName}</div>
                    <div>{senderDetails?.email}</div>
                    <div>{senderDetails?.phoneNumber}</div>
                  </td>
                  <td>{LabelHelper.getPlatformFlowLabel(thankYou.flow)}</td>
                  <td>
                    {thankYou.createdAt
                      ? formatDateForTable(thankYou.createdAt)
                      : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!thankYous?.results?.length && (
          <div className="NoRecord-cstm">No records found!</div>
        )}
      </div>

      <Pagination
        totalRows={thankYous?.count ?? 0}
        currentPage={pageNumber}
        onPaginationChange={(newPage) => setPageNumber(newPage)}
      />

      <Modal
        show={!!selectedThankYou}
        onClose={() => setSelectedThankYou(null)}
      >
        <ViewThankYou thankYouId={selectedThankYou?._id} />
      </Modal>
    </>
  );
}

function UserGifts({ userId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const { data: gifts } = useGetGiftsByUserId(userId, {
    page: pageNumber,
  });

  /** @type {[import('../../typedefs').Gift, React.Dispatch<import('../../typedefs').Gift>]} */
  const [selectedGift, setSelectedGift] = useState(null);

  return (
    <>
      <h4 className="mb-4">User Gifts</h4>

      <div className="overflow-auto">
        <table className="table mb-0 table-center">
          <thead>
            <tr className="border-top">
              <th className="border-bottom w-4 cstm-userheading">Message</th>
              <th className="border-bottom w-4 cstm-userheading">Reply</th>
              <th className="border-bottom w-4 cstm-userheading">From</th>
              <th className="border-bottom w-4 cstm-userheading">Type</th>
              <th className="border-bottom w-4 cstm-userheading">Platform</th>
              <th className="border-bottom w-4 cstm-userheading">Date</th>
            </tr>
          </thead>

          <tbody>
            {gifts?.results?.map((gift) => {
              const senderDetails = GiftUtils.getSenderDetails(gift);
              const replyDetails = GiftUtils.getReplyDetails(gift);

              return (
                <tr
                  key={gift._id}
                  className="cstm-Tabledesign cstm-usertable-design"
                  onClick={() => setSelectedGift(gift)}
                >
                  <td>{gift.message}</td>
                  <td style={{ color: replyDetails.isAutoReply ? "red" : "" }}>
                    {replyDetails.reply}
                  </td>
                  <td>
                    <div>{senderDetails?.firstName}</div>
                    <div>{senderDetails?.email}</div>
                    <div>{senderDetails?.phoneNumber}</div>
                  </td>
                  <td>{gift.giftCard?.type}</td>
                  <td>{LabelHelper.getPlatformFlowLabel(gift.flow)}</td>
                  <td>
                    {gift.createdAt ? formatDateForTable(gift.createdAt) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!gifts?.results?.length && (
          <div className="NoRecord-cstm">No records found!</div>
        )}
      </div>

      <Pagination
        totalRows={gifts?.count ?? 0}
        currentPage={pageNumber}
        onPaginationChange={(newPage) => setPageNumber(newPage)}
      />

      <Modal show={!!selectedGift} onClose={() => setSelectedGift(null)}>
        <ViewGift giftId={selectedGift?._id} />
      </Modal>
    </>
  );
}
