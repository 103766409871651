import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import Services from "../../Services/auth.service";
import PlacesAutocomplete from 'react-places-autocomplete';
import loader from "../../assets/Images/loder.gif";
import { ALLOWED_IMAGE_MIME_TYPES } from '../../common/constants/file.constant';
import { FormFieldError } from '../../shared/components/FormFieldError';
import { createBreadcrumb, useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';
import { APP_ROUTES } from '../../common/app-routes';

const addOrganizationSchema = yup.object().shape({
    name: yup.string().required("Organization name is required"),
    abbreviatedName: yup.string().required("Abbreviated name is required"),
    sector: yup.string().required("Sector is required"),
    provider: yup.string().required("Provider is required"),
    role: yup.string().required("Role is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    webURL: yup.string().url("Enter a valid website url").required("Website address is required"),
    teamSize: yup.string().required("Team size is required"),
    phase: yup.string().required("Phase is required"),
    isEnableGift: yup.boolean().required("isEnableGift is required").default(true),
    isEnableDisclaimer: yup.boolean().required("isEnableDisclaimer is required").default(true)
});

export function AddOrganization() {
    const navigate = useNavigate();
    const routeLocation = useLocation();
    const { register, watch, handleSubmit, formState: { errors, isSubmitting }, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(addOrganizationSchema),
        defaultValues: {
            sector: "",
            provider: "",
            role: "",
            teamSize: "",
            charityName: "",
            phase: "",
            isEnableGift: true,
            isEnableDisclaimer: false
        },
    });
    const [organizationImg, setOrganizationImg] = useState();
    const [organizationImgPreview, setOrganizationImgPreview] = useState();
    const [organizationImgErr, setOrganizationImgErr] = useState("");
    const [bannerImg, setBannerImg] = useState();
    const [bannerImgPreview, setBannerImgPreview] = useState();
    const [bannerImgErr, setBannerImgErr] = useState("");
    const [charities, setCharities] = useState([]);
    const [location, setLocation] = useState("");
    const [locationErr, setLocationErr] = useState("");
    const [googlePlaceId, setGooglePlaceId] = useState("");

    useEffect(() => {
        getCharities();
    }, []);

    useSetBreadcrumbs([
        createBreadcrumb(APP_ROUTES.ORGANIZATION_LIST, routeLocation),
        createBreadcrumb(APP_ROUTES.ADD_ORGANIZATION),
    ]);

    async function getCharities() {
        try {
            const params = {
                page: 1,
                limit: 100,
                isDelete: false,
                isCharityVerified: true,
            };
            const response = await Services.getCharity(params);

            if (response?.data?.status === 200) {
                if (response?.data?.data?.charity_count?.length) {
                    setCharities(response.data.data.charity_count);
                }
                return;
            }

            toast.error("Couldn't get charities");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get charities");
        }
    }

    function handlePlaceChange(address) {
        setLocation(address);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    function handlePlaceSelect(address, placeId) {
        setLocation(address);
        setGooglePlaceId(placeId);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    function handleBannerImageChange(e) {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            setBannerImgErr("File is not allowed");
            return;
        }

        setBannerImgErr("");
        setBannerImgPreview(URL.createObjectURL(file));
        setBannerImg(file);
    }

    function handleOrganizationImageChange(e) {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            setOrganizationImgErr("File is not allowed");
            return;
        }

        setOrganizationImgErr("");
        setOrganizationImgPreview(URL.createObjectURL(file));
        setOrganizationImg(file);
    }

    async function onSubmit(data) {
        if (!organizationImg) {
            setOrganizationImgErr("Organization image is required");
            return;
        }

        if (!bannerImg) {
            setBannerImgErr("Banner image is required");
            return;
        }

        try {
            const organizationImagePath = await uploadImage(organizationImg);
            const bannerImagePath = await uploadImage(bannerImg);

            const createOrganizationData = {
                ...data,
                image: organizationImagePath,
                banner: bannerImagePath,
                location: location,
                googlePlaceId: googlePlaceId,
                teamName: [],
                isOrganizationVerified: true,
                isCharityVerified: true,
            };

            await createOrganization(createOrganizationData);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't create organization");
        }
    }

    async function createOrganization(organizationData) {
        try {
            const response = await Services.addOrganization(organizationData);

            if (response.data.status === 200) {
                toast.success("Successfully created organization");
                return navigate('/organization');
            }

            toast.error("Couldn't create organization");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't create organization");
        }
    }

    function changeGiftStatus(e) {
        let status = e.target.checked;

        if (!status) {
            setValue('isEnableDisclaimer', false);
        }
    }

    async function uploadImage(image) {
        try {
            const formData = new FormData();
            formData.append("image", image);

            const response = await Services.orgimageGet(formData);

            if (response.status === 200) {
                const uploadedImagePath = response.data.data.images[0].location;
                return uploadedImagePath;
            }

            throw new Error("Couldnt upload image");
        } catch (err) {
            throw err;
        }
    }

    return (
        <SidebarLayoutContent headerTitle='Add Organization'>
            <h4 className="mb-4">Add Organization</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card border-0 shadow rounded mb-4 p-4">
                    <div className="mb-4 col-md-7">
                        <PlacesAutocomplete
                            value={location}
                            onChange={handlePlaceChange}
                            onSelect={handlePlaceSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <i className="fi fi-rr-search cstm-search-ro"></i>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'cstm-input-seacrh location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}

                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                            const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };

                                            return (
                                                <div
                                                    key={suggestion.placeId}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <FormFieldError message={locationErr} />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Place Id</label><br />
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Google place id"
                            value={googlePlaceId}
                            readOnly
                        />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Upload Banner Image</label><br />
                        <div className='cstm-Videoalign'>
                            <label htmlFor="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <input
                                accept="image/*"
                                type="file"
                                name="banner"
                                id="banner-image"
                                className='cstm-uploadHide'
                                onChange={handleBannerImageChange}
                            />
                            {bannerImgPreview && <img src={bannerImgPreview} className="AddOrganization-bannerImg" alt="" />}
                        </div>
                        <FormFieldError message={bannerImgErr} />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Upload Image</label><br />
                        <div className='cstm-Videoalign'>
                            <label htmlFor="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <input
                                accept="image/*"
                                type="file"
                                name="image"
                                id="org-image"
                                className='cstm-uploadHide'
                                onChange={handleOrganizationImageChange}
                            />
                            {organizationImgPreview && <img src={organizationImgPreview} className="AddOrganization-bannerImg" alt="" />}
                        </div>
                        <FormFieldError message={organizationImgErr} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organization Name</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Organization Display Name"
                            {...register("name")}
                        />
                        <FormFieldError message={errors.name?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Abbreviated Name</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Abbreviated Name"
                            {...register("abbreviatedName")}
                        />
                        <FormFieldError message={errors.abbreviatedName?.message} />
                    </div>

                    <div className='mb-4'>
                        <label className="cstm-label">Sector</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="sector-public"
                                value="Public"
                                {...register("sector")}
                            />
                            <label className="form-check-label" htmlFor="sector-public">Public</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="sector-private"
                                value="Private"
                                {...register("sector")}
                            />
                            <label className="form-check-label" htmlFor="sector-private">Private</label>
                        </div>
                        <FormFieldError message={errors.sector?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Provider</label>
                        <select
                            className="form-select cstm-category"
                            {...register("provider")}
                        >
                            <option value="" hidden disabled>Select Provider</option>
                            <option value="PrimaryCare">Primary Care</option>
                            <option value="SecondaryCare">Secondary Care</option>
                            <option value="TertiaryCare">Tertiary Care</option>
                            <option value="Community">Community</option>
                        </select>
                        <FormFieldError message={errors.provider?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Role</label>
                        <select
                            className="form-select cstm-category"
                            {...register("role")}
                        >
                            <option value="" disabled>Your Role?</option>
                            <option value="Team Lead">Team Lead</option>
                            <option value="Manager">Manager</option>
                            <option value="Executive">Executive</option>
                            <option value="Other">Other</option>
                        </select>
                        <FormFieldError message={errors.role?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Team Size</label>
                        <select
                            className="form-select cstm-category"
                            {...register("teamSize")}
                        >
                            <option value="" disabled>Select team size</option>
                            <option value="1-50">1-50</option>
                            <option value="51-250">51-250</option>
                            <option value="250-1000">250-1000</option>
                            <option value="1001+">1001+</option>
                        </select>
                        <FormFieldError message={errors.teamSize?.message} />
                    </div>

                    <div className='mb-4'>
                        <label className="cstm-label">Phase</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-test"
                                value="Test"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-test">Test</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-pilot"
                                value="Pilot"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-test">Pilot/Trial</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-programme"
                                value="Programme"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-test">Programme</label>
                        </div>
                        <FormFieldError message={errors.phase?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organisation Email</label>
                        <input
                            type="email"
                            className="cstm-input"
                            placeholder="Enter Your Organization Email"
                            {...register("email")}
                        />
                        <FormFieldError message={errors.email?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Website Address</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Website Address"
                            {...register("webURL")}
                        />
                        <FormFieldError message={errors.webURL?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Support Charity (Optional)</label>
                        <select
                            className="form-select cstm-category"
                            {...register("charityName")}
                        >
                            <option value="" disabled>Select Charity</option>

                            {charities.map((charity) => <option key={charity._id} value={charity.title}>{charity.title}</option>)}
                        </select>
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Gifting Allowed: </label>
                        <input type="checkbox" name="isEnableGift" {...register("isEnableGift", { onChange: ((e) => changeGiftStatus(e)) })} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Discalimer On/Off: </label>
                        <input type="checkbox" disabled={!watch("isEnableGift")} {...register("isEnableDisclaimer")} />
                    </div>

                    <div className="submit-btnMain cstm-loader">
                        <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Add Organization </button>
                        {isSubmitting && (
                            <div id="login-loader" className='loader-main'>
                                <img src={loader} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </SidebarLayoutContent>
    );
}
