import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import './Dashboard.css';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import statsService from '../../Services/stats.service';
import { GlobalStats } from './GlobalStats';
import { useLoaderStore } from '../../stores/loader.store';
import { DashboardStats } from './DashboardStats';
import { Modal } from '../../shared/components/Modal/Modal';
import { DashboardFilters } from './DashboardFilters';
import { QUERY_KEYS } from '../../common/query-keys';

export function Dashboard() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const queryClient = useQueryClient();
    const [filters, setFilters] = useState({
        organizationId: '',
        teamIds: [],
        year: '',
        dateRangeStart: '',
        dateRangeEnd: '',
        platformFlow: '',
    });
    const [showFilters, setShowFilters] = useState(false);

    const { data: statsRes, isFetching: isLoadingStats } = useQuery({
        queryKey: [QUERY_KEYS.APPRECIATION_STATS, {
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }],
        queryFn: () => statsService.getAppreciationStats({
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId || undefined,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }),
        staleTime: Infinity,
    });
    const stats = statsRes?.data;

    async function refreshStatsCache() {
        setShowLoader(true);

        try {
            const res = await statsService.refreshStatsCache(filters.organizationId || undefined);

            if (res.status === 200) {
                queryClient.invalidateQueries([QUERY_KEYS.GLOBAL_STATS]);
                queryClient.invalidateQueries([QUERY_KEYS.APPRECIATION_STATS]);
                queryClient.invalidateQueries([QUERY_KEYS.ENGAGEMENT_STATS]);
                return;
            }

            toast.error("Couldn't refresh stats cache");
        } catch (err) {
            toast.error("Couldn't refresh stats cache");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <SidebarLayoutContent
            headerTitle='Patient & Staff Experience Measures'
            showHeaderBreadcrumbs={false}
        >
            <GlobalStats />

            <div className='d-flex mb-4'>
                <button type='button' className='custom-filter-icon-btn' onClick={() => setShowFilters(true)}>
                    <i className="fi fi-rr-settings-sliders"></i>
                </button>
                <button type='button' className='custom-filter-icon-btn ml-2' onClick={refreshStatsCache}>
                    <i className="fi fi-rr-refresh"></i>
                </button>
            </div>

            <DashboardStats
                stats={stats}
                filters={filters}
                isLoading={isLoadingStats}
            />

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <DashboardFilters
                    filters={filters}
                    onApplyFilters={(newFilters) => {
                        setFilters(newFilters);
                        setShowFilters(false);
                    }}
                />
            </Modal>
        </SidebarLayoutContent>
    );
}
