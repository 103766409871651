import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDebounceSearchTextFn } from '../../../common/hooks/use-debounce.hook';
import { Link, useLocation } from "react-router-dom";
import { SidebarLayoutHeader } from '../../../shared/components/SidebarLayoutHeader';
import thankYouService from '../../../Services/thank-you.service';
import deleteImg from "../../../assets/Images/delete.png";
import { Modal } from '../../../shared/components/Modal/Modal';
import { ThankyouDeleteReason } from "../../../common/enums/thankyou-delete-reason";
import cloudUpload from "../../../assets/Images/cloud-upload.png";
import { Pagination } from '../../shared/Pagination';
import { formatDateForTable } from '../../../common/utils/date.util';
import { useLoaderStore } from '../../../stores/loader.store';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { DeleteConfirmationModalWithReason } from '../../../shared/components/Modal//DeleteConfiramtionModalWithReason';
import { ReleaseDraftThankYou } from './EditReleaseDraftThank';
import { APP_ROUTES } from '../../../common/app-routes';
import { useSetBreadcrumbs } from '../../../shared/hooks/use-set-breadcrumbs.hook';

const DRAFT_THANKYOU_ACTION = Object.freeze({
    RELEASE: "RELEASE",
    SEND_TO_TEAM: "SEND_TO_TEAM",
    SEND_TO_ORGANIZATION: "SEND_TO_ORGANIZATION",
    DELETE: "DELETE",
});

export function DraftThankYouList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const location = useLocation();
    const [draftThankYous, setDraftThankYous] = useState([]);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [resultsCount, setResultsCount] = useState(0);
    const [archiveStatus, setArchiveStatus] = useState('false');
    const [deleteReason, setDeleteReason] = useState(Object.keys(ThankyouDeleteReason)[0]);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useState('');

    /** @type {[{ draftThankyou: any, action: string }, React.Dispatch<{ draftThankyou: any, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useSetBreadcrumbs([{ label: APP_ROUTES.NO_REPLY_DETAILS_LIST.breadcrumb.label, path: APP_ROUTES.NO_REPLY_DETAILS_LIST.route }]);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        setSearchParams(debouncedSearchText);
    });

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getThankYous(pageNumber, pagination.pageSize);
    }, [archiveStatus]);

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });
        getThankYous(pageNumber, pagination.pageSize);
    }, [searchParams]);

    async function getThankYous(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await thankYouService.getDraftThankyous({
                search: searchParams ? searchParams.trim() : '',
                pageNumber: pageNumber,
                pageSize: pageSize,
                isArchived: archiveStatus
            });

            if (res.status === 200) {
                setDraftThankYous(res.data.results);
                setResultsCount(res.data.count);

                return;
            }

            toast.error("Couldn't get thank yous");
        } catch (err) {
            toast.error("Couldn't get thank yous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getThankYous(pageNumber, pagination.pageSize);
    }

    async function handleRelease() {
        try {
            const selectedDraftThankyouId = selectedRow.draftThankyou._id;
            const res = await thankYouService.releaseDraftThankyou({
                draftThankyouId: selectedDraftThankyouId
            });

            if (res.status === 200) {
                toast.success("Successfully released thank you");
                setDraftThankYous((current) =>
                    current.filter((data) => data._id !== selectedDraftThankyouId)
                );
                return;
            }

            toast.error("Couldn't relase thank you");
        }
        catch (err) {
            console.log(err);
        } finally {
            setSelectedRow(null);
        }
    };

    async function handleDeleteThankyou() {
        try {
            const draftThankyou = selectedRow.draftThankyou;
            const res = await thankYouService.deleteDraftThankyous(
                draftThankyou._id,
                deleteReason
            );

            if (res.status === 200) {
                toast.success("Successfully changed thank you status");
                setDraftThankYous((current) =>
                    current.filter((data) => data._id !== draftThankyou._id)
                );
                return;
            }

            toast.error("Couldn't change thank you status");
        }
        catch (err) {
            console.log(err);
        } finally {
            setSelectedRow(null);
        }
    };

    const onConfirmationCancelClick = () => {
        setSelectedRow(null);
    };

    async function handleSendToOrganization() {
        setShowLoader(true);
        try {
            const selectedThankyou = selectedRow.draftThankyou;
            let data = {
                "appreciationType": "draftThankYou",
                "type": "organization",
                "organizationId": selectedThankyou.organizations[0]._id,
                "appreciationId": selectedThankyou._id
            }

            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            setSelectedRow(null);
        }
    }

    async function handleSendToTeam() {
        setShowLoader(true);
        try {
            const selectedThankyou = selectedRow.draftThankyou;
            let data = {
                "appreciationType": "draftThankYou",
                "type": "team",
                "teamId": selectedThankyou.teams[0]._id,
                "appreciationId": selectedThankyou._id
            }

            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            setSelectedRow(null);
        }
    }

    const handleDeleteInputChange = (e) => {
        const { name, value } = e.target;
        setDeleteReason(value);
    };

    const getMessage = (thankyou) => {
        if (!thankyou.originalMessage) {
            return <span>{thankyou.description}</span>
        }
        return <div>
            {thankyou.originalMessage}
            {<div style={{ color: 'green', marginTop: '10px' }}>{thankyou.description}</div>}

        </div>
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title='No Reply Details' />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className='d-flex justify-content-end align-items-center p-2'>
                                <div className="mr-2">
                                    <i className="fi fi-rr-search cstm-search-ro"></i>
                                    <input
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        type="text"
                                        className="cstm-input-seacrh"
                                        placeholder="Search Thankyou"
                                    />
                                </div>
                            </div>
                            {/* <div className='d-flex justify-content-end p-2'>
                                <div className="col-3 px-1">
                                    <select
                                        className="form-select cstm-category"
                                        placeholder='Filter List'
                                        value={archiveStatus}
                                        onChange={(e) => setArchiveStatus(e.target.value)}
                                    >
                                        <option value="all">All</option>
                                        <option value="true">Archived</option>
                                        <option value="false">To review</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className="col-md-12 col-lg-12">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Message</th>
                                            <th className="border-bottom w-4 cstm-userheading">From</th>
                                            <th className="border-bottom w-4 cstm-userheading">To</th>
                                            <th className="border-bottom w-4 cstm-userheading">Team</th>
                                            <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {draftThankYous?.map((thankYou) => {

                                            return (
                                                <tr
                                                    key={thankYou._id}
                                                    className="cstm-Tabledesign cstm-usertable-design"
                                                >
                                                    <td>{getMessage(thankYou)}</td>
                                                    <td>
                                                        <div>{thankYou.createdUserData?.firstName}</div>
                                                    </td>
                                                    <td>
                                                        <div>{thankYou.users ? thankYou.users[0]?.firstName : null}</div>
                                                        <div>{thankYou.users ? thankYou.users[0]?.email : null}</div>
                                                        <div>{thankYou.users ? thankYou.users[0]?.phoneNumber : null}</div>
                                                    </td>
                                                    <td>
                                                        <div>{thankYou.teams && thankYou.teams[0] ? thankYou.teams[0]?.name : 'N/A'}</div>
                                                    </td>
                                                    <td>{thankYou.createdAt ? formatDateForTable(thankYou.createdAt) : null}</td>
                                                    <td>
                                                        {/* {thankYou.appreciationShareDetails.length > 0 && 'Shared'}
                                                        {!thankYou.appreciationShareDetails.length > 0 && */}
                                                        <div>
                                                            <button
                                                                type='button'
                                                                // className="cstm-eye"
                                                                onClick={() => setSelectedRow({ draftThankyou: thankYou, action: DRAFT_THANKYOU_ACTION.RELEASE })}
                                                                className={`${thankYou.appreciationShareDetails.length > 0 ? 'cstm-delete' : 'cstm-delete-share'} mrn-rt`}
                                                            >
                                                                <i className="fi fi-rr-cloud-upload"></i>

                                                            </button>

                                                            <Link
                                                                to={""}
                                                                onClick={() => setSelectedRow({ draftThankyou: thankYou, action: DRAFT_THANKYOU_ACTION.DELETE })}
                                                                className={`${thankYou.appreciationShareDetails.length > 0 ? 'cstm-delete' : 'cstm-delete-share'} mrn-rt`}
                                                            >
                                                                <i class="fi fi-rr-trash"></i>
                                                            </Link>

                                                            <Link
                                                                to={`/user-thankyou-share?id=${thankYou._id}&type=draftThankYou&senderUserId=${thankYou.userIds[0]}`}
                                                                className="cstm-eye"
                                                                state={{ breadcrumbPath: location.pathname + location.search }}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    // className="cstm-eye"
                                                                    onClick={() => setSelectedRow({ draftThankyou: thankYou })}
                                                                    className={`${thankYou.appreciationShareDetails.length > 0 ? 'cstm-delete' : 'cstm-delete-share'} mrn-rt`}
                                                                // data-bs-toggle="modal"
                                                                >
                                                                    <i className="fi fi-rr-user"></i>

                                                                </button>
                                                            </Link>

                                                            {thankYou?.teams && thankYou?.teams[0] &&
                                                                <button
                                                                    type='button'
                                                                    // className="cstm-eye"
                                                                    onClick={() => setSelectedRow({ draftThankyou: thankYou, action: DRAFT_THANKYOU_ACTION.SEND_TO_TEAM })}
                                                                    className={`${thankYou.appreciationShareDetails.length > 0 ? 'cstm-delete' : 'cstm-delete-share'} mrn-rt`}
                                                                >
                                                                    <i className="fi fi-rr-users-alt"></i>

                                                                </button>}

                                                            {thankYou?.orgIds && thankYou?.orgIds[0] &&
                                                                <button
                                                                    type='button'
                                                                    onClick={() => setSelectedRow({ draftThankyou: thankYou, action: DRAFT_THANKYOU_ACTION.SEND_TO_ORGANIZATION })}
                                                                    className={`${thankYou.appreciationShareDetails.length > 0 ? 'cstm-delete' : 'cstm-delete-share'} mrn-rt`}
                                                                >
                                                                    <i className="fi fi-rr-organization-chart"></i>
                                                                </button>}

                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {!draftThankYous?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}

                                {/* {showLoader && (
                                    <div className="NoRecord-cstm">
                                        <img className="pageloader" src={loader} />
                                    </div>
                                )} */}
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>


                {(selectedRow && selectedRow.action === DRAFT_THANKYOU_ACTION.RELEASE) && (
                    <Modal
                        show={true}
                        onClose={() => setSelectedRow(null)}
                    >
                        <ReleaseDraftThankYou
                            draftThankyou={selectedRow.draftThankyou}
                            onReleaseSuccess={() => {
                                setSelectedRow(null);
                                getThankYous(pagination.pageNumber, pagination.pageSize);
                            }}
                        />
                    </Modal>
                )}

                {/* {(selectedRow && selectedRow.action === DRAFT_THANKYOU_ACTION.RELEASE) && (
                    <ConfirmationModal
                        text='Are you sure you want to release this thank you message?'
                        confirmBtnText='Release'
                        imgSrc={cloudUpload}
                        onConfirm={handleRelease}
                        onCancel={onConfirmationCancelClick}
                    />
                )} */}

                {(selectedRow && selectedRow.action === DRAFT_THANKYOU_ACTION.DELETE) && (
                    <DeleteConfirmationModalWithReason
                        text='Are you sure you want to delete this thank you message?'
                        confirmBtnText='Delete'
                        imgSrc={deleteImg}
                        onConfirm={handleDeleteThankyou}
                        onCancel={onConfirmationCancelClick}
                        handleDeleteInputChange={handleDeleteInputChange}
                    />
                )}

                {(selectedRow && selectedRow.action === DRAFT_THANKYOU_ACTION.SEND_TO_TEAM) && (
                    <ConfirmationModal
                        text={`Are you sure want to share this message to ${selectedRow?.draftThankyou?.teams ? selectedRow?.draftThankyou?.teams[0]?.name : ''} team`}
                        confirmBtnText='Share'
                        showImg={false}
                        onConfirm={handleSendToTeam}
                        onCancel={onConfirmationCancelClick}
                    />
                )}

                {(selectedRow && selectedRow.action === DRAFT_THANKYOU_ACTION.SEND_TO_ORGANIZATION) && (
                    <ConfirmationModal
                        text={`Are you sure you want to share this message with everyone at ${selectedRow?.draftThankyou?.organizations ? selectedRow?.draftThankyou?.organizations[0]?.name : ''}`}
                        confirmBtnText='Share'
                        confirmStyle={{ backgroundColor: 'red' }}
                        showImg={false}
                        onConfirm={handleSendToOrganization}
                        onCancel={onConfirmationCancelClick}
                    />
                )}
            </main>
        </>
    );
}