// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-loader {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 0.8);
    z-index: 1500;
    transition-property: opacity;
    transition-duration: 150ms;
}

.full-screen-loader-spinner {
    width: 56px;
    height: 56px;
    border: 4px solid white;
    border-bottom-color: #00CCCC;
    border-radius: 100%;
    display: inline-block;
    margin-bottom: 1rem;
    animation: loader-spinner-rotation 1s linear infinite;
}

.full-screen-loader-text {
    color: white;
    font-weight: bold;
}

@keyframes loader-spinner-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Loader/FullScreenLoader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,WAAW;IACX,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kCAAkC;IAClC,aAAa;IACb,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,4BAA4B;IAC5B,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;IACnB,qDAAqD;AACzD;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".full-screen-loader {\n    position: fixed;\n    top: 0px;\n    bottom: 0px;\n    left: 0px;\n    right: 0px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: rgb(0 0 0 / 0.8);\n    z-index: 1500;\n    transition-property: opacity;\n    transition-duration: 150ms;\n}\n\n.full-screen-loader-spinner {\n    width: 56px;\n    height: 56px;\n    border: 4px solid white;\n    border-bottom-color: #00CCCC;\n    border-radius: 100%;\n    display: inline-block;\n    margin-bottom: 1rem;\n    animation: loader-spinner-rotation 1s linear infinite;\n}\n\n.full-screen-loader-text {\n    color: white;\n    font-weight: bold;\n}\n\n@keyframes loader-spinner-rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
