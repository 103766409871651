import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import loader from "../../assets/Images/loder.gif";


import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import swal from "sweetalert";
import { get } from "jquery";

function Table({ columns, data }) {
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  }, [])
  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  console.log(props);
  React.useEffect(() => {
    // console.log(globalFilter);
  }, [globalFilter]);
  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  return (
    <>
      <main class="page-content bg-light">
        <Header main_title="Event List" title="Event" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">
              {/* <div class="row row justify-content-end">
                 <div class="col-all-auto">
                   <a
                     href="#"
                     data-bs-toggle="modal"
                     data-bs-target="#viewappointment"
                   >
                     <button class="cstm-btn">Add Organization</button>
                   </a>
                 </div>
               </div> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="row justify-content-between align-items-center p-3">
                    <div class="col-md-3">
                      <h4 class="mb-0">Event List</h4>
                    </div>{" "}
                    <div class="col-md-9">
                      <div class="row row justify-content-end">
                        <div class="col-md-5">
                          {" "}
                          <i class="fi fi-rr-search cstm-search-ro"></i>
                          <input
                            name="serch-cahrity"
                            id="serch-cahrity"
                            type="text"
                            class="cstm-input-seacrh"
                            value={globalFilter || ""}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search event"
                          />
                        </div>
                        <div class="col-all-auto">
                        <Link
                            to="/add-event"
                          >
                            <button class="cstm-btn">Add Event</button>
                          </Link>
                          {/* <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#viewappointment"
                          >
                            <button class="cstm-btn">Add Event</button>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="table-responsive bg-white border-top rounded-0">
                      <table
                        {...getTableProps()}
                        class="table mb-0 table-center"
                      >
                        <thead>
                          {" "}
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  class="border-bottom w-22"
                                  {...column.getHeaderProps()}
                                  onClick={() =>
                                    column.toggleSortBy(!column.isSortedDesc)
                                  }
                                >
                                  {column.render("Header")}

                                  {/* {console.log(column.isSortedDesc)} */}
                                  <span>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i>
                                      ) : (
                                        <i class="fi fi-rr-caret-up cstm-table-up-arrow"></i>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.length > 0 &&
                            page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                  {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {!showLoader ? page.length === 0 &&
                        <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-12 mt-4">
                <div class="d-md-flex align-items-center text-center justify-content-between">
                  <span class="text-muted me-3">
                    {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                  </span>
                  <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        href="javascript:void(0)"
                      >
                        Prev
                      </a>
                    </li>}
                    {
                      pageOptions.map(pgnumber => {
                        return (
                          pgnumber !== pageCount && pageCount !== 1 &&
                          <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} >
                            <a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a>
                          </li>)

                      })
                    }
                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        href="javascript:void(0)"
                      >
                        Next
                      </a>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function Index() {
  const [eventData, setEventData] = useState([]);

  const [formFields, setFormFields] = useState({
    title: "",
    description: "",
    location: "",
    // category: "",
    // isCharityVerified: true,
  });
  const [err, seterr] = useState({
    title: "",
    description: "",
    location: "",
    // category: "",
  });
  eventData.forEach((eventData_id, index) => {
    eventData_id.serial = index + 1;
  });

  var DataArray = [];
  useEffect((data, e) => {
    getData(1);
  }, []);
  const getData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      isDelete: false,
      isCharityVerified: true,
    };

    Services.getEvent(queryVar)
      .then((response) => {
        //  console.log(response,"respo eve")
        // return false;
        if (response.data.status === 200) {
          if (response.data.data.event_data.length !== 0) {
            var data = response.data.data.event_data;
            const getDate = (el) =>
              new Date(
                el.createdAt.substring(0, el.createdAt.indexOf("T"))
              ).toLocaleDateString("en-Us");
            var allData = data.map((el) => ({ ...el, createdAt: getDate(el) }));

            allData.map((data1) => {
              DataArray = [...DataArray,data1]
            })
            if (response.data.data.state) {
              getData(response.data.data.state.page + 1);
            }
          }
          setEventData(DataArray);
          //    console.log(response.data.data.event_data[0], "success get char");
        } else {
          console.log("fail get char");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });

  }
  //delet---------

  const onClickCancel = () => {
    document.querySelector('#cancelappointment').classList.remove('show');
    document.querySelector('.modal-backdrop').remove();
  }
  const [selectedEid, setselectedEid] = useState();

  const handleDelete = () => {
    console.log(selectedEid, "selectedEid");
    var data = {
      id: selectedEid,
      isDelete: true
    }

    Services.editEvent(data)
      .then((response) => {
        console.log(response, "response");
        // return false;
        if (response.status === 200) {

          getData(1);
          swal("Success", response.data.message, "success");
          document.querySelector('.modal-backdrop').remove();
          document.querySelector('#cancelappointment').classList.remove('show');
          document.querySelector("#cancelappointment .btn-close").click();

        } else {
          swal("Failed", err.response.data.message, "error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };
  eventData.forEach((eventData_id, index) => {
    eventData_id.nullLocation = "-";
  });
  const columns = [
    {
      Header: "No",
      accessor: "serial",
    },

    {
      Header: "Event name",
      accessor: "title",
      sortable: true,
    },
    {
      Header: "Description",
      accessor: "description",

      // Use our custom fuzzyText filter on this column
    },
    {
      Header: "Location",
      accessor: "nullLocation",
    },
    {
      Header: "Date",
      accessor: "createdAt",
    },
    {
      Header: "Hit link count",
      accessor: "htLinkCount",
      // sortable: true,
    },

    {
      Header: "Actions",

      Cell: (row) => {
        return (
          <div>
             {/* <Link
              to={"/view-event/?id=" + row.cell.row.original._id}
              class="cstm-eye"
            >
              <i class="fi fi-rr-eye"></i>
            </Link>
             */}
             <Link
              to={"/edit-event/?id=" + row.cell.row.original._id}
              class="cstm-eye"
            >
              <i class="fi fi-rr-pencil"></i>
            </Link>
           {/* <Link
              class="cstm-eye"
              data-bs-toggle="modal"
              data-bs-target="#editappointment"
              to={" "}
              onClick={() =>
                handlePopup(row.cell.row.original._id)
              }
            >
              <i class="fi fi-rr-pencil"></i>
            </Link> */}
           
            <Link
              to={" "}
              onClick={() => setselectedEid(row.cell.row.original._id)}
              class="cstm-delete mrn-rt"
              data-bs-toggle="modal"
              data-bs-target="#cancelappointment"
            >
              <i class="fi fi-rr-trash"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "title":
        err.title = value.length > 0 ? "" : "Enter event name";
        break;
      case "description":
        err.description = value.length > 0 ? "" : "Enter description";
        break;
      case "location":
        err.location = value.length > 0 ? "" : "Enter location";
        break;
      // case "country":
      //   err.country = value.length > 0 ? "" : "Enter country";
      //   break;
      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (formFields.title === "") {
      err.title = "Enter event name";
    }
    if (formFields.description === "") {
      err.description = "Enter description";
    }
    if (formFields.location === "") {
      err.location = "Enter location";
    }
    // if (formFields.country === "") {
    //   err.country = "Enter country";
    // }
    console.log(err, "title");
    seterr({ ...err });

    if (
      err.title === "" &&
      err.description === "" &&
      err.location === ""
    ) {
      var addEvent = {
        // "title": "cherity post type test",

        title: formFields.title,
        description: formFields.description,
        location: formFields.location,

        // category: formFields.category,
      };
      console.log(addEvent, "addEvent");
      Services.addEvent(addEvent)
        .then((response) => {
          console.log(response, "responce data");
          // return false;
          if (response.data.status == 200) {
            getData(1);
            swal("Success", response.data.message, "success");
            document.querySelector('.modal-backdrop').remove();
            document.querySelector('#viewappointment').classList.remove('show');
          } else {
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          swal("Failed", err.response.data.message, "error");

        });
    }
  };
//edit event module

  function handlePopup(categoryID) {
    // setCatID(categoryID);
// console.log(categoryID,"categoryID");
    Services.getEventById(categoryID)
      .then((response) => {
        console.log(response, "resonce 13454");
        if (response.data.status === 200) {
          // console.log(response.data.data.title,"response.data.data.title");
          setFormFields( response.data.data );
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });

      console.log(formFields,"formFields");
  }
  return (
    <>
      <Table columns={columns} data={eventData} />
      <div
        class="modal fade"
        id="viewappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Add Event
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4 pt-0">
              <form onSubmit={handleOnSubmit}>
                <div class="mb-4 row">
                  <label class="cstm-label">Event Name</label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter charity name"
                    onChange={handleOnchange}
                  />
                  {err.title !== "" && (
                    <span style={{ color: "red" }}>{err.title}</span>
                  )}
                </div>
                <div class="mb-4 row">
                  <label class="cstm-label">Description</label>
                  <input
                    name="description"
                    id="description"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter description"
                    onChange={handleOnchange}
                  />
                  {err.description !== "" && (
                    <span style={{ color: "red" }}>{err.description}</span>
                  )}
                </div>
                <div class="mb-4 row">
                  <label class="cstm-label">Location</label>
                  <input
                    name="location"
                    id="location"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter city"
                    onChange={handleOnchange}
                  />
                  {err.location !== "" && (
                    <span style={{ color: "red" }}>{err.location}</span>
                  )}
                </div>


                <div class="d-flex">
                  <button class=" cstm-btn">Add Event</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit event model */}

      <div
        class="modal fade"
        id="editappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Edit Event
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4 pt-0">
            <form id="edit-popup" onSubmit={handleOnSubmit}>
                <div class="mb-4 row">
                  <label class="cstm-label">Event Name</label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter charity name"
                    onChange={handleOnchange}
                  />
                  {err.title !== "" && (
                    <span style={{ color: "red" }}>{err.title}</span>
                  )}
                </div>
                <div class="mb-4 row">
                  <label class="cstm-label">Description</label>
                  <input
                    name="description"
                    id="description"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter description"
                    onChange={handleOnchange}
                  />
                  {err.description !== "" && (
                    <span style={{ color: "red" }}>{err.description}</span>
                  )}
                </div>
                <div class="mb-4 row">
                  <label class="cstm-label">Location</label>
                  <input
                    name="location"
                    id="location"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter city"
                    onChange={handleOnchange}
                  />
                  {err.location !== "" && (
                    <span style={{ color: "red" }}>{err.location}</span>
                  )}
                </div>


                <div class="d-flex">
                  <button class=" cstm-btn">Edit Event</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* //delet  */}
      <div
        class="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body py-5">
              <div class="text-center">
                <div class="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ display: "none" }}>
                </button>
                <h4 class="mt-4">
                  Are sure you want to delete
                  <br /> this event?
                </h4>
                <div class="mt-4">
                  <button
                    class="mr-3 cstm-btn cstn-discard"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button class="cstm-btn2 cstn-discard" onClick={onClickCancel}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
