import { AppPlatform } from "../app.enum";

export class ThankYouUtils {
  static getSenderDetails(thankYou) {
    const senderDetails = { firstName: "", email: "", phoneNumber: "" };

    if (thankYou?.platform === AppPlatform.Mobile) {
      senderDetails.firstName = thankYou?.createdBy?.firstName;
      senderDetails.email = thankYou?.createdBy?.email;
      senderDetails.phoneNumber = thankYou?.createdBy?.phoneNumber;
    } else if (thankYou?.platform === AppPlatform.Web) {
      senderDetails.firstName = thankYou?.createdUserData?.firstName;
      senderDetails.email = thankYou?.createdUserData?.email;
      senderDetails.phoneNumber = thankYou?.createdUserData?.phoneNumber;
    }

    return senderDetails;
  }

  static getReplyDetails(thankYou) {
    let reply = "";
    let isAutoReply = false;

    if (thankYou?.replies?.[0]?.description) {
      reply = thankYou?.replies?.[0]?.description;
    } else if (thankYou?.autoReply?.[0]?.replyMessage) {
      reply = thankYou?.autoReply?.[0]?.replyMessage;
      isAutoReply = true;
    }

    return {
      reply: reply,
      isAutoReply: isAutoReply,
    };
  }
}
