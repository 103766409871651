import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../../../shared/components/Modal/Modal';
import { useDebounceSearchTextFn } from '../../../common/hooks/use-debounce.hook';
import { EditNonUserThankyou } from './EditNonUserThankyou';
import { DeleteConfirmationModalWithReason } from '../../../shared/components/Modal/DeleteConfiramtionModalWithReason';
import { ThankyouDeleteReason } from "../../../common/enums/thankyou-delete-reason";
import deleteImg from "../../../assets/Images/delete.png";
import { formatDateForTable } from '../../../common/utils/date.util';
import nonUserService from "../../../Services/non-user.service";
import thankYouService from "../../../Services/thank-you.service";
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import { Pagination } from '../../shared/Pagination';
import { useLoaderStore } from '../../../stores/loader.store';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';

const UNREGISTERED_USER_MESSAGE_ACTION = Object.freeze({
    EDIT: "EDIT"
});

export function NonUserThankYouList() {
    const location = useLocation();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [thankYous, setThankYous] = useState([]);
    const [deleteThankyou, setDeleteThankyou] = useState('');
    const [selectedThankyou, setSelectedThankyou] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchData, setSearchData] = useState('');
    const [deleteReason, setDeleteReason] = useState(Object.keys(ThankyouDeleteReason)[0]);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        setSearchData(debouncedSearchText);
    });

    useEffect(() => {
        getUsernonUserThankyous();
    }, []);

    useEffect(() => {
        getUsernonUserThankyous();
    }, [searchParams, searchData]);

    async function getUsernonUserThankyous() {

        try {
            const res = await nonUserService.getTeamNonUserThankyous(false, {
                page: pagination.pageNumber,
                limit: pagination.pageSize,
                search: searchData
            });

            if (res.status === 200) {
                setThankYous(res.data.thankyou_data);
                setResultsCount(res.data.state?.data_count);
                // toast.success("successfully fetched thankyous");
                return;
            }

            toast.error("Couldn't get thankyous");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get thankyous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams({}, '', pageNumber));
    }

    async function handleSendToOrganization() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "nonUserThankyou",
                "type": "organization",
                "organizationId": selectedThankyou.organizationId,
                "appreciationId": selectedThankyou._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to organization");
                return;
            }
            toast.error("Couldn't send thank yous to organization");
        } catch (err) {
            toast.error("Couldn't send thank yous to organization");
        }
        finally {
            setShowLoader(false);
            handleSendOrganizationCancel();
        }
    }

    async function handleSendToTeam() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "nonUserThankyou",
                "type": "team",
                "teamId": selectedThankyou.team[0]._id,
                "appreciationId": selectedThankyou._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            handleSendTeamCancel();
        }
    }

    const handleSendTeamCancel = () => {
        setSelectedThankyou(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendTeamConfirmationThankyou').classList.remove('show');
    }

    const handleSendOrganizationCancel = () => {
        setSelectedThankyou(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendOrganizationConfirmationThankyou').classList.remove('show');
    }

    async function handleDeleteThankyou() {
        document.querySelector(".modal-backdrop").remove();
        document.querySelector("#deleteThankyou").classList.remove("show");
        document.querySelector("#deleteThankyou .btn-close").click();

        try {
            const res = await nonUserService.deleteNonUserThankyou(deleteThankyou, deleteReason);

            if (res.status === 200) {
                toast.success("Successfully deleted thank you");
                setThankYous((current) =>
                    current.filter((data) => data._id !== deleteThankyou)
                );
                return;
            }

            toast.error("Couldn't delete thank you");
        }
        catch (err) {
            toast.error("Couldn't delete thank you");
            console.log(err);
        }
    };

    const onClickCancelDeletePopup = () => {
        document.querySelector("#deleteThankyou").classList.remove("show");
        document.querySelector(".modal-backdrop").remove();
        setSelectedThankyou(null);
    };


    const handleDeleteInputChange = (e) => {
        const { name, value } = e.target;
        setDeleteReason(value);
    };

    const getMessage = (thankyou) => {
        if (!thankyou.originalMessage) {
            return <span>{thankyou.message}</span>
        }
        return <div>
            {thankyou.originalMessage}
            {<div style={{ color: 'green', marginTop: '10px' }}>{thankyou.message}</div>}

        </div>
    }


    // async function handleDeleteThankyou() {
    //     setShowLoader(true);
    //     try {
    //         const deleteThankyou = selectedRow.thankYou;
    //         const res = await nonUserService.deleteNonUserThankyou(deleteThankyou._id, deleteReason);

    //         if (res.status === 200) {
    //             toast.success("Successfully deleted thank you");
    //             setThankYous((thankYou) => thankYou.filter((t) => t._id !== deleteThankyou._id));
    //             return;
    //         }

    //         toast.error("Couldn't delete thank you");
    //     } catch (err) {
    //         console.log(err);
    //         toast.error("Couldn't delete thank you");
    //     } finally {
    //         setSelectedThankyou(null);
    //         setShowLoader(false);
    //     }
    // }

    return (
        <SidebarLayoutContent headerTitle='Non User Thank you List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search Thankyou"
                        />
                    </div>
                    <div className="d-flex mb-2">
                        <Link
                            to="/non-user-deleted-thankyou-list"
                            className='cstm-btn lh-base ml-2'
                        >
                            Deleted Unregistered User Thankyous
                        </Link>
                    </div>

                    {/* <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search"
                        />
                    </div> */}
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                <th className="border-bottom w-4 cstm-userheading">Team</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankyou, index) => (
                                <tr
                                    key={thankyou._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{getMessage(thankyou)}</td>

                                    <td>
                                        <div>{thankyou.fromUserData?.firstName}</div>
                                        <div>{thankyou.fromUserData?.email ? thankyou.fromUserData?.email : ''}</div>
                                        <div>{thankyou.fromUserData?.phoneNumber ? thankyou.fromUserData?.phoneNumber : ''}</div>
                                    </td>
                                    <td>{thankyou.toStaffName} {thankyou.toStaffLastName}- <br />Unregistered</td>
                                    <td>{thankyou.organization[0]?.name}</td>
                                    <td>{thankyou.team[0]?.name}</td>
                                    <td>{formatDateForTable(thankyou.createdAt)}</td>
                                    <td>
                                        <div>
                                            {/* {thankyou.appreciationShareDetails.length > 0 && 'Shared'}
                                            {!thankyou.appreciationShareDetails.length > 0 &&                                          */}
                                            <span>
                                                {thankyou.fromUserData && thankyou.fromUserData.email && <Link
                                                    to={""}
                                                    onClick={() => setSelectedThankyou({ thankYou: thankyou, action: UNREGISTERED_USER_MESSAGE_ACTION.EDIT })}
                                                    className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                >
                                                    <i className="fi fi-rr-pencil"></i>
                                                </Link>}

                                                <Link
                                                    to={`/user-thankyou-share?id=${thankyou._id}&type=nonUserThankyou&teamIds=${thankyou.teamId}`}
                                                    // className="cstm-eye"
                                                    className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                    state={{ breadcrumbPath: location.pathname + location.search }}
                                                >
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedThankyou(thankyou)}
                                                        // className="cstm-delete mrn-rt"
                                                        className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                    >
                                                        <i className="fi fi-rr-user"></i>
                                                    </button>
                                                </Link>

                                                {thankyou?.team && thankyou?.team[0] &&
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedThankyou(thankyou)}
                                                        // className="cstm-delete mrn-rt"
                                                        className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#sendTeamConfirmationThankyou"
                                                    >
                                                        <i className="fi fi-rr-users-alt"></i>
                                                    </button>}


                                                {thankyou?.organizationId && thankyou.organization[0] &&
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedThankyou(thankyou)}
                                                        className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#sendOrganizationConfirmationThankyou"
                                                    >
                                                        <i className="fi fi-rr-organization-chart"></i>
                                                    </button>}
                                            </span>
                                            {/* } */}
                                            <button
                                                to={" "}
                                                onClick={() => setDeleteThankyou(thankyou._id)}
                                                // className="cstm-delete mrn-rt"
                                                className={`${thankyou.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteThankyou"
                                            >
                                                <i className="fi fi-rr-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            <div
                className="modal fade"
                id="sendOrganizationConfirmationThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure you want to share this message with everyone at
                                    {selectedThankyou?.organization ? ` ${selectedThankyou?.organization[0].name}` : ''}
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        style={{ backgroundColor: 'red' }}
                                        onClick={handleSendToOrganization}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendOrganizationCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="sendTeamConfirmationThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to share this message to
                                    <br /> {selectedThankyou?.teams ? selectedThankyou?.teams[0]?.name : ''} team
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToTeam}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendTeamCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(selectedThankyou && selectedThankyou.action === UNREGISTERED_USER_MESSAGE_ACTION.EDIT) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedThankyou(null)}
                >
                    <EditNonUserThankyou
                        nonUserThankyou={selectedThankyou.thankYou}
                        onEditSuccess={() => {
                            setSelectedThankyou(null);
                            getUsernonUserThankyous();
                        }}
                    />
                </Modal>
            )}

            {/* confirmation dialog delete */}
            <div
                className="modal fade"
                id="deleteThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src={deleteImg} alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to delete
                                    <br /> this thank you message and replies related to this?
                                </h4>
                                <div>
                                    Reason:
                                    <select
                                        name="deleteReason"
                                        className="form-select cstm-category mr-1"
                                        value={deleteReason}
                                        onChange={handleDeleteInputChange}
                                    >
                                        {Object.entries(ThankyouDeleteReason).map(([key, reason]) => (
                                            <option key={key} value={key}>
                                                {reason}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleDeleteThankyou}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="cstm-btn2 cstn-discard"
                                        onClick={onClickCancelDeletePopup}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayoutContent>
    );
}

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams);
}
