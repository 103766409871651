import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import loader from '../../assets/Images/loder.gif';
import Header from '../NavMenuBar/Header';
import partiallyRegisteredUserService from '../../Services/partially-registered-user.service';

export function UserSignupRequestList() {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const [showLoader, setShowLoader] = useState(false);
    const [userRequests, setUserRequests] = useState([]);

    useEffect(() => {
        getUserSignupRequests(userId);
    }, []);

    async function getUserSignupRequests(userId) {
        setShowLoader(true);

        try {
            const res = await partiallyRegisteredUserService.getUserSignupRequests(userId);

            if (res.status === 200) {
                setUserRequests(res.data);

                return;
            }

            toast.error("Couldn't get signup requests");
        } catch (err) {
            toast.error("Couldn't get signup requests");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <main className="page-content bg-light">
            <Header
                title1="Edit Partially Registered User"
                title="Partially Registered Users"
                main_title="Partially Registered Users"
                title_link="partially-registered-users"
            />
            <div className="container-fluid">
                <div className="layout-specing">
                    <h4 className="mb-4">Signup Requests</h4>
                    <div className="row">
                    </div>
                </div>

                <div className="col-md-12 col-lg-12">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Firstname</th>
                                <th className="border-bottom w-4 cstm-userheading">Approve Status</th>
                                <th className="border-bottom w-4 cstm-userheading">Status Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            {userRequests?.approvalDetails?.map((user) => (
                                <tr
                                    key={user._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{user.leadId?.firstName || user.leadName}</td>
                                    <td>{user.status}</td>
                                    <td>{user.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {!userRequests?.approvalDetails?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}

                    {showLoader && (
                        <div className="NoRecord-cstm">
                            <img className="pageloader" src={loader} />
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
}