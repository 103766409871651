import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { formatOrganizationForDropdown, formatTeamForMultiSelectDropdown } from '../../utils/filter.util';
import giftCardService from '../../Services/gift-card.service';
import { useGetOrganizations } from '../../hooks/organization/organization.hook';
import { useGetTeamsByOrganizationId } from '../../hooks/team/team.hook';
import { GiftProcessStateValues } from '../../features/gift/models/gift-process-state.enum';
import { MultiSelectDropdown } from '../../shared/components/MultiSelectDropdown/MultiSelectDropdown';
import { getDateStrFromDateObj } from '../../common/utils/date.util';
import { sortObjectArrayByStringField } from '../../common/utils/utils';

export function GiftListFilters({ filters, onApplyFilters }) {
    const [organizationFilterOptions, setOrganizationFilterOptions] = useState([]);
    const [teamFilterOptions, setTeamFilterOptions] = useState([]);
    const [giftCards, setGiftCards] = useState([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(filters.organizationId);
    const [selectedTeamIds, setSelectedTeamIds] = useState(filters.teamIds);
    const [selectedGiftCardId, setSelectedGiftCardId] = useState(filters.giftCardId);
    const [selectedGiftProcessState, setSelectedGiftProcessState] = useState(filters.giftProcessState);
    const [selectedPlatformFlow, setSelectedPlatformFlow] = useState(filters.platformFlow);
    const [selectedDateFilterType, setSelectedDateFilterType] = useState(filters.dateFilterType);
    const [dateRangeStart, setDateRangeStart] = useState(() => {
        if (filters.dateRangeStart) {
            return new Date(filters.dateRangeStart);
        }

        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        return currentDate;
    });
    const [dateRangeEnd, setDateRangeEnd] = useState(() => {
        if (filters.dateRangeEnd) {
            return new Date(filters.dateRangeEnd);
        }

        const currentDate = new Date();
        return currentDate;
    });

    const { data: organizationsData } = useGetOrganizations();
    const { data: teamsData } = useGetTeamsByOrganizationId(selectedOrganizationId);

    useEffect(() => {
        getGiftTypes();
    }, []);

    useEffect(() => {
        if (organizationsData?.results) {
            const organizationFilterOptionsData = organizationsData?.results?.map((team) => formatOrganizationForDropdown(team));
            sortObjectArrayByStringField(organizationFilterOptionsData, 'label');
            setOrganizationFilterOptions(organizationFilterOptionsData);
        }
    }, [organizationsData]);

    useEffect(() => {
        if (teamsData?.results) {
            const teamFilterOptionsData = teamsData?.results?.map((team) => formatTeamForMultiSelectDropdown(team));
            setTeamFilterOptions(teamFilterOptionsData);
        }
    }, [teamsData]);

    async function getGiftTypes() {
        try {
            const res = await giftCardService.getGiftCards();

            if (res.status == 200) {
                setGiftCards(res.data?.results);
                return;
            }

            toast.error("Couldn't get gift cards");
        } catch (err) {
            toast.error("Couldn't get gift cards");
        }
    }

    function onApplyClick() {
        onApplyFilters({
            ...filters,
            organizationId: selectedOrganizationId,
            teamIds: selectedTeamIds,
            giftCardId: selectedGiftCardId,
            giftProcessState: selectedGiftProcessState,
            platformFlow: selectedPlatformFlow,
            dateFilterType: selectedDateFilterType,
            dateRangeStart: selectedDateFilterType === "range" ? getDateStrFromDateObj(dateRangeStart) : '',
            dateRangeEnd: selectedDateFilterType === "range" ? getDateStrFromDateObj(dateRangeEnd) : '',
        });
    }

    return (
        <div>
            <div className="mb-2">
                <select
                    className="form-select cstm-category"
                    placeholder='Filter organization'
                    value={selectedOrganizationId}
                    onChange={(e) => {
                        setSelectedOrganizationId(e.target.value);
                        setTeamFilterOptions([]);
                        setSelectedTeamIds([]);
                    }}
                >
                    <option value="">All Organizations</option>

                    {organizationFilterOptions.map((organization) => (
                        <option key={organization.value} value={organization.value}>{organization.label}</option>
                    ))}
                </select>
            </div>
            <div className="mb-2">
                <MultiSelectDropdown
                    label="Teams"
                    items={teamFilterOptions}
                    selectedIds={selectedTeamIds}
                    disabled={!selectedOrganizationId}
                    onChange={(selectedIds) => {
                        setSelectedTeamIds(selectedIds);
                    }}
                />
            </div>
            <div className="mb-2">
                <select
                    className="form-select cstm-category"
                    placeholder='Filter gift type'
                    value={selectedGiftCardId}
                    onChange={(e) => setSelectedGiftCardId(e.target.value)}
                >
                    <option value="">All Gift Types</option>

                    {giftCards?.map((giftCard) => (
                        <option key={giftCard._id} value={giftCard._id}>{giftCard.type}</option>
                    ))}
                </select>
            </div>
            <div className="mb-2">
                <select
                    className="form-select cstm-category"
                    value={selectedGiftProcessState}
                    onChange={(e) => setSelectedGiftProcessState(e.target.value)}
                >
                    <option value="">All States</option>

                    {GiftProcessStateValues.map((processState) => (
                        <option key={processState} value={processState}>{processState}</option>
                    ))}
                </select>
            </div>
            <div className='mb-2'>
                <select
                    className="form-select cstm-category"
                    value={selectedPlatformFlow}
                    onChange={(e) => setSelectedPlatformFlow(e.target.value)}
                >
                    <option value="">PX/SX</option>
                    <option value="1">Patient Experience</option>
                    <option value="2">Staff Experience</option>
                </select>
            </div>
            <div className='mb-2'>
                <select
                    className="form-select cstm-category"
                    value={selectedDateFilterType}
                    onChange={(e) => setSelectedDateFilterType(e.target.value)}
                >
                    <option value="">All Time</option>
                    <option value="range">Date Range</option>
                </select>
            </div>
            <div className='d-flex mb-2'>
                <div className='w-50 mr-1'>
                    <DatePicker
                        selected={dateRangeStart}
                        onChange={(date) => setDateRangeStart(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                        disabled={selectedDateFilterType !== "range"}
                    />
                </div>
                <div className='w-50 ml-1'>
                    <DatePicker
                        selected={dateRangeEnd}
                        onChange={(date) => setDateRangeEnd(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                        disabled={selectedDateFilterType !== "range"}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
                <button type='button' className='cstm-submitbutton' onClick={onApplyClick}>Apply</button>
            </div>
        </div>
    );
}
