// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-to-clipboard-icon-btn {
  height: 40px;
  width: 40px;
  border: 1px solid #00cccc;
  border-radius: 50%;
  color: #00cccc;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

.copy-to-clipboard-icon-btn:hover {
  background-color: #00cccc;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/CopyToClipboard/CopyToClipboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".copy-to-clipboard-icon-btn {\n  height: 40px;\n  width: 40px;\n  border: 1px solid #00cccc;\n  border-radius: 50%;\n  color: #00cccc;\n  background-color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  line-height: 0;\n}\n\n.copy-to-clipboard-icon-btn:hover {\n  background-color: #00cccc;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
