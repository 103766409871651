export const DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const STATS_START_DETAILS = {
    YEAR: 2022,
    MONTH: 12,
};

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const YEARS = [];

for (let i = STATS_START_DETAILS.YEAR; i <= currentYear; i++) {
    YEARS.push(i);
}

export { YEARS };
