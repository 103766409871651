import baseApi from "./base-api";

function getWelcomeEmailSettings()  {
    return baseApi.get(`admin/email-template/Welcome Email`);
} 

function updateEmailTemplate(id, videoLinks) {
    return baseApi.put(`admin/email-template/${id}`, 
        videoLinks
    );
}

const EmailSettingService = {
    getWelcomeEmailSettings: getWelcomeEmailSettings,
    updateEmailTemplate: updateEmailTemplate
};

export default EmailSettingService;