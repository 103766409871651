import React, { useEffect, useState } from 'react'
import Header from '../NavMenuBar/Header';
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import $ from 'jquery';
import { Col, Container, Row } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";


function AddGift() {

	//new state for gifting according to countries
	const [countryData, setCountryData] = useState([]);
	const [selectedCountries, setSelectedCountries] = useState([]);
	const [selectedCountryPricing, setSelectedCountryPricing] = useState([]);

	setTimeout(() => {
		$(".gift-price").keypress(function (e) {
			var charCode = (e.which) ? e.which : e.keyCode;

			if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
				return false;
			}
			return true;

		});

	}, 500);

	const history = useNavigate();
	const getid = useLocation().search;
	const cahrID = new URLSearchParams(getid).get('id');


	useEffect(() => {

		const loggedInUser = localStorage.getItem("token");
		!loggedInUser && history("/");
		// $(".gift-price").keypress(function (e) {
		// 	var charCode = (e.which) ? e.which : e.keyCode;
		// 	console.log(charCode, "charcode");
		// 	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		// 		return false;
		// 	}
		// 	return true;

		// });

	})
	const [formFields, setFormFields] = useState({
		type: "",
		giftName: "",
		title: "",
		sub_title: "",
		message: "",
		status: "",
		banner: "",
		platformFee: "",
		image_video: [],


	});
	const [err, seterr] = useState({
		type: "",
		giftName: "",
		title: "",
		sub_title: "",
		message: "",
		price: "",
		status: "",
		banner: "",
		image_video: "",


	});


	const [imagesPrev, setImagesPrev] = useState([]);
	const [finalImages, setFinalImages] = useState([]);
	const [multiData, setMultiData] = useState([]);


	var imgExtArray = [];
	const arrayImages = [];
	var imgArray = [];

	function deleteImages(e) {
		const s = imagesPrev.filter((item, index) => index !== e);
		const fs = finalImages.filter((item, index) => index !== e);

		setFinalImages([...fs]);
		setImagesPrev([...s]);
	}
	const handleImageChange = (e) => {
		e.preventDefault();
		err.image_video = e.target.value.length > 0 ? "" : "Select image or video";
		var files = e.target.files;
		var filesArr = Array.prototype.slice.call(files);

		filesArr.forEach(function (f, index) {
			imgArray = [...imgArray, f];
		});
		setFinalImages([...finalImages, ...imgArray]);
		const arr = [];
		console.log(imgArray, "imgArraydatatata");
		imgArray.forEach(function (f, index) {
			console.log(f, "f");
			// fileArray.push(f);
			var u = URL.createObjectURL(f);
			arr.push(u);
			var filesplit = f.name.split(".").pop();
			var imageExtension = [
				"png",
				"jpg",
				"jpeg",
				"apng",
				".avif",
				"jfif",
				"pjpeg",
				"pjp",
				"svg",
				"webp",
			];
			var videoExtension = [
				"mp4",
				"MOV",
				"mov",
				"wmv",
				"avi",
				"avchd",
				"flv",
				"f4v",
				"swf",
				"mkv",
				"webm",
				"html5",
				"mpeg-2",
			];
			imageExtension.includes(filesplit) &&
				imgExtArray.push({ url: u, type: "image" });
			videoExtension.includes(filesplit) &&
				imgExtArray.push({ url: u, type: "video" });
		});

		setImagesPrev([...imagesPrev, ...imgExtArray]);
		setMultiData([...multiData, ...arr]);
	};
	//image onchange 

	// const [file, setFile] = useState();
	// const handleImageChange = (e) => {
	// 	e.preventDefault();
	// 	setFile(URL.createObjectURL(e.target.files[0]));
	// 	let charImage = e.target.files[0];
	// 	var fileExt = charImage.name.split('.').pop();

	// 	var ExtArray = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2", "png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

	// 	ExtArray.includes(fileExt) ? (err.banner = "") :
	// 		(
	// 			err.banner = "Please upload image or video only",
	// 			formFields.banner = ""
	// 		);

	// 	var videoDisplay = document.getElementById("video-uploaded");

	// 	var imageDisplay = document.getElementById("b-img");

	// 	var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];

	// 	var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

	// 	videoExtension.includes(fileExt) ?
	// 		(
	// 			videoDisplay.style.display = "block",
	// 			imageDisplay.style.display = "none"
	// 		) :
	// 		(videoDisplay.style.display = "none"
	// 			// imageDisplay.style.display = "block"
	// 		);

	// 	seterr({ ...err });

	// 	if (err.banner === "") {
	// 		let formData = new FormData();
	// 		formData.append("image", e.target.files[0]);

	// 		Services.charimageGet(formData)
	// 			.then((response) => {
	// 				if (response.status === 200) {
	// 					formFields.banner = response.data.data.images[0].location;
	// 				}
	// 			})
	// 			.catch(function (err) {
	// 				console.log(err, "err");
	// 			});
	// 	}
	// };
	//repeater field add
	const [priceField, setPriceField] = useState([{ price: "" }])
	var priceArray = [];
	const handleOnchangePrice = (i, e) => {
		const { value, name } = e.target;

		if (name === "price") {
			err.price = value.length > 0 ? "" : "Enter price";
		}
		seterr({ ...err })
		let newFormValues = [...priceField];
		newFormValues[i][e.target.name] = e.target.value;
		setPriceField(newFormValues);
	}

	const addPriceFields = () => {
		setPriceField([...priceField, { price: "" }])
	}

	const removeFormFields = (i) => {
		let newFormValues = [...priceField];
		newFormValues.splice(i, 1);
		setPriceField(newFormValues)
	}
	priceField.map((price1) => {
		priceArray.push(price1.price);
	})


	console.log(priceArray, "array");
	const handleOnchange = (e) => {
		const { value, name } = e.target;
		switch (name) {
			case "title":
				err.title = value.length > 0 ? "" : "Enter media title";
				break;
			case "type":
				err.type = value.length > 0 ? "" : "Enter gift type";
				break;
			case "giftName":
				err.giftName = value.length > 0 ? "" : "Enter gift name";
				break;

			case "sub_title":
				err.sub_title = value.length > 0 ? "" : "Enter gift slogan";
				break;
			case "message":
				err.message = value.length > 0 ? "" : "Enter description";
				break;
			case "status":
				err.status = value.length > 0 ? "" : "Select status";
				break;
			default:
				break;
		}

		setFormFields({ ...formFields, [name]: value });
		seterr({ ...err });
	};
	const handleOnSubmit = (e) => {
		e.preventDefault();

		if (formFields.title === "") {
			err.title = "Enter media title";
		} else {
			err.title = "";
		}
		if (formFields.type === "") {
			err.type = "Enter gift type";
		} else {
			err.type = "";
		}
		if (formFields.giftName === "") {
			err.giftName = "Enter gift name";
		} else {
			err.giftName = "";
		}
		if (formFields.sub_title === "") {
			err.sub_title = "Enter gift slogan";
		} else {
			err.sub_title = "";
		}
		if (formFields.message === "") {
			err.message = "Enter description";
		} else {
			err.message = "";
		}
		// if (formFields.status === "") {
		// 	err.status = "Select status";
		// } else {
		// 	err.status = "";
		// }
		// if (formFields.banner === "") {
		// 	err.banner = "Select banner image";
		// } else {
		// 	err.banner = "";
		// }
		if (finalImages.length === 0) {
			err.image_video = "Select image or video";
		} else {
			err.image_video = "";
		}
		if (priceField[0].price === "") {
			err.price = "Enter price"

		} else {
			err.price = "";
		}

		seterr({ ...err });
		console.log(err, "err");
		if (
			err.title === "" &&
			err.type === "" &&
			err.giftName === "" &&
			err.sub_title === "" &&
			err.message === "" &&
			err.image_video === "" &&

			// err.banner === "" &&
			err.status === ""
			&& err.price === ""

		) {
			$(".loader-main").show();

			if (finalImages.length > 0) {
				const fileData = new FormData();
				finalImages.forEach((file) => fileData.append('image', file));
				// images and videos multiple uploaded
				Services.charimageGet(fileData)
					.then((response) => {
						console.log(response, "send data multi file");

						if (response.status === 200) {

							const ImagesDataArrar = response.data.data.images;
							ImagesDataArrar.map((image) => {
								arrayImages.push(image.location);
							})
							AddGiftData(arrayImages);
						}
					})
					.catch(function (err) {
						console.log(err, "err");
					});
			}
		}
	};

	function AddGiftData(image_video) {
		var addGift = {
			type: formFields.type,
			giftName: formFields.giftName,
			title: formFields.title,
			sub_title: formFields.sub_title,
			message: formFields.message,
			image_video: image_video,
			status: formFields.status,
			platformFee: formFields.platformFee,
			price: priceArray,
			priceWithCurrency: [...selectedCountryPricing], //added by Rajkumari for new Gifting according to countries

		};
		// console.log(addGift, "addGift");
		// return false;
		Services.addGift(addGift)
			.then((response) => {
				$(".loader-main").hide();

				if (response.data.status == 200) {
					swal("Success", response.data.message, "success");
					$("#add-gift")[0].reset();
					$('#b-img').remove();
					$('#o-img').remove();
					history("/gift");
				} else {
					swal("Failed", response.data.message, "error");
				}
			})
			.catch((err) => {
				$(".loader-main").hide();
				swal("Failed", err.response.data.message, "error");
			});

	}

	/*****State of Gifting according to Country
* @author - Rajkumari
* @createdAt - 5th Dec
*/
	useEffect(() => {
		Services.countryDataList()
			.then((response) => {
				if (response.status === 200) {
					const optionsArray = response.data.data;
					let countries = [{ label: "All", value: "*" }];
					for (var i in optionsArray) {
						var item = optionsArray[i];
						countries.push({
							label: item.countryName,
							value: item.currencyCode,
						});
						setCountryData(countries);
					}
				}
			})
			.catch(function (err) {
				console.log(err, "err");
			});
	}, []);

	useEffect(() => {
		if (selectedCountries.length > 0) {
			setSelectedCountryPricing(selectedCountries);
		}
	}, [selectedCountries]);

	const onCountryChange = (value, event) => {
		if (event.action === "select-option" && event.option.value === "*") {
			setSelectedCountries(countryData);
			err.country = "";
			seterr({ ...err });
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "*"
		) {
			setSelectedCountries([]);
		} else if (event.action === "deselect-option") {
			setSelectedCountries(value.filter((o) => o.value !== "*"));
		} else if (value.length === countryData.length - 1) {
			setSelectedCountries(countryData);
		} else {
			setSelectedCountries(value);
		}
	};
	const countryDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
		if (value && value.some((o) => o.value === "*")) {
			return `${placeholderButtonLabel}: All Countries Selected`;
		} else {
			return `${placeholderButtonLabel}: ${value.length} Countries Selected`;
		}
	};

	var countryPriceArr = [];
	const handleCountryPriceChange = (i, e) => {
		const { value, name } = e.target;
		if (name === "price") {
			err.price = value.length > 0 ? "" : "Enter price";
		}
		seterr({ ...err });
		let newFormValues = [...selectedCountryPricing];
		countryPriceArr.push(e.target.value);
		newFormValues[i][e.target.name] = countryPriceArr;
		console.log("countryPriceArr", JSON.stringify(countryPriceArr));
		setSelectedCountryPricing(newFormValues);
	};
	return (
		<>
			<main class="page-content bg-light">
				<Header main_title="Gifting" title_link="gift" title="Gifting List" title1="Add Gift" title1_link="add-gift" />
				<div class="container-fluid">
					<div class="layout-specing">

						<div class="row">
							<div class="col-md-12">
								<div class="card border-0 shadow rounded">
									<div class="d-flex justify-content-between align-items-center p-3 border-bottom">
										<h4 class="mb-0">Add Gift</h4>
									</div>
									<div class="p-3 col-md-10 col-lg-7">
										<form id="add-gift" onSubmit={handleOnSubmit}>
											<div class="mb-4">
												<label class="cstm-label">Upload Image Or Video</label><br />
												{/* {filevideo && <img id="o-img" src={filevideo} width="112px" height="112px" />} */}

												<div className='cstm-video-image-upload'>
													<label for="o-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

													<div className='cstm-vi-in'>
														{imagesPrev.map((data, index) =>

															data.type === 'image' ?

																(<>
																	<div className='cstm-vi-box'>
																		<span className="cstm-vi-bx"> <img class="multi-images" controls src={data.url} alt="..." /></span>
																		<button type="button" onClick={() => deleteImages(index)}>
																			✖
																		</button>
																	</div>
																</>
																) : (
																	<>  <div className='cstm-vi-box'>
																		<span className="cstm-vi-bx"> <video class="multi-videos" controls src={data.url} alt="..." /></span>
																		<button type="button" onClick={() => deleteImages(index)}>
																			✖
																		</button>
																	</div>
																	</>
																)
														)}
													</div>


													<input
														// accept=".mov,.mp4"
														multiple
														type="file"
														name="image_video"
														id="o-image"
														className='cstm-uploadHide'
														// style={{ opacity: 0, cursor: 'pointer' }}
														onChange={handleImageChange}
													/>
												</div>

												{err.image_video !== "" && (
													<span style={{ color: "red" }} className="ml-3">
														{err.image_video}
													</span>
												)
												}

											</div>

											<div class="row">
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Gift Name</label>
														<input
															name="giftName"
															id="giftName"
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter gift name"
														/>
														{err.giftName !== "" && (
															<span style={{ color: "red" }}>
																{err.giftName}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Gift Type</label>
														<input
															name="type"
															id="type"
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter gift type"
														/>
														{err.type !== "" && (
															<span style={{ color: "red" }}>
																{err.type}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-4">
													<div class="mb-3">
														<label class="cstm-label">Media Title</label>
														<input
															name="title"
															id="title"
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter media title"
														/>
														{err.title !== "" && (
															<span style={{ color: "red" }}>
																{err.title}
															</span>
														)
														}
													</div>
												</div>
												<div class="col-md-6">
													<div class="mb-3">
														<label class="cstm-label">Gift Slogan</label>
														<input
															name="sub_title"
															id="sub_title"
															onChange={handleOnchange}
															type="text"
															class="cstm-input"
															placeholder="Enter gift slogan"
														/>
														{err.sub_title !== "" && (
															<span style={{ color: "red" }}>
																{err.sub_title}
															</span>
														)
														}
													</div>
												</div>
												<div class="mb-4">
													{/* <div class="mb-6"> */}
													<label class="cstm-label">Description</label>
													<textarea
														name="message"
														id="message"
														onChange={handleOnchange}
														type="text"
														class="cstm-input cstm-textarea"
														rows="5"
														placeholder="Enter description"
													/>
													{err.message !== "" && (
														<span style={{ color: "red" }}>
															{err.message}
														</span>
													)
													}
													{/* </div> */}
												</div>
												{/* repeter field add  */}
												{priceField.map((element, index) => (
													<div className="form-inline cstm-gift-price-main" key={index}>
														<label class="cstm-label">Default Gift Price In USD</label>
														<input
															name="price"
															id={`price${index}`}
															onChange={(e) => handleOnchangePrice(index, e)}
															type="text"
															class="cstm-input gift-price"
															placeholder="Enter gift price"
														/>

														{
															index ?
																<button class="cstm-gp-btn" type="button" onClick={() => removeFormFields(index)}><i class="fi fi-rr-trash"></i></button>
																: null
														}
													</div>
												))}
												{/* <div class="col-md-12">
													<div class="mb-2 mb-4">
														<button class="mr-3 cstm-btn" type="button" onClick={() => addPriceFields()}>Add</button>
													</div>
												</div> */}
												{err.price !== "" && (
													<span style={{ color: "red" }}>
														{err.price}
													</span>
												)
												}

												<div class="col-md-6">
													<div class="mb-3">
														<label class="cstm-label">Platform Fee</label>
														<input
															name="platformFee"
															id="platformFee"
															onChange={handleOnchange}
															type="number"
															class="cstm-input"
															placeholder="Enter platform fee"
														/>
													</div>
												</div>

												{/* Gifting Pricing according to countries selected @author- Rajkumari at 5th Dec */}
												<div class="mb-4">
													<label class="cstm-label">Select Countries</label>
													{/* <label class="cstm-label">Gift Price</label> */}

													<ReactMultiSelectCheckboxes
														class="form-select"
														options={countryData}
														placeholderButtonLabel="Select Country for Gifting Price"
														value={selectedCountries}
														onChange={onCountryChange}
														getDropdownButtonLabel={countryDropdownButtonLabel}
													/>
												</div>

												{selectedCountries && selectedCountries.map((item, index) => (
													<>
														{item.value !== "*" &&
															<Container key={index}>
																<Row>
																	<label
																		style={{ marginBottom: -5 }}
																		class="cstm-label">
																		Gift Price
																	</label>
																	<Col
																		xs={4}
																		style={{ marginTop: 20, fontSize: 14 }}>
																		{item.label}
																	</Col>
																	<Col
																		xs={3}
																		style={{
																			marginTop: 20,
																			fontSize: 14,
																			textAlign: "center",
																		}}>
																		{item.value}
																	</Col>
																	<Col xs={5}>
																		<input
																			name="price"
																			id={`price${index}`}
																			onChange={(e) =>
																				handleCountryPriceChange(index, e)
																			}
																			type="number"
																			class="cstm-input gift-price"
																			placeholder="Enter gift price"
																		/>
																	</Col>
																</Row>
															</Container>
														}
													</>

												))}

												<div class="mb-4">
													<label class="cstm-label">Role</label>
													<select
														name="status"
														class="form-select cstm-category"
														aria-label="Default select example"
														onChange={handleOnchange}
													>
														<option value="" selected>
															Select status
														</option>
														<option value="true">Active</option>
														<option value="false">Deactive</option>

													</select>
													{err.status !== "" && (
														<span style={{ color: "red" }}>
															{err.status}
														</span>
													)
													}
												</div>


												<div class="col-md-12">
													<div class="mb-2 mt-1">
														<button class="mr-3 cstm-btn">Add Gift</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</main>


		</>
	)
}

export default AddGift;

