// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-multiselect-container {
    position: relative;
}

.custom-multiselect-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    cursor: pointer;
}

.custom-multiselect-header.disabled {
    background-color: #EEEEEE;
    cursor: not-allowed;
}

.custom-multiselect-header i {
    line-height: 1;
}

.custom-multiselect-dropdown {
    position: absolute;
    width: 100%;
    max-height: 10rem;
    padding: 0.5rem 1rem;
    list-style: none;
    background-color: white;
    overflow-y: auto;
    border: 1px solid #EEEEEE;
    border-radius: 0.5rem;
    z-index: 100;
}

.custom-multiselect-dropdown li {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/MultiSelectDropdown/MultiSelectDropdown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".custom-multiselect-container {\n    position: relative;\n}\n\n.custom-multiselect-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 16px 24px;\n    border: 1px solid #EEEEEE;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.custom-multiselect-header.disabled {\n    background-color: #EEEEEE;\n    cursor: not-allowed;\n}\n\n.custom-multiselect-header i {\n    line-height: 1;\n}\n\n.custom-multiselect-dropdown {\n    position: absolute;\n    width: 100%;\n    max-height: 10rem;\n    padding: 0.5rem 1rem;\n    list-style: none;\n    background-color: white;\n    overflow-y: auto;\n    border: 1px solid #EEEEEE;\n    border-radius: 0.5rem;\n    z-index: 100;\n}\n\n.custom-multiselect-dropdown li {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
