import React, { useEffect, useState } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import loader from "../../assets/Images/loder.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const EditEvent = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const cahrID = new URLSearchParams(getid).get("id");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
const [showLatitude, setLatitude] = useState('');
const [showLongitude, setLongitude] = useState('');


  const [editEventVal, setEditEventVal] = useState({
    title: "",
    banner: "",
    image_video: [],
    category: "",
    categoryId: "",
    postalCode: "",
    address: "",
    webURL: "",
    about: "",
    startDate: new Date(),
    endDate: new Date(),
    startTime: "00:00",
    endTime: "00:00"
    // date: "",
    // time: "",
  });
  const [errEditEventVal, setEditErrEventVal] = useState({
    title: "",
    banner: "",
    image_video: "",
    category: "",
    postalCode: "",
    webURL: "",
    about: "",
    // date: "",
    startDate: new Date(),
    endDate: new Date(),
    startTime: "00:00",
    endTime: "00:00"
  });


  var imgExtArray = [];
  var imgArray = [];
  var bannimg = "";
  var imgExtArray = [];
  var videoExtArray = [];
  var videoArray = [];
  const DataArrayCat = [];
  const arrayImages = [];

  const [showCategoryIds, setShowCategoryIds] = useState("");
  const [charCategory, setCharCategory] = useState([]);
  const [videoPrev, setVideoPrev] = useState([]);
  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [arrayOfGetData, setArrayOfGetData] = useState([]);
  const [getBannerImg, setGetBannerImg] = useState("");
  const [getMultiFile, setMultiFile] = useState([]);


  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    Services.getEventById(cahrID)
      .then((response) => {

        if (response.data.status === 200) {
          var arrayImages = response.data.data.image_video;
          arrayImages.map(multifile => {
            var filesplit = multifile.split('.').pop();
            var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
            var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
            imageExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'image' });
            videoExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'video' });
          })

          setEditEventVal({

            title: response.data.data.title,
            // banner: response.data.data.banner ? response.data.data.banner : '',
            image_video: arrayImages,
            postalCode: response.data.data.postalCode,
            address: response.data.data.address,
            webURL: response.data.data.webURL,
            about: response.data.data.description,
            categoryId: response.data.data.categoryId, 
            startDate:response.data.data.startDate,
            endDate:response.data.data.endDate
          });

          setLatitude(response.data.data.latitude);
          setLongitude(response.data.data.longitude);
          setVideoPrev([...videoPrev, ...videoExtArray]);
          setMultiFile([...arrayImages]);
          setShowCategoryIds(response.data.data.categoryId);
          setGetBannerImg(response.data.data.banner);
          let tempStartDate = new Date(response.data.data.startDate);
          let tempEndDate = new Date(response.data.data.endDate);
          
          setStartDate(new Date(response.data.data.startDate));
          setEndDate(new Date(response.data.data.endDate));
          setStartTime(String(tempStartDate.getHours()).padStart(2, "0") + ":" + String(tempStartDate.getMinutes()).padStart(2, "0"));
          setEndTime(String(tempEndDate.getHours()).padStart(2, "0") + ":" + String(tempEndDate.getMinutes()).padStart(2, '0'));



        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
    getCatData(1);
  }, []);


  const handleSelect = address => {
    setEditEventVal({...editEventVal,['address']:address});

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
      }  
        )
      .catch(error => console.error('Error', error));

  };

  const handleChange = address => {
    setEditEventVal({...editEventVal,['address']:address});
  };

  //   get category for add charity
  const getCatData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getEventCat(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.category_count.length !== 0) {
            var data = response.data.data.category_count;
            data.map((data1) => {
              DataArrayCat.push(data1);
            });
          }
          setCharCategory(DataArrayCat);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };

  // console.log(editEventVal, "editEventVal");
  const handleOnchangeUpdate = (e) => {
    const { value, name } = e.target;
    console.log(e.target.value !== "", "target")

    if (e.target.name == "title") {
      errEditEventVal.title = value.length > 0 ? "" : "Enter event name";
    }
    if (e.target.name == "category") {
      errEditEventVal.category = value.length > 0 ? "" : "Enter category";
    }
    // if (e.target.name == "postalCode") {
    //   errEditEventVal.postalCode = value.length > 0 ? "" : "Enter postalcode";
    // }
    if (e.target.name == "webURL") {
      errEditEventVal.webURL = value.length > 0 && !new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      ).test(value)
        ? "Enter a valid website url"
        : "";
    }
    if (e.target.name == "about") {
      errEditEventVal.about = value.length > 0 ? "" : "enter about";
    }
    setEditEventVal({ ...editEventVal, [name]: value });
    setEditErrEventVal({ ...errEditEventVal });
    setShowCategoryIds(editEventVal.categoryId = e.target.value);
  };
  //image onchange

  const [file, setFile] = useState();
  const [bannerImg, setBannerImg] = useState("")
  const handleImageChangeBanner = (e) => {
    e.preventDefault();
    errEditEventVal.banner = e.target.value.length > 0 ? "" : "Select banner image";

    setFile(URL.createObjectURL(e.target.files[0]));
    var charImage = e.target.files[0];
    setBannerImg(charImage);
    console.log(charImage, "charImage");
    setEditEventVal({ ...editEventVal, banner: '' })

  }

  function deleteImagesGet(e) {

    const vp = videoPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setVideoPrev([...vp]);

  }

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs])
    setImagesPrev([...s]);

  }

  const handleImageChangeorg = (e) => {
    e.preventDefault();
    errEditEventVal.image_video = e.target.value.length > 0 ? "" : "Select image or video";


    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];

    })
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    imgArray.forEach(function (f, index) {

      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split('.').pop();
      var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
      var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
      imageExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'image' });
      videoExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'video' });

    })

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);

  };


  const handleOnSubmitUpdate = (e) => {
    e.preventDefault();

    if (editEventVal.title === "") {
      errEditEventVal.title = "Enter event name";
    } else {
      errEditEventVal.title = "";
    }
    if (editEventVal.categoryId === "") {
      errEditEventVal.category = "Enter category";
    } else {
      errEditEventVal.category = "";
    }
    if (finalImages.length === 0) {
      errEditEventVal.image_video = "Select image or video 111";
    } else {
      errEditEventVal.image_video = "";

    }

    if (editEventVal.image_video === "") {
      errEditEventVal.image_video = "Select image or video";

    } else {
      errEditEventVal.image_video = "";
    }
    // if (editEventVal.banner === "") {
    //   errEditEventVal.banner = "Select banner image";
    // } else {
    //   errEditEventVal.banner = "";
    // }

    if (editEventVal.webURL !== "") {
      if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(editEventVal.webURL)) {

        errEditEventVal.webURL = "Enter a valid website url";
      }
      else {
        errEditEventVal.webURL = "";
      }

    }
    if (editEventVal.about === "") {
      errEditEventVal.about = "Write about";
    } else {
      errEditEventVal.about = "";
    }
    setEditErrEventVal({ ...errEditEventVal });
    setEditEventVal({ ...editEventVal });
console.log(errEditEventVal,"err")

    if (videoPrev) {

      videoPrev.map(data => {
        videoArray.push(data.url);
      })
      setArrayOfGetData(videoArray)
    }
    console.log(videoArray, "vieoArray")
    if (
      errEditEventVal.title === "" &&
      // errEditEventVal.banner === "" &&
      errEditEventVal.image_video === "" &&
      errEditEventVal.category === "" &&
      // errEditEventVal.postalCode === "" &&
      errEditEventVal.webURL === "" &&
      errEditEventVal.about === ""
    ) {
      $(".loader-main").show();

      // if (bannerImg !== "")
      //   await UploadBannerImage()
console.log(finalImages,"finalImages");
      if (finalImages.length >0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {

            if (response.status === 200) {
              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              var finalImagesData = [];
              finalImagesData = [...arrayImages, ...videoArray]
              EditEventData(finalImagesData);

              // EditEventData(finalImagesData, getBannerImg);

              // if (bannimg) {
              //   EditEventData(finalImagesData, bannimg);

              // } else {
              //   EditEventData(finalImagesData, getBannerImg);

              // }


            }

          })
          .catch(function (err) {
            console.log(err, "err");

          });
      } else {
        console.log("jfdsjgh");

        EditEventData(videoArray);

        // if (bannimg) {
        //   EditEventData(videoArray, bannimg);

        // } else {
        //   EditEventData(videoArray, getBannerImg);

        // }

      }

      function EditEventData(finalImagesData) {
        let tempStartTime = new Date(startDate);
        let tempTime = startTime.split(':');
        tempStartTime.setHours(tempTime[0], tempTime[1], 0, 0);
  
        let tempEndTime = new Date(endDate);
        let tempETime = endTime.split(':');
        tempEndTime.setHours(tempETime[0], tempETime[1], 0, 0);
        // if (editEventVal) {

          var editEventData = {

            title: editEventVal.title, 
            image_video: finalImagesData,
            startDate: tempStartTime,
            endDate: tempEndTime,
            // time:time,
            postalCode: editEventVal.postalCode,
            address: editEventVal.address,
            webURL: editEventVal.webURL,
            description: editEventVal.about,
            categoryId: editEventVal.category,
            id: cahrID,
            latitude:showLatitude,
            longitude:showLongitude,
          };
          console.log(editEventData,"editEventData");
// return false;
          Services.editEvent(editEventData)
            .then((response) => {
              $(".loader-main").hide();

              if (response.data.status == 200) {
                swal("Success", response.data.message, "success");

              } else {
                swal("Failed", response.data.message, "error");
              }
            })
            .catch((err) => {
              swal("Failed", err.response.data.message, "error");
            });
        // }
      }

    }
  };


  // async function UploadBannerImage() {
  //   return new Promise((resolve, reject) => {
  //     let formData = new FormData();
  //     formData.append("image", bannerImg);
  //     //banner image upload
  //     Services.charimageGet(formData)
  //       .then((response) => {
  //         console.log(response, "response");
  //         if (response.status === 200) {
  //           bannimg = response.data.data.images[0].location;
  //           editEventVal.banner = response.data.data.images[0].location;
  //           resolve("success")
  //         } else {
  //           reject("failed")
  //         }
  //       })
  //       .catch(function (err) {
  //         reject("failed")
  //         console.log(err, "err");
  //       });
  //   })


  // }



  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Edit Event"
          title="Event List"
          main_title="Event"
          title_link="event"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Edit Event</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-4">
                  <form id="edit-charity" onSubmit={handleOnSubmitUpdate}>
                    {/* <div class="mb-4">
                            <label class="cstm-label">
                              Upload Banner Image
                            </label><br />
                            <div className="cstm-Videoalign">
                              <label for="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                              
                              {
                                editEventVal.banner !== undefined &&
                                editEventVal.banner !== "" &&
                                <img
                                  id="imageB"
                                  className="AddOrganization-bannerImg"
                                  src={"https://human-backend-data.s3.eu-west-2.amazonaws.com/" + editEventVal.banner}
                                />
                              }
                              {file && file.length > 0 && <img
                                id="b-img"
                                className="AddOrganization-bannerImg"
                                src={file}
                              />}
                              <input
                                // accept="image/*"
                                type="file"
                                name="banner"
                                id="banner-image"
                                className='cstm-uploadHide'
                                // value={"https://human-backend-data.s3.eu-west-2.amazonaws.com/"+editEventVal.banner}

                                // style={{ opacity: 0, cursor: 'pointer' }}
                                onChange={handleImageChangeBanner}
                              />
                            </div>
                            {errEditEventVal.banner !== "" && (
                              <span style={{ color: "red" }}>
                                {errEditEventVal.banner}
                              </span>
                            )}
                          </div> */}
                    <div class="mb-4 ">
                      <label class="cstm-label">Upload Image or Video</label><br />


                      <div className="cstm-video-image-upload">
                        <label for="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                        <div className='cstm-vi-in'>
                          {imagesPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<>
                                <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx"> <img class="multi-images" controls src={data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImages(index)}>
                                    ✖
                                  </button>
                                </div>
                              </>
                              ) : (
                                <>
                                  <div className='cstm-vi-box'>
                                    <span className="cstm-vi-bx"> <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                    <button type="button" onClick={() => deleteImages(index)}>
                                      ✖
                                    </button>
                                  </div>
                                </>
                              )

                          )}
                        {/* </div>


                        <div className='cstm-vi-in'> */}

                          {videoPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<>
                                <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx"> <img class="multi-images getbyid" width="200px" height="200px" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImagesGet(index)}>
                                    ✖
                                  </button></div>
                              </>
                              ) : (
                                <> <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx"> <video class="multi-videos getbyid" width="200px" height="200px" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImagesGet(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )

                          )}
                        </div>

                        <input
                          // accept=".mov,.mp4"
                          multiple
                          type="file"
                          name="image_video"
                          id="org-image"
                          className='cstm-uploadHide'
                          // value={editEventVal.image_video}
                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeorg}
                        />
                        {errEditEventVal.image_video !== "" && (
                          <span style={{ color: "red" }} className="ml-3">
                            {errEditEventVal.image_video}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Event  Name
                      </label>
                      <input
                        name="title"
                        id="event-name"
                        type="text"
                        class="cstm-input"
                        placeholder="Event Name"
                        value={editEventVal.title}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditEventVal.title !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditEventVal.title}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Category</label>
                      <select
                        name="categoryId"
                        class="form-select cstm-category"
                        onChange={handleOnchangeUpdate}
                        value={showCategoryIds}
                      >
                        {/* <option value="">Category</option> */}

                        {charCategory.map((cat) => {
                          return (
                            <option value={cat._id}>{cat.title}</option>
                          );
                          // console.log(cat.title,"single cat")
                        })}
                      </select>
                      {errEditEventVal.category !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {errEditEventVal.category}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Event Address(Optional)
                      </label>
                      {/* <input
                        name="address"
                        id="address"
                        type="text"
                        class="cstm-input"
                        placeholder="Event Address"
                        value={editEventVal.address}
                        onChange={handleOnchangeUpdate}
                      /> */}

                        <PlacesAutocomplete
                              value={editEventVal.address}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                   <i class="fi fi-rr-search cstm-search-ro"></i>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'cstm-input-seacrh location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Event Website Link (Optional)
                      </label>
                      <input
                        name="webURL"
                        id="webURL"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        value={editEventVal.webURL}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditEventVal.webURL !== "" && (
                        <span style={{ color: "red" }}>{errEditEventVal.webURL}</span>
                      )}
                    </div>
                    {/* <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event Postcode</label>
                      <input
                        name="postalCode"
                        id="postalCode"
                        type="text"
                        class="cstm-input"
                        placeholder="Event Postcode"
                        value={editEventVal.postalCode}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditEventVal.postalCode !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditEventVal.postalCode}
                        </span>
                      )}
                    </div> */}
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event Start Date</label>
                      <DatePicker
                        selected={startDate}
                        placeholderText="DD/MM/YYYY"
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                      />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Start Time</label>
                      <input type="time" value={startTime} onChange={ev => setStartTime(ev.target.value)} class="cstm-input" id="start-time" name="start-time" />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event End Date</label>
                      <DatePicker
                        selected={endDate}
                        placeholderText="DD/MM/YYYY"
                        onChange={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                      />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">End Time</label>
                      <input type="time" value={endTime} onChange={ev => setEndTime(ev.target.value)} class="cstm-input" id="end-end-time" name="time" />
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">About</label>
                      <textarea
                        name="about"
                        id="about"
                        type="textarea"
                        rows="5"
                        class="cstm-input cstm-textarea"
                        placeholder="About..."
                        value={editEventVal.about ? editEventVal.about : ''}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditEventVal.about !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditEventVal.about}
                        </span>)}
                    </div>

                    {/* <div class="d-flex">
                            <button class=" cstm-btn">Edit Charity</button>
                          </div> */}

                    <div className="submit-btnMain cstm-loader">
                      <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">UPDATE & SAVE</button>
                      <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                        <img src={loader} />
                      </div>
                    </div>
                  </form>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditEvent;

