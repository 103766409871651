import { useState } from "react";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination.constant";

export function usePagination(defaultPageSize = DEFAULT_PAGE_SIZE) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  function resetPagination(resetPageSize = false) {
    setPageNumber(1);

    if (resetPageSize) {
      setPageSize(defaultPageSize);
    }
  }

  return {
    pageNumber: pageNumber,
    pageSize: pageSize,
    setPageNumber: setPageNumber,
    setPageSize: setPageSize,
    resetPagination: resetPagination,
  };
}
