/**
 * @param {URLSearchParams} searchParams
 * @param {string[]} singleFilters
 * @param {string[]} multiFilters
 */
export function getListStateFromSearchParams(searchParams, singleFilters = [], multiFilters = []) {
    const filters = {};

    singleFilters.forEach((filterKey) => {
        const filterValue = searchParams.get(filterKey);
        if (filterValue) {
            filters[filterKey] = filterValue;
        } else {
            filters[filterKey] = '';
        }
    });

    multiFilters.forEach((filterKey) => {
        const filterValues = searchParams.getAll(filterKey);
        if (filterValues?.length) {
            filters[filterKey] = filterValues;
        } else {
            filters[filterKey] = [];
        }
    });

    return {
        filters: filters,
        searchText: searchParams.get('searchText') || '',
        pageNumber: Number(searchParams.get('page') || 1),
    };
}

/**
 * @param {object} filters
 * @param {string} searchText
 * @param {number} pageNumber
 * @param {string[]} singleFilters
 * @param {string[]} multiFilters
 * @returns {URLSearchParams}
 */
export function createSearchParamsFromListState(filters, searchText, pageNumber, singleFilters = [], multiFilters = []) {
    const searchParams = new URLSearchParams();

    if (searchText) {
        searchParams.set('searchText', searchText);
    }

    if (pageNumber && pageNumber !== 1) {
        searchParams.set('page', pageNumber);
    }

    singleFilters.forEach((filterKey) => {
        const filterValue = filters[filterKey];
        if (filterValue) {
            searchParams.set(filterKey, filterValue);
        }
    });

    multiFilters.forEach((filterKey) => {
        const filterValues = filters[filterKey];
        if (filterValues?.length) {
            filterValues.forEach((filterValue) => {
                searchParams.append(filterKey, filterValue);
            });
        }
    });

    return searchParams;
}
