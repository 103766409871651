export function downloadCsvFromData(csvData, csvName) {
    const href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv;charset=utf-8' }));

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', csvName || 'data.csv');
    link.click();

    link.remove();
    URL.revokeObjectURL(href);
}

export function generateCsvFileName(prefix) {
    return `${prefix}-${(new Date()).toLocaleString()}.csv`;
}
