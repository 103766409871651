import React from 'react'
import Header from '../NavMenuBar/Header';
import deleteImg from '../../assets/Images/delete.png';

function requestedCharity() {
  return (
    <>
        
       <main class="page-content bg-light">
            <Header title="Requested Charity" main_title="Charity" main_title_link="charity"/>
            <div class="container-fluid">
               <div class="layout-specing">
                
                  <div class="row">
                     <div class="col-md-12">
                           <div class="card border-0 shadow rounded">
                             <div class="row justify-content-between align-items-center p-3">
                               <div class="col-md-2"><h4 class="mb-0">Requested Charity</h4></div>
							   <div class="col-md-10">
							   <div class="row row justify-content-end">
								   <div class="col-md-4">
								   <i class="fi fi-rr-search cstm-search-ro"></i><input name="name" id="name" type="text" class="cstm-input-seacrh" placeholder="Search Charity" />
								   </div>
								   	{/* <div class="col-md-2"> 
								      <select class="cstm-select cstm-om-slc" name="City" id="City">
											<option value="City">City</option>
											<option value="City2">City 2</option>
									  </select>
								    </div>
									 <div class="col-md-2"> 
								      <select class="cstm-select cstm-om-slc" name="Country" id="Country">
											<option value="Country">Country</option>
											<option value="Country2">Country 2</option>
									  </select>
								    </div> */}
									 <div class="col-all-auto"> 
									<button class="cstm-btn">Add Charity</button>
									</div>
							   	</div>
							   </div>
                           </div>
                            
							<div class="col-md-12 col-lg-12">
							 <div class="table-responsive bg-white border-top rounded-0">
                              <table class="table mb-0 table-center">
                                 <thead>
                                    <tr>
                                       <th class="border-bottom w-4">No.</th>
                                       <th class="border-bottom w-25">Charity name</th>
                                       <th class="border-bottom w-25">Category <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i></th>
                                       <th class="border-bottom w-14">City <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i></th>
                                       <th class="border-bottom w-14">Country <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i></th>
									   <th class="border-bottom w-18">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>1</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="requested-dharity-details.html" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									 <tr>
                                       <td>2</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									  <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>3</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>4</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>5</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>6</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									    <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>7</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									    <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>8</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									    <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>9</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>10</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									    <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>11</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>12</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									    <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>13</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									  <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>14</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									 <tr>
                                       <td>15</td>
                                       <td>A Better World (organization)</td>
                                       <td>Children's and Family Services </td>
                                       <td>Alaska</td>
                                       <td>United States</td>
									   <td> 
									       <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
										   <a href="#" class="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment"><i class="fi fi-rr-cross"></i></a> 
									   </td>
                                    </tr>
									
									
                                 </tbody>
                              </table>
                           </div>
							</div> 

                        </div>
                     </div>
                  </div>
				  
				  
                        <div class="row text-center">
                            <div class="col-12 mt-4">
                                <div class="d-md-flex align-items-center text-center justify-content-between">
                                    <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
                                    <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                        <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
				  
				  
               </div>
            </div>
         </main>

         <div class="modal fade" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body py-5">
                        <div class="text-center">
                            <div class="d-flex justify-content-center">
                                <img src={deleteImg} />
                            </div>
                             <h4 class="mt-4">Are sure you want to delete<br /> this charity?</h4>
							 <div class="mt-4">
                                <button class="mr-3 cstm-btn cstn-discard">Delete</button>
								<button class="cstm-btn2 cstn-discard">Cancel</button> 
                            </div>
							
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default requestedCharity
