import React, { useEffect, useState } from "react";
import Header from "../NavMenuBar/Header";
import userProfileImg from "../../assets/Images/photo.png";
import deleteImg from "../../assets/Images/delete.png";
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/Images/loder.gif";
import { usePlacesWidget } from "react-google-autocomplete";

const ViewEvent = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const cahrID = new URLSearchParams(getid).get("id");
  const [charData, setCharData] = useState({});
  const [charityId, setCharityID] = useState();
  //   console.log(charData, "setCharData");
  const [editcharityVal, setEditCharityVal] = useState({
    title: "",
    banner: "",
    image_video: [],
    category: "",
    categoryId: "",
    registrationNumber: "",
    webURL: "",
    about: "",
    location: "",
    isCharityVerified: true,
  });

  const [showCategoryIds, setShowCategoryIds] = useState("");
  const [catVal, setCatVal] = useState("");
  const [imagesPrev, setImagesPrev] = useState([]);
  const [videoPrev, setVideoPrev] = useState([]);
  var imgExtArray = [];
  var videoExtArray = [];

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    setCharityID(cahrID);
    Services.getCharityById(cahrID)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data.image_video, "all data");
          var arrayImages = response.data.data.image_video;
          arrayImages.map((multifile) => {
            var filesplit = multifile.split(".").pop();
            var imageExtension = [
              "png",
              "jpg",
              "jpeg",
              "apng",
              ".avif",
              "jfif",
              "pjpeg",
              "pjp",
              "svg",
              "webp",
            ];
            var videoExtension = [
              "mp4",
              "mov",
              "wmv",
              "avi",
              "avchd",
              "flv",
              "f4v",
              "swf",
              "mkv",
              "webm",
              "html5",
              "mpeg-2",
            ];
            imageExtension.includes(filesplit) && imgExtArray.push(multifile);
            videoExtension.includes(filesplit) && videoExtArray.push(multifile);
            // console.log(multifile,"multifile");
          });

          setImagesPrev(imgExtArray);
          setVideoPrev(videoExtArray);

          setEditCharityVal({
            title: response.data.data.title,
            banner: response.data.data.banner ? response.data.data.banner : "",
            image_video: arrayImages,
            registrationNumber: response.data.data.registrationNumber,
            webURL: response.data.data.webURL,
            about: response.data.data.about,
            location: response.data.data.location,
            categoryId: response.data.data.categoryId,
          });
          setShowCategoryIds(response.data.data.categoryId);
          //   setCharData(response.data.data);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
    Services.getChatCategoryById(showCategoryIds)
      .then((response) => {
        console.log(response, "resonce");
        if (response.data.status === 200) {
          setCatVal(response.data.data.title);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }, []);

  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="View Charity"
          title="Charity List"
          main_title="Charity"
          title_link="charity"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Charity Details</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 ">
                  <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body p-4">
                        {/* <form id="edit-charity" onSubmit={handleOnSubmitUpdate}> */}
                        <div class="mb-4 row">
                          <label class="cstm-label">Charity Banner Image</label>
                          <br />

                          <span>
                            {editcharityVal.location
                              ? editcharityVal.location
                              : ""}
                          </span>

                          {editcharityVal.banner !== undefined &&
                            editcharityVal.banner !== "" && (
                              <img
                                id="imageB"
                                className="AddOrganization-bannerImg"
                                src={
                                  `${process.env.REACT_APP_S3_BASE_URL}/` +
                                  editcharityVal.banner
                                }
                              />
                            )}

                          <div class="mb-4 ">
                            <label class="cstm-label">
                              Charity Videos Or Images
                            </label>
                            <br />
 
                            {imagesPrev.map((url) => (
                              <span className="ml-3">
                                {" "}
                                <img
                                  class="multi-videos"
                                  width="200px"
                                  height="200px"
                                  controls
                                  src={
                                    `${process.env.REACT_APP_S3_BASE_URL}/` +
                                    url
                                  }
                                  alt="..."
                                />
                              </span>
                            ))}
                            {videoPrev.map((url) => (
                              <span className="ml-3">
                                {" "}
                                <video
                                  class="multi-videos"
                                  width="200px"
                                  height="200px"
                                  controls
                                  src={
                                    `${process.env.REACT_APP_S3_BASE_URL}/` +
                                    url
                                  }
                                  alt="..."
                                />
                              </span>
                            ))}
                          </div>
                        </div>

                        <div class="mb-4">
                          <label class="cstm-label">Charity Display Name</label>
                          <br />
                          {editcharityVal.title}
                        </div>
                        <div class="mb-4">
                          <label class="cstm-label">Category</label>
                          <br />
                          {catVal}
                        </div>

                        <div class="mb-4">
                          <label class="cstm-label">Website Address</label>
                          <br />
                          {editcharityVal.webURL}
                        </div>
                        <div class="mb-4">
                          <label class="cstm-label">Registration Number</label>
                          <br />
                          {editcharityVal.registrationNumber}
                        </div>

                        <div class="mb-4">
                          <label class="cstm-label">About</label>
                          <br />
                          {editcharityVal.about ? editcharityVal.about : ""}
                        </div>

                        {/* <div class="d-flex">
                            <button class=" cstm-btn">Edit Charity</button>
                          </div> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewEvent;

