import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import Services from "../../Services/auth.service";
import { LOCAL_STORAGE_KEYS } from "../../common/constants/local-storage.constant";
import { QUERY_KEYS } from "../../common/query-keys";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.showBreadcrumbs
 */
export function SidebarLayoutHeader({ title, showBreadcrumbs = true }) {
    const navigate = useNavigate();
    /** @type {[string, React.Dispatch<string>]} */
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
    const { data: profileRes } = useQuery({
        queryKey: [QUERY_KEYS.PROFILE, userId],
        queryFn: Services.getAdminprofile,
        staleTime: Infinity,
        enabled: !!userId,
    });

    useEffect(() => {
        if (profileRes && profileRes.status === 200) {
            setProfileImageUrl(`${process.env.REACT_APP_S3_BASE_URL}/${profileRes.data.data.image}`);
        }
    }, [profileRes]);

    function onLogoutClick() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
        navigate("/");
    };

    return (
        <div className="top-header">
            <div className="header-bar d-flex justify-content-between border-bottom cstm-header-image">
                <div className="">
                    <h6 className="mb-0">{title}</h6>
                    {showBreadcrumbs && <Breadcrumbs />}
                </div>
                <ul className="list-unstyled mb-0">
                    <li className="list-inline-item mb-0 ms-1">
                        <div className="dropdown dropdown-primary">
                            <button
                                type="button"
                                className="btn btn-pills dropdown-toggle p-0"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img
                                    src={profileImageUrl}
                                    alt=""
                                    height="40px"
                                    width="40px"
                                />
                                <i className="fi fi-rr-caret-down cstm-icon-mn ml-2"></i>
                            </button>
                            <div className="dropdown-menu dd-menu dropdown-menu-end csrm-user-dv">
                                <NavLink
                                    to="/dashboard"
                                    className={({ isActive }) => (isActive ? "active-us" : "")}
                                >
                                    Dashboard
                                </NavLink>
                                <NavLink
                                    to="/profile"
                                    className={({ isActive }) => (isActive ? "active-us" : "")}
                                >
                                    Profile
                                </NavLink>
                                <NavLink
                                    to="/account-setting"
                                    className={({ isActive }) => (isActive ? "active-us" : "")}
                                >
                                    Account Setting
                                </NavLink>
                                <button type="button" onClick={onLogoutClick} className="cstm-logout">
                                    Logout
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
