import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Modal } from '../Modal/Modal';
import { useGetHelperInfos } from '../../../hooks/webadmin-content/webadmin-content.hook';
import webadminContentService from '../../../Services/webadmin-content.service';
import { QUERY_KEYS } from '../../../common/query-keys';
import './Tooltip.css';

/**
 * @param {Object} props
 * @param {string} props.code
 * @param {"top" | "right" | "bottom" | "left"} props.placement
 */
export function HelperInfoTooltip({ code, placement = "bottom" }) {
    const queryClient = useQueryClient();
    const { data: helperInfos } = useGetHelperInfos();
    const [showTooltip, setShowTooltip] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const content = helperInfos?.find((helperInfo) => helperInfo.code === code)?.description || '';

    async function editHelperInfo(newContent) {
        try {
            const res = await webadminContentService.updateHelperInfo(code, {
                description: newContent,
            });

            if (res.status === 200) {
                toast.success("Successfully updated helper info");
                queryClient.invalidateQueries([QUERY_KEYS.WEBADMIN_CONTENT_HELPER_INFOS]);
                return;
            }

            toast.error("Couldn't update helper info");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update helper info");
        } finally {
            setShowEditModal(false);
        }
    }

    return (
        <>
            <OverlayTrigger
                placement={placement}
                show={showTooltip}
                overlay={
                    <Popover className='info-editable-tooltip-container'>
                        <Popover.Header as="div" className='d-flex justify-content-end'>
                            <button
                                type='button'
                                className='info-editable-tooltip-edit-btn'
                                onClick={() => {
                                    setShowTooltip(false);
                                    setShowEditModal(true);
                                }}
                            >
                                <i className="fi fi-rr-pencil"></i>
                            </button>
                        </Popover.Header>
                        <Popover.Body>
                            <div>
                                {content ? (
                                    <div className='info-editable-tooltip-content'>{content}</div>
                                ) : (
                                    <div>No description</div>
                                )}
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <i className="fi fi-rr-info info-editable-info-icon" onClick={() => setShowTooltip(!showTooltip)}></i>
            </OverlayTrigger>

            <Modal
                show={showEditModal}
                onClose={() => setShowEditModal(false)}
            >
                <EditHelperInfoContent
                    content={content}
                    onSaveEdit={(newContent) => editHelperInfo(newContent)}
                />
            </Modal>
        </>
    );
}

/**
 * @param {Object} props
 * @param {string} props.content
 * @param {(content: string) => void} props.onSaveEdit
 */
function EditHelperInfoContent({ content, onSaveEdit }) {
    const [newContent, setNewContent] = useState(content);

    return (
        <div>
            <textarea
                className='cstm-textarea'
                rows={4}
                cols={40}
                value={newContent}
                onChange={(e) => setNewContent(e.target.value)}
            ></textarea>
            <div className="d-flex justify-content-end mt-2">
                <button type='button' className='cstm-submitbutton' onClick={() => onSaveEdit(newContent)}>Save</button>
            </div>
        </div>
    );
}
