import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import deleteImg from "../../assets/Images/delete.png";
import calendarImg from "../../assets/Images/calendar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import * as Yup from "yup";
import Services from "../../Services/auth.service";
import Radar, { async } from "radar-sdk-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import loader from "../../assets/Images/loder.gif";


var moment = require("moment");

Radar.initialize("prj_test_pk_dad1a8b7dc4af84b6005d4dee338eee83c33ea1c");

function LocationGreetings() {
  var allGreetingData = [];
  const [GreetingData, setGreetingData] = useState([]);
  const [AllYearArr, setAllYearArr] = useState([]);
  const [greetingId, setgreetingId] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCountry, setCountry] = useState("All");
  const [showMonth, setMonth] = useState("All");
  const [showYear, setYear] = useState("All");

  const onChangeStartDate = (date, type) => {
    console.log(date,"d");
    if (type == 'add') {
      if (date === null) {
        errAddVal.startDate = "Select startdate";
      } else {
        errAddVal.startDate = "";
      }
      setAddVal({ ...addVal, ['startDate']: date });
    } else if (type == 'edit') {
      if (date === null) {
        errEditVal.startDate1 = "Select startdate";
      } else {
        errEditVal.startDate1 = "";
      }
      setEditVal({ ...editVal, ['startDate1']: date });
    }

  }
  const onChangeEndDate = (date, type) => {
    if (type == 'add') {
      if (date === null) {
        errAddVal.endDate = "Select enddate";
      } else {
        errAddVal.endDate = "";
      }
      setAddVal({ ...addVal, ['endDate']: date });
    } else if (type == 'edit') {
      console.log(date, 'date');
      if (date === null) {
        errEditVal.endDate1 = "Select enddate";
      } else {
        errEditVal.endDate1 = "";
      }
      setEditVal({ ...editVal, ['endDate1']: date });
    }

  }

  // const onChangedate = (range,type) => {
  //   console.log(range,'ranf');
  //   // // range is a moment-range object
  //   if(type === 'add'){
  //     setAddVal({ ...addVal, ['date']: range });
  //   }else{
  //     setEditVal({ ...editVal, ['date1']: range });
  //   }

  // }

  // const convertDate = (str) => {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }



  const onclickAdd = () => {
    console.log($('#addgreetingmodelform'), 'fdg');
    setAddVal({ ...addVal, ["country"]: "", ["isRecurring"]: false, ["message"]: "" });
    $('#addgreetingmodelform')[0].reset();
    $('#message').val('');
    $('.error-msg').hide();
  }

  const [editVal, setEditVal] = useState({
    country1: "",
    startDate1: "",
    endDate1: "",
    message1: "",
    greeting_id: "",
    isRecurring1: false
  });

  const [errEditVal, setErrEditVal] = useState({
    country1: "",
    startDate1: "",
    endDate1: "",
    message1: "",
  });

  const [addVal, setAddVal] = useState({
    country: "",
    startDate: "",
    endDate: "",
    message: "",
    isRecurring: false
  });

  const [errAddVal, setErrAddVal] = useState({
    country: "",
    startDate: "",
    endDate: "",
    message: "",
  });

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    $('.error-msg').show();
    //console.log('hiiiiiiii',data);
    if (addVal.country == "") {
      errAddVal.country = "Select country";
    } else {
      errAddVal.country = "";
    }

    if (addVal.startDate == "") {
      errAddVal.startDate = "Select startdate";
    } else {
      errAddVal.startDate = '';
    }

    if (addVal.endDate == "") {
      errAddVal.endDate = "Select enddate";
    } else {
      errAddVal.endDate = '';
    }

    if (addVal.message == "") {
      errAddVal.message = "Enter message";
    } else {
      errAddVal.message = "";
    }

    // setCatVal()
    setErrAddVal({ ...errAddVal });


    console.log(errAddVal, 'errAddVal');
    if (errAddVal.country === '' && errAddVal.message === '' && errAddVal.startDate === '' && errAddVal.endDate === '') {
      console.log('bcvb');
      var latitude = "";
      var longitude = "";

      await Radar.autocomplete(
        {
          query: addVal.country,
          limit: 1,
        },
        function (err, result) {
          if (!err) {
            console.log(result.addresses[0].latitude, "result");
            latitude = result.addresses[0].latitude;
            longitude = result.addresses[0].longitude;
          }
        }
      );

      setTimeout(() => {
        var bodyData = {
          message: addVal.message,
          location:
            "https://satellites.pro/plan/" +
            addVal.country +
            "_map#" +
            latitude +
            "," +
            longitude +
            ",5",
          startDate: addVal.startDate,
          endDate: addVal.endDate,
          country: addVal.country,
          latitude: latitude,
          longitude: longitude,
          isRecurring: addVal.isRecurring
        };

        console.log(bodyData, 'bodyData');
        // return false
        Services.addLocationGreeting(bodyData).then((response) => {
          console.log(response, "response--");
          if (response.data.status === 200) {
            swal("Success", response.data.message, "success");
            getData(1);
            document.querySelector(".modal-backdrop").remove();
            // document.querySelector("#addgreetingmodel").classList.remove("show");
            document.querySelector("#addgreetingmodel .btn-close").click();

          } else {
            swal("Failed", response.data.message, "error");
          }
        }).catch((err) => {
          swal("Failed", err.response.data.message, "error");
        });
      }, 1000);
    }
  };

  const handleOnchangeAdd = (e) => {
    console.log(e.target.value, e.target.name);
    const { value, name } = e.target;
    console.log(value, 'val');
    if (name == "country") {
      errAddVal.country = value.length > 0 ? "" : "Select country";
    }

    if (name == "message") {
      errAddVal.message = value.length > 0 ? "" : "Enter message";
    }

    // setCatVal()

    if (name == 'isRecurring') {
      setAddVal({ ...addVal, [name]: e.target.checked });
    } else {
      setAddVal({ ...addVal, [name]: value });
    }


    setErrAddVal({ ...errAddVal });

    // setCatVal(e.target.value)
  };


  const handleOnchangeUpdate = (e) => {
    console.log(e.target.value, e.target.name);
    const { value, name } = e.target;

    if (e.target.name == "country1") {
      errEditVal.country1 = value.length > 0 ? "" : "Select country";
    }

    if (e.target.name == "message1") {
      errEditVal.message1 = value.length > 0 ? "" : "Enter message";
    }

    // setCatVal()
    if (name == 'isRecurring1') {
      setEditVal({ ...editVal, [name]: e.target.checked });
    } else {
      setEditVal({ ...editVal, [name]: value });
    }

    setErrEditVal({ ...errEditVal });

    // setCatVal(e.target.value)
  };

  const handleOnSubmitUpdate = async (e) => {
    // console.log("fgvcx", e);
    e.preventDefault();
    $('.error-msg').show();
    if (editVal.country1 == "") {
      errEditVal.country1 = "Select country";
    } else {
      errEditVal.country1 = "";
    }

    if (editVal.startDate1 == "") {
      errEditVal.startDate1 = "Enter startDate";
    } else {
      errEditVal.startDate1 = "";
    }

    if (editVal.endDate1 == "") {
      errEditVal.endDate1 = "Enter endDate";
    } else {
      errEditVal.endDate1 = "";
    }

    if (editVal.message1 == "") {
      errEditVal.message1 = "Enter message";
    } else {
      errEditVal.message1 = "";
    }

    setErrEditVal({ ...errEditVal });
    if (errEditVal.country1 === '' && errEditVal.startDate1 === '' && errEditVal.endDate1 === '' && errEditVal.message1 === '') {
      var latitude = "";
      var longitude = "";

      await Radar.autocomplete(
        {
          query: editVal.country1,
          limit: 1,
        },
        function (err, result) {
          if (!err) {
            console.log(result.addresses[0].latitude, "result");
            latitude = result.addresses[0].latitude;
            longitude = result.addresses[0].longitude;
          }
        }
      );

      setTimeout(() => {
        var bodyData = {
          id: editVal._id,
          message: editVal.message1,
          location:
            "https://satellites.pro/plan/" +
            editVal.country1 +
            "_map#" +
            latitude +
            "," +
            longitude +
            ",5",
          date: editVal.date1,
          country: editVal.country1,
          latitude: latitude,
          longitude: longitude,
          isRecurring: editVal.isRecurring1,
          startDate: editVal.startDate1,
          endDate: editVal.endDate1
        };
        Services.editLocationGreeting(bodyData).then((response) => {
          console.log(response, "response");
          if (response.data.status === 200) {
            swal("Success", response.data.message, "success");
            getData(1);
            document.querySelector(".modal-backdrop").remove();
            document.querySelector("#editgreetingmodel .btn-close").click();
          } else {
            swal("Failed", response.data.message, "error");
            alert("Something went to wrong! Try again");
          }
        }).catch((err) => {
          swal("Failed", err.response.data.message, "error");
        });
      }, 1000);
    }
  };

  function dateCheck(from, to, check) {

    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getData(1);
    let currYear = new Date().getFullYear();
    let futureYear = currYear + 10;
    let pastYear = currYear - 20;
    var yearArr = [];
    for (let year = pastYear; year <= futureYear; year++) {
      yearArr.push(year);
    }
    setAllYearArr(yearArr);
  }, []);

  async function getData(pageno) {
    const bodyData = {
      page: pageno,
      limit: 100,
    };

    var res = await Services.getLocationGreeting(bodyData);

    if (res.status === 200) {
      console.log(res, "res");
      if (res.data.data.message_data.length !== 0) {
        var data = res.data.data.message_data;
        data.map((data1) => {
          // var days = [
          //   "Sunday",
          //   "Monday",
          //   "Tuesday",
          //   "Wednesday",
          //   "Thursday",
          //   "Friday",
          //   "Saturday",
          // ];
          // var dayName = days[new Date(data1.date).getDay()];
          // data1.display_date =
          // moment(data1.date).format("DD/MM/YYYY") + "- " + dayName;
          allGreetingData = [...allGreetingData,data1]
        });

        if (res.data.data.state) {
          getData(res.data.data.state.page + 1);
        }
      }
    }

    console.log(allGreetingData, "allGreetingData");
    setGreetingData(allGreetingData);
  }

  const setEditData = (country, startdate, enddate, message, _id, isRecurring) => {
    $('.error-msg').hide();
    setEditVal({ country1: country, message1: message, _id: _id, isRecurring1: isRecurring, startDate1: new Date(startdate), endDate1: new Date(enddate) });

  };

  const onClickCancel = () => {
    document.querySelector(".modal-backdrop").remove();
    document.querySelector("#cancelappointment .btn-close").click();
  };

  const onClickDelete = () => {
    Services.deleteLocationGreeting(greetingId).then((response) => {
      if (response.data.status === 200) {
        swal("Success", response.data.message, "success");
        document.querySelector("#cancelappointment .btn-close").click();
        document.querySelector(".modal-backdrop").remove();
        getData(1);
        $("body").removeAttr("style");
        $("body").removeClass("modal-open");

      } else {
        swal("Failed", response.data.message, "error");
      }
    });
  };

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
    filterData(e.target.value, '', '', 1);
    // filterData(e.target.value, showMonth, showYear);
  };

  const onChangeMonth = (e) => {
    setMonth(e.target.value);
    var year1 = showYear;
    var month1 = showMonth;

    if (year1 === "All") {
      year1 = new Date().getFullYear();
    }

    if (e.target.value === "All") {
      month1 = new Date().getMonth();
    } else {
      month1 = e.target.value;
    }
    setStartDate(new Date(year1 + "-" + month1 + "-01"));
    setEndDate(new Date(year1 + "-" + month1 + "-30"));
    filterData(showCountry, new Date(year1 + "-" + month1 + "-01"), new Date(year1 + "-" + month1 + "-30"), 1);
  };

  const onChangeYear = (e) => {
    // setStartDate(new Date(e.target.value+'-'+'01'+'-01'))
    setYear(e.target.value);
    var year1 = showYear;
    var month1 = showMonth;

    if (e.target.value === "All") {
      year1 = new Date().getFullYear();
    } else {
      year1 = e.target.value;
    }

    if (month1 === "All") {
      month1 = new Date().getMonth();
    }
    setStartDate(new Date(year1 + "-" + month1 + "-01"));
    setEndDate(new Date(year1 + "-" + month1 + "-30"));
    filterData(showCountry, new Date(year1 + "-" + month1 + "-01"), new Date(year1 + "-" + month1 + "-30"), 1);
    // filterData(showCountry, showMonth, e.target.value);
  };
  var allfilterData = [];

  const filterData = async (country, startdate, enddate, page) => {
    console.log(startdate, enddate, '--1');
    console.log(startDate, endDate, '--2', page);
    if (startdate !== "" || enddate !== "") {
      var start = startdate;
      var end = enddate;
    } else {
      var start = startDate;
      var end = endDate;
    }

    if (start !== "") {
      // start = startDate.split('T')[0];
      start = moment(start).format("YYYY-MM-DD")
    }

    if (end !== "") {
      // end = endDate.split('T')[0];
      end = moment(end).format("YYYY-MM-DD")
    }

    console.log(start, end, 'final');
    var bodyData = {};
    if (country !== 'All') {
      bodyData['country'] = country;
    } else {
      bodyData['country'] = "";
    }
    bodyData['startDate'] = start
    bodyData['endDate'] = end;
    bodyData['limit'] = 10;
    bodyData['page'] = page;
    console.log(bodyData)
    var res = await Services.searchLocationGreeting(bodyData);
    if (res.status === 200) {
      if (res.data.data.message_data.length !== 0) {
        res.data.data.message_data.map((data1) => {
          allfilterData.push(data1);
        });

        if (res.data.data.state) {
          filterData(showCountry, res.data.data.state.page + 1);
        }
      }
    }

    if (allfilterData.length !== 0) {
      setGreetingData(allfilterData);
    } else {
      setGreetingData([]);
    }
  }




  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Select country"),
    date: Yup.string().required("Select date"),
    // festival_name: Yup.string().required('Enter name'),
    Write_greeting: Yup.string().required("Enter message"),
  });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   mode: "onchange",
  //   resolver: yupResolver(validationSchema),
  // });



  return (
    <>
      <main class="page-content bg-light">
        <Header title="Location Greetings" />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Location Greeting</h4>

            <div class="row">
              <div class="col-md-4">
                <div class="card border-0 shadow rounded p-3 mb-4">
                  <h5 class="mb-3">Calender</h5>

                  <div class="col-md-12 pb-3">
                    <select
                      class="cstm-select cstm-om-slc"
                      name="country_search"
                      id="country_search"
                      onChange={(e) => onChangeCountry(e)}
                    >
                      <option value="All">All Country</option>
                      <option value="India">India</option>
                      <option value="Canada">Canada</option>
                      <option value="United States">United States</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Liberia">Liberia</option>
                    </select>
                  </div>

                  <div class="row pb-4">
                    <div class="col-md-7">
                      <select
                        class="cstm-select cstm-om-slc"
                        name="month_search"
                        id="month_search"
                        onChange={(e) => onChangeMonth(e)}
                      >
                        <option value="All">All Month</option>
                        <option value="01">Janaury</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                    <div class="col-md-5">
                      <select
                        class="cstm-select cstm-om-slc"
                        name="year_search"
                        id="year_search"
                        onChange={(e) => onChangeYear(e)}
                      >
                        <option value="All">All Year</option>
                        {AllYearArr.length !== 0 &&
                          AllYearArr.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                      </select>
                    </div>
                  </div>


                  {/* <img src={calendarImg} /> */}
                  {/* <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      selectsRange={true}
                      inline
                      endDate={endDate}
                    /> */}
                  {/* <DatePicker
											selected={startDate}
											dateFormat='yyyy-MM-dd'
											name='startDate1'
											onChange={(startdate) =>{ setStartDate(startdate); filterData(showCountry,startdate,endDate,1)}} />

                      <DatePicker
											selected={endDate}
											dateFormat='yyyy-MM-dd'
											name='endDate1'
											onChange={(enddate) => {setEndDate(enddate); filterData(showCountry,startDate,enddate,1)}} /> */}
                  <div class="row ">
                    <div class="col-md-6">
                    <label class="cstm-label">Start date</label>
                      <DatePicker
                        selected={startDate}
                        dateFormat='MM/dd/yyyy'
                        name='startDate1'
                        onChange={(startdate) => { setStartDate(startdate); filterData(showCountry, startdate, endDate, 1) }} placeholderText={'Start date'} />
                    </div>
                    <div class="col-md-6">
                    <label class="cstm-label">End date</label>
                      <DatePicker
                        selected={endDate}
                        dateFormat='MM/dd/yyyy'
                        name='endDate1'
                        onChange={(enddate) => { setEndDate(enddate); filterData(showCountry, startDate, enddate, 1) }} placeholderText={'End date'} />
                    </div>
                  </div>
                  <div></div>

                  {/* <div class="d-flex justify-content-between mb-4 mt-2">
								    <h5 class="mb-0">Default Greetings</h5>
								    <div class="cstm-add-greetings-link"><a href="#" data-bs-toggle="modal" data-bs-target="#addgreetingmodel">Add Greetings</a></div>
								</div>
								
								
								<div class="card shadow py-2 px-3 mb-3">
								    <div class="row">
										<div class="col-md-7"><h6 class="mb-0">Good Morning</h6></div>
										<div class="col-md-5 text-lg-end">
										 <a href="#" class="edit-link mr-3" data-bs-toggle="modal" data-bs-target="#addgreetingmodel">Edit</a>
										 <a href="#" class="delete-link" data-bs-toggle="modal" data-bs-target="#cancelappointment">Delete</a>
										</div>
									</div>
								</div>
								
								<div class="card shadow py-2 px-3 mb-3">
								    <div class="row">
										<div class="col-md-7"><h6 class="mb-0">Good Afternoon</h6></div>
										<div class="col-md-5 text-lg-end">
										 <a href="#" class="edit-link mr-3" data-bs-toggle="modal" data-bs-target="#addgreetingmodel">Edit</a>
										 <a href="#" class="delete-link" data-bs-toggle="modal" data-bs-target="#cancelappointment">Delete</a>
										</div>
									</div>
								</div>
								
							  <div class="card shadow py-2 px-3 mb-3">
								    <div class="row">
										<div class="col-md-7"><h6 class="mb-0">Good Evening</h6></div>
										<div class="col-md-5 text-lg-end">
										 <a href="#" class="edit-link mr-3">Edit</a>
										 <a href="#" class="delete-link" data-bs-toggle="modal" data-bs-target="#cancelappointment">Delete</a>
										</div>
									</div>
								</div>
								
								  <div class="card shadow py-2 px-3 mb-3">
								    <div class="row">
										<div class="col-md-7"><h6 class="mb-0">Good Night</h6></div>
										<div class="col-md-5 text-lg-end">
										 <a href="#" class="edit-link mr-3" data-bs-toggle="modal" data-bs-target="#addgreetingmodel">Edit</a>
										 <a href="#" class="delete-link" data-bs-toggle="modal" data-bs-target="#cancelappointment">Delete</a>
										</div>
									</div>
								</div> */}
                </div>
              </div>
              <div class="col-md-8">
                <div class="card border-0 shadow rounded mb-4">
                  <div class="d-flex justify-content-between align-items-center p-3 border-bottom mb-3">
                    <div class="col-md-2">
                      <h5 class="mb-0">Greetings</h5>
                    </div>
                    <div class="col-md-10">
                      <div class="row row justify-content-end">
                        {/* <div class="col-md-4"> 
								      <select class="cstm-select cstm-om-slc" name="Country" id="All">
											<option value="all">All</option>
											<option value="all">All 2</option>
									  </select>
								    </div> */}
                        <div class="col-all-auto">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addgreetingmodel"
                            onClick={onclickAdd}
                          >
                            <button class="cstm-btn">Add Greeting</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="card border-0 shadow rounded p-3 m-3 mt-0 cstm-greeting-active">
								<div class="d-flex justify-content-between align-items-center">
								   <div class="col-md-6">
								   <div class="d-flex mb-2">
									   <h5 class="mb-0">Requested Charity</h5>
									   <span class="cstm-published">Published</span>
								   </div>
								   <p class="text-muted mb-0 cstm-tx14">18/02/2022- Thrusday</p>
								   </div>
								   <div class="col-md-6 text-lg-end"><h6>United State</h6></div>
							   </div>
							   <hr />
							   <h6>Greeting Message</h6>
							   <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
							   <div class="d-flex">
							     <a href="#" class="edit-link mr-3" data-bs-toggle="modal" data-bs-target="#addgreetingmodel">Edit</a>
								 <a href="#" class="delete-link"  data-bs-toggle="modal" data-bs-target="#cancelappointment">Delete</a>
							   </div>
							</div> */}

                  {GreetingData.length !== 0 ?
                    (GreetingData.map((greeting) => (
                      <div class="card border-0 shadow rounded p-3 m-3 mt-0 ">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="col-md-6">
                            <div class="d-flex mb-2">
                              <h5 class="mb-0">{greeting.message}</h5>
                            </div>
                            <p class="text-muted mb-0 cstm-tx14">
                              {greeting.startDate && greeting.endDate && 'From : '+ moment(greeting.startDate.split('T')[0]).format("MM/DD/YYYY")  + ' - To : ' + moment(greeting.endDate.split('T')[0]).format("MM/DD/YYYY")}
                            </p>
                          <br/>

                          <div class="d-flex">
                          <a
                            href="javascript:void(0)"
                            class="edit-link mr-3"
                            data-bs-toggle="modal"
                            data-bs-target="#editgreetingmodel"
                            onClick={(e) =>
                              setEditData(
                                greeting.country,
                                greeting.startDate.split('T')[0],
                                greeting.endDate.split('T')[0],
                                greeting.message,
                                greeting._id,
                                greeting.isRecurring
                              )
                            }
                          >
                            Edit
                          </a>
                          <a
                            href="javascript:void(0)"
                            class="delete-link"
                            data-bs-toggle="modal"
                            data-bs-target="#cancelappointment"
                            onClick={() => setgreetingId(greeting._id)}
                          >
                            Delete
                          </a>
                        </div>
                        </div>

                          <div class="col-md-6 text-lg-end">
                            <h6>{greeting.country}</h6>
                          </div>
                        </div>
                        <hr />
                        {/* <h6>Greeting Message</h6>
                        <p>{greeting.message}</p> */}
                        
                      </div>
                    ))) : (<div className="NoRecord-cstm">No record found!</div>)}
                  {/* <div class="card border-0 shadow rounded p-3 m-3 mt-0 ">
								<div class="d-flex justify-content-between align-items-center">
								   <div class="col-md-6">
								   <div class="d-flex mb-2">
									   <h5 class="mb-0">Requested Charity</h5>
								   </div>
								   <p class="text-muted mb-0 cstm-tx14">18/02/2022- Thrusday</p>
								   </div>
								   <div class="col-md-6 text-lg-end"><h6>United State</h6></div>
							   </div>
							   <hr />
							   <h6>Greeting Message</h6>
							   <p><textarea name="comments" id="comments" rows="3" class="cstm-textarea px-3 py-2" placeholder="Write greetings...."></textarea></p>
							   <div class="d-flex">
							    <button class="cstm-btn">Add Greeting</button>
							   </div>
							</div>   */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div
        class="modal fade"
        id="addgreetingmodel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      // onHide={()=>alert('dsf')}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Add Greetings
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={onSubmitAdd} id="addgreetingmodelform">
              <div class="modal-body p-4 pt-0">
                <div class="mb-4">
                  <div class="mb-3">
                    <label class="cstm-label">Country</label>
                    <select
                      onChange={handleOnchangeAdd}
                      class="cstm-select"
                      name="country"
                      id="country"
                      value={addVal.country}
                    >
                      <option value="">Select Country</option>
                      <option value="India">India</option>
                      <option value="Canada">Canada</option>
                      <option value="United States">United States</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Liberia">Liberia</option>
                      {/* <option value="City2">Select country 2</option> */}
                    </select>
                    {errAddVal.country !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.country}
                      </span>
                    )}
                  </div>

                  {/* <div class="col-md-6">
                    <label class="cstm-label"> Recurring Date Setup for Greetings</label>
                    <input
                     onChange={handleOnchangeAdd}
                      name="isRecurring"
                      id="isRecurring"
                      type="checkbox"
                      class="cstm-input"
                      value={addVal.isRecurring}
                    />
                  </div> */}
                  <div className="row mb-3 pr-0">
                    <div className="col-10">
                      <label class="cstm-label"> Recurring Date Setup for Greetings</label>
                    </div>
                    <div className="col-2 align-items-center p-0">
                      <input
                        onChange={handleOnchangeAdd}
                        name="isRecurring"
                        id="isRecurring"
                        type="checkbox"
                        class="cstm-addgreetinginput"
                        value={addVal.isRecurring}
                      />
                    </div>
                  </div>
                  <div class="row pl-0">
                    <div class="col-md-6">
                    <label class="cstm-label">Start Date</label>
                      <DatePicker
                        selected={addVal.startDate}
                        dateFormat='MM/dd/yyyy'
                        name='startDate'
                        placeholderText="Start date"
                        onChange={(date) => onChangeStartDate(date, 'add')} />
                      {errAddVal.startDate !== "" && (
                        <span class="error-msg" style={{ color: "red" }}>
                          {errAddVal.startDate}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6">
                    <label class="cstm-label">End Date</label>

                      <DatePicker
                        selected={addVal.endDate}
                        dateFormat='MM/dd/yyyy'
                        placeholderText="End date"
                        name='endDate'
                        onChange={(date) => onChangeEndDate(date, 'add')} />
                      {errAddVal.endDate !== "" && (
                        <span class="error-msg" style={{ color: "red" }}>
                          {errAddVal.endDate}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div class="col-md-6">
                    <label class="cstm-label">Date</label>
                    <DatePicker
											selected={addVal.startDate}
											dateFormat='yyyy-MM-dd'
											name='startDate'
											onChange={(date) => onChangeStartDate(date,'add')} />

                    {errAddVal.startDate !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.startDate}
                      </span>
                    )}
										<img src={transfer} alt='transfer' className='date-transfer' />
										<DatePicker
											selected={addVal.endDate}
											dateFormat='yyyy-MM-dd'
											name='endDate'
											onChange={(date) => onChangeEndDate(date,'add')} />

                    {errAddVal.endDate !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.endDate}
                      </span>
                    )}
                    <input
                     onChange={handleOnchangeAdd}
                      name="date"
                      id="date"
                      type="date"
                      class="cstm-input"
                      placeholder="yyyy-mm-dd"
                      data-date-format="YYYY-MM-DD"
                      value={addVal.date}
                    />
                   <DateRangePicker
                    onChange={(item) => onChangedate(item.selection,'add')}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[addVal.date]}
                    direction="horizontal"
                  />
                  
                    {errAddVal.date !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.date}
                      </span>
                    )}
                  </div> */}

                </div>

                {/* <div class="mb-4">
																		<label class="cstm-label">Festival name</label>
																		<input {...register('festival_name')} name="festival_name" id="festival_name" type="text" class="cstm-input" placeholder="Write festival name" />
												<span className='error-yup'>{errors.festival_name?.message}</span>
										</div> */}

                <div class="mb-4">
                  <label class="cstm-label">Write greetings</label>
                  <textarea
                    onChange={handleOnchangeAdd}
                    name="message"
                    id="message"
                    rows="3"
                    class="cstm-textarea px-3 py-2"
                    placeholder="Write here"
                    value={addVal.message}
                  ></textarea>
                  {errAddVal.message !== "" && (
                    <span class="error-msg" style={{ color: "red" }}>
                      {errAddVal.message}
                    </span>
                  )}
                </div>
                <div className="submit-btnMain cstm-loader">
                  <button type="submit" className="btn-submit-login-form cstm-submitbutton">Add Greeting </button>
                  <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                    <img src={loader} />
                  </div>
                </div>

                {/* <div class="d-flex">
                  <button class=" cstm-btn" type="submit">
                    Add Greeting
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editgreetingmodel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Edit Greetings
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleOnSubmitUpdate}>
              <div class="modal-body p-4 pt-0">
                <div class="mb-4">
                  <div class="mb-3">
                    <label class="cstm-label">Country</label>
                    <select
                      class="cstm-select"
                      name="country1"
                      value={editVal.country1}
                      id="country1"
                      onChange={handleOnchangeUpdate}
                    >
                      <option value="">Select Country</option>
                      <option value="India">India</option>
                      <option value="Canada">Canada</option>
                      <option value="United States">United States</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Liberia">Liberia</option>
                      {/* <option value="City2">Select country 2</option> */}
                    </select>
                    {errEditVal.country !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errEditVal.country1}
                      </span>
                    )}
                  </div>
                  <div className="row mb-3 pr-0">
                    <div className="col-10">
                      <label class="cstm-label"> Recurring Date Setup for Greetings</label>
                    </div>
                    <div className="col-2 align-items-center">
                      <input
                        onChange={handleOnchangeAdd}
                        name="isRecurring"
                        id="isRecurring"
                        type="checkbox"
                        class="cstm-addgreetinginput"
                        value={addVal.isRecurring}
                      />
                    </div>
                  </div>

                  {/* <div class="col-md-6">
                    <label class="cstm-label"> Recurring Date Setup for Greetings</label>
                    <input
                     onChange={handleOnchangeUpdate}
                      name="isRecurring1"
                      id="isRecurring1"
                      type="checkbox"
                      class="cstm-input"
                      checked={editVal.isRecurring1}
                      value={editVal.isRecurring1}
                    />
                  </div> */}

                  <div class="row pl-0">
                    <div class="col-md-6">
                    <label class="cstm-label">Start date</label>
                      <DatePicker
                        selected={editVal.startDate1}
                        dateFormat='MM/dd/yyyy'
                        name='startDate1'
                        placeholderText={'Start date'}
                        onChange={(date) => onChangeStartDate(date, 'edit')} />

                      {errEditVal.startDate1 !== "" && (
                        <span class="error-msg" style={{ color: "red" }}>
                          {errEditVal.startDate1}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6">
                    <label class="cstm-label">End date</label>
                      <DatePicker
                        selected={editVal.endDate1}
                        dateFormat='MM/dd/yyyy'
                        name='endDate1'
                        placeholderText={'End date'}
                        onChange={(date) => onChangeEndDate(date, 'edit')} />
                      {errEditVal.endDate1 !== "" && (
                        <span class="error-msg" style={{ color: "red" }}>
                          {errEditVal.endDate1}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <div class="col-md-6">
                    <label class="cstm-label">Date</label>
                    <DatePicker
                      selected={editVal.startDate1}
                      dateFormat='yyyy-MM-dd'
                      name='startDate1'
                      onChange={(date) => onChangeStartDate(date, 'edit')} />

                    {errEditVal.startDate1 !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errEditVal.startDate1}
                      </span>
                    )}
                    <DatePicker
                      selected={editVal.endDate1}
                      dateFormat='yyyy-MM-dd'
                      name='endDate1'
                      onChange={(date) => onChangeEndDate(date, 'edit')} />

                    {errEditVal.endDate1 !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errEditVal.endDate1}
                      </span>
                    )}
                    <DateRangePicker
                    onChange={(item) => onChangedate(item.selection,'edit')}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    // startdate={editVal.date1.startdate}
                    // enddate={editVal.date1.endDate}
                    ranges={[editVal.date1]}
                    direction="horizontal"
                  />
                    <input
                      name="date1"
                      id="date1"
                      type="date"
                      class="cstm-input"
                      value={moment(editVal.date1).utc().format("YYYY-MM-DD")}
                      onChange={handleOnchangeUpdate}
                      placeholder="yyyy-mm-dd"
                      data-date-format="YYYY-MM-DD"
                    />
                    {errEditVal.date1 !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>{errEditVal.date1}</span>
                    )}
                    
                  </div> */}
                </div>

                {/* <div class="mb-4">
																		<label class="cstm-label">Festival name</label>
																		<input {...register('festival_name')} name="festival_name" id="festival_name" type="text" class="cstm-input" placeholder="Write festival name" />
												<span className='error-yup'>{errors.festival_name?.message}</span>
										</div> */}

                <div class="mb-4">
                  <label class="cstm-label">Write greetings</label>
                  <textarea
                    onChange={handleOnchangeUpdate}
                    name="message1"
                    value={editVal.message1}
                    id="message1"
                    rows="3"
                    class="cstm-textarea px-3 py-2"
                    placeholder="Write here"
                  ></textarea>
                  {errEditVal.message1 !== "" && (
                    <span class="error-msg" style={{ color: "red" }}>{errEditVal.message1}</span>
                  )}
                </div>

                <div class="d-flex">
                  <button class=" cstm-btn" type="submit">
                    Edit Greeting
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body py-5">
              <div class="text-center">
                <div class="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ display: "none" }}>
                </button>
                <h4 class="mt-4">
                  Are sure you want to delete
                  <br /> this Greeting?
                </h4>
                <div class="mt-4">
                  <button
                    class="mr-3 cstm-btn cstn-discard"
                    onClick={onClickDelete}
                  >
                    Delete
                  </button>
                  <button
                    class="cstm-btn2 cstn-discard"
                    onClick={onClickCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationGreetings;
