import { useEffect } from "react";
import { useBreadcrumbStore } from "../../stores/breadcrumb.store";

/**
 * @param {import('../../typedefs').BreadcrumbItem[]} breadcrumbs
 */
export function useSetBreadcrumbs(breadcrumbs) {
    const setBreadcrumbs = useBreadcrumbStore((state) => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs(breadcrumbs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

/**
 * @param {*} appRouteItem 
 * @param {import('react-router-dom').Location} location 
 * @param {Object} routeParams 
 * @returns {import('../../typedefs').BreadcrumbItem}
 */
export function createBreadcrumb(appRouteItem, location, routeParams = {}) {
    return {
        label: appRouteItem.breadcrumb.label,
        path: location?.state?.breadcrumbPath || generateRouteWithParams(appRouteItem.route, routeParams),
    };
}

/**
 * @param {string} route 
 * @param {Object} params 
 * @returns {string}
 */
function generateRouteWithParams(route, params = {}) {
    Object.keys(params).forEach((key) => {
        route = route.replace(`:${key}`, params[key]);
    });
    return route;
}
