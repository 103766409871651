import './TextWithLabel.css';

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {string} props.label
 * @param {boolean} props.isMultiline
 * @param {React.CSSProperties} props.textStyle
 * @param {React.CSSProperties} props.labelStyle
 */
export function TextWithLabel({
  text,
  label,
  isMultiline = false,
  textStyle,
  labelStyle,
}) {
  return (
    <div className="h-text-with-label">
      <span className="h-label" style={labelStyle}>
        {label}:
      </span>
      <p
        className={`h-text ${isMultiline ? "is-multiline" : ""}`}
        style={textStyle}
      >
        {text}
      </p>
    </div>
  );
}
