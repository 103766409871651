import baseApi from "./base-api";

function createCrowdFund(data) {
    return baseApi.post(`admin/settings/crowd-fund-settings`, data);
}

function updateCrowdFund(id, data) {
    return baseApi.put(`admin/settings/crowd-fund-settings/${id}`, data);
}

function getCrowdFundList() {
    return baseApi.get(`admin/settings/crowd-fund-settings`);
}

function getCrowdFundById(id) {
    return baseApi.get(`admin/settings/crowd-fund-settings/${id}`);
}

function deleteCrowdFundById(id) {
    return baseApi.delete(`admin/settings/crowd-fund-settings/${id}`);
}

const settingService = {
    createCrowdFund: createCrowdFund,
    updateCrowdFund: updateCrowdFund,
    getCrowdFundList: getCrowdFundList,
    getCrowdFundById: getCrowdFundById,
    deleteCrowdFundById: deleteCrowdFundById
};

export default settingService;