function getThankYouSenderDetails(thankYou) {
    if (thankYou.platform === 'mobile' && thankYou.createdBy) {
        return {
            firstName: thankYou.createdBy.firstName,
            email: thankYou.createdBy.email,
            phoneNumber: thankYou.createdBy.phoneNumber,
        };
    }

    if (thankYou.platform === 'web' && thankYou.createdUserData) {
        return {
            firstName: thankYou.createdUserData.firstName,
            email: thankYou.createdUserData.email,
            phoneNumber: thankYou.createdUserData.phoneNumber,
        };
    }
}

const thankYouUtils = {
    getThankYouSenderDetails: getThankYouSenderDetails,
};

export default thankYouUtils;
