import { useQuery } from '@tanstack/react-query';
import organizationService from '../../Services/organization.service';
import { QUERY_KEYS } from '../../common/query-keys';

export function useGetOrganizations() {
    const { data: organizationsRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATIONS],
        queryFn: () => organizationService.getOrganizations(),
        staleTime: Infinity,
    });

    return {
        data: organizationsRes?.data,
        isLoading: isLoading,
    };
}

/**
 * @param {string} organizationId
 * @param {Object} params
 * @param {string} params.includeTeamCount
 * @param {string} params.includeUserCount
 */
export function useGetOrganizationById(organizationId, params = {}) {
    const { data: organizationRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.ORGANIZATION_BY_ID, organizationId],
        queryFn: () => organizationService.getOrganizationById(organizationId, params),
        enabled: !!organizationId,
        staleTime: Infinity,
    });

    return {
        data: organizationRes?.data,
        isLoading: isLoading,
    };
}
