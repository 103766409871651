import React, { useState, useEffect } from 'react'
import Header from '../NavMenuBar/Header';
import { Link, useNavigate, useParams } from "react-router-dom";
import Services from '../../Services/auth.service'
import swal from 'sweetalert';
import * as Yup from "yup"; // used when validating with a pre-built solution
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import loader from "../../assets/Images/loder.gif";
import $ from 'jquery';






function AccountSetting() {

   const history = useNavigate();
   useEffect(() => {
      const loggedInUser = localStorage.getItem("token");
      !loggedInUser && history("/");
   }, []);

   const validationSchema = Yup.object().shape({
      old_pwd: Yup.string().required('Enter old password'),
      new_pwd: Yup.string().required('Enter new password'),
      c_pwd: Yup.string().oneOf([Yup.ref('new_pwd'), null], 'Confirm password not matched with password').required('Enter confirm password'),

   });
   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      getValues
   } = useForm({
      mode: "onchange",
      resolver: yupResolver(validationSchema)
   });
   // password = watch("password", "");

   const onSubmit = async (data, e) => {


      var dataValue = {

         "oldPassword": data.old_pwd,
         "newPassword": data.new_pwd,
      }
      $(".loader-main").show();

      Services.resetpassword(dataValue)
         .then(data => {
            $(".loader-main").hide();

            // console.log(data, "API respohnse");

            if (data.status == 200) {
               swal("Success", data.data.message, "success");

            } else {
               swal("Failed", data.data.message, "error");

            }
         }).catch(err => {
            console.log(err, "catch error");
            // swal("Failed ", data.data.message, "error");

         })
   }
   const resetData = () => {

   }
   // reset();
   // };

   // console.log(errors);

   return (
      <>
         <main class="page-content bg-light">
            <Header title="Account setting" main_title="Dashboard" />
            <div class="container-fluid">
               <div class="layout-specing">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="card border-0 shadow rounded">
                           <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                              <h4 class="mb-0">Change Password</h4>
                           </div>

                           <div class="p-3 col-md-10 col-lg-8">
                              <form id="changepassword" onSubmit={handleSubmit(onSubmit)}>
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="mb-3">
                                          <label class="cstm-label">Old password</label>
                                          <input name="old_pwd" {...register('old_pwd')} id="old_pwd" type="password" class="cstm-input" placeholder="********************" />
                                          <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.old_pwd?.message}</span>
                                       </div>
                                    </div>
                                    <div class="col-md-12">
                                       <div class="mb-3">
                                          <label class="cstm-label">New password</label>
                                          <input name="new_pwd" {...register('new_pwd')} id="new_pwd" type="password" class="cstm-input" placeholder="********************" />
                                          <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.new_pwd?.message}</span>

                                       </div>
                                    </div>
                                    <div class="col-md-12">
                                       <div class="mb-3">
                                          <label class="cstm-label">Confirm password</label>
                                          <input name="c_pwd" {...register('c_pwd')} id="c_pwd" type="password" class="cstm-input" placeholder="********************" />
                                          <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.c_pwd?.message}</span>

                                       </div>
                                    </div>

                                    {/* <div class="col-md-12">
                                    <div class="mb-2 mt-1">
                                       <button class="mr-3 cstm-btn">Save Changes</button>
                                       <button class="clear cstm-btn2 cstn-discard"onClick={resetData} >Discard</button>
                                    </div>
                                 </div> */}
                                    <div className="submit-btnMain cstm-loader">
                                       <button type="submit" className="btn-submit-login-form cstm-submitbutton">Save Changes</button>
                                       <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                          <img src={loader} />
                                       </div>
                                    </div>

                                 </div>
                              </form>


                           </div>

                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </main>
      </>
   )
}

export default AccountSetting