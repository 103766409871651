// import React , { useEffect ,useState } from 'react'
// import Header from '../NavMenuBar/Header';
// import { Navigate, useNavigate } from "react-router-dom";



// function requestedOrganinzation() {

//    // const history = useNavigate();

//    // useEffect(() => {
//    //    const loggedInUser = localStorage.getItem("token");
//    //    !loggedInUser && history("/");
//    // }, []);

//   return (
//     <>
//          <main class="page-content bg-light">
//             <Header title="Requested Organinzation" main_title="Organization" />
//             <div class="container-fluid">
//                <div class="layout-specing">

//                   <div class="row">
//                      <div class="col-md-12">
//                               <div class="card border-0 shadow rounded">


//                         <div class="row justify-content-between align-items-center p-3 border-bottom">
//                                <div class="col-md-3"><h4 class="mb-0">Requested Organization</h4></div>
// 							   <div class="col-md-9">

// 							   <div class="row row justify-content-end">
// 							   <div class="col-md-4">
// 							   <i class="fi fi-rr-search cstm-search-ro"></i><input name="name" id="name" type="text" class="cstm-input-seacrh" placeholder="Search requested organization" /> 
// 							   </div>
// 							   	</div>

// 							   </div>
//                            </div>

// 							<div class="col-md-12 col-lg-12">

// 							 <div class="table-responsive bg-white rounded">
//                               <table class="table mb-0 table-center">
//                                  <thead>
//                                     <tr>
//                                        <th class="border-bottom w-4">No.</th>
//                                        <th class="border-bottom w-22">Organization name</th>
//                                        <th class="border-bottom w-14">Total departments</th>
//                                        <th class="border-bottom w-14">Total members</th>
//                                        <th class="border-bottom w-15">Subscription</th>
// 									   <th class="border-bottom w-15">Pay per members</th>
// 									   <th class="border-bottom w-16">Action</th>
//                                     </tr>
//                                  </thead>
//                                  <tbody>
//                                     <tr>
//                                        <td>1</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									       <a href="organization-profile.html" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>2</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>3</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>4</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>5</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>6</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>7</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>8</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>9</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>10</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>11</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>12</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>13</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>14</td>
//                                        <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
// 									<tr>
//                                        <td>15</td>
//                                         <td>International Monetary Fund (IMF)</td>
//                                        <td>20</td>
//                                        <td>110</td>
//                                        <td>Corporate</td>
// 									   <td>4</td>
// 									   <td> 
// 									      <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a> 
// 										   <a href="#" class="cstm-chekmank"><i class="fi fi-rr-check"></i></a> 
// 										   <a href="#" class="cstm-cross mrn-rt"><i class="fi fi-rr-cross"></i></a> 
// 									   </td>
//                                     </tr>
//                                  </tbody>
//                               </table>
//                            </div>
// 							</div> 

//                         </div>
//                      </div>
//                   </div>


//                         <div class="row text-center">
//                             <div class="col-12 mt-4">
//                                 <div class="d-md-flex align-items-center text-center justify-content-between">
//                                     <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
//                                     <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
//                                         <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
//                                         <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
//                                         <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
//                                         <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
//                                         <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>


//                </div>
//             </div>
//          </main>
//     </>
//   )
// }

// export default requestedOrganinzation


import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import swal from "sweetalert";

//datatable
import { useTable, usePagination, useGlobalFilter } from "react-table";

function Table({ columns, data }) {
   const props = useTable(
      {
         columns,
         data,
         initialState: { pageIndex: 0, pageSize: 5 }
      },
      useGlobalFilter, // useGlobalFilter!
      usePagination
   );
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
      state,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
   } = props;
   console.log(props);
   React.useEffect(() => {
      // console.log(globalFilter);
   }, [globalFilter]);
   let firstRecord = pageIndex * pageSize + 1;
   let lastRecord = firstRecord + pageSize - 1;

   return (
      <>
         <main class="page-content bg-light">
            <Header main_title="Requested Organization" title="Organization " />
            <div class="container-fluid">
               <div class="layout-specing">
                  <div class="row">
                     {/* <div class="row row justify-content-end">
                <div class="col-all-auto">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#viewappointment"
                  >
                    <button class="cstm-btn">Add Organization</button>
                  </a>
                </div>
              </div> */}
                     <div class="col-md-12">
                        <div class="card border-0 shadow rounded">
                           <div class="row justify-content-between align-items-center p-3 border-bottom">
                              <div class="col-md-3">
                                 <h4 class="mb-0">Requested Organization</h4>
                              </div>{" "}
                              <div class="col-md-9">
                                 <div class="row row justify-content-end">
                                    <div class="col-md-5">
                                       {" "}
                                       <i class="fi fi-rr-search cstm-search-ro"></i>
                                       <input
                                          name="serch-organization"
                                          id="serch-organization"
                                          type="text"
                                          class="cstm-input-seacrh"
                                          value={globalFilter || ""}
                                          onChange={(e) => setGlobalFilter(e.target.value)}
                                          placeholder="Search requested organization"
                                       />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-12 col-lg-12">
                              <div class="table-responsive bg-white rounded">
                                 <table
                                    {...getTableProps()}
                                    class="table mb-0 table-center"
                                 >
                                    <thead>
                                       {" "}
                                       {headerGroups.map((headerGroup) => (
                                          <tr {...headerGroup.getHeaderGroupProps()}>
                                             {headerGroup.headers.map((column) => (
                                                <th
                                                   class="border-bottom w-22"
                                                   {...column.getHeaderProps()}
                                                >
                                                   {column.render("Header")}
                                                   {/* Render the columns filter UI */}
                                                </th>
                                             ))}
                                          </tr>
                                       ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                       {
                                          page.length > 0 && page.map((row, i) => {
                                             prepareRow(row);
                                             return (
                                                <tr {...row.getRowProps()}>
                                                   {row.cells.map((cell) => {
                                                      return (
                                                         <td {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                         </td>
                                                      );
                                                   })}
                                                </tr>
                                             );
                                          })}
                                    </tbody>

                                 </table>
                                 {page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row text-center">
                     <div class="col-12 mt-4">
                        <div class="d-md-flex align-items-center text-center justify-content-between">
                           <span class="text-muted me-3">
                              Showing {firstRecord} - {lastRecord < rows.length ? lastRecord : rows.length} out of {rows.length}
                           </span>
                           <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">

                              {pageOptions.length !== 1 && <li class="page-item">
                                 <a
                                    class="page-link"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    href="javascript:void(0)"
                                 >
                                    Prev
                                 </a>
                              </li>}
                              {

                                 pageOptions.map(pgnumber => {
                                    {/* console.log(pgnumber, "pgnumber") */ }
                                    return (
                                       pgnumber + 1 !== pageCount &&
                                       <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                                    {/* return( pgnumber) */ }
                                 })
                              }

                              {pageOptions.length !== 1 && <li class="page-item">
                                 <a
                                    class="page-link"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    href="javascript:void(0)"
                                 >
                                    Next
                                 </a>
                              </li>}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      </>
   );
}

function RequestedOrganinzation() {
   const history = useNavigate();

   const [orgData, setOrgData] = useState([]);
   //   const [formFields, setFormFields] = useState({
   //     "name": "",
   //     "role": "",
   //     "email": "",
   //     "charityName": "",
   //     "isOrganizationVerified": true,
   //     "isCharityVerified": true,
   //     "location": ""
   //   });
   //   const [err, seterr] = useState({
   //     "name": "",
   //     "role": "",
   //     "email": "",
   //     "charityName": "",
   //     "isOrganizationVerified": true,
   //     "isCharityVerified": true,
   //     "location": ""
   //   });

   orgData.forEach((orgData_id, index) => {
      orgData_id.serial = index + 1;
   });
   const DataArray = [];
   useEffect((data, e) => {
      const loggedInUser = localStorage.getItem("token");
      !loggedInUser && history("/");

      console.log(selectedUid, "setselectedid");
      getData(1);
   }, []);

   const getData = (pageno) => {
      const queryVar = {
         page: pageno,
         limit: 10,
         isOrganizationVerified: false,
         isCharityVerified: false,
      };

      Services.getOrganization(queryVar)
         .then((response) => {
            if (response.data.status === 200) {
               if (response.data.data.organization_data.length !== 0) {
                  var data = response.data.data.organization_data;
                  data.map((data1) => {
                     DataArray.push(data1)
                  })
                  if (response.data.data.state) {
                     getData(response.data.data.state.page + 1);
                  }
               }
               setOrgData(DataArray);
            } else {
               console.log("error");
            }
         })
         .catch(function (err) {
            console.log(err, "erron api");
         });
   }


   const handleApprove = (organizationID) => {

      Services.orgVerify(organizationID)
         .then((response) => {
            // console.log(response, "response")
            if (response.data.status === 200) {
               swal("Success", response.data.message, "success");
               document.querySelector('.modal-backdrop').remove();
               document.querySelector('#viewappointment').classList.remove('show');

            } else {
               swal("Failed", response.data.message, "error");

            }
         })
         .catch(function (err) {
            swal("Failed", err.response.data.message, "error");

         });
   }


   //delet---------

   const [selectedUid, setselectedUid] = useState();

   //   const handleDelete = () => {
   //     Services.deleteOrganization(selectedUid)
   //       .then((response) => {
   //         if (response.status === 200) {
   //           setOrgData(
   //             orgData.filter((post) => {
   //               return post._id !== selectedUid;
   //             })
   //           );
   //           swal("Success", response.data.message, "success");
   //           document.querySelector('.modal-backdrop').remove();
   //           document.querySelector('#cancelappointment').classList.remove('show');
   //         } else {
   //           swal("Failed", response.data.message, "error");

   //         }
   //       })
   //       .catch(function (err) {
   //         swal("Failed", err.response.data.message, "error");

   //       });
   //   };

   const columns = [
      {
         Header: "No",
         accessor: "serial",
      },

      {
         Header: "Organization Name",
         accessor: "name",
      },
      {
         Header: "Total Departments",
         accessor: "totalTeams",

         // Use our custom fuzzyText filter on this column
      },
      {
         Header: "Total Members",
         accessor: "totalMembers",

         // Use our custom fuzzyText filter on this column
      },

      {
         Header: "Actions",

         Cell: (row) => {
            return (
               <div>

                  <Link

                     to={"/organization-profile/?id=" + row.cell.row.original._id}
                     class="cstm-eye"
                  >
                     <i class="fi fi-rr-eye"></i>
                  </Link>
                  <Link
                     to={" "}
                     onClick={() => handleApprove(row.cell.row.original._id)}
                     class="cstm-chekmank"
                     data-bs-toggle="modal"
                     data-bs-target="#cancelappointment"
                  >
                     <i class="fi fi-rr-check"></i>
                  </Link>
                  {/* <Link
                     to={" "}
                     onClick={() => handleDiscard(row.cell.row.original._id)}
                     class="cstm-cross mrn-rt"
                     data-bs-toggle="modal"
                     data-bs-target="#cancelappointment"
                  >
                     <i class="fi fi-rr-cross"></i>
                  </Link> */}
               </div>
            );
         },
      },
   ];

   // const handleOnchange = (e) => {
   //    const { value, name } = e.target;

   //    switch (name) {
   //       case "name":
   //          err.name = value.length > 0 ? "" : "Enter organization name!";

   //          break;

   //       case "charityName":
   //          err.charityName = value.length > 0 ? "" : "Enter charity name!";
   //          break;
   //       case "email":
   //          err.email = value.length > 0 ? "" : "Enter your email";

   //          err.email = !new RegExp(
   //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
   //          ).test(value)
   //             ? "Enter a valid email address"
   //             : "";
   //          break;
   //       case "location":
   //          err.location = value.length > 0 ? "" : "Enter your location!";
   //          break;

   //       case "role":
   //          err.role = value.length > 0 ? "" : "Enter role!";
   //          break;
   //       default:
   //          break;
   //    }

   //    setFormFields({ ...formFields, [name]: value });
   //    seterr({ ...err });
   // };

   // const handleOnSubmit = (e) => {
   //    e.preventDefault();

   //    // console.log(formFields,"formdata")

   //    if (formFields.name === "") {
   //       err.name = "Enter organization name";
   //       // seterr({...err});
   //    } else {
   //       err.name = "";
   //    }

   //    if (formFields.charityName === "") {
   //       err.charityName = "Enter charity name";

   //    } else {
   //       err.charityName = "";
   //    }

   //    if (formFields.role === "") {
   //       err.role = "Enter role"

   //    } else {
   //       err.role = "";
   //    }


   //    if (formFields.email === "") {
   //       err.email = "Enter email"

   //    } else {
   //       err.email = "";
   //    }

   //    if (formFields.location === "") {
   //       err.location = "Enter your location"

   //    } else {
   //       err.location = "";
   //    }
   //    console.log(err, "err");
   //    seterr({ ...err });
   //    // setFormFields({ ...formFields });


   //    if (err.name === "" && err.charityName === "" && err.role === "" && err.email === "" && err.location === "") {

   //       var addOrgData = {
   //          name: formFields.name,
   //          role: formFields.role,
   //          email: formFields.email,
   //          charityName: formFields.charityName,
   //          isOrganizationVerified: true,
   //          isCharityVerified: true,
   //          location: formFields.location,
   //       };

   //       Services.addOrganization(addOrgData)
   //          .then((response) => {
   //             // return false;
   //             if (response.data.status == 200) {
   //                getData(1);
   //                swal("Success", response.data.message, "success");

   //                document.querySelector('.modal-backdrop').remove();
   //                document.querySelector('#viewappointment').classList.remove('show');
   //             } else {
   //                swal("Failed", response.data.message, "error");
   //             }
   //          })
   //          .catch((err) => {
   //             swal("Failed", err.response.data.message, "error");

   //          });
   //    };
   // }


   return (
      <>
         <Table columns={columns} data={orgData} />
         {/* <div
            class="modal fade"
            id="viewappointment"
            tabindex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
         >
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header border-0 p-4">
                     <h4 class="modal-title" id="exampleModalLabel1">
                        Add Organization
                     </h4>
                     <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     ></button>
                  </div>
                  <div class="modal-body p-4 pt-0">
                     <form onSubmit={handleOnSubmit}>
                        <div class="mb-4 row">
                           <label class="cstm-label">Email</label>
                           <input
                              name="email"
                              id="email"
                              type="email"
                              class="cstm-input"
                              placeholder="Enter your email"
                              onChange={handleOnchange}
                           />
                           {err.email !== "" && (
                              <span style={{ color: "red" }}>
                                 {err.email}
                              </span>
                           )
                           }
                        </div>
                        <div class="mb-4 row">
                           <label class="cstm-label">Organization Name</label>
                           <input
                              name="name"
                              id="org-name"
                              type="text"
                              class="cstm-input"
                              placeholder="Write organization name"
                              onChange={handleOnchange}
                           />
                           {err.name !== "" && (
                              <span style={{ color: "red" }}>
                                 {err.name}
                              </span>
                           )
                           }

                        </div>
                        <div class="mb-4 row">
                           <label class="cstm-label">Charity Name</label>
                           <input
                              name="charityName"
                              id="char-name"
                              type="text"
                              class="cstm-input"
                              placeholder="Write charity name"
                              onChange={handleOnchange}
                           />
                           {err.charityName !== "" && (
                              <span style={{ color: "red" }}>
                                 {err.charityName}
                              </span>
                           )
                           }
                        </div>
                        <div class="mb-4 row">
                           <label class="cstm-label">Role</label>
                           <input
                              name="role"
                              id="role"
                              type="text"
                              class="cstm-input"
                              placeholder="Write role"
                              onChange={handleOnchange}
                           />
                           {err.role !== "" && (
                              <span style={{ color: "red" }}>
                                 {err.role}
                              </span>
                           )
                           }
                        </div>
                        <div class="mb-4 row">
                           <label class="cstm-label">Location</label>
                           <input
                              name="location"
                              id="location"
                              type="text"
                              class="cstm-input"
                              placeholder="Write location"
                              onChange={handleOnchange}
                           />
                           {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                 {err.location}
                              </span>
                           )
                           }
                        </div>

                        <div class="d-flex">
                           <button class=" cstm-btn">
                              Add Organization
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div> */}
         {/* //delet  */}
         {/* <div
            class="modal fade"
            id="cancelappointment"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
         >
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body py-5">
                     <div class="text-center">
                        <div class="d-flex justify-content-center">
                           <img src={deleteImg} />
                        </div>
                        <h4 class="mt-4">
                           Are sure you want to delete
                           <br /> this requested organization?
                        </h4>
                        <div class="mt-4">
                           <button
                              class="mr-3 cstm-btn cstn-discard"
                              onClick={handleDelete}
                           >
                              Delete
                           </button>
                           <button class="cstm-btn2 cstn-discard">Cancel</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
      </>
   );
}

export default RequestedOrganinzation;

