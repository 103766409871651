import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useLocation, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";

const ViewNews = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const newsID = new URLSearchParams(getid).get("id");
  // const [newsData, setNewsData] = useState([]);
  const [startDate, setStartDate] = useState();

  const [newsData, setNewsData] = useState({
    news_title: "",
    banner: "",
    image_video: [],
    description: "",
    source: "",
    location: "",
    // uploadDate: "",
  });
  const [imagesPrev, setImagesPrev] = useState([]);
  const [videoPrev, setVideoPrev] = useState([]);
  var imgExtArray = [];
  var videoExtArray = [];
  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getData();
  }, []);

  const getData = () => {
    var queryVar = {
      page: 1,
      limit: 100,
    };

    Services.getNewsById(newsID, queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          var arrayImages = response.data.data.image_video;
          arrayImages.map((multifile) => {
            var filesplit = multifile.split(".").pop();
            var imageExtension = [
              "png",
              "jpg",
              "jpeg",
              "apng",
              ".avif",
              "jfif",
              "pjpeg",
              "pjp",
              "svg",
              "webp",
            ];
            var videoExtension = [
              "mp4",
              "mov",
              "wmv",
              "avi",
              "avchd",
              "flv",
              "f4v",
              "swf",
              "mkv",
              "webm",
              "html5",
              "mpeg-2",
            ];
            imageExtension.includes(filesplit) && imgExtArray.push(multifile);
            videoExtension.includes(filesplit) && videoExtArray.push(multifile);
            // console.log(multifile,"multifile");
          });

          setImagesPrev(imgExtArray);
          setVideoPrev(videoExtArray);

          setNewsData({
            news_title: response.data.data.news_title,
            image_video: arrayImages,
            description: response.data.data.description,
            location: response.data.data.location,
            source: response.data.data.source,
          });

          var data = response.data.data.uploadDate;
          var uploadDate =
            data !== undefined
              ? //  arr[i].createdAt = new Date();
                new Date(data).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
              : "";
          setStartDate(uploadDate);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="View News"
          title="News and Stories"
          main_title="News"
          title_link="news-stories"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">View News</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}

              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 ">
                  <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body p-4">
                        <div class="mb-4">
                          <label class="cstm-label">
                            News and Stories Video or Image
                          </label>
                          <br />

                          <br />

                          {imagesPrev.map((url) => (
                            <span className="ml-3">
                              {" "}
                              <img
                                class="multi-videos"
                                width="200px"
                                height="200px"
                                controls
                                src={
                                  `${process.env.REACT_APP_S3_BASE_URL}/` +
                                  url
                                }
                                alt="..."
                              />
                            </span>
                          ))}
                          {videoPrev.map((url) => (
                            <span className="ml-3">
                              {" "}
                              <video
                                class="multi-videos"
                                width="200px"
                                height="200px"
                                controls
                                src={
                                  `${process.env.REACT_APP_S3_BASE_URL}/` +
                                  url
                                }
                                alt="..."
                              />
                            </span>
                          ))}
                        </div>
                        <div class="mb-4">
                          <label class="cstm-label">News Title</label>
                          <br />
                          {newsData.news_title}
                        </div>
                        <div class="mb-4">
                          <label class="cstm-label">News Date</label>
                          <br />
                          {startDate}
                        </div>

                        <div class="mb-4">
                          <label class="cstm-label">Publisher/Source</label>
                          <br />
                          {newsData.source}
                        </div>

                        <div class="mb-4">
                          <label class="cstm-label">Location</label>
                          <br />
                          {newsData.location}
                        </div>
                        <div class="mb-4">
                          <label class="cstm-label">Description</label>
                          <br />
                          {newsData.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewNews;
