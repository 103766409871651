import { useQuery } from "@tanstack/react-query";
import userService from "../Services/user.service";
import { QUERY_KEYS } from "../common/query-keys";

/**
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string} params.search
 */
export function useGetUsers(params = {}) {
  const { data: userRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: () => userService.getUsers(params),
    staleTime: Infinity,
  });

  return {
    data: userRes?.data,
    isLoading: isLoading,
  };
}

/**
 * @param {string} userId
 */
export function useGetUserById(userId) {
  const { data: userRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USER_BY_ID, userId],
    queryFn: () => userService.getUserById(userId),
    enabled: !!userId,
    staleTime: Infinity,
  });

  return {
    data: userRes?.data,
    isLoading: isLoading,
  };
}
