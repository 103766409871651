import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import currencyService from '../../Services/currency.service';
import { Link, useLocation } from "react-router-dom";

export function EditCurrency() {
    const getid = useLocation().search;
    const currencyId = new URLSearchParams(getid).get('id');

    const [currency, setCurrency] = useState({});

    const [formFields, setFormFields] = useState({
        _id: "",
        creditCardPercentage: "",
        currencyConversionCharge: "",
        fixedTransactionFeeInAud: ""
    });

    const [err, seterr] = useState({
        creditCardPercentage: "",
        currencyConversionCharge: "",
        fixedTransactionFeeInAud: ""
    });

    useEffect(() => {
        getCurrencyDetails();
    }, []);

    async function getCurrencyDetails() {
        try {
            const response = await currencyService.getCurrencyById(currencyId);

            if (response?.status === 200) {
                const currencyData = response.data;
                setCurrency(currencyData);

                setFormFields({
                    _id: response.data?._id,
                    creditCardPercentage: response.data?.creditCardPercentage,
                    currencyConversionCharge: response.data?.currencyConversionCharge,
                    fixedTransactionFeeInAud: response.data?.fixedTransactionFeeInAud
                });

                return;
            }

            toast.error("Couldn't get currency");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get currency");
        }
    }

    const handleOnchange = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case "creditCardPercentage":
                err.title = value.length > 0 ? "" : "Enter credit card percentage";
                break;
            case "currencyConversionCharge":
                err.title = value.length > 0 ? "" : "Enter currency conversion percentage";
                break;
            case "fixedTransactionFeeInAud":
                err.title = value.length > 0 ? "" : "Enter fixed transaction fee";
                break;
            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        seterr({ ...err });
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.creditCardPercentage === "") {
            err.creditCardPercentage = "Enter credit card percentage";
        } else {
            err.creditCardPercentage = "";
        }

        if (formFields.currencyConversionCharge === "") {
            err.currencyConversionCharge = "Enter currency conversion percentage";
        } else {
            err.currencyConversionCharge = "";
        }

        if (formFields.fixedTransactionFeeInAud === "") {
            err.fixedTransactionFeeInAud = "Enter fixed transaction fee";
        } else {
            err.fixedTransactionFeeInAud = "";
        }

        seterr({ ...err });

        updateCurrencyDetails();

    };

    async function updateCurrencyDetails() {
        try {
            let data = {
                creditCardPercentage: formFields.creditCardPercentage,
                currencyConversionCharge: formFields.currencyConversionCharge,
                fixedTransactionFeeInAud: formFields.fixedTransactionFeeInAud
            };

            const response = await currencyService.updateCurrencyById(currencyId, data);

            if (response?.status === 200) {
                toast.success("Successfully updated currency details");
                return;
            }
            toast.error("Couldn't update currency");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update currency");
        }
    }

    return (
        <>
            <main class="page-content bg-light">
                <Header main_title="Edit Currency" title_link="currency-settings" title="Currency List" title1="Edit Currency" />
                <div class="container-fluid">
                    <div class="layout-specing">

                        <div class="row">
                            <div class="col-md-12">
                                <form id="add-gift" onSubmit={handleOnSubmit}>
                                    <div class="card border-0 shadow rounded">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    Currency: {currency?.currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label class="cstm-label">Credit card transaction in percentage</label>
                                                    <input
                                                        name="creditCardPercentage"
                                                        id="creditCardPercentage"
                                                        value={formFields.creditCardPercentage}
                                                        onChange={handleOnchange}
                                                        type="text"
                                                        class="cstm-input"
                                                        placeholder="Enter credit card percentage"
                                                    />
                                                    {err.creditCardPercentage !== "" && (
                                                        <span style={{ color: "red" }}>
                                                            {err.creditCardPercentage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label class="cstm-label">Currency conversion charge percentage</label>
                                                    <input
                                                        name="currencyConversionCharge"
                                                        id="currencyConversionCharge"
                                                        value={formFields.currencyConversionCharge}
                                                        onChange={handleOnchange}
                                                        type="text"
                                                        class="cstm-input"
                                                        placeholder="Enter Currency conversion percentage"
                                                    />
                                                    {err.currencyConversionCharge !== "" && (
                                                        <span style={{ color: "red" }}>
                                                            {err.currencyConversionCharge}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label class="cstm-label">Fixed transaction fee in AUD</label>
                                                    <input
                                                        name="fixedTransactionFeeInAud"
                                                        id="fixedTransactionFeeInAud"
                                                        value={formFields.fixedTransactionFeeInAud}
                                                        onChange={handleOnchange}
                                                        type="text"
                                                        class="cstm-input"
                                                        placeholder="Enter fixed transaction fee"
                                                    />
                                                    {err.fixedTransactionFeeInAud !== "" && (
                                                        <span style={{ color: "red" }}>
                                                            {err.fixedTransactionFeeInAud}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-2 mt-1">
                                            <button class="mr-3 cstm-btn">Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}