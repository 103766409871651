export const TEAM_CLASSIFICATIONS = [
    { value: "Surgical_LongStay", label: "Surgical - Long Stay", description: "" },
    { value: "Surgical_DaySurgery", label: "Surgical - Day Surgery", description: "" },
    { value: "Medical", label: "Medical - Long Stay", description: "" },
    { value: "Medical_Short_Stay", label: "Medical - Short Stay", description: "" },
    { value: "Home_Care", label: "Home Care", description: "" },
    { value: "A_And_E", label: "A&E", description: "" },
    { value: "Maternity", label: "Maternity", description: "" },
    { value: "Paediatric_Medical", label: "Paediatric - Medical", description: "" },
    { value: "Paediatric_Surgical", label: "Paediatric - Surgical", description: "" },
    { value: "Theatres", label: "Theatres", description: "Recovery, Endoscopy, etc..." },
    { value: "Ancillary", label: "Ancillary", description: "Radiology, Pharmacy, Allied Health, etc..." },
    { value: "Outpatients", label: "Outpatients", description: "" },
    { value: "CareFacilities", label: "Care Facilities", description: "Aged care, Hospices, etc..." },
    { value: "Administration", label: "Administration", description: "" },
    { value: "Charity", label: "Charity", description: "" },
];
