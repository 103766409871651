import { useQuery } from "@tanstack/react-query";
import thankyouPostImg from "../../assets/Images/thank-you-post.png";
import giftPostImg from "../../assets/Images/gift-post.png";
import joniedUsersImg from "../../assets/Images/jonied-users.png";
import statsService from "../../Services/stats.service";
import { QUERY_KEYS } from "../../common/query-keys";
import { HelperInfoTooltip } from "../../shared/components/Tooltip/HelperInfoTooltip";
import { HelperInfoCode } from "../../common/models/helper-info-code.enum";

export function EngagementStats({ filters }) {
    const { data: engagementStatsRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.ENGAGEMENT_STATS, {
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }],
        queryFn: () => statsService.getEngagementStats({
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId || undefined,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }),
        staleTime: Infinity,
    });
    const engagementStats = engagementStatsRes?.data;
    const user = engagementStats?.user;
    const patient = engagementStats?.patient;

    return (
        <div className="row mt-4">
            <div className="col-md-6">
                <div className={`card position-relative features feature-primary rounded border-0 shadow p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
                    <div className="position-absolute top-0 end-0 p-2">
                        <HelperInfoTooltip code={HelperInfoCode.DASHBOARD_ENGAGEMENT_STATS_STAFF_ENGAGEMENT} />
                    </div>

                    <div className="mb-2 d-flex flex-column align-items-center">
                        <img src={joniedUsersImg} alt="" />
                        <p className="text-muted mb-0 cstm-tx14">Staff Engagement</p>
                    </div>

                    <div className="mt-2">
                        <div>
                            <img src={thankyouPostImg} alt="" />
                            <h5>Thank You</h5>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Total:</span>
                            <span>{user?.thankyou?.totalEngagementsCount}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Unique Engagements:</span>
                            <span>{user?.thankyou?.totalUniqueEngagements}</span>
                        </div>
                    </div>

                    <div className="mt-2">
                        <div>
                            <img src={giftPostImg} alt="" />
                            <h5>Gift</h5>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Total:</span>
                            <span>{user?.gift?.totalEngagementsCount}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Unique Engagements:</span>
                            <span>{user?.gift?.totalUniqueEngagements}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className={`card position-relative features feature-primary rounded border-0 shadow p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
                    <div className="position-absolute top-0 end-0 p-2">
                        <HelperInfoTooltip code={HelperInfoCode.DASHBOARD_ENGAGEMENT_STATS_PATIENT_ENGAGEMENT} />
                    </div>

                    <div className="mb-2 d-flex flex-column align-items-center">
                        <img src={joniedUsersImg} alt="" />
                        <p className="text-muted mb-0 cstm-tx14">Patient Engagement</p>
                    </div>

                    <div className="mt-2">
                        <div>
                            <img src={thankyouPostImg} alt="" />
                            <h5>Thank You</h5>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Total:</span>
                            <span>{patient?.thankyou?.totalEngagementsCount}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Unique Engagements:</span>
                            <span>{patient?.thankyou?.totalUniqueEngagements}</span>
                        </div>
                    </div>

                    <div className="mt-2">
                        <div>
                            <img src={giftPostImg} alt="" />
                            <h5>Gift</h5>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Total:</span>
                            <span>{patient?.gift?.totalEngagementsCount}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span>Unique Engagements:</span>
                            <span>{patient?.gift?.totalUniqueEngagements}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
