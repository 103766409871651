// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-dashboard-stats-widget-img {
  width: 48px;
  height: 48px;
}

.h-dashboard-stats-widget-text-label {
  font-weight: 600;
  margin-right: 4px;
}

.h-dashboard-stats-widget-text-small {
  font-size: 0.75rem;
}

.h-dashboard-stats-widget-expand-btn {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  line-height: 0;
}
.h-dashboard-stats-widget-expand-btn.expanded {
  transform: rotate(180deg);
}
.h-dashboard-stats-widget-expand-icon {
  color: #00cccc;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".h-dashboard-stats-widget-img {\n  width: 48px;\n  height: 48px;\n}\n\n.h-dashboard-stats-widget-text-label {\n  font-weight: 600;\n  margin-right: 4px;\n}\n\n.h-dashboard-stats-widget-text-small {\n  font-size: 0.75rem;\n}\n\n.h-dashboard-stats-widget-expand-btn {\n  background: none;\n  border: none;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  padding: 0.5rem;\n  line-height: 0;\n}\n.h-dashboard-stats-widget-expand-btn.expanded {\n  transform: rotate(180deg);\n}\n.h-dashboard-stats-widget-expand-icon {\n  color: #00cccc;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
