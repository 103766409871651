import { create } from "zustand";

export const useLoaderStore = create((set) => ({
    showLoader: false,
    loadingText: "",
    setShowLoader: (showLoader) => {
        set(() => ({
            showLoader: showLoader,
        }));
    },
    setLoadingText: (text) => {
        set(() => ({
            loadingText: text,
        }));
    },
}));
