import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import swal from 'sweetalert';
import $ from 'jquery';
import Services from '../../Services/auth.service';
import logo from '../../assets/Images/admin_logo.svg';
import loader from "../../assets/Images/loder.gif";
import { LOCAL_STORAGE_KEYS } from '../../common/constants/local-storage.constant';
import { baseApiInterceptors } from '../../api/base-api.interceptor';

const Login = () => {  
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

        if (token) {
            navigate("/dashboard");
        }
    }, []);

    const validationSchema = Yup.object().shape({
        user_email: Yup.string().email('Invalid email format').required('Enter email'),
        password: Yup.string().required('Enter password')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onchange',
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data, e) => {
        const dataValue = {
            "email": data.user_email,
            "password": data.password
        };

        Services.adminLogin(dataValue)
            .then(response => {  
                $(".loader-main").show();

                if (response.data.status == 200) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.data.data.token);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, response.data.data.user_details._id);
                    baseApiInterceptors.addAuthHeaderInterceptor();
                    navigate("/dashboard");
                } else {
                    swal("Failed", "Oops! You have entered an invalid user name or password.", "error");
                }
            }).catch(err => {
                swal("Failed", "Oops! You have entered an invalid user name or password.", "error");
            });
    }

    return (
        <>
        <div className="container cstm-login"> 
            <div className="col-12">
            <div className="logo-brand-admin-portal"> 
                <img src={logo} alt="logo" />
            </div>
            <div><b>Admin Portal</b></div>
            <br/>

            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-3">
                    <label htmlFor="" className='pb-1'>Email*</label>
                    <input type="email" {...register('user_email')} name="user_email" className="form-control" placeholder="Email or username" id="username" required="" />
                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.user_email?.message}</span>
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="" className='pb-1'>Password*</label>
                    <input type="password" className="form-control" {...register('password')} placeholder="Password" name="password" id="password" required="" />
                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.password?.message}</span>
                </div>
                <div className="submit-btnMain cstm-loader">
                    <button type="submit" className="btn-submit-login-form cstm-submitbutton">Submit </button>
                    <div id="login-loader" className='loader-main' style={{display:'none'}}>
                        <img src={loader} alt="" />
                    </div>
                </div>
            </form>
            </div> 
        </div>
        </>
    );
}

export default Login;
