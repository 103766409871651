import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import currencyService from '../../Services/currency.service';
import { Link, useLocation } from "react-router-dom";

export function CurrencySetting() {
    const [currencyList, setCurrencyList] = useState([]);

    useEffect(() => {
        getCurrencyList();
    }, []);

    async function getCurrencyList() {
        try {
            const response = await currencyService.getCurrencies();

            if (response?.status === 200) {
                const currencyData = response.data;
                setCurrencyList(currencyData);

                return;
            }

            toast.error("Couldn't get currency list");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get currency list");
        }
    }


    return (
        <main className="page-content bg-light">
            <Header title1="Currency Settings" title="Currency List" main_title="Currency Settings" title_link="currency-settings" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div>
                        <h4 className="mb-4">Currency Settings</h4>
                    </div>

                    <div className="overflow-auto">
                        <table className="table mb-0 table-center">
                            <thead>
                                <tr className="border-top">
                                    <th className="border-bottom w-4 cstm-userheading">Currency</th>
                                    <th className="border-bottom w-4 cstm-userheading">AUD Conversion Rate</th>
                                    <th className="border-bottom w-4 cstm-userheading">Credit card transaction</th>
                                    <th className="border-bottom w-4 cstm-userheading">Conversion charge</th>
                                    <th className="border-bottom w-4 cstm-userheading">Fixed transaction fee</th>
                                    <th className="border-bottom w-4 cstm-userheading">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {currencyList && currencyList.map((element, index) => {
                                    return (
                                        <tr
                                            key={element._id}
                                            className="cstm-Tabledesign cstm-usertable-design"
                                        >
                                            <td>{element.currency}</td>
                                            <td>{element.audConversionRate}</td>
                                            <td>{element.creditCardPercentage}</td>
                                            <td>{element.currencyConversionCharge}</td>
                                            <td>{element.fixedTransactionFeeInAud}</td>
                                            <td>
                                                <div>
                                                    <Link
                                                        to={`/currency-details?id=${element._id}`}
                                                        className="cstm-eye"
                                                        state={''}
                                                    >
                                                        <button
                                                            type='button'
                                                            className="cstm-eye"
                                                            onClick={() => console.log('') }
                                                            class="cstm-delete mrn-rt"
                                                        // data-bs-toggle="modal"
                                                        >
                                                            <i className="fi fi-rr-edit"></i>

                                                        </button>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>

                                    );
                                })}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
        </main>
    );
}