import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { useLoaderStore } from '../../../stores/loader.store';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import settingService from '../../../Services/settings.service';

const CROWD_FUND_SETTINGS_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function CrowdFundingSettings() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [crowdFunds, setCrowdFunds] = useState();
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        getCrowdFundList();
    }, []);

    async function getCrowdFundList() {
        setShowLoader(true);

        try {
            const res = await settingService.getCrowdFundList();

            if (res.status == 200) {
                toast.success("Successfully fetched crowd fund settings");
                setCrowdFunds(res.data);
                return;
            }

            toast.error("Couldn't fetch crowd fund settings");
        }
        catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't fetch crowd fund settings");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function handleDeleteCrowdFund() {
        setShowLoader(true);
        try {
            const deleteCrowdFund = selectedRow.crowdFund;
            const res = await settingService.deleteCrowdFundById(deleteCrowdFund._id);

            if (res.status === 200) {
                toast.success("Successfully deleted crowd fund");
                setCrowdFunds((crowdFund) => crowdFund.filter((t) => t._id !== deleteCrowdFund._id));
                return;
            }

            toast.error("Couldn't delete crowd fund");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete crowd fund");
        } finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    return (
        <SidebarLayoutContent headerTitle='Settings - Crowd Fund'>
            <div className="card border-0 shadow rounded">
                <div className="row justify-content-between align-items-center p-3">
                    <div className="col-md-3">
                        <h4 className="mb-0">Crowd Fund Settings</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row row justify-content-end">
                            <div className="col-all-auto">
                                <Link
                                    to="/add-crowd-fund-settings"
                                >
                                    <button className="cstm-btn">Add Crowd Fund</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">No</th>
                                <th className="border-bottom w-4 cstm-userheading">Country</th>
                                <th className="border-bottom w-4 cstm-userheading">Country Code</th>
                                <th className="border-bottom w-4 cstm-userheading">Crowd Fund Url</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {crowdFunds?.map((crowdFund, index) => (
                                <tr
                                    key={crowdFund._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{index + 1}</td>
                                    <td>{crowdFund.countryName}</td>
                                    <td>{crowdFund.countryCode}</td>
                                    <td>{crowdFund.crowdFundUrl}</td>
                                    <td>
                                        <div>
                                            <Link
                                                to={`/edit-crowd-fund-settings/${crowdFund._id}`}
                                                className="cstm-delete mrn-rt"
                                            >
                                                <i className="fi fi-rr-edit"></i>
                                            </Link>

                                            {/* delete button */}
                                            <Link
                                                to={""}
                                                onClick={() => setSelectedRow({ crowdFund: crowdFund, action: CROWD_FUND_SETTINGS_ACTION.DELETE })}
                                                class="cstm-delete mrn-rt"
                                            >
                                                <i class="fi fi-rr-trash"></i>
                                            </Link>
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {(selectedRow && selectedRow.action === CROWD_FUND_SETTINGS_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this crowd fund setting?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteCrowdFund}
                    onCancel={() => setSelectedRow(null)}
                />
            )}

        </SidebarLayoutContent>
    );
}