import React, { useEffect, useState, useRef } from "react";
import Header from "../NavMenuBar/Header";
import userProfileImg from "../../assets/Images/photo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import * as Yup from "yup"; // used when validating with a pre-built solution
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loader from "../../assets/Images/loder.gif";
import $ from 'jquery';


function Profile() {
  const history = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
  }, []);

  //image upload and preview
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // end img preview
  const [formFields, setFormFields] = useState({});
  const [proimg, setProImg] = useState();

  const [editerr, setediterr] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: "",
    phoneNumber: "",
    countryCode: "",
    bio: "",
    address: "",
    image: ""
  });

  // const [file, setFile] = useState([]);

  useEffect(() => {
    Services.getAdminprofile()
      .then((response) => {
        if (response.status === 200) {
          setFormFields(response.data.data);
          setProImg(response.data.data.image);

        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }, []);

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    // let errors = formData.errors;
    switch (name) {
      case "firstName":
        // console.log(editerr.firstName, "fhsdfhsdf");
        editerr.firstName = value.length > 0 ? "" : "Enter your first name";

        break;

      case "lastName":
        editerr.lastName = value.length > 0 ? "" : "Enter your last name";
        break;

      case "address":
        editerr.address = value.length > 0 ? "" : "Enter your address!";
        break;

      case "bio":
        editerr.bio = value.length > 0 ? "" : "Enter your bio";
        break;

      case "phoneNumber":
        if (value.length !== 10) {
          // we will set the error state
          editerr.phoneNumber = "phone atleast have 10 number";
        } else if (!/^[0-9\b]+$/.test(value)) {
          editerr.phoneNumber = "Enter only number";
        } else if (value.length === "") {
          editerr.phoneNumber = "Please enter a number";
        } else {
          editerr.phoneNumber = "";
        }
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    setediterr({ ...editerr });
  };
  // useEffect(()=>{

  // },[])
  const [file, setFile] = useState();
  const handleImageChange = (e) => {
    e.preventDefault();
    setFile(URL.createObjectURL(e.target.files[0]));
    var img = document.getElementById("image");
    img.style.display = "none";
    let file = e.target.files[0];

    var fileExt = file.name.split('.').pop();

    var ExtArray = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

    if (ExtArray.includes(fileExt)) {
      editerr.image = "";
    } else {
      editerr.image = "Please upload image only";
      formFields.image = "";
    }

    setediterr({ ...editerr });

    if (editerr.image === "") {
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      $(".loader-main").show();

      Services.imageGet(formData)
        .then((response) => {
          $(".loader-main").hide();

          // console.log(response, "send data");
          if (response.status === 200) {
            // console.log("succes upload");
            formFields.image = response.data.data.images[0].location;
            // setProImg(formFields.image);
            console.log(formFields.image, "image set");
          } else {
            console.log("fail upload");
          }
        })
        .catch(function (err) {
          console.log(err, "err");
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formFields.firstName === "") {
      editerr.firstName = "Enter your first name";
      // setediterr()
    }
    if (formFields.lastName === "") {
      editerr.lastName = "Enter your last name";
    }
    if (formFields.phoneNumber === "") {
      editerr.phoneNumber = "Please enter a number";
    }
    if (formFields.address === "") {
      editerr.address = "Enter your address";
    }
    if (formFields.bio === "") {
      editerr.bio = "Enter your bio";
    }
    setediterr({ ...editerr });
    // console.log(editerr,"editerr");

    if (
      editerr.firstName === "" &&
      editerr.lastName === "" &&
      editerr.email === "" &&
      editerr.phoneNumber === "" &&
      editerr.address === "" &&
      editerr.bio === "" &&
      editerr.image === ""
    ) {
      // return false;
      var dataValue = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        phoneNumber: formFields.phoneNumber,
        address: formFields.address,
        bio: formFields.bio,
        image: formFields.image,
      };
      $(".loader-main").show();

      Services.editAdminProfile(dataValue)
        .then((response) => {
      $(".loader-main").hide();

          // console.log(response, "send data");
          if (response.status === 200) {
            setProImg(response.data.data.image);
            console.log(response.data.data.image, "responce img");
            swal("Success", response.data.message, "success");
          } else {
            swal("Failed", response.data.message, "error");
          }
        })
        .catch(function (err) {
          swal("Failed", err.response.data.messag, "error");
          console.log(err, "err");
        });
    }
  };
  // const handleClear =()=>{
  //   setFormFields({...formFields});
  // }
  console.log(proimg, "proimg");
  return (
    <>
      <main class="page-content bg-light">

        <Header title="Profile" main_title="Dashboard" profileimage={proimg !== undefined &&
          proimg.length > 0
          ? `${process.env.REACT_APP_S3_BASE_URL}/` +
          proimg
          : userProfileImg} />
        {/* {"https://human-backend-data.s3.eu-west-2.amazonaws.com/" + proimg} */}

        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h4 class="mb-0">Profile</h4>
                  </div>
                  <div class="p-3 col-md-10 col-lg-8">
                    <form id="profile" onSubmit={handleSubmit}>
                      <div class="mb-4 cstm-profile-img cstm-AdminProfile-img d-flex  align-items-center ">
                        <div class="mr-5 items-center">
                          {/* <img src={userProfileImg} /> */}

                          {/* //image preview  */}
                          {/* {imageUrl && selectedImage ? (
                            <img src={imageUrl} alt={selectedImage.name} width="112px" height="112px" />
                          ) :
                            (<img src={userProfileImg} />)

                          } */}
                          {/* {file && <img src={file} />} */}
                          {file && editerr.image === "" && <img src={file} />}
                          <img
                            id="image"
                            src={
                              formFields.image !== undefined &&
                                formFields.image !== null
                                ? `${process.env.REACT_APP_S3_BASE_URL}/` +
                                formFields.image
                                : userProfileImg
                            }
                          />
                          {editerr.image !== "" && (
                            <span style={{ color: "red" }}>
                              {editerr.image}
                            </span>
                          )}

                          {/* <img src={formFields.image !== undefined && formFields.image.length > 0 ? "https://human-backend-data.s3.eu-west-2.amazonaws.com/" + formFields.image : userProfileImg}/> */}

                          {/* {
                         (formFields.image !== "" )?(<img src={"https://human-backend-data.s3.eu-west-2.amazonaws.com/" + formFields.image } width="112px" height="112px" />):
                         ("GFH")
                         } */}

                          {/* <img src={"https://human-backend-data.s3.eu-west-2.amazonaws.com/" + (formFields.image == "" ? ("6303787db84ba4990787731c/profile/1661839019387.jpeg") : (formFields.image))} width="112px" height="112px" /> */}
                          <div className="submit-btnMain cstm-loader">
                            <label for="select-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                              <img src={loader} />
                            </div>
                          </div>
                          <input
                            accept="image/*"
                            type="file"
                            name="image"
                            id="select-image"
                            className='cstm-uploadHide'
                            // style={{ opacity: 0, cursor: 'pointer' }}
                            onChange={handleImageChange}
                          />
                        </div>
                        {/* <div>
                          <button class="mr-3 cstm-btn">Update image</button>
                          <button class="cstm-btn2" onClick={removeImage}>
                            Remove image
                          </button>
                        </div> */}
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="cstm-label">First name</label>
                            <input
                              name="firstName"
                              value={formFields.firstName}
                              id="firstName"
                              onChange={handleOnchange}
                              type="text"
                              class="cstm-input"
                              placeholder="Jane"
                            />
                            {editerr.firstName !== "" && (
                              <span style={{ color: "red" }}>
                                {editerr.firstName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="cstm-label">Last name</label>
                            <input
                              name="lastName"
                              value={formFields.lastName}
                              id="lastName"
                              onChange={handleOnchange}
                              type="text"
                              class="cstm-input"
                              placeholder="Cooper"
                            />
                            {editerr.lastName.length > 0 && (
                              <span style={{ color: "red" }}>
                                {editerr.lastName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="cstm-label">Email</label>
                            <input
                              name="email"
                              id="email"
                              value={formFields.email}
                              type="email"
                              class="cstm-input"
                              placeholder="jane.son@example.com"
                              disabled
                            />
                            {editerr.email !== "" && (
                              <span style={{ color: "red" }}>
                                {editerr.email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="cstm-label">Contact no</label>
                            <input
                              name="phoneNumber"
                              id="phoneNumber"
                              value={formFields.phoneNumber}
                              onChange={handleOnchange}
                              type="text"
                              maxlength="10"
                              class="cstm-input"
                              placeholder="+61 8888 8888"
                            />
                            {editerr.phoneNumber !== "" && (
                              <span style={{ color: "red" }}>
                                {editerr.phoneNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="cstm-label">Address</label>
                            <input
                              name="address"
                              id="address"
                              value={formFields.address}
                              onChange={handleOnchange}
                              type="text"
                              class="cstm-input"
                              placeholder="4517 Washington Ave. Manchester, Kentucky 39495"
                            />
                            {editerr.address !== "" && (
                              <span style={{ color: "red" }}>
                                {editerr.address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="cstm-label">Bio</label>
                            <textarea
                              name="bio"
                              id="bio"
                              rows="5"
                              value={formFields.bio}
                              onChange={handleOnchange}
                              class="cstm-textarea"
                              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."
                            ></textarea>
                            {editerr.bio !== "" && (
                              <span style={{ color: "red" }}>
                                {editerr.bio}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="submit-btnMain cstm-loader">
                          <button type="submit" className="btn-submit-login-form cstm-submitbutton">Save Changes</button>
                          <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                            <img src={loader} />
                          </div>
                        </div>

                        {/* <div class="col-md-12">
                          <div class="mb-2 mt-1">
                            <button class="mr-3 cstm-btn">Save Changes</button>
                            
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Profile;
