import baseApi from "./base-api";

function getUsersWithNoTeams({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("users/team/users/no-teams", {
        page: pageNumber,
        limit: pageSize,
    });
}

function getTeams({ organizationIds, search, pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("teams", {
        organizationIds: organizationIds,
        search: search,
        page: pageNumber,
        limit: pageSize,
    });
}

function getTeamById(teamId) {
    return baseApi.get(`teams/${teamId}`);
}

function updateTeam(teamId, teamUpdateData) {
    return baseApi.put(`teams/${teamId}`, teamUpdateData);
}

const teamService = {
    getTeams: getTeams,
    getTeamById: getTeamById,
    updateTeam: updateTeam,
    getUsersWithNoTeams: getUsersWithNoTeams
};

export default teamService;
