import baseApi from "./base-api";

function getSignedUrlForUpload(path, contentType) {
    return baseApi.post(`storage/signed-url`, {
        "path": path,
        "contentType": contentType
    });
}

function intitiateVideoTranscode(sourcePath, destinationPath) {
    return baseApi.post(`video/transcode`, {
        "inputPath": sourcePath,
        "outputPath": destinationPath
    });
}

const storageService = {
    getSignedUrlForUpload: getSignedUrlForUpload,
    intitiateVideoTranscode: intitiateVideoTranscode
};

export default storageService;