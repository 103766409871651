import { DEFAULT_PAGE_SIZE } from "../../common/constants/pagination.constant";

/**
 * @param {Object} props
 * @param {number} props.currentPage
 * @param {number} props.totalRows
 * @param {number} props.rowsPerPage
 * @param {number} props.maxVisiblePages - maximum number of visible pages in each side
 * @param {(newPage: number) => void} props.onPaginationChange
 */
export function Pagination({
    currentPage = 1,
    totalRows = 0,
    rowsPerPage = DEFAULT_PAGE_SIZE,
    maxVisiblePages = 2,
    onPaginationChange
}) {
    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    function onNextPageClick() {
        if (currentPage < noOfPages) {
            onPaginationChange(currentPage + 1);
        }
    }

    function onPrevPageClick() {
        if (currentPage > 1) {
            onPaginationChange(currentPage - 1);
        }
    }

    function onPageNumberClick(pageNumber) {
        onPaginationChange(pageNumber);
    }

    const firstRowCount = ((currentPage - 1) * rowsPerPage) + 1;
    const lastRowCount = firstRowCount + rowsPerPage - 1 < totalRows ? firstRowCount + rowsPerPage - 1 : totalRows;
    const pageNumsToDisplay = generatePageNumbersToDisplay(currentPage, noOfPages, maxVisiblePages);

    return (
        <div className="row text-center">
            <div className="col-12 mt-4">
                <div className="d-md-flex align-items-center text-center justify-content-between">
                    <span className="text-muted me-3">
                        Showing {firstRowCount} - {lastRowCount} out of {totalRows}
                    </span>

                    <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={onPrevPageClick}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </button>
                        </li>

                        {pageNumsToDisplay.map((num, index) => (
                            <li
                                key={index}
                                className={`page-item ${num === currentPage ? 'active' : ''}`}
                            >
                                {num === "..." ? (
                                    <span className="page-link">...</span>
                                ) : (
                                    <button
                                        className="page-link"
                                        onClick={() => onPageNumberClick(num)}
                                    >
                                        {num}
                                    </button>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={onNextPageClick}
                                disabled={noOfPages === currentPage}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

/**
 * @param {number} currentPage 
 * @param {number} noOfPages 
 * @param {number} maxVisiblePages 
 */
function generatePageNumbersToDisplay(currentPage, noOfPages, maxVisiblePages) {
    const pageNums = [];
    const startPage = Math.max(1, currentPage - maxVisiblePages);
    const endPage = Math.min(noOfPages, currentPage + maxVisiblePages);

    if (startPage > 1) {
        pageNums.push(1);
        if (startPage > 2) {
            pageNums.push("...");
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNums.push(i);
    }

    if (endPage < noOfPages) {
        if (endPage < noOfPages - 1) {
            pageNums.push("...");
        }
        pageNums.push(noOfPages);
    }

    return pageNums;
}
