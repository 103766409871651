export const ALLOWED_IMAGE_MIME_TYPES = [
    "png",
    "jpg",
    "jpeg",
    "apng",
    ".avif",
    "jfif",
    "pjpeg",
    "pjp",
    "svg",
    "webp",
];

export const ALLOWED_VIDEO_MIME_TYPES = [
    "mp4",
    "MOV",
    "mov",
    "wmv",
    "avi",
    "avchd",
    "flv",
    "f4v",
    "swf",
    "mkv",
    "webm",
    "html5",
    "mpeg-2",
];
