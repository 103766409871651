import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useLocation, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import swal from "sweetalert";
import loader from "../../assets/Images/loder.gif";
import $ from "jquery";


import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
 
function Table({ columns, data }) {
  const [showLoader,setLoader]=useState(true);
  useEffect(()=>{
    setTimeout(()=>{
      setLoader(false);
    },1500)
  },[])

  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  return (
    <>
      <main class="page-content bg-light">
        <Header title="Charity Categories" main_title="Charity" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">
              {/* <div class="row row justify-content-end">
                                <div class="col-all-auto">
                                    <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#viewappointment"
                                    >
                                        <button class="cstm-btn">Add Organization</button>
                                    </a>
                                </div>
                            </div> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="row justify-content-between align-items-center p-3">
                    <div class="col-md-3">
                      <h4 class="mb-0">Charity Category</h4>
                    </div>{" "}
                    <div class="col-md-9">
                      <div class="row row justify-content-end">
                        <div class="col-md-5">
                          {" "}
                          <i class="fi fi-rr-search cstm-search-ro"></i>
                          <input
                            name="serch-organization"
                            id="serch-organization"
                            type="text"
                            class="cstm-input-seacrh"
                            value={globalFilter || ""}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search category"
                          />
                        </div>
                        <div class="col-all-auto">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#viewappointment"
                          >
                             <button class="cstm-btn">Add Category</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="table-responsive bg-white border-top rounded-0" >
                      <table
                        {...getTableProps()}
                        class="table mb-0 table-center"
                      >
                        <thead>
                          {" "}
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  class="border-bottom w-22"
                                  {...column.getHeaderProps()}
                                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}

                                >
                                  {column.render("Header")}
                                  {/* Render the columns filter UI */}
                                  <span>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i>
                                        : <i class="fi fi-rr-caret-up cstm-table-up-arrow"></i>
                                      : ''}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.length > 0 &&
                            page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                  {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {!showLoader ? page.length === 0 &&
                         <div className="NoRecord-cstm">No record found!</div>  :<div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-12 mt-4">
                <div class="d-md-flex align-items-center text-center justify-content-between">
                  <span class="text-muted me-3">
                  {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                  </span>
                  <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">

                    {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        href="javascript:void(0)"
                      >
                        Prev
                      </a>
                    </li>)}

                    {
                      pageOptions.map(pgnumber => {
                        return (
                          pgnumber !== pageCount && pageCount !== 1 &&
                          <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                      })
                    }

                    {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        href="javascript:void(0)"
                      >
                        Next
                      </a>
                    </li>)}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </main>
    </>
  );
}

function CharityCategories() {
  const history = useNavigate();
var DataArray=[];
  const [charCategory, setCharCategory] = useState([]);
  charCategory.forEach((charCategory_id, index) => {
    charCategory_id.serial = index + 1;
  });

  useEffect((data, e) => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");


    getData(1);
  }, []);

  const getData = (pageno)=>{

    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getCharityCat(queryVar)
      .then((response) => {
        console.log(response.data.data.category_count, "response cat");
        if (response.data.status === 200) {
          if(response.data.data.category_count.length !== 0){
            var data=response.data.data.category_count;
            data.map((data1)=>{
              DataArray = [...DataArray,data1]
             })
             if(response.data.data.state){
               getData(response.data.data.state.page+1);
             }
         }
         setCharCategory(DataArray);
          // setCharCategory(response.data.data.category_count);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });

  }

  const [selectedCatid, setSelectedCatid] = useState();

  const onClickCancel = ()=>{
    document.querySelector('#cancelappointment').classList.remove('show');
    document.querySelector('.modal-backdrop').remove();
  }

  const handleDelete = () => {
    Services.deleteCharityCategory(selectedCatid)
      .then((response) => {
        if (response.status === 200) {
          setCharCategory(
            charCategory.filter((post) => {
              return post._id !== selectedCatid;
            })
          );
          swal("Success", response.data.message, "success");
          document.querySelector('.modal-backdrop').remove();
          document.querySelector('#cancelappointment').classList.remove('show');
          document.querySelector("#cancelappointment .btn-close").click();


        } else {
          swal("Failed", response.data.message, "error");
          
        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");

      });
  };

  const columns = [
    {
      Header: "No",
      accessor: "serial",
    },
    {
      Header: "Category",
      accessor: "title",
    },
    {
      Header: "Actions",

      Cell: (row) => {
        return (
          <div>
            <Link
              class="cstm-eye"
              data-bs-toggle="modal"
              data-bs-target="#editappointment"
              to={" "}
              onClick={() =>
                handlePopup(row.cell.row.original._id)
              }
            >
              <i class="fi fi-rr-pencil"></i>
            </Link>
            <Link
              to={" "}
              onClick={() =>
                setSelectedCatid(row.cell.row.original._id)
              }
              class="cstm-delete mrn-rt"
              data-bs-toggle="modal"
              data-bs-target="#cancelappointment"
            >
              <i class="fi fi-rr-trash"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  // const [catVal, setCatVal] = useState();
  const [catId, setCatID] = useState();
  const [editcatVal, setEditCatVal] = useState({
    title: ""
  });
  const [errEditCatVal, setEditErrCatVal] = useState({
    title: ""

  });

  const [addcat, setCat] = useState({
    title: ""
  });
  const [errCat, setErrCat] = useState({
    title: ""
  });

  function handlePopup(categoryID) {
    setCatID(categoryID);

    Services.getChatCategoryById(categoryID)
      .then((response) => {
        console.log(response, "resonce");
        if (response.data.status === 200) {
          getData(1);
          setEditCatVal({ title: response.data.data.title });

        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }


  const handleOnchange = (e) => {
    const { value, name } = e.target;
    if (e.target.name == "title") {
      errCat.title = value.length > 0 ? "" : "Enter category";
    }
    setCat({ ...addcat, [name]: value });
    setErrCat({ ...errCat });

  };
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (addcat.title === "") {
      errCat.title = "Enter category";
      // seterr({...err});
    } else {
      errCat.title = "";
    }
    setErrCat({ ...errCat });
    if (errCat.title === "") {

      var date = (new Date()).toISOString().split('T')[0];//yyyy-mm-dd formate
      var addCat = {
        title: addcat.title,
        type: 0,
        country: 'India',
        date: date,
      };

      console.log(addCat, "addCat data");
      Services.addCharityCat(addCat)
        .then((response) => {
          if (response.data.status == 200) {
            getData(1);
            swal("Success", response.data.message, "success");
            document.querySelector('.modal-backdrop').remove();
            document.querySelector('#viewappointment').classList.remove('show');
          document.querySelector("#viewappointment .btn-close").click();

          $("#add-charity-popup")[0].reset();

          } else {
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          console.log(err, "catch error");
        });
    }
  };

  const handleOnchangeUpdate = (e) => {
    const { value, name } = e.target;

    if (e.target.name == "title") {
      errEditCatVal.title = value.length > 0 ? "" : "Enter category";
    }
    // setCatVal()
    setEditCatVal({ ...editcatVal, [name]: value });
    setEditErrCatVal({ ...errEditCatVal });

    // setCatVal(e.target.value)
  };

  const handleOnSubmitUpdate = (e) => {
    e.preventDefault();

    if (editcatVal.title === "") {
      errEditCatVal.title = "Enter category";
      // seterr({...err});
    } else {
      errEditCatVal.title = "";
    }
    setEditErrCatVal({ ...errEditCatVal });
    if (errEditCatVal.title === "") {

      if (editcatVal) {
        var editCatVal = {
          title: editcatVal.title,
          id: catId,
        };
        Services.editCharityCat(editCatVal)
          .then((response) => {
            // console.log(response.data, "responce data updated");
            if (response.data.status == 200) {
              getData(1);
              swal("Success", response.data.message, "success");
              document.querySelector('.modal-backdrop').remove();
              document.querySelector('#editappointment').classList.remove('show');
          document.querySelector("#editappointment .btn-close").click();


          // $("#edit-charity-popup")[0].reset();


            } else {
              swal("Failed", response.data.message, "error");
            }
          })
          .catch((err) => {
            console.log(err, "catch error");
          });
      }
    }
  };

  return (
    <>
      <Table columns={columns} data={charCategory} />

      <div
        class="modal fade"
        id="viewappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Add Category
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4 pt-0">
              <form id="add-charity-popup" onSubmit={handleOnSubmit}>
                <div class="mb-4">
                  <label class="cstm-label">Category</label>
                  <input
                    onChange={handleOnchange}
                    name="title"
                    id="char-category"
                    type="text"
                    class="cstm-input"
                    placeholder="Enter category"

                  />
                  {errCat.title !== "" && (
                    <span style={{ color: "red" }}>
                      {errCat.title}
                    </span>
                  )
                  }
                </div>

                <div class="d-flex justify-content-end">
                  <button class=" cstm-btn">Add Category</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="editappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Edit Category
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4 pt-0">
              <form id="edit-charity-popup" onSubmit={handleOnSubmitUpdate}>
                <div class="mb-4">
                  <label class="cstm-label">Category</label>
                  <input
                    onChange={handleOnchangeUpdate}
                    name="title"
                    id="char-category"
                    type="text"
                    class="cstm-input"
                    value={editcatVal.title}

                  />
                  {errEditCatVal.title !== "" && (
                    <span style={{ color: "red" }}>
                      {errEditCatVal.title}
                    </span>
                  )
                  }

                </div>
                <div class="d-flex justify-content-end">
                  <button class=" cstm-btn">Edit Category</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body py-5">
              <div class="text-center">
                <div class="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ display: "none" }}>
                </button>
                <h4 class="mt-4">
                  Are sure you want to delete
                  <br /> this category?
                </h4>
                <div class="mt-4">
                  <button
                    class="mr-3 cstm-btn cstn-discard"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button class="cstm-btn2 cstn-discard" onClick={onClickCancel}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CharityCategories;
