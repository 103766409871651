import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import Services from "../../Services/auth.service";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import loader from "../../assets/Images/loder.gif";
import swal from "sweetalert";

function Table({ columns, data }) {
  const [showLoader,setLoader]=useState(true);
  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  console.log(props);
  React.useEffect(() => {
    // console.log(globalFilter);
  }, [globalFilter]);
  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  useEffect(()=>{
    setTimeout(()=>{
      setLoader(false);
    },2000)
  },[])

  return (
    <>
      <main className="page-content bg-light">
        <Header main_title="Announcements" title="Quick Tips List" />
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="row">
              {/* <div className="row row justify-content-end">
                <div className="col-all-auto">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#viewappointment"
                  >
                    <button className="cstm-btn">Add Organization</button>
                  </a>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="card border-0 shadow rounded">
                  <div className="row justify-content-between align-items-center p-3">
                    <div className="col-md-3">
                      <h4 className="mb-0">Quick Tips</h4>
                    </div>{" "}
                    <div className="col-md-9">
                      <div className="row row justify-content-end">
                        <div className="col-md-5">
                          {" "}
                          <i className="fi fi-rr-search cstm-search-ro"></i>
                          <input
                            name="serch-organization"
                            id="serch-organization"
                            type="text"
                            className="cstm-input-seacrh"
                            value={globalFilter || ""}
                            onChange={(e) => {setGlobalFilter(e.target.value); setTimeout(()=>{ if(document.querySelector("#trip").rows.length >= 1){ setLoader(false) } },500)
                          }}
                            placeholder="Search Tip"
                          />
                        </div>
                        {/* <div className="col-all-auto">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#viewappointment"
                          >
                            <button className="cstm-btn">Add Tip</button>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <div className="table-responsive bg-white border-top rounded-0">
                      <table
                        {...getTableProps()}
                        className="table mb-0 table-center"
                        id="trip"
                      >
                        <thead>
                          {" "}
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  className="border-bottom w-22"
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                  {/* Render the columns filter UI */}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {
                            page.length > 0 && page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                  {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>

                      </table>
                      
                      {!showLoader ? page.length === 0 &&
                                                <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                            }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-12 mt-4">
                <div className="d-md-flex align-items-center text-center justify-content-between">
                  <span className="text-muted me-3">
                  { page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                  </span>
                  <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">

                    {pageOptions.length !== 1 &&  page.length !== 0 && ( <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        href="javascript:void(0)"
                      >
                        Prev
                      </a>
                    </li>)}
                    {

                      pageOptions.map(pgnumber => {
                        {/* { console.log(pageIndex, "pageIndex") } */}
                       {/* pgnumber=pgnumber+1 */}
                        return (
                          pgnumber !== pageCount && pageCount !== 1 &&
                          <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber+1} </a></li>)

                        {/* return( pgnumber) */ }
                      })
                    }

                    {pageOptions.length !== 1 && page.length !== 0 &&( <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        href="javascript:void(0)"
                      >
                        Next
                      </a>
                    </li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
   
      </main>
    </>
  );
}

function Quicktip() {
  const [quicktipsData, setquicktipsData] = useState([]);
  const [selectedTipId, setselectedTipId] = useState();

  var allQuicktipData = [];
  useEffect((data, e) => {
    getData(1)
  },[])

  quicktipsData.forEach((quicktipsData, index) => {
    quicktipsData.serial = index + 1;
  });

  const onClickCancel = ()=>{
    document.querySelector('.modal-backdrop').remove();
    document.querySelector("#cancelappointment .btn-close").click();
  }

  const onClickDelete = () => {
    Services.deleteQuicktip(selectedTipId)
      .then((response) => {
        if (response.status === 200) {
          getData(1);
          swal("Success", response.data.message, "success");
          document.querySelector('.modal-backdrop').remove();
          document.querySelector("#cancelappointment .btn-close").click();
        } else {
          swal("Failed", response.data.message, "error");

        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  const columns = [
    {
      Header: "No.",
      accessor: "serial",
    },
    {
      Header: "Screen Name",
      accessor: "screenName",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Button Name",
      accessor: "buttonName",
    },
    {
      Header: "Actions",

      Cell: (row) => {
        return (
          <div>

          <Link to={"/view-quicktip/?id=" + row.cell.row.original._id}
              className="cstm-eye"
            >
              <i className="fi fi-rr-eye"></i>
            </Link>
{/*             
            <Link
              to={" "}
              onClick={() => setselectedTipId(row.cell.row.original._id)}
              className="cstm-delete mrn-rt"
              data-bs-toggle="modal"
              data-bs-target="#cancelappointment"
            >
              <i className="fi fi-rr-trash"></i>
            </Link> */}
          </div>
        );
      },
    },
  ];

  const getData =(pageno)=>{
    
    const queryVar = {
      page: pageno,
      limit: 100
    };

    Services.getQuicktip(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.quick_tip_count.length !== 0) {
            var data = response.data.data.quick_tip_count;
            data.map((data1) => {
              allQuicktipData = [...allQuicktipData,data1]
            })
            if (response.data.data.state) {
               getData(response.data.data.state.page + 1);
            }
         }
          setquicktipsData(allQuicktipData);
        } else {
          console.log("error");
        }
        console.log(allQuicktipData,'allQuicktipData');
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }

  return(
  <>
      <Table columns={columns} data={quicktipsData} />
     
      {/* Delete Tips  */}
      <div
        className="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5">
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{display:"none"}}>
              </button>
                <h4 className="mt-4">
                  Are sure you want to delete
                  <br /> this tips?
                </h4>
                <div className="mt-4">
                  <button
                    className="mr-3 cstm-btn cstn-discard"
                    onClick={onClickDelete}
                  >
                    Delete
                  </button>
                  <button className="cstm-btn2 cstn-discard" onClick={onClickCancel}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
  )
} 

export default Quicktip;