import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/query-keys";
import giftService from "../Services/gift.service";

/**
 * @param {string} userId
 */
export function useGetGiftsByUserId(userId, params = {}) {
  const { data: giftsRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GIFTS, { userId: userId, ...params }],
    queryFn: () =>
      giftService.getGifts({
        userIds: [userId],
        ...params,
      }),
    enabled: !!userId,
    staleTime: Infinity,
  });

  return {
    data: giftsRes?.data,
    isLoading: isLoading,
  };
}

/**
 * @param {string} giftId
 */
export function useGetGiftById(giftId) {
  const { data: giftRes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GIFT_BY_ID, giftId],
    queryFn: () => giftService.getGiftById(giftId),
    enabled: !!giftId,
    staleTime: Infinity,
  });

  return {
    data: giftRes?.data,
    isLoading: isLoading,
  };
}
