import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { useNavigate } from "react-router-dom";
import {S3_BASEURL} from "../../Constants/app.constants"
function AppQRCode() {
  const history = useNavigate();

  let qrCode = S3_BASEURL+ "assets/qrimages/downloadapp/downloadapp/qr.png";
  const[appQRCode, setAppQRCode]= useState(qrCode)
  
  useEffect((data, e) => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
  }, []);


/***
 * Generate Or Rengenerate QR Code function
 * @author Rajkumari
 * @createdat - 8 Dec
 */
const generateAppQRCode = ()=>{
   
    Services.generateQrCodeForDownloadAppWebPage()
      .then((response) => {
        if (response.status === 200) {
          console.log("res", response.data);
          let qrCode = response.data.data;
         setAppQRCode(S3_BASEURL + qrCode);
        }
      })
      .catch((err) => {});
}
  return (
        <main class="page-content bg-light">
        <Header title="App QR Code" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">

              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="row justify-content-between align-items-center p-3">
                    <div class="col-md-3">
                      <h4 class="mb-0">Download App QR Code</h4>
                    </div>{" "}
                   
                  </div>
                  <div class="col-md-12 col-lg-12">
                     <div class="text-center">

                      <img src={appQRCode} alt= "app qr"
                       style={{width:250, height:250}}/>
                  
                    </div>
                      <div  class="text-center" style={{marginTop:20, marginBottom:20}}>
                        <button  variant="primary"
                              class=" cstm-btn" onClick={generateAppQRCode}> Generate QR Code</button>
                               <button  variant="primary" style={{marginLeft:20}}
                              class=" cstm-btn" onClick={()=>{
                                  window.open(appQRCode, "_blank");
                              }}> Download QR Code </button>
                    </div>
                      
                   

                      
                  </div>
                </div>
              </div>
            </div>

         
          </div>
        </div>
      </main>
  );
}

export default AppQRCode;
