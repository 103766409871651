import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import loader from "../../assets/Images/loder.gif";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";
import swal from "sweetalert";

import {
    useTable,
    useFilters,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";

function Table({ columns, data }) {
    const [showLoader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 1500)
    }, [])

    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 },
        },
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
    } = props;
    console.log(props);
    React.useEffect(() => {
        // console.log(globalFilter);
    }, [globalFilter]);
    let firstRecord = pageIndex * pageSize + 1;
    let lastRecord = firstRecord + pageSize - 1;

    return (
        <>
            <main class="page-content bg-light">
                <Header main_title="Community Moderation" title="Block List" />
                <div class="container-fluid">
                    <div class="layout-specing">
                        <div class="row">
                            {/* <div class="row row justify-content-end">
                 <div class="col-all-auto">
                   <a
                     href="#"
                     data-bs-toggle="modal"
                     data-bs-target="#viewappointment"
                   >
                     <button class="cstm-btn">Add Organization</button>
                   </a>
                 </div>
               </div> */}
                            <div class="col-md-12">
                                <div class="card border-0 shadow rounded">
                                    <div class="row justify-content-between align-items-center p-3 ">
                                        <div class="col-md-3">
                                            <h4 class="mb-0">Block List</h4>
                                        </div>{" "}
                                        <div class="col-md-9">
                                            <div class="row row justify-content-end">
                                                <div class="col-md-5">
                                                    {" "}
                                                    <i class="fi fi-rr-search cstm-search-ro"></i>
                                                    <input
                                                        name="serch-cahrity"
                                                        id="serch-cahrity"
                                                        type="text"
                                                        class="cstm-input-seacrh"
                                                        value={globalFilter || ""}
                                                        onChange={(e) => { setGlobalFilter(e.target.value) }}
                                                        placeholder="Search user"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="table-responsive bg-white border-top rounded-0">
                                            <table
                                                {...getTableProps()}
                                                class="table mb-0 table-center cstm-cl-mn3"
                                            >
                                                <thead>
                                                    {" "}
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map((column) => (
                                                                <th
                                                                    class="border-bottom"
                                                                    {...column.getHeaderProps()}
                                                                    onClick={() =>
                                                                        column.toggleSortBy(!column.isSortedDesc)
                                                                    }
                                                                >
                                                                    {column.render("Header")}

                                                                    {/* {console.log(column.isSortedDesc)} */}
                                                                    <span>
                                                                        {column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <i class="fi fi-rr-caret-down cstm-table-down-arrow"></i>
                                                                            ) : (
                                                                                <i class="fi fi-rr-caret-up cstm-table-up-arrow"></i>
                                                                            )
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </span>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </thead>
                                                <tbody {...getTableBodyProps()}>
                                                    {page.length > 0 &&
                                                        page.map((row, i) => {
                                                            {/* console.log(row.length,"row") */ }
                                                            prepareRow(row);
                                                            return (
                                                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                                    {row.cells.map((cell) => {
                                                                        return (
                                                                            <td {...cell.getCellProps()}>
                                                                                {cell.render("Cell")}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>



                                            {!showLoader ? page.length === 0 &&
                                                <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-12 mt-4">
                                <div class="d-md-flex align-items-center text-center justify-content-between">
                                    <span class="text-muted me-3">
                                        {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                                    </span>
                                    <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                        {pageOptions.length !== 1 && page.length !== 0 && (
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    onClick={() => previousPage()}
                                                    disabled={!canPreviousPage}
                                                    href="javascript:void(0)"
                                                >
                                                    Prev
                                                </a>
                                            </li>
                                        )}
                                        {pageOptions.map((pgnumber) => {
                                            // console.log(pgnumber, "pgnumber")
                                            return (
                                                pgnumber !== pageCount && pageCount !== 1 && (
                                                    <li
                                                        className={`page-item ${pageIndex == pgnumber ? "active" : ""
                                                            }`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="javascript:void(0)"
                                                            onClick={() => gotoPage(pgnumber)}
                                                        >
                                                            {" "}
                                                            {pgnumber + 1}{" "}
                                                        </a>
                                                    </li>
                                                )
                                            );

                                            {
                                                /* return( pgnumber) */
                                            }
                                        })}

                                        {pageOptions.length !== 1 && page.length !== 0 && (
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    onClick={() => nextPage()}
                                                    disabled={!canNextPage}
                                                    href="javascript:void(0)"
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

function BlockList() {
    const history = useNavigate();
    var DataArray = [];
    const [comData, setChomData] = useState([]);

    function createData(_id, username, postType, updatedAt, action) {
        return {
            _id,
            username,
            postType,
            updatedAt,

        };
    }

    comData.forEach((comData_id, index) => {
        comData_id.serial = index + 1;
    });

    //   const DataArrayCat = [];
    //   const [charCategory, setCharCategory] = useState([]);
    // charData.forEach((charData_id, index) => { charData_id.serial = index + 1; });
    useEffect((data, e) => {
        // getCatData(1);
        getData(1);
        //  console.log(selectedCid, "setselectedid");
    }, []);

    const getData = (pageno) => {
        const queryVar = {
            page: pageno,
            limit: 100,
            isBlock:true
        };

        Services.getCommunityModeration(queryVar)
            .then((response) => {
                // return false;
                if (response.data.status === 200) {
                    if (response.data.data.moderation_data.length !== 0) {
                        var arr = response.data.data.moderation_data;

                        if (response.data.data.moderation_data.length > 0) {
                            var newarr = [];
                            for (var i = 0; i < arr.length; i++) {
                                var updatedAt =
                                    arr[i].updatedAt !== undefined
                                        ?
                                        //  arr[i].updatedAt = new Date();
                                        new Date(arr[i].updatedAt).toLocaleDateString("en-US", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                        }) : "";
                                newarr[i] = createData(
                                    arr[i]._id,
                                    arr[i].username,
                                    arr[i].postType,
                                    updatedAt,
    
                                );
    
                            }
                            newarr.map((data1) => {
                                DataArray = [...DataArray,data1]
                            })
                            if (response.data.data.state) {
                                getData(response.data.data.state.page + 1);
                            }
                            // console.log(newarr, "newarr");
                            setChomData(DataArray);
                        }
                    }
                    // setChomData(DataArray);
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    //delet---------

    const [selectedCid, setselectedCid] = useState();

    const handleDelete = () => {
        console.log(selectedCid, "selectedCid");
        var data = {
            id: selectedCid,
            isBlock: false
        }
        Services.editCharity(data)
            .then((response) => {
                // return false;
                if (response.status === 200) {

                    // setCharData(
                    //   charData.filter((post) => {
                    //     return post._id !== selectedCid;
                    //   })
                    // );
                    getData(1);
                    swal("Success", response.data.message, "success");
                    document.querySelector(".modal-backdrop").remove();
                    document.querySelector("#cancelappointment").classList.remove("show");
                    document.querySelector("#cancelappointment .btn-close").click();

                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };
    const onClickCancel = () => {
        document.querySelector("#cancelappointment").classList.remove("show");
        document.querySelector(".modal-backdrop").remove();
    };

    const columns = [
        {
            Header: "No",
            accessor: "serial",
        },

        {
          Header: "User Name",
          accessor: "name",
        },
        // {
        //   Header: "Category",
        //   accessor: "totalTeams",
        // sortable: true,

        //   // Use our custom fuzzyText filter on this column
        // },
        {
            Header: "Post Type",
            accessor: "postType",
            // sortable: true,
        },
        {
            Header: "Block Date",
            accessor: "updatedAt",
            // sortable: true,
        },

        {
            Header: "Actions",

            Cell: (row) => {
                return (
                    <div>
                        {/* <Link
              to={"/view-charity/?id=" + row.cell.row.original._id}
              class="cstm-eye"
            >
              <i class="fi fi-rr-eye"></i>
            </Link>
            <Link
              class="cstm-eye"
              to={"/edit-charity/?id=" + row.cell.row.original._id}
            >
              <i class="fi fi-rr-pencil"></i>
            </Link> */}
                        <Link
                            to={" "}
                            onClick={() => setselectedCid(row.cell.row.original._id)}
                            class="cstm-restore"
                            data-bs-toggle="modal"
                            data-bs-target="#cancelappointment"
                        >
                            <i class="fi fi-rr-refresh"></i>
                        </Link>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <Table columns={columns} data={comData} />

            {/* //delet  */}
            <div
                class="modal fade"
                id="cancelappointment"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body py-5">
                            <div class="text-center">
                                <div class="d-flex justify-content-center">
                                    <img src={deleteImg} />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 class="mt-4">
                                    Are sure you want to unblock
                                    <br /> this user?
                                </h4>
                                <div class="mt-4">
                                    <button
                                        class="mr-3 cstm-btn cstn-discard"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        class="cstm-btn2 cstn-discard"
                                        onClick={onClickCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlockList;



