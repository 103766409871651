import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import {useLocation, useNavigate } from "react-router-dom";
import loader from "../../assets/Images/loder.gif";



//datatable
import { useTable, usePagination, useGlobalFilter } from "react-table";

function Table({ columns, data }) {
  const history = useNavigate();
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  }, [])

  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = props;
  console.log(props);
  React.useEffect(() => {
    // console.log(globalFilter);
  }, [globalFilter]);
  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  return (
    <>
      <main class="page-content bg-light">
        <Header title="Orders" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div class="row">

              <div class="col-md-12">
                <div class="card border-0 shadow rounded">
                  <div class="row justify-content-between align-items-center p-3">
                    <div class="col-md-3">
                      <h4 class="mb-0">Orders List</h4>
                    </div>{" "}
                    <div class="col-md-9">
                                 {/* <div class="row row justify-content-end">
                                    <div class="col-md-5">
                                       {" "}
                                       <i class="fi fi-rr-search cstm-search-ro"></i>
                                       <input
                                          name="serch-orders"
                                          id="serch-orders"
                                          type="text"
                                          class="cstm-input-seacrh"
                                          value={globalFilter || ""}
                                          onChange={(e) => setGlobalFilter(e.target.value)}
                                          placeholder="Search Orders"
                                       />
                                    </div>
                                   <div class="col-all-auto">
                                       <Link
                                          
                                       >
                                          <button class="cstm-btn">Filter</button>
                                       </Link>
                                    </div> 
                                 </div>*/}
                    </div>  
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="table-responsive bg-white border-top rounded-0">
                      <table
                        {...getTableProps()}
                        class="table mb-0 table-center cstm-cl-mn2"
                      >
                        <thead>
                          {" "}
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  class="border-bottom"
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                  {/* Render the columns filter UI */}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {
                            page.length > 0 && page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                  {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>

                      </table>
                      {!showLoader ? page.length === 0 &&
                        <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row text-center">
              <div class="col-12 mt-4">
                <div class="d-md-flex align-items-center text-center justify-content-between">
                  <span class="text-muted me-3">
                    {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}

                  </span>
                  <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">

                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        href="javascript:void(0)"
                      >
                        Prev
                      </a>
                    </li>}
                    {

                      pageOptions.map(pgnumber => {
                        {/* console.log(pgnumber, "pgnumber") */ }
                        return (
                          pgnumber !== pageCount && pageCount !== 1 &&
                          <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                        {/* return( pgnumber) */ }
                      })
                    }

                    {pageOptions.length !== 1 && <li class="page-item">
                      <a
                        class="page-link"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        href="javascript:void(0)"
                      >
                        Next
                      </a>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}


function Orders() {
  const history = useNavigate();
  const [orders, setOrdersData] = useState([]);


  orders.forEach((teams_id, index) => {
    teams_id.serial = index + 1;
  });

  var DataArray = [];
  useEffect((data, e) => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    getData(1);
  }, []);

  const getData = (pageno) => {
    const requestParams = {
      limit:10,
      page:pageno,
      status:"",
      giftPartnerName:"",
      location:"",
    };
    Services.ordersListing(requestParams)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.order_data.length !== 0) {
            var orderData = response.data.data.order_data;
            orderData.map((data1) => {
              DataArray = [...DataArray, data1]
            })
            if (response.data.data.state) {
              getData(response.data.data.state.page + 1);
            }
          }
         setOrdersData(DataArray);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  }


  const columns = [
    {
      Header: "No",
      accessor: "serial",
    },
 
 {
      Header: "Gift Partner Name",
      accessor: "giftPartnerData[0].title",
    },
    {
      Header: "Gift Name",
      accessor: "giftCardData[0].type",
    },
    {
      Header: "Amount",
      accessor: "paymentData[0].amount", 
    
    },
    {
      Header: "Sender Name",
      accessor: "senderData[0].firstName",
    },

     {
      Header: "Recipient Name",
      accessor: "receiverData[0].firstName",
    },
    
    {
      Header: "Order Status",
      accessor: "orderStatus", 
    
    },
  ];


  return (
    <>
      <Table columns={columns} data={orders} />

      {/* <div
        class="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body py-5">
              <div class="text-center">
                <div class="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ display: "none" }}>
                </button>
                <h4 class="mt-4">
                  Are sure you want to delete
                  <br /> this team?
                </h4>
                <div class="mt-4">
                  <button
                    class="mr-3 cstm-btn cstn-discard"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button class="cstm-btn2 cstn-discard" onClick={onClickCancel}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div> */}
    </>
  );
}

export default Orders;
