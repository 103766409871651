import { useEffect, useMemo, useState } from 'react';
import "./GiftList.css"
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import { EditGift } from './EditGift';
import { Pagination } from '../shared/Pagination';
import giftService from '../../Services/gift.service';
import thankYouService from '../../Services/thank-you.service';
import { formatDateForTable } from '../../common/utils/date.util';
import { downloadCsvFromData, generateCsvFileName } from '../../common/utils/csv.util';
import { useDebounceSearchTextFn } from '../../common/hooks/use-debounce.hook';
import { useLoaderStore } from '../../stores/loader.store';
import { Modal } from '../../shared/components/Modal/Modal';
import { GiftListFilters } from './GiftListFilters';
import { DEFAULT_PAGE_SIZE } from '../../common/constants/pagination.constant';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../utils/list-state.util';
import { APP_ROUTES } from '../../common/app-routes';
import { createBreadcrumb, useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';

const GIFT_LIST_MESSAGE_ACTION = Object.freeze({
    EDIT: "EDIT"
});


export function GiftList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [gifts, setGifts] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getGiftListStateFromSearchParams(searchParams), [searchParams]);
    const filters = state.filters;
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [searchText, setSearchText] = useState(state.searchText);
    const [selectedGift, setselectedGift] = useState({});
    const [showFilters, setShowFilters] = useState(false);
    const [sortBy, setSortBy] = useState(state.sortBy);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        if (debouncedSearchText !== state.searchText) {
            setSearchParams(createGiftListSearchParams(filters, debouncedSearchText, 1, sortBy));
        }
    });

    useSetBreadcrumbs([createBreadcrumb(APP_ROUTES.GIFT_LIST)]);

    useEffect(() => {
        getGifts();
    }, [searchParams]);

    useEffect(() => {
        if (sortBy != null) {
            setSearchParams(createGiftListSearchParams(filters, state.searchText, state.pageNumber, sortBy));
            getGifts();
        }
    }, [sortBy]);

    async function getGifts() {
        setShowLoader(true);

        try {
            const res = await giftService.getGifts({
                search: state.searchText ? state.searchText.trim() : '',
                page: pagination.pageNumber,
                limit: pagination.pageSize,
                organizationIds: filters.organizationId ? [filters.organizationId] : [],
                teamIds: filters.teamIds,
                giftCardId: filters.giftCardId,
                processState: filters.giftProcessState,
                platformFlow: filters.platformFlow,
                dateRangeStart: filters.dateFilterType === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.dateFilterType === "range" ? filters.dateRangeEnd : undefined,
                sortBy: sortBy ? sortBy : ''
            });

            if (res.status === 200) {
                setGifts(res.data.results);
                setResultsCount(res.data.count);
                return;
            }

            toast.error("Couldn't get gifts");
        } catch (err) {
            toast.error("Couldn't get gifts");
        } finally {
            setShowLoader(false);
        }
    }

    async function downloadAsCsv() {
        setShowLoader(true);

        try {
            const res = await giftService.downloadAsCsv({
                search: state.searchText ? state.searchText.trim() : '',
                organizationIds: filters.organizationId ? [filters.organizationId] : [],
                teamIds: filters.teamIds,
                giftCardId: filters.giftCardId,
                processState: filters.giftProcessState,
                platformFlow: filters.platformFlow,
                dateRangeStart: filters.dateFilterType === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.dateFilterType === "range" ? filters.dateRangeEnd : undefined,
            });

            const csvName = generateCsvFileName('gifts');
            downloadCsvFromData(res, csvName);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download csv");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleSendToOrganization() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "gift",
                "type": "organization",
                "organizationId": selectedGift.organizations[0]._id,
                "appreciationId": selectedGift._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the gift to organization");
                return;
            }
            toast.error("Couldn't send thank yous to organization");
        } catch (err) {
            toast.error("Couldn't send thank to organization");
        }
        finally {
            setShowLoader(false);
            handleSendOrganizationCancel();
        }
    }


    function onPaginationChange(pageNumber) {
        setSearchParams(createGiftListSearchParams(filters, state.searchText, pageNumber));
    }

    function getGiftSenderDetails(gift) {
        if (gift.platform === 'mobile' && gift.createdBy) {
            return {
                firstName: gift.createdBy.firstName,
                email: gift.createdBy.email,
                phoneNumber: gift.createdBy.phoneNumber,
            };
        }

        if (gift.platform === 'web' && gift.createdUserData) {
            return {
                firstName: gift.createdUserData.firstName,
                email: gift.createdUserData.email,
                phoneNumber: gift.createdUserData.phoneNumber,
            };
        }
    }

    async function handleSendToTeam() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "gift",
                "type": "team",
                "teamId": selectedGift.teams[0]._id,
                "appreciationId": selectedGift._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            handleSendTeamCancel();
        }
    }

    const handleSendOrganizationCancel = () => {
        setselectedGift(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendOrganizationConfirmationGift').classList.remove('show');
    }

    const handleSendTeamCancel = () => {
        setselectedGift(null);
        document.querySelector('#sendTeamConfirmationGift').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
    }

    function onApplyFilters(newFilters) {
        setShowFilters(false);
        setSearchParams(createGiftListSearchParams(newFilters, state.searchText));
    }

    const getMessage = (gift) => {
        if (!gift.originalMessage) {
            return <span>{gift.message}</span>
        }
        return <div>
            {gift.originalMessage}
            {<div style={{ color: 'green', marginTop: '10px' }}>{gift.message}</div>}

        </div>
    }

    return (
        <SidebarLayoutContent headerTitle='Gift List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search Users"
                        />
                    </div>

                    <button type='button' className='custom-filter-icon-btn mr-2' onClick={() => setShowFilters(true)}>
                        <i className="fi fi-rr-settings-sliders"></i>
                    </button>

                    <button
                        type='button'
                        className="cstm-eye m-0"
                        onClick={downloadAsCsv}
                    >
                        <i className="fi fi-rr-download"></i>
                    </button>
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                {/* <th className="border-bottom w-4 cstm-userheading">Auto Reply</th> */}
                                <th onClick={(e) => setSortBy(!sortBy ? 'reply' : '')} className="border-bottom w-4 cstm-userheading clickable">Reply
                                    {sortBy == 'reply' && <span className="arrow">&#9650;</span>}
                                </th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Type</th>
                                <th className="border-bottom w-4 cstm-userheading">Platform</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {gifts?.map((gift) => {
                                const senderDetails = getGiftSenderDetails(gift);

                                return (
                                    <tr
                                        key={gift._id}
                                        className="cstm-Tabledesign cstm-usertable-design"
                                    >
                                        <td>{getMessage(gift)}</td>
                                        {/* <td style={{ color: 'red' }}>{gift.autoReply ? gift.autoReply[0]?.replyMessage : null}</td> */}
                                        <td>{gift.replies ? gift.replies[0]?.message : null} 
                                            {gift.autoReply && gift.autoReply[0]?.replyMessage && <div style={{ color: 'green', marginTop: '10px' }}>{gift.autoReply[0]?.replyMessage}</div>}
                                        </td>
                                        <td>
                                            <div>{senderDetails?.firstName}</div>
                                            <div>{senderDetails?.email}</div>
                                            <div>{senderDetails?.phoneNumber}</div>
                                        </td>
                                        <td>
                                            <div>{gift.users ? gift.users[0]?.firstName : null}</div>
                                            <div>{gift.users ? gift.users[0]?.email : null}</div>
                                            <div>{gift.users ? gift.users[0]?.phoneNumber : null}</div>
                                        </td>
                                        <td>{gift.giftCard?.type}</td>
                                        <td>{gift.flow === "1" ? "Patient Experience" : "Staff Experience"}</td>
                                        <td>{gift.createdAt ? formatDateForTable(gift.createdAt) : null}</td>
                                        <td>
                                            <div>
                                                {gift && gift.createdUserData && gift.createdUserData.email && <Link
                                                    to={""}
                                                    onClick={() => setselectedGift({ gift: gift, action: GIFT_LIST_MESSAGE_ACTION.EDIT })}
                                                    className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}s
                                                >
                                                    <i className="fi fi-rr-pencil"></i>
                                                </Link>}

                                                <Link
                                                    to={"/view-gift-details?id=" + gift._id}
                                                    // className="cstm-eye"
                                                    className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}

                                                >
                                                    <i className="fi fi-rr-eye"></i>
                                                </Link>
                                                {/* {gift.appreciationShareDetails.length > 0 && 'Shared'} */}
                                                {gift.flow === "1" &&
                                                    <span>

                                                        <Link
                                                            to={`/user-thankyou-share?id=${gift._id}&type=gift&teamIds=${gift.teamIds}&senderUserId=${gift.userIds[0]}`}
                                                            className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                            state={{ breadcrumbPath: location.pathname + location.search }}
                                                        >
                                                            <button
                                                                type='button'
                                                                onClick={() => setselectedGift(gift)}
                                                                className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#cancelappointment"
                                                            >
                                                                <i className="fi fi-rr-user"></i>
                                                            </button>
                                                        </Link>

                                                        {gift?.teams && gift?.teams[0] &&
                                                            <button
                                                                type='button'
                                                                onClick={() => setselectedGift(gift)}
                                                                className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#sendTeamConfirmationGift"
                                                            >
                                                                <i className="fi fi-rr-users-alt"></i>
                                                            </button>}

                                                        {gift?.orgIds && gift?.orgIds[0] &&
                                                            <button
                                                                type='button'
                                                                onClick={() => setselectedGift(gift)}
                                                                className={`${gift.appreciationShareDetails.length > 0 ? 'cstm-delete-share' : 'cstm-delete'} mrn-rt`}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#sendOrganizationConfirmationGift"
                                                            >
                                                                <i className="fi fi-rr-organization-chart"></i>
                                                            </button>}
                                                    </span>}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {!gifts?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            {/* Organization share confirmation */}
            <div
                className="modal fade"
                id="sendOrganizationConfirmationGift"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure you want to share this message with everyone at {selectedGift?.organizations ? selectedGift?.organizations[0]?.name : ''}
                                </h4>
                                <div className="mt-4">
                                    <button
                                        style={{ backgroundColor: 'red' }}
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToOrganization}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendOrganizationCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="sendTeamConfirmationGift"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to share this message to
                                    <br /> {selectedGift?.teams ? selectedGift?.teams[0]?.name : ''} team
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToTeam}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendTeamCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(selectedGift && selectedGift.action === GIFT_LIST_MESSAGE_ACTION.EDIT) && (
                <Modal
                    show={true}
                    onClose={() => setselectedGift(null)}
                >
                    <EditGift
                        giftData={selectedGift.gift}
                        onEditSuccess={() => {
                            setselectedGift(null);
                            getGifts(pagination.pageNumber, pagination.pageSize);
                        }}
                    />
                </Modal>
            )}

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <GiftListFilters
                    filters={filters}
                    onApplyFilters={onApplyFilters}
                />
            </Modal>
        </SidebarLayoutContent>
    );
}

const singleFilters = ['organizationId', 'giftCardId', 'giftProcessState', 'platformFlow', 'dateFilterType', 'dateRangeStart', 'dateRangeEnd'];
const multiFilters = ['teamIds'];

function createGiftListSearchParams(filters, searchText, pageNumber, sortBy) {
    return createSearchParamsFromListState(filters, searchText, pageNumber, singleFilters, multiFilters, sortBy);
}

function getGiftListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams, singleFilters, multiFilters);
}
