import React from "react";
import Header from "../NavMenuBar/Header";
import info from "../../assets/Images/info.png";
import { Link, useNavigate, useParams } from "react-router-dom";


const TicketsSupport = () => {
    return (
        <main class="page-content bg-light">
            <Header main_title="Issue-Tickets" title="Issue-Tickets" />
            <div class="container-fluid">
                <div class="layout-specing">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card border-0 shadow rounded">
                                <div class="row justify-content-between align-items-center p-3 border-bottom">
                                    <div class="col-md-2"><h4 class="mb-0">Issue Tickets</h4></div>
                                    <div class="col-md-10">
                                        <div class="row row justify-content-end">
                                            <div class="col-md-2 cstm-Replied-by-user">
                                                <img src={info} />
                                                <span>(RE: - Replied by user)</span>
                                            </div>
                                            <div class="col-md-4">
                                                <i class="fi fi-rr-search cstm-search-ro"></i><input name="name" id="name" type="text" class="cstm-input-seacrh" placeholder="Search by ticket number" />
                                            </div>
                                            <div class="col-md-2">
                                                <select class="cstm-select cstm-om-slc" name="Status" id="City">
                                                    <option value="City">Status</option>
                                                    <option value="City2">Status 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">

                                    <div class="table-responsive bg-white rounded">
                                        <table class="table mb-0 table-center">
                                            <thead>
                                                <tr>
                                                    <th class="border-bottom w-4">No.</th>
                                                    <th class="border-bottom w-27">Ticket Number</th>
                                                    <th class="border-bottom w-27">Ticket Subject</th>
                                                    <th class="border-bottom w-15">Ticket Generated By</th>
                                                    <th class="border-bottom w-15">Date</th>
                                                    <th class="border-bottom w-12">Status</th>
                                                    <th class="border-bottom w-12">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="pending-status"><Link to="helpcenter-pending"><button>pending</button></Link></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><span className="re-text">Re:</span> #123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><span className="re-text">Re:</span> #123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="in-progress-status"><button>In-Progress</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="completed-status"><button>Completed</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="completed-status"><button>Completed</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="completed-status"><button>Completed</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="completed-status"><button>Completed</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>#123456789</td>
                                                    <td>Issue regarding invite Members</td>
                                                    <td>Theresa Webb</td>
                                                    <td>11/06/2022</td>
                                                    <td className="completed-status"><button>Completed</button></td>
                                                    <td>
                                                        <a href="#" class="cstm-eye"><i class="fi fi-rr-eye"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col-12 mt-4">
                            <div class="d-md-flex align-items-center text-center justify-content-between">
                                <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
                                <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

       
        
        <div class="container-fluid">
                <div class="layout-specing">
                <h1>pending</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card border-0 shadow rounded">
                                <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                                    <div class="ticket-no"><h4 class="mb-0">Ticket No :- #123456789</h4></div>
                                    <div className="pending-status"><button>pending</button></div>
                                </div>

                                <div className="pending-main1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="p-3 issue-regarding-no">
                                            <h4>Issue regarding invite Members</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                        <div className="ticket1 px-3">
                                            <span>Ticket Generated By: </span>
                                            <strong>Theresa Webb</strong>
                                        </div>
                                        <div className="ticket1 px-3 pb-3">
                                            <span>Date:- </span>
                                            <strong>04/04/2018</strong>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 p-4">
                                        <div className="checkmark-main">
                                        <textarea type="text" placeholder="Write a answer" className="w-100 border-0 cstm-textarea"></textarea>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-between align-items-center border-top pt-3">
                                                <div className="col-checkmark">
                                                    <input type="checkbox" className="cstm-checkbox"></input>
                                                    <span className="checkmark-content">Chekmark as resolve ticket issue</span>
                                                </div>
                                                <button class="cstm-btn">Add Greeting</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col-12 mt-4">
                            <div class="d-md-flex align-items-center text-center justify-content-between">
                                <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
                                <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="layout-specing">
                <h1>In-Progress</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card border-0 shadow rounded">
                                <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                                    <div class="ticket-no"><h4 class="mb-0">Ticket No :- #123456789</h4></div>
                                    <div className="in-progress-status"><button>In-Progress</button></div>
                                </div>

                                <div className="pending-main2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="p-3 issue-regarding-no">
                                                <h4>Issue regarding invite Members</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <div className="ticket1 px-3 pb-5">
                                                <span>Ticket Generated By: </span>
                                                <strong>Theresa Webb</strong>
                                            </div>
                                            <div className="ticket1 px-3 pb-5">
                                                <span>Date:- </span>
                                                <strong>04/04/2018</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="">
                                            <div className="inprogress-bg mx-3">
                                            <div className="col-md-12">
                                                <div className="p-3 issue-regarding-no">
                                                    <h4>Ticket reply by admin</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-end align-items-center">
                                                <div className="ticket1 px-3 pb-3">
                                                    <span>Date:- </span>
                                                    <strong>04/04/2018</strong>
                                                </div>
                                            </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    
                                </div>

                                <div className="row">
                                    <div className="col-md-12 p-4">
                                        <div className="checkmark-main">
                                            <textarea type="text" placeholder="Write a answer" className="w-100 border-0 cstm-textarea"></textarea>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-between align-items-center border-top pt-3">
                                                    <div className="col-checkmark">
                                                        <input type="checkbox" className="cstm-checkbox"></input>
                                                        <span className="checkmark-content">Chekmark as resolve ticket issue</span>
                                                    </div>
                                                    <button class="cstm-btn">Add Greeting</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col-12 mt-4">
                            <div class="d-md-flex align-items-center text-center justify-content-between">
                                <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
                                <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="layout-specing">
                <h1>In-Progress</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card border-0 shadow rounded">
                                <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                                    <div class="ticket-no"><h4 class="mb-0">Ticket No :- #123456789</h4></div>
                                    <div className="in-progress-status"><button>In-Progress</button></div>
                                </div>

                                <div className="pending-main3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="px-3 pt-3 issue-regarding-no">
                                                <h4>Issue regarding invite Members</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-items-center pb-5">
                                            <div className="ticket1 px-3">
                                                <span>Ticket Generated By: </span>
                                                <strong>Theresa Webb</strong>
                                            </div>
                                            <div className="ticket1 px-3">
                                                <span>Date:- </span>
                                                <strong>04/04/2018</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="">
                                            <div className="inprogress-bg mx-3">
                                            <div className="col-md-12">
                                                <div className="p-3 issue-regarding-no">
                                                    <h4>Ticket reply by admin</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-end align-items-center">
                                                <div className="ticket1 px-3 pb-3">
                                                    <span>Date:- </span>
                                                    <strong>04/04/2018</strong>
                                                </div>
                                            </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="border-bottom py-3"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="px-3 pt-3 issue-regarding-no">
                                                <h4><span class="re-text">Re:</span> Issue regarding invite Members</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and type setting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <div className="ticket1 px-3 pb-3">
                                                <span>Ticket Generated By: </span>
                                                <strong>Theresa Webb</strong>
                                            </div>
                                            <div className="ticket1 px-3 pb-3">
                                                <span>Date:- </span>
                                                <strong>04/04/2018</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 p-4">
                                        <div className="checkmark-main">
                                            <textarea type="text" placeholder="Write a answer" className="w-100 border-0 cstm-textarea2"></textarea>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-between align-items-center border-top pt-3">
                                                    <div className="col-checkmark">
                                                        <input type="checkbox" className="cstm-checkbox"></input>
                                                        <span className="checkmark-content">Chekmark as resolve ticket issue</span>
                                                    </div>
                                                    <button class="cstm-btn">Add Greeting</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row text-center">
                        <div class="col-12 mt-4">
                            <div class="d-md-flex align-items-center text-center justify-content-between">
                                <span class="text-muted me-3">Showing 1 - 10 out of 50</span>
                                <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default TicketsSupport;