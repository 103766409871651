import { useQuery } from '@tanstack/react-query';
import teamService from '../../Services/team.service';
import { QUERY_KEYS } from '../../common/query-keys';

export function useGetTeamsByOrganizationId(organizationId) {
    const { data: teamsRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.TEAMS, { organizationId: organizationId }],
        queryFn: () => teamService.getTeams({
            organizationIds: [organizationId],
        }),
        enabled: !!organizationId,
        staleTime: Infinity,
    });

    return {
        data: teamsRes?.data,
        isLoading: isLoading,
    };
}
