import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import loader from "../../assets/Images/loder.gif";
import organizationService from "../../Services/organization.service";


const AddCharity = () => {
    const history = useNavigate();
    useEffect(() => {

        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
    }, []);

    // const [charData, setCharData] = useState([]);
    const [formFields, setFormFields] = useState({
        title: "",
        banner: "",
        image_video: [],
        category: "",
        registrationNumber: "",
        webURL: "",
        websiteUrl: "",
        email: "",
        mediaLink: "",
        about: "",
        location: "",
        isCharityVerified: true,
        allowedInPx: false
    });
    const [err, seterr] = useState({
        title: "",
        banner: "",
        image_video: "",
        category: "",
        mediaLink: "",

        // registrationNumber: "",
        webURL: "",
        websiteUrl: "",
        email: "",
        about: "",
        location: "",

    });

    const [imagesPrev, setImagesPrev] = useState([]);
    const [finalImages, setFinalImages] = useState([]);
    const [multiData, setMultiData] = useState([]);
    const [charCategory, setCharCategory] = useState([]);
    const [file, setFile] = useState();
    const [bannerImg, setBannerImg] = useState("");
    const [bnImg, setBnImg] = useState("");
    const [organizationSelectionOptions, setOrganizationSelectionOptions] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState();
    const [organizations, setOrganizations] = useState();

    var imgExtArray = [];
    const arrayImages = [];
    var imgArray = [];
    const DataArrayCat = [];


    const handleChange = address => {
        setFormFields({ ...formFields, ['location']: address });
    };

    const handleSelect = address => {
        setFormFields({ ...formFields, ['location']: address });
    };



    useEffect((data, e) => {
        getCatData(1);
        getOrganizations();
    }, []);

    function changeOrganziationSelection(e, value) {
        let found = organizations.find((x) => {
            return x._id == e.option.value && x.charities.length > 0;
        });
        if (found && e.action != 'deselect-option') {
            toast.error(`This organization is already linked to chairty: ${found.charities[0].title}`);
            return;
        }
        setSelectedOrganizations(value);
    }

    async function getOrganizations() {
        setOrganizationSelectionOptions([]);

        try {
            const res = await organizationService.getOrganizations(1, 50);

            if (res.status == 200) {
                let organizationsData = res.data?.results;

                if (organizationsData?.length) {
                    setOrganizations(organizationsData);
                    const organizationSelectionOptionsData = organizationsData.map((organization) => formatOrganizationForDropdown(organization));

                    setOrganizationSelectionOptions(organizationSelectionOptionsData);
                }

                return;
            }

            toast.error("Couldn't get organizations");
        } catch (err) {
            toast.error("Couldn't get organizations");
        }
    }

    function formatOrganizationForDropdown(organization) {
        return {
            value: organization._id,
            label: organization.name,
        };
    }

    //   get category for add charity
    const getCatData = (pageno) => {
        const queryVar = {
            page: pageno,
            limit: 100,
            type: 0,
        };

        Services.getCharityCat(queryVar)
            .then((response) => {
                console.log(response.data.data.category_count, "response cat");
                if (response.data.status === 200) {
                    if (response.data.data.category_count.length !== 0) {
                        var data = response.data.data.category_count;
                        data.map((data1) => {
                            DataArrayCat.push(data1);
                        });
                    }
                    setCharCategory(DataArrayCat);
                } else {
                    console.log("error");
                }
            })
            .catch(function (err) {
                console.log(err, "erron api");
            });
    };


    //image onchange 

    const handleOnchangeUpdateCheckbox = (e) => {
        const { checked, name } = e.target;

        setFormFields({ ...formFields, [name]: checked });
    }

    const handleImageChangeBanner = (e) => {
        e.preventDefault();
        err.banner = e.target.value.length > 0 ? "" : "Select banner image";

        setFile(URL.createObjectURL(e.target.files[0]));
        // $(".uploaded-banner").show();
        // $('.btn-disable').prop("disabled", true);
        let charImage = e.target.files[0];
        setBannerImg(charImage);
        // var fileExt = charImage.name.split('.').pop();

        // var ExtArray = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];

        // ExtArray.includes(fileExt) ? (err.banner = "") :
        //     (
        //         err.banner = "Please upload image only",
        //         formFields.banner = ""
        //     );
        // seterr({ ...err })
    };

    function deleteImages(e) {
        const s = imagesPrev.filter((item, index) => index !== e);
        const fs = finalImages.filter((item, index) => index !== e);

        setFinalImages([...fs])
        setImagesPrev([...s]);

    }

    const handleImageChangeorg = (e) => {
        e.preventDefault();
        err.image_video = e.target.value.length > 0 ? "" : "Select image or video";


        var files = e.target.files;
        var filesArr = Array.prototype.slice.call(files);

        filesArr.forEach(function (f, index) {
            imgArray = [...imgArray, f];

        })
        setFinalImages([...finalImages, ...imgArray]);
        const arr = [];
        console.log(imgArray, "imgArraydatatata");
        imgArray.forEach(function (f, index) {
            console.log(f, "f");
            // fileArray.push(f);
            var u = URL.createObjectURL(f);
            arr.push(u);
            var filesplit = f.name.split('.').pop();
            var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
            var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
            imageExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'image' });
            videoExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'video' });


        })

        setImagesPrev([...imagesPrev, ...imgExtArray]);
        setMultiData([...multiData, ...arr]);

    };

    //for select location
    // const { ref, autocompleteRef } = usePlacesWidget({
    //     apiKey: 'AIzaSyB_io531iqO_YAdJLL6FLBmVTe6yDQ76m4',
    //     onPlaceSelected: (place) => {

    //         if (place !== "") {
    //             err.location = "";
    //             formFields.location = place.formatted_address;

    //         } else {
    //             err.location = "Select your organization";
    //             formFields.location = "";
    //         }

    //         seterr({ ...err })
    //     }
    // });
    //end location
    const handleOnchange = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case "title":
                err.title = value.length > 0 ? "" : "Enter charity name";
                break;
            case "category":
                err.category = value.length > 0 ? "" : "Enter category";
                break;

            case "webURL":
                // err.webURL = value.length > 0 ? "" : "Enter your website address";
                err.webURL = !new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(value)
                    ? "Enter a valid donation website url"
                    : "";
                break;
            case "websiteUrl":
                // err.webURL = value.length > 0 ? "" : "Enter your website address";
                err.websiteUrl = !new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(value)
                    ? "Enter a valid website url"
                    : "";
                break;
            case "mediaLink":
                err.mediaLink = value.length > 0 &&
                    !new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(value) ? "Enter a valid media link"
                    : "";
                break;
            case "about":
                err.about = value.length > 0 ? "" : "Write about";
                break;
            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        seterr({ ...err });
    };
    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.title === "") {
            err.title = "Enter charity name";
        } else {
            err.title = "";
        }
        if (formFields.category === "") {
            err.category = "Enter category";
        } else {
            err.category = "";
        }
        if (finalImages.length === 0) {
            err.image_video = "Select image or video";
        } else {
            err.image_video = "";
        }
        if (bannerImg === "") {
            err.banner = "Select banner image";
        } else {
            err.banner = "";
        }

        if (formFields.webURL === "") {

            err.webURL = "Enter your donation website address";
        }
        else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.webURL)) {

            err.webURL = "Enter a valid donation website url";
        }
        else {
            err.webURL = "";
        }

        // website validation
        if (formFields.websiteUrl === "") {

            err.websiteUrl = "Enter your website address";
        }
        else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.websiteUrl)) {

            err.websiteUrl = "Enter a valid website url";
        }
        else {
            err.websiteUrl = "";
        }

        // email validation
        if (formFields.email === "") {

            err.email = "Enter email address";
        }
        else {
            err.email = "";
        }

        if (formFields.mediaLink !== "") {
            if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.mediaLink)) {

                err.mediaLink = "Enter a valid media link";
            }
            else {
                err.mediaLink = "";
            }
        }

        if (formFields.about === "") {
            err.about = "Write about";
        } else {
            err.about = "";
        }
        seterr({ ...err });
        console.log(err, "err");
        console.log(finalImages, "finalImages");

        if (formFields.allowedInPx && (!selectedOrganizations || selectedOrganizations.length === 0)) {
            swal("Validation Error", "Please select a organization to allow the charity in PX", "error");
            return;
        }

        if (
            err.title === "" &&
            err.banner === "" &&
            err.image_video === "" &&
            err.category === "" &&
            err.websiteUrl == "" &&
            err.email == "" &&
            // err.registrationNumber === "" &&
            err.webURL === "" &&
            err.about === ""
        ) {
            $(".loader-main").show();
            var imgbanner = "";
            if (finalImages.length > 0) {
                const fileData = new FormData();
                finalImages.forEach((file) => fileData.append('image', file));
                // images and videos multiple uploaded
                Services.charimageGet(fileData)
                    .then((response) => {
                        console.log(response, "send data multi file");

                        if (response.status === 200) {

                            const ImagesDataArrar = response.data.data.images;
                            ImagesDataArrar.map((image) => {
                                arrayImages.push(image.location);
                            })
                            if (bannerImg) {
                                let formData = new FormData();
                                formData.append("image", bannerImg);
                                //banner image upload
                                Services.charimageGet(formData)
                                    .then((response) => {
                                        console.log(response, "ressss");
                                        if (response.status === 200) {
                                            formFields.banner = response.data.data.images[0].location;
                                            // setBnImg(`${response.data.data.images[0].location}`);
                                            imgbanner = response.data.data.images[0].location;
                                            AddCharityData(arrayImages, imgbanner);


                                        }
                                    })
                                    .catch(function (err) {
                                        console.log(err, "err");
                                    });
                            }
                        }

                    })
                    .catch(function (err) {
                        console.log(err, "err");

                    });
            }
            function AddCharityData(arrayImages, bnImg) {

                var addCharity = {
                    title: formFields.title,
                    banner: bnImg,
                    image_video: arrayImages,
                    registrationNumber: formFields.registrationNumber,
                    webURL: formFields.webURL,
                    websiteUrl: formFields.websiteUrl,
                    email: formFields.email,
                    about: formFields.about,
                    location: formFields.location,
                    isCharityVerified: formFields.isCharityVerified,
                    mediaLink: formFields.mediaLink,
                    categoryId: formFields.category,
                    allowedInPx: formFields.allowedInPx,
                    organizationIds: selectedOrganizations?.map((organization) => organization.value) || [],
                };
                console.log(addCharity, "addCharity");
                Services.addCharity(addCharity)
                    .then((response) => {
                        $(".loader-main").hide();

                        if (response.data.status == 200) {

                            swal("Success", response.data.message, "success");
                            $("#add-charity")[0].reset();
                            $('#b-img').remove();
                            $('#o-img').remove();
                            $('.uploded-video').remove();
                            $('.multi-images').remove();
                            $('.multi-video').remove();
                            history('/charity');


                        } else {
                            swal("Failed", response.data.message, "error");
                        }
                    })
                    .catch((err) => {

                        swal("Failed", err.response.data.message, "error");
                    });


            }
        }

    };

    return (
        <>
            <main class="page-content bg-light">
                <Header title1="Add Charity" title="Charity List" main_title="Charity" title_link="charity" />
                <div class="container-fluid">
                    <div class="layout-specing">
                        <h4 class="mb-4">Add Charity</h4>
                        <div class="row">
                            {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
                            <div class="col-md-12">
                                <div class="card border-0 shadow rounded mb-4 p-4">
                                    <form id="add-charity" onSubmit={handleOnSubmit}>
                                        <div class="mb-4 col-md-7">
                                            <PlacesAutocomplete
                                                value={formFields.location}
                                                onChange={handleChange}
                                                onSelect={handleSelect}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <i class="fi fi-rr-search cstm-search-ro"></i>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search Places ...',
                                                                className: 'cstm-input-seacrh location-search-input',
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                            {err.location !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.location}
                                                </span>
                                            )
                                            }
                                        </div>

                                        <div class="mb-4">
                                            <label class="cstm-label">Upload Logo</label><br />

                                            <label for="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                                            {/* <div id="login-loader" className='loader-main uploaded-banner' style={{ display: 'none' }}>
                                                            <img src={loader} />
                                                        </div> */}
                                            {file && file.length > 0 && <img
                                                id="b-img"
                                                className="AddOrganization-bannerImg"
                                                src={file}
                                            />}


                                            <input
                                                accept="image/*"
                                                type="file"
                                                name="banner"
                                                id="banner-image"
                                                className='cstm-uploadHide'
                                                // style={{ opacity: 0, cursor: 'pointer' }}
                                                onChange={handleImageChangeBanner}
                                            />
                                            {/* {file && <img id="b-img" src={file} className="AddOrganization-bannerImg" />} */}
                                            {err.banner !== "" && (
                                                <span style={{ color: "red" }} className="ml-3">
                                                    {err.banner}
                                                </span>
                                            )
                                            }

                                        </div>

                                        <div className="upload__img-wrap"></div>

                                        <div class="mb-4">
                                            <label class="cstm-label">Upload Image Or Video</label><br />
                                            {/* {filevideo && <img id="o-img" src={filevideo} width="112px" height="112px" />} */}

                                            <div className='cstm-video-image-upload'>
                                                <label for="o-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                                                <div className='cstm-vi-in'>
                                                    {imagesPrev.map((data, index) =>

                                                        data.type === 'image' ?

                                                            (<>
                                                                <div className='cstm-vi-box'>
                                                                    <span className="cstm-vi-bx"> <img class="multi-images" controls src={data.url} alt="..." /></span>
                                                                    <button type="button" onClick={() => deleteImages(index)}>
                                                                        ✖
                                                                    </button>
                                                                </div>
                                                            </>
                                                            ) : (
                                                                <>  <div className='cstm-vi-box'>
                                                                    <span className="cstm-vi-bx"> <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                                                    <button type="button" onClick={() => deleteImages(index)}>
                                                                        ✖
                                                                    </button>
                                                                </div>
                                                                </>
                                                            )
                                                    )}
                                                </div>


                                                <input
                                                    // accept=".mov,.mp4"
                                                    multiple
                                                    type="file"
                                                    name="image_video"
                                                    id="o-image"
                                                    className='cstm-uploadHide'
                                                    // style={{ opacity: 0, cursor: 'pointer' }}
                                                    onChange={handleImageChangeorg}
                                                />
                                            </div>

                                            {err.image_video !== "" && (
                                                <span style={{ color: "red" }} className="ml-3">
                                                    {err.image_video}
                                                </span>
                                            )
                                            }

                                        </div>

                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Charity Display Name</label>
                                            <input
                                                name="title"
                                                id="char-name"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Charity Display Name"
                                                onChange={handleOnchange}
                                            />
                                            {err.title !== "" && (
                                                <span style={{ color: "red" }}>{err.title}</span>
                                            )}
                                        </div>
                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Category</label>
                                            <select
                                                name="category"
                                                class="form-select cstm-category"
                                                aria-label="Default select example"
                                                onChange={handleOnchange}
                                            >
                                                <option value="" selected>
                                                    Category
                                                </option>

                                                {charCategory.map((cat) => {
                                                    return <option value={cat._id}>{cat.title}</option>;
                                                    // console.log(cat.title,"single cat")
                                                })}
                                            </select>

                                            {err.category !== "" && (
                                                <span style={{ color: "red" }}>{err.category}</span>
                                            )}
                                        </div>

                                        {/* Donation web url */}
                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Charity Donation URL</label>
                                            <input
                                                name="webURL"
                                                id="webURL"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Donation Website Address"
                                                onChange={handleOnchange}
                                            />
                                            {err.webURL !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.webURL}
                                                </span>
                                            )
                                            }
                                        </div>

                                        {/* Website url */}
                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Charity Website URL</label>
                                            <input
                                                name="websiteUrl"
                                                id="websiteUrl"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Website Address"
                                                onChange={handleOnchange}
                                            />
                                            {err.websiteUrl !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.websiteUrl}
                                                </span>
                                            )
                                            }
                                        </div>

                                        {/* Email */}
                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Charity Email</label>
                                            <input
                                                name="email"
                                                id="email"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Enter Email Address"
                                                onChange={handleOnchange}
                                            />
                                            {err.email !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.email}
                                                </span>
                                            )
                                            }
                                        </div>

                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Media Link</label>
                                            <input
                                                name="mediaLink"
                                                id="mediaLink"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Media Link"
                                                onChange={handleOnchange}
                                            />
                                            {err.mediaLink !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.mediaLink}
                                                </span>
                                            )
                                            }
                                        </div>
                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">Registration Number</label>
                                            <input
                                                name="registrationNumber"
                                                id="webUregistrationNumberRL"
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Charity Registration Number"
                                                onChange={handleOnchange}
                                            />
                                            {/* {err.registrationNumber !== "" && (
                                                            <span style={{ color: "red" }}>
                                                                {err.registrationNumber}
                                                            </span>
                                                        )
                                                        } */}
                                        </div>

                                        <div class="mb-4 col-md-7">
                                            <label class="cstm-label">About</label>
                                            <textarea
                                                name="about"
                                                id="about"
                                                type="textarea"
                                                rows="5"
                                                class="cstm-input cstm-textarea"
                                                placeholder="About..."
                                                onChange={handleOnchange}
                                            />
                                            {err.about !== "" && (
                                                <span style={{ color: "red" }}>
                                                    {err.about}
                                                </span>
                                            )
                                            }
                                        </div>

                                        <div className="mb-4 col-md-7">
                                            <input name="allowedInPx" id="allowedInPx" type="checkbox" checked={formFields.allowedInPx} onChange={handleOnchangeUpdateCheckbox} />
                                            <label className="cstm-label">Available in PX</label>
                                        </div>

                                        {organizationSelectionOptions && organizationSelectionOptions.length > 0 && <div className="mb-4">
                                            <label className="cstm-label">Organization Association</label>

                                            <ReactMultiSelectCheckboxes
                                                className="form-select"
                                                options={organizationSelectionOptions}
                                                placeholderButtonLabel="Select organizations"
                                                value={selectedOrganizations}
                                                onChange={(value, e) => changeOrganziationSelection(e, value)}
                                                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${value?.length ? value.length : 0} organization(s) selected`}
                                            />
                                        </div>}
                                        {/* <div class="d-flex">
                                                        <button class=" cstm-btn">
                                                            Add Charity
                                                        </button>
                                                    </div> */}
                                        <div className="submit-btnMain cstm-loader">
                                            <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Add Charity </button>
                                            <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                                <img src={loader} />
                                            </div>
                                        </div>
                                    </form>

                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default AddCharity;