import "./ThankYou.css";
import { ThankYouUtils } from "../../common/utils/thankyou.util";
import { formatDateForTable } from "../../common/utils/date.util";
import { LabelHelper } from "../../common/utils/label.helper";
import { TextWithLabel } from "../../shared/components/TextWithLabel/TextWithLabel";
import { useGetThankYouById } from "../../hooks/thankyou.hook";

/**
 * @param {Object} props
 * @param {string} props.thankYouId
 */
export function ViewThankYou({ thankYouId }) {
  const { data: thankYou } = useGetThankYouById(thankYouId);
  const senderDetails = ThankYouUtils.getSenderDetails(thankYou);

  return (
    <div className="view-thankyou-container">
      <div className="card border-0 shadow rounded mb-4 p-2">
        <div>
          <TextWithLabel label="Message" text={thankYou?.description} isMultiline />
          <TextWithLabel
            label="Reply"
            text={thankYou?.replies?.[0]?.description}
            isMultiline
          />
          <TextWithLabel
            label="Auto Reply"
            text={thankYou?.autoReply?.[0]?.replyMessage}
            isMultiline
          />
          <TextWithLabel label="Sender Name" text={senderDetails?.firstName} />
          <TextWithLabel label="Sender Email" text={senderDetails?.email} />
          <TextWithLabel
            label="Sender Phone"
            text={senderDetails?.phoneNumber}
          />
          <TextWithLabel
            label="Receiver Name"
            text={thankYou?.users?.[0]?.firstName}
          />
          <TextWithLabel
            label="Receiver Email"
            text={thankYou?.users?.[0]?.email}
          />
          <TextWithLabel
            label="Receiver Phone"
            text={thankYou?.users?.[0]?.phoneNumber}
          />
          <TextWithLabel
            label="Platform"
            text={LabelHelper.getPlatformFlowLabel(thankYou?.flow)}
          />
          <TextWithLabel
            label="Date"
            text={thankYou?.createdAt ? formatDateForTable(thankYou?.createdAt) : null}
          />
        </div>
      </div>
    </div>
  );
}
