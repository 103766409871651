// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-container {
    position: relative;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.custom-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.6;
}

.custom-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-modal-inner {
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
}

.custom-modal-content {
    max-height: 80vh;
    max-width: 90vw;
    overflow: auto;
}

.confirmation-modal-inner {
    padding: 2rem;
    background-color: white;
    border-radius: 1rem;
    max-width: 512px;
    text-align: center;
}

.modal-close-icon-btn {
	height: 24px;
	width: 24px;
	border: 1px solid #00CCCC;
	border-radius: 50%;
	color: #00CCCC;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
}

.modal-close-icon-btn:hover {
	background-color: #00CCCC;
	color: white;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;CACC,YAAY;CACZ,WAAW;CACX,yBAAyB;CACzB,kBAAkB;CAClB,cAAc;CACd,uBAAuB;CACvB,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,YAAY;AACb","sourcesContent":[".custom-modal-container {\n    position: relative;\n    z-index: 1000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n}\n\n.custom-modal-backdrop {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background-color: black;\n    opacity: 0.6;\n}\n\n.custom-modal {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    overflow-y: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.custom-modal-inner {\n    padding: 1rem;\n    background-color: white;\n    border-radius: 1rem;\n}\n\n.custom-modal-content {\n    max-height: 80vh;\n    max-width: 90vw;\n    overflow: auto;\n}\n\n.confirmation-modal-inner {\n    padding: 2rem;\n    background-color: white;\n    border-radius: 1rem;\n    max-width: 512px;\n    text-align: center;\n}\n\n.modal-close-icon-btn {\n\theight: 24px;\n\twidth: 24px;\n\tborder: 1px solid #00CCCC;\n\tborder-radius: 50%;\n\tcolor: #00CCCC;\n\tbackground-color: white;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tline-height: 0;\n}\n\n.modal-close-icon-btn:hover {\n\tbackground-color: #00CCCC;\n\tcolor: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
