import baseApi from "./base-api";

function getGiftCards({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("gift-cards", {
        page: pageNumber,
        limit: pageSize,
    });
}

const giftCardService = {
    getGiftCards: getGiftCards,
};

export default giftCardService;
