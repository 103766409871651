import React, { useState, useEffect } from 'react';
import Header from '../NavMenuBar/Header';
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';import loader from "../../assets/Images/loder.gif";

const AddAdminUser = () => {
  //auto complete
  const [formFields, setFormFields] = useState({
    "first_name": "",
    "last_name" : "",
    "role": "",
    "bio":"",
    "email": "",
    "location": "",
    "password": "",
    "phone_no": "",
    "resource_access": "",
    "allow_organization":false,
    "allow_team":false,
    "allow_event":false,
    "allow_cherity":false,
    "allow_announcement":false,
    "allow_subscription":false,
    "allow_users":false,
    "allow_help_center":false,
    "allow_greetings" : false,
    "allow_gifting" :false,
    "allow_news":false
   
  });
  const [err, seterr] = useState({
    "first_name": "",
    "last_name" : "",
    "role": "",
    "email": "",
    "location": "",
    "password": "",
    "phone_no": "",
    "resource_access": "",
  });

  const handleChange = address => {
    setFormFields({...formFields,['location']:address});
  };
 
  const handleSelect = address => {
    setFormFields({...formFields,['location']:address});
  };

  //image onchange 

  const [file, setFile] = useState();
  const onChange = (e) =>{
    console.log(e,'name');
    console.log(e.target.value,'name'); 
    console.log(e.target.checked,'target');
    if(e.target.value == 'All'){
      console.log('hiiii');
      $(".case").prop("checked",e.target.checked );
      setFormFields({ ...formFields, ["allow_organization"]: e.target.checked ,["allow_team"]: e.target.checked ,["allow_event"]: e.target.checked ,["allow_cherity"]: e.target.checked ,["allow_announcement"]: e.target.checked ,["allow_subscription"]: e.target.checked ,["allow_users"]: e.target.checked ,["allow_help_center"]: e.target.checked ,["allow_greetings"]: e.target.checked ,["allow_gifting"]: e.target.checked ,["allow_news"]: e.target.checked  });
    }else{
      console.log('jiii');
      if ($(".case").length == $(".case:checked").length) {
        $("#All").prop("checked", true);
      } else {
        $("#All").prop("checked", false);
      }

      setFormFields({ ...formFields, [e.target.value]: e.target.checked });
    }
    

  }   

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "first_name":
        err.first_name = value.length > 0 ? "" : "Enter full name";
        break;
      case "last_name":
        err.last_name = value.length > 0 ? "" : "Enter last name";
        break;
      case "password":
        err.password = value.length > 0 ? "" : "Enter password";
        break;
      case "email":
        err.email = value.length > 0 ? "" : "Enter your email";
        err.email = !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
          ? "Enter a valid email address"
          : "";
        break;
      case "phone_no":
      err.phone_no = value.length > 0 ? "" : "Enter phone number";
        break;
      case "role":
        err.role = value.length > 0 ? "" : "Enter role";
        break;
      case "location":
        err.location = value.length > 0 ? "" : "Enter location";
        break;
      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();


    if (formFields.first_name === "") {
      err.first_name = "Enter first name";

    } else {
      err.first_name = "";
    }

    if (formFields.last_name === "") {
      err.last_name = "Enter last name";

    } else {
      err.last_name = "";
    }

    if (formFields.password === "") {
      err.password = "Enter password";

    } else {
      err.password = "";
    }

    if (formFields.role === "") {
      err.role = "Enter role"

    } else {
      err.role = "";
    }

    if (formFields.phone_no === "") {
      err.phone_no = "Enter phone number"

    } else {
      err.phone_no = "";
    }

    if (formFields.location === "") {
      err.location = "Enter location"

    } else {
      err.location = "";
    }
    

    if (formFields.email === "") {
      err.email = "Enter email"

    } else {
      err.email = "";
    }
    seterr({ ...err });
    console.log(err, "err");

    if (err.first_name === "" && err.last_name === "" &&  err.password === "" && err.role === "" && err.email === "" && err.phone_no === "" && err.location === "") {
      console.log(formFields,'formFields');
      // return false;
      var bodydata = {
        firstName: formFields.first_name,
        lastName: formFields.last_name,
        password: formFields.password,
        email: formFields.email,
        phoneNumber: formFields.phone_no,
        address: formFields.location,
        role: formFields.role,
        countryCode: "",
        image: "",
        bio:formFields.bio,
        sub_admin_access: {
          allow_organization: formFields.allow_organization,
          allow_team: formFields.allow_team,
          allow_event: formFields.allow_event,
          allow_cherity: formFields.allow_cherity,
          allow_announcement: formFields.allow_announcement,
          allow_subscription: formFields.allow_subscription,
          allow_users: formFields.allow_users,
          allow_help_center: formFields.allow_help_center,
          allow_greetings: formFields.allow_greetings,
          allow_gifting: formFields.allow_gifting,
          allow_news: formFields.allow_news
        }
      };

      console.log(bodydata,'bodydata');
      // return false;
      $(".loader-main").show();
      Services.addSubAdminUser(bodydata)
        .then((response) => {
          console.log(response, "responce afteR ADD")
          // return false;
          $(".loader-main").hide();

          if (response.data.status == 200) {
            // getData(1);
            swal("Success", response.data.message, "success");
            $('#add-organization')[0].reset();
     
          } else {
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          swal("Failed", err.response.data.message, "error");

        });
    };
  }
  return (
    <>
      <main class="page-content bg-light">
        <Header title="Add Admin User" main_title="Admin Setting" />
        <div class="container-fluid">
          <div class="layout-specing">

            <h4 class="mb-4">Add Admin User</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-3">
           
                        <form id="add-organization" onSubmit={handleOnSubmit}>

                          <div class="mb-4 col-md-7 ">
                            <label class="cstm-label">First name</label>
                            <input
                              name="first_name"
                              id="first_name"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter first name"
                              onChange={handleOnchange}
                            />
                            {err.first_name !== "" && (
                              <span style={{ color: "red" }}>
                                {err.first_name}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7 ">
                            <label class="cstm-label">Last name</label>
                            <input
                              name="last_name"
                              id="last_name"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter last name"
                              onChange={handleOnchange}
                            />
                            {err.last_name !== "" && (
                              <span style={{ color: "red" }}>
                                {err.last_name}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7 ">
                            <label class="cstm-label">Password</label>
                            <input  
                              name="password"
                              id="password"
                              type="password"
                              class="cstm-input"
                              placeholder="Enter password"
                              onChange={handleOnchange}
                            />
                            {err.password !== "" && (
                              <span style={{ color: "red" }}>
                                {err.password}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7 ">
                            <label class="cstm-label">Phone number</label>
                            <input
                              name="phone_no"
                              id="phone_no"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter phone number"
                              onChange={handleOnchange}
                            />
                            {err.phone_no !== "" && (
                              <span style={{ color: "red" }}>
                                {err.phone_no}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7 ">
                            <label class="cstm-label">Email</label>
                            <input
                              name="email"
                              id="email"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter email"
                              onChange={handleOnchange}
                            />
                            {err.email !== "" && (
                              <span style={{ color: "red" }}>
                                {err.email}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7">
                            <label class="cstm-label">Location</label>
                          <PlacesAutocomplete
                            value={formFields.location}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                <input
                                    {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        >
                                        <span>{suggestion.description}</span>
                                        </div>
                                    );
                                    })}
                                </div>
                                </div>
                            )}
                            </PlacesAutocomplete>
                            {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                {err.location}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7">
                            <label class="cstm-label">Role</label>
                            <select
                              name="role"
                              class="form-select cstm-category"
                              aria-label="Default select example"
                              onChange={handleOnchange}
                            >
                              <option value="" selected>
                                Select Role
                              </option>
                              <option value="Sub-Admin">Sub-Admin</option>
                              <option value="Supervisor">Supervisor</option>
                              <option value="Manager">Manager</option>

                            </select>
                            {err.role !== "" && (
                              <span style={{ color: "red" }}>
                                {err.role}
                              </span>
                            )
                            }
                          </div>
                          <div class="mb-4 col-md-7">
                              <label class="cstm-label">Bio</label>
                              <textarea
                                  name="bio"
                                  id="bio"
                                  type="textarea"
                                  rows="5"
                                  class="cstm-input cstm-textarea"
                                  placeholder="Enter bio info..."
                                  onChange={handleOnchange}
                              />
                          </div>
                          <div class="mb-4 col-md-7">
                            <div className='table-responsive bg-white border-top rounded-0 border cstm-rr'>
                            <table class="table mb-0 table-center">
                              <tr>
                                <th className='border-bottom'>Role Resources</th>
                                <th className='border-bottom'><input type="checkbox" id="All" value="All" onChange={(e) => onChange(e)}/> Select All</th>
                              </tr>
                              <tr>
                                <td>User</td>
                                <td><input type="checkbox" id="allow_users" class="case" name="resources_access[]" value="allow_users" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Organization</td>
                                <td><input type="checkbox" id="allow_organization" class="case" name="resources_access[]" value="allow_organization" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Charity</td>
                                <td><input type="checkbox" id="allow_cherity" class="case" name="resources_access[]" value="allow_cherity" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Location Greetings</td>
                                <td><input type="checkbox" id="allow_greetings" class="case" name="resources_access[]" value="allow_greetings" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Gifting</td>
                                <td><input type="checkbox" id="allow_gifting" class="case" name="resources_access[]" value="allow_gifting" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Events</td>
                                <td><input type="checkbox" id="allow_event" class="case" name="resources_access[]" value="allow_event" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Team</td>
                                <td><input type="checkbox" id="allow_team" class="case" name="resources_access[]" value="allow_team" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>News</td>
                                <td><input type="checkbox" id="allow_news"  class="case" name="resources_access[]" value="allow_news" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Subscription </td>
                                <td><input type="checkbox" id="allow_subscription" class="case" name="resources_access[]" value="allow_subscription" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Anouncement</td>
                                <td><input type="checkbox" id="allow_announcement" class="case" name="resources_access[]" value="allow_announcement" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              <tr>
                                <td>Help Center/Support</td>
                                <td><input type="checkbox" id="allow_help_center" class="case" name="resources_access[]" value="allow_help_center" onChange={(e) => onChange(e)} /> Active</td>
                              </tr>
                              
                            </table>
                            </div>
                            
                            {err.resource_access !== "" && (
                              <span style={{ color: "red" }}>
                                {err.resource_access}
                              </span>
                            )
                            }
                          </div>

                        
                          <div className="submit-btnMain cstm-loader">
                            <button type="submit" className="btn-submit-login-form cstm-submitbutton">Add Admin User </button>
                            <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                              <img src={loader} />
                            </div>
                          </div>

                          {/* <div class="d-flex">
                            <button class=" cstm-btn">
                              Add Organization
                            </button>
                          </div> */}
                        </form>
               
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AddAdminUser
