import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import SelectSearch from 'react-select-search';
import loader from "../../../assets/Images/loder.gif";
import Header from "../../NavMenuBar/Header";
import Services from "../../../Services/auth.service";
import userService from "../../../Services/user.service";
import teamService from "../../../Services/team.service";
import { mainSpinner } from "../../../utils/spinner.util";
import { ALLOWED_IMAGE_MIME_TYPES } from "../../../common/constants/file.constant";
import organizationService from "../../../Services/organization.service";

export function CreateUser() {
    const history = useNavigate();
    const [formFields, setFormFields] = useState({
        firstName: "",
        phoneNumber: "",
        company: "",
        countryCode: "",
        password: "",
        image: null,
        occupation: "",
        email: ""
    });
    const [tempProfileImage, setTempProfileImage] = useState();
    const [organizationSelectionOptions, setOrganiztionSelectionOptions] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [teamSelectionOptions, setTeamSelectionOptions] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);

    const [err, seterr] = useState({
        firstName: "",
        countryCode: "",
        phoneNumber: "",
        password: "",
        email: "",
        company: ""
    });

    useEffect(() => {
        getTOrganizations();
    }, []);

    useEffect(() => {
        if (selectedOrganization) {
            setFormFields({
                ...formFields,
                company: selectedOrganization
            });
            getTeams(selectedOrganization);
        }
    }, [selectedOrganization]);

    async function getTOrganizations() {
        setOrganiztionSelectionOptions([]);

        try {
            const res = await organizationService.getOrganizations(1, 50);

            if (res.status == 200) {
                let organizationsData = res.data?.results;

                if (organizationsData?.length) {
                    const organizationSelectionOptionsData = organizationsData.map((organization) => formatOrganizationForDropdown(organization));

                    setOrganiztionSelectionOptions(organizationSelectionOptionsData);
                }

                return;
            }

            toast.error("Couldn't get organizations");
        } catch (err) {
            toast.error("Couldn't get organizations");
        }
    }

    function formatOrganizationForDropdown(organization) {
        return {
            value: organization._id,
            name: organization.name,
        };
    }

    async function getTeams(organizationId) {
        setTeamSelectionOptions([]);

        try {
            const res = await teamService.getTeams({
                organizationIds: [organizationId],
            });

            if (res.status == 200) {
                let teamsData = res.data?.results;

                if (teamsData?.length) {
                    const teamSelectionOptionsData = teamsData.map((team) => formatTeamForDropdown(team));

                    setTeamSelectionOptions(teamSelectionOptionsData);
                }

                return;
            }

            toast.error("Couldn't get teams");
        } catch (err) {
            toast.error("Couldn't get teams");
        }
    }

    function formatTeamForDropdown(team) {
        return {
            value: team._id,
            label: team.name,
        };
    }

    async function uploadProfileImage(file) {
        mainSpinner.show();

        try {
            const formData = new FormData();
            formData.append("image", file);

            const res = await Services.imageGet(formData);

            if (res.status == 200) {
                setFormFields({
                    ...formFields,
                    image: res.data.data.images[0].location,
                });

                return;
            }

            toast.error("Couldn't upload profile image");
        } catch (err) {
            toast.error("Couldn't upload profile image");
        } finally {
            mainSpinner.hide();
        }
    }

    function handleError() {
        if (formFields.firstName === "") {
            err.title = "Enter first name";
        } else {
            err.firstName = "";
        }

        if (formFields.countryCode === "") {
            err.countryCode = "Enter country code";
        } else {
            err.countryCode = "";
        }

        if (selectedOrganization === "" || !selectedOrganization) {
            err.selectedOrganization = "Select Organization";
        } else {
            err.selectedOrganization = "";
        }

        if (formFields.phoneNumber === "") {
            err.phoneNumber = "Enter phone number";
        } else {
            err.phoneNumber = "";
        }

        if (formFields.password === "") {
            err.password = "Enter password";
        } else {
            err.password = "";
        }

        if (formFields.email === "") {
            err.email = "Enter email";
        } else {
            err.email = "";
        }

        seterr({ ...err });

        return err.firstName === "" &&
            err.countryCode === "" &&
            err.phoneNumber === "" &&
            err.password === "" &&
            err.email === "" &&
            err.selectedOrganization === "";

    }

    function removeProfileImage() {
        setTempProfileImage(null);
        setFormFields({
            ...formFields,
            image: null,
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber' || name === 'countryCode') {
            const re = /^[0-9]+$/;

            // if value is not blank, then test the regex

            if (value === '' || re.test(value)) {
                setFormFields({
                    ...formFields,
                    [name]: value
                });
            }

            return;
        }

        setFormFields({
            ...formFields,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            return;
        }

        setTempProfileImage(URL.createObjectURL(file));

        uploadProfileImage(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let userData = {
            ...formFields,
            isCreatedByAdmin: true,
            teamIds: selectedTeams?.map((team) => team.value) || [],
        };

        if (handleError()) {
            createUser(userData);
        }
    };

    async function createUser(userData) {
        mainSpinner.show();

        try {
            const res = await userService.createUser(userData);

            if (res.status == 200) {
                toast.success("Successfully created user");
                history("/user");
                return;
            }

            toast.error("Couldn't create user");
            if (res.message) {
                toast.error(res.meessage);
            }
        } catch (err) {
            toast.error("Couldn't create user");
            if (err.message) {
                toast.error(err.message);
            }
        } finally {
            mainSpinner.hide();
        }
    }

    return (
        <main className="page-content bg-light">
            <Header
                title1="Create User"
                title="User List"
                main_title="User"
                title_link="user"
            />
            <div className="container-fluid">
                <div className="layout-specing">
                    <h4 className="mb-4">Create User</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 shadow rounded mb-4 p-2">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 cstm-profile-img cstm-AdminProfile-img d-flex align-items-center ">
                                        <div className="mr-5 items-center">
                                            <div className="mb-2">
                                                {tempProfileImage && (
                                                    <img
                                                        src={tempProfileImage}
                                                    />
                                                )}

                                                {(!tempProfileImage && formFields.image) && (
                                                    <img
                                                        id="image"
                                                        src={`${process.env.REACT_APP_S3_BASE_URL}/${formFields.image}`}
                                                    />
                                                )}

                                                {/* place holder when profile image not available */}
                                                {(!tempProfileImage && !formFields.image) && (
                                                    <div
                                                        style={{
                                                            width: "120px",
                                                            height: "120px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#00CCCC"
                                                        }}
                                                    ></div>
                                                )}
                                            </div>

                                            <div>
                                                <label htmlFor="select-image" className='cstm-uploadbtn cstm-btn mr-2'>Choose File</label>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    name="image"
                                                    id="select-image"
                                                    className='cstm-uploadHide'
                                                    onChange={handleImageChange}
                                                />

                                                <button
                                                    type="button"
                                                    onClick={removeProfileImage}
                                                    disabled={formFields.image === null}
                                                    className="cstm-btn"
                                                >
                                                    Remove Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">First Name</label>
                                        <input
                                            name="firstName"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="First Name"
                                            value={formFields.firstName}
                                            onChange={handleInputChange}
                                        />

                                        {err.firstName !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.firstName}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Country Code</label>
                                        <input
                                            name="countryCode"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Country Code"
                                            value={formFields.countryCode}
                                            onChange={handleInputChange}
                                        />

                                        {err.countryCode !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.countryCode}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Phone number</label>
                                        <input
                                            name="phoneNumber"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Phone number"
                                            value={formFields.phoneNumber}
                                            onChange={handleInputChange}
                                        />

                                        {err.phoneNumber !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.phoneNumber}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Password</label>
                                        <input
                                            name="password"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Password"
                                            value={formFields.password}
                                            onChange={handleInputChange}
                                        />
                                        {err.password !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.password}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Email (Note: This will be marked as verified email address)</label>
                                        <input
                                            name="email"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Email"
                                            value={formFields.email}
                                            onChange={handleInputChange}
                                        />
                                        {err.email !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.email}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Job title</label>
                                        <input
                                            name="occupation"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Job title"
                                            value={formFields.occupation}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Organization</label>

                                        <SelectSearch options={organizationSelectionOptions} value={selectedOrganization} onChange={(value, e) => setSelectedOrganization(value)} name="organization" placeholder="Choose organization" />
                                        {err.selectedOrganization !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.selectedOrganization}
                                            </span>
                                        )
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Teams</label>

                                        <ReactMultiSelectCheckboxes
                                            className="form-select"
                                            options={teamSelectionOptions}
                                            placeholderButtonLabel="Select teams for user"
                                            value={selectedTeams}
                                            onChange={(value, e) => setSelectedTeams(value)}
                                            getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${value.length} team(s) selected`}
                                        />
                                    </div>

                                    <div className="submit-btnMain cstm-loader">
                                        <button type="submit" className="btn-submit-login-form cstm-submitbutton">Create User</button>
                                        <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                            <img src={loader} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}