import { axiosBaseApiClient } from "./axios-client";
import { LOCAL_STORAGE_KEYS } from "../common/constants/local-storage.constant";

let authHeaderInterceptor = null;

function addAuthHeaderInterceptor() {
    removeAuthHeaderInterceptor();

    authHeaderInterceptor = axiosBaseApiClient.interceptors.request.use(function (config) {
        if (!config.headers) {
            config.headers = {};
        }

        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

        config.headers["Authorization"] = token;

        return config;
    });
}

function removeAuthHeaderInterceptor() {
    if (authHeaderInterceptor !== null) {
        axiosBaseApiClient.interceptors.request.eject(authHeaderInterceptor);
        authHeaderInterceptor = null;
    }
}

export const baseApiInterceptors = {
    addAuthHeaderInterceptor: addAuthHeaderInterceptor,
    removeAuthHeaderInterceptor: removeAuthHeaderInterceptor,
};
