import React, { useState, useEffect } from 'react';
import Header from '../NavMenuBar/Header';
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import loader from "../../assets/Images/loder.gif";


const AddGiftPartner = () => {
  const history = useNavigate();
  useEffect(() => {

    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
  }, []);

  // const [charData, setCharData] = useState([]);
  const [formFields, setFormFields] = useState({
    title: "",
    banner: "",
    image_video: [],
    category: "",
    webAddress: "",
    mediaLink: "",
    location: "",
    role: "",
    role_other: "",
    organisationEmail: "",
    charity:""
    // isCharityVerified: true,
  });
  const [err, seterr] = useState({
    title: "",
    banner: "",
    image_video: "",
    category: "",
    mediaLink: "",
    webAddress: "",
    location: "",
    role: "",
    role_other: "",
    organisationEmail: ""

  });
  const [showLatitude, setLatitude] = useState('');
  const [showLongitude, setLongitude] = useState('');
  const [selectStatus, setSelectStatus] = useState(false);
  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [giftPartnerCategory, setGiftPartnerCategory] = useState([]);
  const [giftCharity, setCharityCategory] = useState([]);

  const [file, setFile] = useState();
  const [bannerImg, setBannerImg] = useState("");
  
  var imgExtArray = [];
  const arrayImages = [];
  var imgArray = [];
  const DataArrayCat = [];
  
  var pageno=1;
  var limit=20;
  var CharityDataArray = [];

  const handleChange = address => {
    setFormFields({ ...formFields, ['location']: address });

    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      console.log('Success', latLng)
      setLatitude(latLng.lat)
      setLongitude(latLng.lng)
    }  
      )
    .catch(error => console.error('Error', error));
    
  };

  const handleSelect = address => {
    setFormFields({ ...formFields, ['location']: address });
  };



  useEffect((data, e) => {
    getCatData(1);
    getCharityData(pageno,limit);
  }, []);

  //   get category for add gift partner
  const getCatData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getGift(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.gift_card_data.length !== 0) {
            var data = response.data.data.gift_card_data;
            data.map((data1) => {
              DataArrayCat.push(data1);
            });
          }
          setGiftPartnerCategory(DataArrayCat);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };

  const getCharityData = (pageno,limit) => {
    const queryVar = {
      page:pageno,
      limit: limit,
      isDelete: false,
      isCharityVerified: true,
    };

    Services.getCharity(queryVar)
      .then((response) => {
        console.log(response,"response chariy")
        // return false;
        if (response.data.status === 200) {
          if (response.data.data.charity_count.length !== 0) {
            var data = response.data.data.charity_count;
            data.map((data1) => {
              CharityDataArray.push(data1);

            });
            if (response.data.data.state) {
              pageno=response.data.data.state.page + 1;
              limit=response.data.data.state.data_count
            }
          }
          setCharityCategory(CharityDataArray);
        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  //image onchange 



  const handleImageChangeBanner = (e) => {
    e.preventDefault();
    err.banner = e.target.value.length > 0 ? "" : "Select banner image";

    setFile(URL.createObjectURL(e.target.files[0]));
    let charImage = e.target.files[0];
    setBannerImg(charImage);
  };

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs])
    setImagesPrev([...s]);

  }

  const handleImageChangeorg = (e) => {
    e.preventDefault();
    err.image_video = e.target.value.length > 0 ? "" : "Select image or video";
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);

    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];

    })
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    console.log(imgArray, "imgArraydatatata");
    imgArray.forEach(function (f, index) {
      console.log(f, "f");
      // fileArray.push(f);
      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split('.').pop();
      var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
      var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
      imageExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'image' });
      videoExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'video' });


    })

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);

  };

  const handleOnchangeRole = (e) => {
    if (e.target.value === "") {
      err.role_other = "Enter your role"
    } else {
      err.role_other = ""
    }
    seterr({ ...err })
    setFormFields({ ...formFields, role: e.target.value })
  }

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "title":
        err.title = value.length > 0 ? "" : "Enter charity name";
        break;
      case "category":
        err.category = value.length > 0 ? "" : "Enter category";
        break;
      case "role":
        err.role = value.length > 0 ? "" : "Enter role";
        if (value === "other") {
          setSelectStatus(true);
        } else {
          setSelectStatus(false);
        }
        break;

      case "webAddress":
        // err.webAddress = value.length > 0 ? "" : "Enter your website address";
        err.webAddress = !new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(value)
          ? "Enter a valid website url"
          : "";
        break;
      case "mediaLink":
        err.mediaLink = value.length > 0 &&
          !new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(value) ? "Enter a valid media link"
          : "";
        break;
      case "organisationEmail":
        err.organisationEmail = value.length > 0 ? "" : "Enter organization email";

        err.organisationEmail = !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
          ? "Enter a valid email address"
          : "";
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (formFields.title === "") {
      err.title = "Enter gift partner name";
    } else {
      err.title = "";
    }
    if (formFields.category === "") {
      err.category = "Enter category";
    } else {
      err.category = "";
    }
    if (finalImages.length === 0) {
      err.image_video = "Select image or video";
    } else {
      err.image_video = "";
    }
    if (bannerImg === "") {
      err.banner = "Select banner image";
    } else {
      err.banner = "";
    }

    if (formFields.webAddress === "") {

      err.webAddress = "Enter your website address";
    }
    else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.webAddress)) {

      err.webAddress = "Enter a valid website url";
    }
    else {
      err.webAddress = "";
    }


    // if (formFields.mediaLink === "") {

    //   err.mediaLink = "Enter your media link";
    // }
    // else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.mediaLink)) {

    //   err.mediaLink = "Enter a valid media link";
    // }
    // else {
    //   err.mediaLink = "";
    // }
    if (formFields.organisationEmail === "") {
      err.organisationEmail = "Enter organisation email"

    } else {
      err.organisationEmail = "";
    }

    if (formFields.mediaLink !== "") {
      if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(formFields.mediaLink)) {

        err.mediaLink = "Enter a valid media link";
      }
      else {
        err.mediaLink = "";
      }
    }


    seterr({ ...err });
    console.log(err, "err");

    if (
      err.title === "" &&
      err.banner === "" &&
      err.image_video === "" &&
      err.category === "" &&
      err.webAddress === "" &&
      err.organisationEmail === ""
    ) {
      $(".loader-main").show();
      var imgbanner = "";
      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {

            if (response.status === 200) {

              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              if (bannerImg) {
                let formData = new FormData();
                formData.append("image", bannerImg);
                //banner image upload
                Services.charimageGet(formData)
                  .then((response) => {
                    if (response.status === 200) {
                      formFields.banner = response.data.data.images[0].location;
                      // setBnImg(`${response.data.data.images[0].location}`);
                      imgbanner = response.data.data.images[0].location;
                      AddGiftPartnerData(arrayImages, imgbanner);


                    }
                  })
                  .catch(function (err) {
                    console.log(err, "err");
                  });
              }
            }

          })
          .catch(function (err) {
            console.log(err, "err");

          });
      }
      function AddGiftPartnerData(arrayImages, bnImg) {

        var addGiftPartner = {
          title: formFields.title,
          banner: bnImg,
          image_video: arrayImages,
          webURL: formFields.mediaLink,
          webAddress: formFields.webAddress,
          location: formFields.location,
          categoryId: formFields.category,
          role: formFields.role,
          organisationEmail: formFields.organisationEmail,
          charityId:formFields.charity,
          latitude:showLatitude,
          longitude:showLongitude,

        };
        console.log(addGiftPartner, "addGiftPartner");
        Services.addGiftPartner(addGiftPartner)
          .then((response) => {
            $(".loader-main").hide();

            if (response.data.status == 200) {

              swal("Success", response.data.message, "success");
              $("#add-giftpartner")[0].reset();
              $('#b-img').remove();
              $('#o-img').remove();
              $('.uploded-video').remove();
              $('.multi-images').remove();
              $('.multi-video').remove();
              history('/gift-partner');


            } else {
              swal("Failed", response.data.message, "error");
            }
          })
          .catch((err) => {

            swal("Failed", err.response.data.message, "error");
          });


      }
    }

  };

  return (
    <>
      <main class="page-content bg-light">
        <Header title1="Add Gift Partner" title="Gift Partner List" main_title="Gift Partner" title_link="gift-partner" />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Add Gift Partner</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-4">
                  <form id="add-giftpartner" onSubmit={handleOnSubmit}>
                    <div class="mb-4 col-md-7">
                      <PlacesAutocomplete
                        value={formFields.location}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <i class="fi fi-rr-search cstm-search-ro"></i>
                            <input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'cstm-input-seacrh location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {err.location !== "" && (
                        <span style={{ color: "red" }}>
                          {err.location}
                        </span>
                      )
                      }
                    </div>

                    <div class="mb-4">
                      <label class="cstm-label">Upload Logo</label><br />

                      <label for="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                      {/* <div id="login-loader" className='loader-main uploaded-banner' style={{ display: 'none' }}>
                                                            <img src={loader} />
                                                        </div> */}
                      {file && file.length > 0 && <img
                        id="b-img"
                        className="AddOrganization-bannerImg"
                        src={file}
                      />}


                      <input
                        accept="image/*"
                        type="file"
                        name="banner"
                        id="banner-image"
                        className='cstm-uploadHide'
                        // style={{ opacity: 0, cursor: 'pointer' }}
                        onChange={handleImageChangeBanner}
                      />
                      {/* {file && <img id="b-img" src={file} className="AddOrganization-bannerImg" />} */}
                      {err.banner !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {err.banner}
                        </span>
                      )
                      }

                    </div>

                    <div className="upload__img-wrap"></div>

                    <div class="mb-4">
                      <label class="cstm-label">Upload Image Or Video</label><br />
                      {/* {filevideo && <img id="o-img" src={filevideo} width="112px" height="112px" />} */}

                      <div className='cstm-video-image-upload'>
                        <label for="o-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                        <div className='cstm-vi-in'>
                          {imagesPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<>
                                <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx"> <img class="multi-images" controls src={data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImages(index)}>
                                    ✖
                                  </button>
                                </div>
                              </>
                              ) : (
                                <>  <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx"> <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImages(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )
                          )}
                        </div>


                        <input
                          // accept=".mov,.mp4"
                          multiple
                          type="file"
                          name="image_video"
                          id="o-image"
                          className='cstm-uploadHide'
                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeorg}
                        />
                      </div>

                      {err.image_video !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {err.image_video}
                        </span>
                      )
                      }

                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Gift Partner Name</label>
                      <input
                        name="title"
                        id="char-name"
                        type="text"
                        class="cstm-input"
                        placeholder="Gift Partner Name"
                        onChange={handleOnchange}
                      />
                      {err.title !== "" && (
                        <span style={{ color: "red" }}>{err.title}</span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Organisation Email</label>
                      <input
                        name="organisationEmail"
                        id="organisationEmail"
                        type="email"
                        class="cstm-input"
                        placeholder="Enter Your Organization Email"
                        onChange={handleOnchange}
                      />
                      {err.organisationEmail !== "" && (
                        <span style={{ color: "red" }}>
                          {err.organisationEmail}
                        </span>
                      )
                      }
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Category</label>
                      <select
                        name="category"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchange}
                      >
                        <option value="" selected>
                          Category
                        </option>

                        {giftPartnerCategory.map((cat) => {
                          return <option value={cat._id}>{cat.type}</option>;
                          // console.log(cat.title,"single cat")
                        })}
                      </select>

                      {err.category !== "" && (
                        <span style={{ color: "red" }}>{err.category}</span>
                      )}
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Role</label>
                      <select
                        name="role"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchange}
                      >
                        <option value="principle">Principle</option>
                        <option value="manager">Manager</option>
                        <option value="other">Other</option>
                      </select>
                      {err.role !== "" && (
                        <span style={{ color: "red" }}>{err.role}</span>
                      )}
                    </div>

                    {selectStatus &&
                      <div class="mb-4 col-md-7">
                        <label class="cstm-label">Enter Other Role</label>
                        <input type="text" class="cstm-input" name="role_other" onChange={handleOnchangeRole} />
                        {err.role_other !== "" && (
                          <span style={{ color: "red" }}>{err.role_other}</span>
                        )}
                      </div>
                    }


                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Website Address</label>
                      <input
                        name="webAddress"
                        id="webAddress"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        onChange={handleOnchange}
                      />
                      {err.webAddress !== "" && (
                        <span style={{ color: "red" }}>
                          {err.webAddress}
                        </span>
                      )
                      }
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Media Link</label>
                      <input
                        name="mediaLink"
                        id="mediaLink"
                        type="text"
                        class="cstm-input"
                        placeholder="Media Link"
                        onChange={handleOnchange}
                      />
                      {err.mediaLink !== "" && (
                        <span style={{ color: "red" }}>
                          {err.mediaLink}
                        </span>
                      )
                      }
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Support Charity(Optional)</label>
                      <select
                        name="charity"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchange}
                      >
                        <option value="" selected>
                          Select Charity
                        </option>

                        {giftCharity.map((cat) => {
                          return <option value={cat._id}>{cat.title}</option>;
                          // console.log(cat.title,"single cat")
                        })}
                      </select>
                    </div>

                    <div className="submit-btnMain cstm-loader">
                      <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Add Gift Partner </button>
                      <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                        <img src={loader} />
                      </div>
                    </div>
                  </form>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}

export default AddGiftPartner

