import baseApi from "./base-api";

function getPartiallyRegisteredUsers({ search, pageNumber, pageSize, signupStatuses } = {}) {
    return baseApi.getWithParams("users/partially-registered", {
        search: search,
        page: pageNumber,
        limit: pageSize,
        signupStatuses: signupStatuses
    });
}

function getUserSignupRequests(userId){
    return baseApi.get(`users/partially-registered/signup-request/${userId}`);
}

function getNotesByPhoneNumber(countryCode, phoneNumber) {
    return baseApi.get(`users/partially-registered/note/${countryCode}/${phoneNumber}`);
}

function getTempUserDataByPhoneNumber(countryCode, phoneNumber) {
    return baseApi.get(`temp-users/${countryCode}/${phoneNumber}`);  
}

function addNotesByPhoneNumber(countryCode, phoneNumber, note) {
    const data = {
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        note: note,
    };
    return baseApi.post(`users/partially-registered/note`, data);
}

/**
 * @param {string} userId
 */
function deletePartiallyRegisteredUser(userId) {
    return baseApi.delete(`users/partially-registered/${userId}`);
}

/**
 * @param {Object} params
 * @param {string} params.search
 */
function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("users/partially-registered/download-csv", params);
}

const partiallyRegisteredUserService = {
    getPartiallyRegisteredUsers: getPartiallyRegisteredUsers,
    getTempUserDataByPhoneNumber: getTempUserDataByPhoneNumber,
    getNotesByPhoneNumber: getNotesByPhoneNumber,
    addNotesByPhoneNumber: addNotesByPhoneNumber,
    deletePartiallyRegisteredUser: deletePartiallyRegisteredUser,
    downloadAsCsv: downloadAsCsv,
    getUserSignupRequests: getUserSignupRequests
};

export default partiallyRegisteredUserService;
