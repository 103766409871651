/** @format */

import axios from "axios";
import authHeader from "./auth.header";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080/";

const adminLogin = (adminLogin) => {
  return axios.post(API_URL + "admin/login", adminLogin);
};

const resetpassword = (resetpassword) => {
  return axios.post(API_URL + "admin/change_password", resetpassword, {
    headers: authHeader(),
  });
};

const getAdminprofile = () => {
  return axios.get(API_URL + "admin/get_profile", { headers: authHeader() });
};

const editAdminProfile = (editAdminProfile) => {
  return axios.put(API_URL + "admin/update_profile", editAdminProfile, {
    headers: authHeader(),
  });
};
const imageGet = (imageGet) => {
  return axios.post(API_URL + "upload/image/profile", imageGet, {
    headers: { user_id: localStorage.getItem("user_id") },
  });
};
const getAdminUser = (getAdminUser) => {
  return axios.post(API_URL + "admin/get", getAdminUser, {
    headers: authHeader(),
  });
};
// const searchAdminUser = (searchAdminUser) => {
//   return axios.post(API_URL + "user/get_filter_user" , searchAdminUser , {headers: authHeader()})
// }
const getOrganization = (getOrganization) => {
  return axios.post(API_URL + "admin/organization/get", getOrganization, {
    headers: authHeader(),
  });
};
const getUnregisteredOrganization = (getOrganization) => {
  return axios.post(
    API_URL + "admin/organization/unregistered/get",
    getOrganization,
    { headers: authHeader() }
  );
};
const getUnregisteredOrganizationUsers = (getOrganization, orgId) => {
  return axios.post(
    API_URL + "admin/organization/unregistered/get/users/" + orgId,
    getOrganization,
    { headers: authHeader() }
  );
};

const deleteOrganization = (deleteOrganization) => {
  return axios.delete(API_URL + "admin/organization/" + deleteOrganization, {
    headers: authHeader(),
  });
};
const orgVerify = (org_id) => {
  return axios.get(API_URL + "admin/organization/verify/" + org_id, {
    headers: authHeader(),
  });
};
const addOrganization = (addOrganization) => {
  return axios.post(API_URL + "admin/organization", addOrganization, {
    headers: authHeader(),
  });
};

const getGiftList = (giftData) => {
  return axios.post(API_URL + "admin/gift/list", giftData, {
    headers: authHeader(),
  });
};

const getThankYouList = (thankyouData) => {
  return axios.post(API_URL + "admin/thank-you/list", thankyouData, {
    headers: authHeader(),
  });
};
const getOrganizationById = (getOrganizationById, data) => {
  return axios.post(
    API_URL + "admin/organization/" + getOrganizationById,
    data,
    { headers: authHeader() }
  );
};
const editOrganization = (editOrganization) => {
  return axios.put(API_URL + "admin/organization", editOrganization, {
    headers: authHeader(),
  });
};

//gift partner
const getGiftPartner = (getGiftPartner) => {
  return axios.post(API_URL + "admin/gift_partner/get", getGiftPartner, {
    headers: authHeader(),
  });
};
const deleteGiftPartner = (deleteGiftPartner) => {
  return axios.delete(API_URL + "admin/gift_partner/" + deleteGiftPartner, {
    headers: authHeader(),
  });
};
const addGiftPartner = (addGiftPartner) => {
  return axios.post(API_URL + "admin/gift_partner", addGiftPartner, {
    headers: authHeader(),
  });
};
const getGiftPartnerById = (getGiftPartnerById) => {
  return axios.get(API_URL + "admin/gift_partner/" + getGiftPartnerById, {
    headers: authHeader(),
  });
};
const editGiftPartner = (editGiftPartner) => {
  return axios.put(API_URL + "admin/gift_partner", editGiftPartner, {
    headers: authHeader(),
  });
};

//charity list
const getCharity = (getCharity) => {
  return axios.post(API_URL + "admin/charity/get", getCharity, {
    headers: authHeader(),
  });
};
const deleteCharity = (deleteCharity) => {
  return axios.delete(API_URL + "admin/charity/" + deleteCharity, {
    headers: authHeader(),
  });
};
const addCharity = (addCharity) => {
  return axios.post(API_URL + "admin/charity", addCharity, {
    headers: authHeader(),
  });
};
const getCharityById = (getCharityById) => {
  return axios.get(API_URL + "admin/charity/" + getCharityById, {
    headers: authHeader(),
  });
};
const editCharity = (editCharity) => {
  return axios.put(API_URL + "admin/charity", editCharity, {
    headers: authHeader(),
  });
};
//charity category
const getCharityCat = (getCharityCat) => {
  return axios.post(API_URL + "admin/category/get", getCharityCat, {
    headers: authHeader(),
  });
};
const deleteCharityCategory = (deleteCharityCategory) => {
  return axios.delete(API_URL + "admin/category/" + deleteCharityCategory, {
    headers: authHeader(),
  });
};
const addCharityCat = (addCharityCat) => {
  return axios.post(API_URL + "admin/category", addCharityCat, {
    headers: authHeader(),
  });
};
const editCharityCat = (editCharityCat) => {
  return axios.put(API_URL + "admin/category", editCharityCat, {
    headers: authHeader(),
  });
};
const getChatCategoryById = (getChatCategoryById) => {
  return axios.get(API_URL + "admin/category/" + getChatCategoryById, {
    headers: authHeader(),
  });
};
const charVerify = (char_id) => {
  return axios.get(API_URL + "admin/charity/verify/" + char_id, {
    headers: authHeader(),
  });
};
//event list

const getEvent = (getEvent) => {
  return axios.post(API_URL + "admin/get_event", getEvent, {
    headers: authHeader(),
  });
};
const deleteEvent = (deleteEvent) => {
  return axios.delete(API_URL + "admin/event/" + deleteEvent, {
    headers: authHeader(),
  });
};
const addEvent = (addEvent) => {
  return axios.post(API_URL + "admin/event", addEvent, {
    headers: authHeader(),
  });
};
const editEvent = (editEvent) => {
  return axios.put(API_URL + "admin/event", editEvent, {
    headers: authHeader(),
  });
};
const getEventById = (getEventById) => {
  return axios.get(API_URL + "admin/event/" + getEventById, {
    headers: authHeader(),
  });
};

//end event list api

//end event category

// Location Greeting
const addLocationGreeting = (addLocationGreeting) => {
  return axios.post(API_URL + "admin/welcome", addLocationGreeting, {
    headers: authHeader(),
  });
};

const getLocationGreeting = (getLocationGreeting) => {
  return axios.post(API_URL + "admin/get_welcome", getLocationGreeting, {
    headers: authHeader(),
  });
};

const deleteLocationGreeting = (id) => {
  return axios.delete(API_URL + "admin/welcome/" + id, {
    headers: authHeader(),
  });
};

const editLocationGreeting = (editLocationGreeting) => {
  return axios.put(API_URL + "admin/welcome", editLocationGreeting, {
    headers: authHeader(),
  });
};
const searchLocationGreeting = (searchLocationGreeting) => {
  console.log(searchLocationGreeting, "searchLocationGreeting");
  return axios.post(
    API_URL + "admin/get_welcome_filter",
    searchLocationGreeting,
    { headers: authHeader() }
  );
};

//Announcement - Quicktip
const getQuicktip = (getQuicktip) => {
  return axios.post(API_URL + "admin/quick_tip/get", getQuicktip, {
    headers: authHeader(),
  });
};

const deleteQuicktip = (id) => {
  return axios.delete(API_URL + "admin/quick_tip/" + id, {
    headers: authHeader(),
  });
};

const getQuicktipById = (id1) => {
  return axios.get(API_URL + "admin/quick_tip/" + id1, {
    headers: authHeader(),
  });
};

const editQuicktip = (editQuicktip) => {
  return axios.put(API_URL + "admin/quick_tip", editQuicktip, {
    headers: authHeader(),
  });
};

//TC
const getTermsCondition = (getTermsCondition) => {
  return axios.get(API_URL + "admin/get_term_condition", {
    headers: authHeader(),
  });
};

const updateTermsCondition = (updateTermsCondition) => {
  return axios.put(API_URL + "admin/term_condition", updateTermsCondition, {
    headers: authHeader(),
  });
};

//team suggestion
const getTeamSuggestion = () => {
  return axios.get(API_URL + "admin/team_suggestion/get_all", {
    headers: authHeader(),
  });
};
const deleteTeamSuggestion = (deleteTeamSuggestion) => {
  return axios.delete(
    API_URL + "admin/team_suggestion/" + deleteTeamSuggestion,
    { headers: authHeader() }
  );
};
const addTeamSuggestion = (addTeamSuggestion) => {
  return axios.post(API_URL + "admin/team_suggestion", addTeamSuggestion, {
    headers: authHeader(),
  });
};
const getTeamSuggestionById = (getTeamSuggestionById) => {
  return axios.post(
    API_URL + "admin/team_suggestion/" + getTeamSuggestionById,
    "",
    { headers: authHeader() }
  );
};
const editTeamSuggestion = (editTeamSuggestion) => {
  return axios.put(API_URL + "admin/team_suggestion", editTeamSuggestion, {
    headers: authHeader(),
  });
};

//team
const getTeam = () => {
  return axios.get(API_URL + "admin/team/get_all", { headers: authHeader() });
};
const deleteTeam = (deleteTeam) => {
  return axios.delete(API_URL + "admin/team/" + deleteTeam, {
    headers: authHeader(),
  });
};
const addTeam = (addTeam) => {
  return axios.post(API_URL + "admin/team", addTeam, { headers: authHeader() });
};
const getTeamById = (getTeamById) => {
  return axios.post(API_URL + "admin/team/" + getTeamById, "", {
    headers: authHeader(),
  });
};
const editTeam = (editTeam) => {
  return axios.put(API_URL + "admin/team", editTeam, { headers: authHeader() });
};

const getTeamByOrganizationId = (data) => {
  return axios.post(API_URL + "admin/get_team", data, { headers: authHeader() })
}

const getTeamJoinRequests = (data) => {
  return axios.post(API_URL + "admin/team_member/get/pending-request", data, { headers: authHeader() })
}

const addUserToTeam = (data) => {
  let headerData = { userType: 1, ...authHeader() }
  return axios.post(API_URL + "admin/team_member/accept-join-request", data, { headers:  headerData })  
}

//news and story 
const getNews = (getNews) => {
  return axios.post(API_URL + "admin/news_story/get", getNews, {
    headers: authHeader(),
  });
};
const deleteNews = (deleteNews) => {
  return axios.delete(API_URL + "admin/news_story/" + deleteNews, {
    headers: authHeader(),
  });
};
const addNews = (addNews) => {
  return axios.post(API_URL + "admin/news_story", addNews, {
    headers: authHeader(),
  });
};
const getNewsById = (getNewsById) => {
  return axios.get(API_URL + "admin/news_story/" + getNewsById, {
    headers: authHeader(),
  });
};
const editNews = (editNews) => {
  return axios.put(API_URL + "admin/news_story", editNews, {
    headers: authHeader(),
  });
};

//gift
const getGift = (getGift) => {
  return axios.post(API_URL + "admin/gift_card/get", getGift, {
    headers: authHeader(),
  });
};
const deleteGift = (deleteGift) => {
  return axios.delete(API_URL + "admin/gift_card/" + deleteGift, {
    headers: authHeader(),
  });
};
const addGift = (addGift) => {
  return axios.post(API_URL + "admin/gift_card", addGift, {
    headers: authHeader(),
  });
};
const getGiftById = (getGiftById) => {
  return axios.get(API_URL + "admin/gift_card/" + getGiftById, {
    headers: authHeader(),
  });
};
const editGift = (editGift) => {
  return axios.put(API_URL + "admin/gift_card", editGift, {
    headers: authHeader(),
  });
};

//event category
const getEventCat = (getEventCat) => {
  return axios.post(API_URL + "admin/eventCategory/get", getEventCat, {
    headers: authHeader(),
  });
};
const deleteEventCat = (deleteEventCat) => {
  return axios.delete(API_URL + "admin/eventCategory/" + deleteEventCat, {
    headers: authHeader(),
  });
};
const addEventCat = (addEventCat) => {
  return axios.post(API_URL + "admin/eventCategory", addEventCat, {
    headers: authHeader(),
  });
};
const getEventCatById = (getEventCatById) => {
  return axios.get(API_URL + "admin/eventCategory/" + getEventCatById, {
    headers: authHeader(),
  });
};
const editEventCat = (editEventCat) => {
  return axios.put(API_URL + "admin/eventCategory", editEventCat, {
    headers: authHeader(),
  });
};

//faq
const getFaq = (getFaq) => {
  return axios.post(API_URL + "admin/get_faqs", getFaq, {
    headers: authHeader(),
  });
};

const addFaq = (addFaq) => {
  return axios.post(API_URL + "admin/faqs", addFaq, { headers: authHeader() });
};

const deleteFaq = (deleteFaq) => {
  return axios.delete(API_URL + "admin/faqs/" + deleteFaq, {
    headers: authHeader(),
  });
};

const editFaq = (editFaq) => {
  return axios.put(API_URL + "admin/faqs/", editFaq, { headers: authHeader() });
};

//image
const orgimageGet = (orgimageGet) => {
  return axios.post(API_URL + "upload/image/organization", orgimageGet, {
    headers: { user_id: localStorage.getItem("user_id") },
  });
};
const charimageGet = (charimageGet) => {
  return axios.post(API_URL + "upload/image/category", charimageGet, {
    headers: { user_id: localStorage.getItem("user_id") },
  });
};

const getUserProfile = (getProfileHeader) => {
  return axios.get(API_URL + "user", { headers: getProfileHeader });
};

const userResetPassword = (userResetPassword, tokenData) => {
  return axios.post(API_URL + "user/reset_password", userResetPassword, {
    headers: tokenData,
  });
};

//Get Admin User
const getSubAdminUser = (getSubAdminUser) => {
  return axios.post(API_URL + "admin/get_sub_admin", getSubAdminUser, {
    headers: authHeader(),
  });
};

const getSubAdminUserByID = (getSubAdminUserByID) => {
  return axios.get(API_URL + "admin/get_sub_admin/" + getSubAdminUserByID, {
    headers: authHeader(),
  });
};

const addSubAdminUser = (addSubAdminUser) => {
  return axios.post(API_URL + "admin/create_sub_admin", addSubAdminUser, {
    headers: authHeader(),
  });
};

const editSubAdminUser = (editSubAdminUser) => {
  return axios.put(API_URL + "admin/subadmin_update", editSubAdminUser, {
    headers: authHeader(),
  });
};

const deleteSubAdminUser = (deleteSubAdminUser) => {
  return axios.delete(API_URL + "admin/delete_subadmin/" + deleteSubAdminUser, {
    headers: authHeader(),
  });
};

//AcceptableUserPolicy
const getAcceptableUserPolicy = (getAcceptableUserPolicy) => {
  return axios.get(API_URL + "admin/get_acceptable_use_policy", {
    headers: authHeader(),
  });
};

const updateAcceptableUserPolicy = (updateAcceptableUserPolicy) => {
  return axios.put(
    API_URL + "admin/acceptable_use_policy",
    updateAcceptableUserPolicy,
    { headers: authHeader() }
  );
};

//Privacy policy
const getPrivacyPolicy = (getPrivacyPolicy) => {
  return axios.get(API_URL + "admin/get_privacy_policy", {
    headers: authHeader(),
  });
};

const updatePrivacyPolicy = (updatePrivacyPolicy) => {
  return axios.put(API_URL + "admin/privacy_policy", updatePrivacyPolicy, {
    headers: authHeader(),
  });
};

//Privacy setting
const getPrivacySetting = () => {
  return axios.get(API_URL + "admin/get_privacy_setting", {
    headers: authHeader(),
  });
};

const updatePrivacySetting = (updatePrivacySetting) => {
  return axios.put(API_URL + "admin/privacy_setting", updatePrivacySetting, {
    headers: authHeader(),
  });
};

//community moderation

const getCommunityModeration = (getCommunityModeration) => {
  return axios.post(
    API_URL + "admin/community_moderation/get",
    getCommunityModeration,
    { headers: authHeader() }
  );
};

const getCommunityModerationByID = (getCommunityModerationByID) => {
  return axios.get(
    API_URL + "admin/community_moderation/" + getCommunityModerationByID,
    { headers: authHeader() }
  );
};

const BlockUnblockCommu = (BlockUnblockCommu) => {
  return axios.post(
    API_URL + "admin/community_moderation/block_unblock",
    BlockUnblockCommu,
    { headers: authHeader() }
  );
};

const reorderGifting = (reorderGifting) => {
  return axios.post(API_URL + "admin/gift_card_reorder", reorderGifting, { headers: authHeader() })
}
const generateQrCodeAtAdmin = (requestParams) => {
  return axios.post(API_URL + "admin/generateQrCodeAtAdmin", requestParams, {
    headers: authHeader(),
  });
};

const countryDataList = () => {
  return axios.get(API_URL + "admin/getCountryList", { headers: authHeader() });
};
const generateQrCodeForDownloadAppWebPage = ()=>{
  return axios.get(API_URL+ "admin/generateQrCodeForDownloadAppWebPage",{
      headers: authHeader(),
  })
}




const ordersListing = (requestParams) =>{
  return axios.post(API_URL+"admin/getOrdersList", requestParams, {headers:authHeader()})
}

export default {
  adminLogin,
  reorderGifting,
  resetpassword,
  editAdminProfile,
  getAdminprofile,
  imageGet,
  getAdminUser,
  // searchAdminUser,
  editCharity,
  getOrganization,
  getUnregisteredOrganization,
  getUnregisteredOrganizationUsers,
  getOrganizationById,
  editOrganization,
  deleteOrganization,
  orgVerify,
  addOrganization,

  getCharity,
  deleteCharity,
  getCharityCat,
  deleteCharityCategory,
  addCharityCat,
  editCharityCat,
  getChatCategoryById,
  addCharity,
  getCharityById,
  charVerify,
  //-------------

  // gift partner

  getGiftPartner,
  deleteGiftPartner,
  addGiftPartner,
  getGiftPartnerById,
  editGiftPartner,

  // event listing
  getEvent,
  deleteEvent,
  addEvent,
  editEvent,
  getEventById,

  //event category
  getEventCat,
  deleteEventCat,
  addEventCat,
  getEventCatById,
  editEventCat,

  //location gritting
  addLocationGreeting,
  getLocationGreeting,
  deleteLocationGreeting,
  editLocationGreeting,
  searchLocationGreeting,
  getTermsCondition,
  updateTermsCondition,

  //Quicktip
  getQuicktip,
  deleteQuicktip,
  getQuicktipById,
  editQuicktip,
  //team suggetion
  getTeamSuggestion,
  deleteTeamSuggestion,
  addTeamSuggestion,
  editTeamSuggestion,
  getTeamSuggestionById,
  //team

  getTeam,
  deleteTeam,
  addTeam,
  editTeam,
  getTeamById,
  //news and story

  getNews,
  deleteNews,
  addNews,
  getNewsById,
  editNews,

  //gift

  getGift,
  deleteGift,
  addGift,
  getGiftById,
  editGift,
  countryDataList,

  orgimageGet,
  charimageGet,
  //faq
  getFaq,
  addFaq,
  deleteFaq,
  editFaq,

  getUserProfile,
  userResetPassword,

  getSubAdminUser,
  getSubAdminUserByID,
  addSubAdminUser,
  deleteSubAdminUser,
  editSubAdminUser,
  getAcceptableUserPolicy,
  updateAcceptableUserPolicy,
  getPrivacyPolicy,
  updatePrivacyPolicy,
  getPrivacySetting,
  updatePrivacySetting,

  //comunity moderation
  getCommunityModeration,
  getCommunityModerationByID,
  BlockUnblockCommu,
  
  //added by Rajkumari
  generateQrCodeAtAdmin,
generateQrCodeForDownloadAppWebPage,

  getTeamByOrganizationId,
  getTeamJoinRequests,
  addUserToTeam,

  //orders
  ordersListing,
  getThankYouList,
  getGiftList
};
