import { useEffect, useState } from 'react';
import { MultiSelectDropdown } from '../../shared/components/MultiSelectDropdown/MultiSelectDropdown';

const SignupStatuses = [{
    label: "Not Requested For Approval",
    id: "NotRequestedForApproval"
},
{
    label: "Requested For Approval",
    id: "RequestedForApproval"
},
{
    label: "Approved",
    id: "Approved"
},
{
    label: "Declined",
    id: "Declined"
}];

export function PartialRegisteredUserListFilters({ filters, onApplyFilters }) {
    const [selectedSignupStatuses, setSignupStatuses] = useState(filters.signupStatuses);

    function onApplyClick() {
        onApplyFilters({
            ...filters,
            signupStatuses: selectedSignupStatuses
        });
    }

    return (
        <div style={{height: '400px', width: '400px'}} className="mb-2">
            <MultiSelectDropdown
                label="Status"
                items={SignupStatuses}
                selectedIds={selectedSignupStatuses}
                onChange={(selectedStatuses) => {
                    setSignupStatuses(selectedStatuses);
                }}
            />

            <div className="d-flex justify-content-end mt-2">
                <button type='button' className='cstm-submitbutton' onClick={onApplyClick}>Apply</button>
            </div>
        </div>

    );
}