export const HelperInfoCode = Object.freeze({
    DASHBOARD_GLOBAL_STATS_USER_COUNT: "DASHBOARD_GLOBAL_STATS_USER_COUNT",
    DASHBOARD_GLOBAL_STATS_ORGANIZATION_COUNT: "DASHBOARD_GLOBAL_STATS_ORGANIZATION_COUNT",
    DASHBOARD_GLOBAL_STATS_CHARITY_COUNT: "DASHBOARD_GLOBAL_STATS_CHARITY_COUNT",
    DASHBOARD_GLOBAL_STATS_REVENUE: "DASHBOARD_GLOBAL_STATS_REVENUE",
    DASHBOARD_STATS_USERS: "DASHBOARD_STATS_USERS",
    DASHBOARD_STATS_TEAMS: "DASHBOARD_STATS_TEAMS",
    DASHBOARD_STATS_INTERACTIONS: "DASHBOARD_STATS_INTERACTIONS",
    DASHBOARD_STATS_THANKYOUS: "DASHBOARD_STATS_THANKYOUS",
    DASHBOARD_STATS_GIFTS: "DASHBOARD_STATS_GIFTS",
    DASHBOARD_STATS_REPLIES: "DASHBOARD_STATS_REPLIES",
    DASHBOARD_STATS_SHARED_MESSAGES: "DASHBOARD_STATS_SHARED_MESSAGES",
    DASHBOARD_ENGAGEMENT_STATS_STAFF_ENGAGEMENT: "DASHBOARD_ENGAGEMENT_STATS_STAFF_ENGAGEMENT",
    DASHBOARD_ENGAGEMENT_STATS_PATIENT_ENGAGEMENT: "DASHBOARD_ENGAGEMENT_STATS_PATIENT_ENGAGEMENT",
});
