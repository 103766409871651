export const QUERY_KEYS = Object.freeze({
    ORGANIZATIONS: 'organizations',
    ORGANIZATION_BY_ID: 'organization-by-id',
    TEAMS: 'teams',
    USERS: 'users',
    USER_BY_ID: 'user-by-id',
    THANKYOUS: 'thank-yous',
    THANKYOU_BY_ID: 'thank-you-by-id',
    GIFTS: 'gifts',
    GIFT_BY_ID: 'gift-by-id',
    PROFILE: 'profile',
    WEBADMIN_CONTENT_HELPER_INFOS: 'webadmin-content-helper-infos',
    GLOBAL_STATS: 'global-stats',
    APPRECIATION_STATS: 'appreciation-stats',
    ENGAGEMENT_STATS: 'engagement-stats',
});
