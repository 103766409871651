import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState,ContentState,convertToRaw} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { convertToHTML } from 'draft-convert';
import swal from "sweetalert";

const TermsCondition = () =>{
  const [content,setContent] = useState(EditorState.createEmpty());
  const [contenthtml,setContenthtml] = useState();
  const [termsId,setTermsId] = useState();
  useEffect(()=>{
    var bodyData ={
      "page":1,
      "limit":10
    }
    Services.getTermsCondition(bodyData).then((response)=>{
      console.log(response,'response');
      if(response.status === 200){
        //setContent(response.data.data.message_data.description);
        setTermsId(response.data.data.message_data[0]._id);
        if(response.data.data.description !== null){
          const contentBlock = htmlToDraft(response.data.data.message_data[0].description);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setContent(editorState);
        }
      }
    }).
    catch((err)=>{
      console.log(err,'err');
    })
  },[])

  const handleOnSubmitUpdate = (e) =>{
  e.preventDefault();
    var bodyData = {
      "id":termsId,
      "description":contenthtml
    }
    Services.updateTermsCondition(bodyData).then((response)=>{
      if (response.data.status === 200) {
        swal("Success", response.data.message, "success");
      } else {
        swal("Failed", response.data.message, "error");
      }
    }).catch((err)=>{
      swal("Failed",err.response.data.message, "error");

      console.log(err,'err');
    })
  }

  const onEditorStateChange = (editorState) => {
    setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setContent(editorState)
  };

  return(
    <>
    <main className="page-content bg-light">
    <Header title="Terms & Condition" main_title="Help Center/Support" />
    <div className="container-fluid">
      <div className="layout-specing">
        <div className="row">
          <div className="col-md-12">
            <div className="card border-0 shadow rounded">
              <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h4 className="mb-0">Terms & Condition</h4>
              </div>
              <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                <form id="" onSubmit={handleOnSubmitUpdate}>
               
                  <Editor
                    editorState={content}
                    onEditorStateChange={onEditorStateChange}    
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      fontFamily: {
                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto','condor', 'Roboto Condensed', 'Robot Condensed Light']
                      },
                      fontSize: {
                        options: [10,12, 14, 16, 18, 20, 24, 30, 36, 48, 60],
                       },
                      // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                    }}

                  />

                  <div className="d-flex">
                    <button className=" cstm-btn" type="submit">
                      Submit
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
    </>
  )
}

export default TermsCondition;