import { PlatformFlow } from "../app.enum";

export class LabelHelper {
  /**
   * @param {string} flow
   * @returns {string}
   */
  static getPlatformFlowLabel(flow) {
    switch (flow) {
      case PlatformFlow.PatientExperience:
        return "Patient Experience";
      case PlatformFlow.StaffExperience:
        return "Staff Experience";
      default:
        return "";
    }
  }
}
