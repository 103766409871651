import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import { LOCAL_STORAGE_KEYS } from '../../common/constants/local-storage.constant';

function SidebarLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    if (!token) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <SideBar />
      <Outlet />
    </>
  );
}

export default SidebarLayout;
