import { axiosBaseApiClient } from "../api/axios-client";

async function apiGet(path) {
    try {
        const response = await axiosBaseApiClient.get(path);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiGetWithParams(path, params) {
    try {
        const response = await axiosBaseApiClient.get(path, {
            params: params,
        });

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

export async function apiPost(path, data) {
    try {
        const response = await axiosBaseApiClient.post(path, data);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiPut(path, data) {
    try {
        const response = await axiosBaseApiClient.put(path, data);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiPatch(path, data) {
    try {
        const response = await axiosBaseApiClient.patch(path, data);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiDelete(path) {
    try {
        const response = await axiosBaseApiClient.delete(path);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiDeleteWithParams(path, params) {
    try {
        const response = await axiosBaseApiClient.delete(path, {
            params: params
        });

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

const baseApi = Object.freeze({
    get: apiGet,
    getWithParams: apiGetWithParams,
    post: apiPost,
    put: apiPut,
    patch: apiPatch,
    delete: apiDelete,
    deleteWithParams: apiDeleteWithParams
});

export default baseApi;
