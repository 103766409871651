import baseApi from "./base-api";

/**
 * @param {Object} params
 * @param {string} params.search - search text
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string[]} params.userIds
 * @param {string} params.giftCardId
 * @param {string} params.processState
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function getGifts(params = {}) {
    return baseApi.getWithParams("gifts", params);
}

/**
 * @param {string} giftId
 */
function getGiftById(giftId) {
    return baseApi.get(`gifts/${giftId}`);
}

/**
 * @param {Object} params
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string} params.giftCardId
 * @param {string} params.processState
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("gifts/download-csv", params);
}

function updateGiftProcessState(giftId, processState, notes) {
    const updateData = {
        processState: processState,
        giftAdminNotes: notes,
    };

    return baseApi.put(`gifts/${giftId}/process-state`, updateData);
}

function resendVoucherEmailApi(giftId, processState, notes, voucherLink, giftAmount) {
    const updateData = {
        processState: processState,
        giftAdminNotes: notes,
        voucherLink: voucherLink.trim(),
        giftAmount: giftAmount
    };

    return baseApi.put(`gifts/${giftId}/voucher/resend`, updateData);
}

function updateGiftVoucher(giftId, processState, notes, voucherLink, giftAmount) {
    const updateData = {
        processState: processState,
        giftAdminNotes: notes,
        voucherLink: voucherLink.trim(),
        giftAmount: giftAmount
    };

    return baseApi.put(`gifts/${giftId}/voucher`, updateData);
}

function getModerationFailedGifts({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("gifts/moderationFailedGift", {
        page: pageNumber,
        limit: pageSize
    });
}

const giftService = {
    getGifts: getGifts,
    getGiftById: getGiftById,
    downloadAsCsv: downloadAsCsv,
    updateGiftProcessState: updateGiftProcessState,
    getModerationFailedGifts: getModerationFailedGifts,
    updateGiftVoucher: updateGiftVoucher,
    resendVoucherEmailApi: resendVoucherEmailApi
    
};

export default giftService;
