import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { useLoaderStore } from '../../../stores/loader.store';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import moderationSettingService from '../../../Services/modertion-settings.service';

const BLACKLIST_WORD_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function UnregisteredUserBlacklistWordsSettings() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [word, setWord] = useState();
    const [words, setWords] = useState();
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        getUnregisteredUserblackListWords();
    }, []);

    async function saveBlackListWord() {
        setShowLoader(true);

        try {
            let data = {
                type: 'UnRegisteredUserByPassTeamLeadNotificationWords',
                word: word
            };

            const res = await moderationSettingService.addBlackListWord(data);

            if (res.status == 200) {
                toast.success("Successfully saved word");
                await getUnregisteredUserblackListWords();
                return;
            }

            toast.error("Couldn't save word");
        }
        catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't save word");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function getUnregisteredUserblackListWords() {
        setShowLoader(true);

        try {
            const res = await moderationSettingService.getBlackListWords('UnRegisteredUserByPassTeamLeadNotificationWords');

            if (res.status === 200) {
                setWords(res.data?.words);
                return;
            }

            toast.error("Couldn't get words");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get words");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleDeleteWord() {
        setShowLoader(true);

        try {
            const word = selectedRow.word;
            const res = await moderationSettingService.deleteBlackListWord('UnRegisteredUserByPassTeamLeadNotificationWords', word);

            if (res.status === 200) {
                let tempWords = words.filter((data => data !== word));
                setWords(tempWords);
                toast.success("Successfully deleted the word");
                return;
            }

            toast.error("Couldn't delete word");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't delete word");
        } finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    return (
        <SidebarLayoutContent headerTitle='Moderation - Unregisterd'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                </div>
                <div className='p-3'>
                    <h4 className="mb-0">'To' Field Automation List</h4>
                </div>
                <div>
                    <div className="row">
                        <div className="col-sm">
                            <input
                                name="word"
                                type="text"
                                className="cstm-input"
                                placeholder="Word"
                                value={word}
                                onChange={(e) => { setWord(e.target.value) }}
                            />
                        </div>
                        <div className="col-sm">
                            <button
                                className="cstm-btn"
                                onClick={(e) => { saveBlackListWord() }}
                            >
                                Add Word
                            </button>

                        </div>
                    </div>
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">No</th>
                                <th className="border-bottom w-4 cstm-userheading">Word</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {words?.map((word, index) => (
                                <tr
                                    key={word._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{index + 1}</td>
                                    <td>{word}</td>
                                    <td>
                                    <div>
                                        <Link
                                            onClick={() => setSelectedRow({ word: word, action: BLACKLIST_WORD_ACTION.DELETE })}
                                            className="cstm-delete mrn-rt"
                                        >
                                            <i className="fi fi-rr-trash"></i>
                                        </Link>
                                    </div>

                                </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {(selectedRow && selectedRow.action === BLACKLIST_WORD_ACTION.DELETE) && (
                <ConfirmationModal
                    text={`Are you sure you want to delete the word ${selectedRow?.word}?`}
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteWord}
                    onCancel={() => setSelectedRow(null)}
                />
            )}
        </SidebarLayoutContent>
    );
}