function getGiftSenderDetails(gift) {
    if (gift.platform === 'mobile' && gift.createdBy) {
        return {
            firstName: gift.createdBy.firstName,
            email: gift.createdBy.email,
            phoneNumber: gift.createdBy.phoneNumber,
        };
    }

    if (gift.platform === 'web' && gift.createdUserData) {
        return {
            firstName: gift.createdUserData.firstName,
            email: gift.createdUserData.email,
            phoneNumber: gift.createdUserData.phoneNumber,
        };
    }
}

const giftUtils = {
    getGiftSenderDetails: getGiftSenderDetails,
};

export default giftUtils;
