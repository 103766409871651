import React, { useEffect,useState } from 'react'
import PropTypes from 'prop-types'
import loader from '../../../assets/Images/loder.gif'
import * as Yup from "yup"; // used when validating with a pre-built solution
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Services from '../../../Services/auth.service';
import swal from 'sweetalert';

function ResetPassword() {

   const [UserData,setUserdata] = useState([]);
   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);

   const token = urlParams.get('token')

   useEffect(()=>{
   
      var tokenData = { Authorization: token}
      Services.getUserProfile(tokenData).then((response)=>{
         console.log(response,'resssss');
         if(response.data.status === 200){
            setUserdata(response.data.data);
         }
      })
   },[])

   const validationSchema = Yup.object().shape({
      new_pwd: Yup.string().required('Enter new password'),
      c_pwd: Yup.string().oneOf([Yup.ref('new_pwd'), null], 'Confirm password not matched with password').required('Enter confirm password'),
      otp: Yup.string().required('Enter otp')
   });
   
   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      getValues
   } = useForm({
      mode: "onchange",
      resolver: yupResolver(validationSchema)
   });


   const onSubmit = (data) =>{
      console.log('hiiii',data);
      var bodyData = {
         "id":UserData._id,
         "otp":data.otp,
         "password": data.new_pwd
      }

      var tokenData = { Authorization: token}
      Services.userResetPassword(bodyData,tokenData).then((response)=>{
         console.log(response,'reeee');
         if (response.data.status == 200) {
            swal("Success", response.data.message, "success");
            swal({
               title: "Success",
               text:  response.data.message,
               type: "success"
           }).then(function() {
               window.location = "https://stackoverflow.com/";
           });

         } else {
            swal("Failed", response.data.message, "error");

         }
      }).catch((err)=>{
         swal("Failed", err.response.data.message, "error");
      });
   }



    return (
        <>
           <main className="page-content bg-light">
              {/* <Header title="Account setting" main_title="Dashboard" /> */}
              <div className="container-fluid">
                 <div className="layout-specing">
                    <div className="row">
                       <div className="col-md-12">
                          <div className="card border-0 shadow rounded">
                             <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                <h4 className="mb-0">Reset Password</h4>
                             </div>
  
                             <div className="p-3 col-md-10 col-lg-8">
                                <form id="changepassword" onSubmit={handleSubmit(onSubmit)}>
                                   <div className="row">
                                   <div className="col-md-12">
                                         <div className="mb-3">
                                            <label className="cstm-label">OTP</label>
                                            <input name="new_pwd" {...register('otp')} id="otp" type="text" className="cstm-input" placeholder="Enter otp" />
                                            <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.otp?.message}</span>
  
                                         </div>
                                      </div>
                                      <div className="col-md-12">
                                         <div className="mb-3">
                                            <label className="cstm-label">New password</label>
                                            <input name="new_pwd" {...register('new_pwd')} id="new_pwd" type="password" className="cstm-input" placeholder="********************" />
                                            <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.new_pwd?.message}</span>
  
                                         </div>
                                      </div>
                                      <div className="col-md-12">
                                         <div className="mb-3">
                                            <label className="cstm-label">Confirm password</label>
                                            <input name="c_pwd" {...register('c_pwd')} id="c_pwd" type="password" className="cstm-input" placeholder="********************" />
                                            <span className="invalid-error-changepwd" style={{ color: "red" }}>{errors.c_pwd?.message}</span>
  
                                         </div>
                                      </div>
  
                                     
                                      <div className="submit-btnMain cstm-loader">
                                         <button type="submit" className="btn-submit-login-form cstm-submitbutton">Save Changes</button>
                                         <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                            <img src={loader} />
                                         </div>
                                      </div>
  
                                   </div>
                                </form>
  
  
                             </div>
  
                          </div>
                       </div>
                    </div>
  
                 </div>
              </div>
           </main>
        </>
     )
}

export default ResetPassword

