import baseApi from "./base-api";

/**
 * @param {Object} params
 * @param {string} params.search - search text
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {boolean} params.isActive
 * @param {("image" | "occupation" | "teams")[]} params.nullFields
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function getUsers(params = {}) {
    return baseApi.getWithParams("users", params);
}

/**
 * @param {string} userId
 */
function getUserById(userId) {
    return baseApi.get(`users/${userId}`);
}

function createUser(userUpdateData) {
    return baseApi.post(`users`, userUpdateData);
}

function updateUser(userId, userUpdateData) {
    return baseApi.put(`users/${userId}`, userUpdateData);
}

function enableUserAccount(userId) {
    return baseApi.post(`users/${userId}/enable-account`);
}

function disableUserAccount(userId) {
    return baseApi.post(`users/${userId}/disable-account`);
}

/**
 * 
 * @param {Object} params
 * @param {string} params.search - search text
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {("image" | "occupation" | "teams")[]} params.nullFields
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("users/download-csv", params);
}

function getPendingEmailVerificationUsers({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("users/email-verified/false", {
        page: pageNumber,
        limit: pageSize,
    });
}

function verifyUserEmail(userId) {
    return baseApi.put(`users/email/verify/user/${userId}`);
}

function sendVerifyUserEmail(userId) {
    return baseApi.get(`users/resend/verify-email/${userId}`);
}

const userService = {
    getUsers: getUsers,
    getUserById: getUserById,
    updateUser: updateUser,
    enableUserAccount: enableUserAccount,
    disableUserAccount: disableUserAccount,
    downloadAsCsv: downloadAsCsv,
    getPendingEmailVerificationUsers: getPendingEmailVerificationUsers,
    verifyUserEmail: verifyUserEmail,
    createUser: createUser,
    sendVerifyUserEmail: sendVerifyUserEmail
};

export default userService;
