import React, { useState, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import Services from "../../Services/auth.service";
import PlacesAutocomplete from 'react-places-autocomplete';
import loader from "../../assets/Images/loder.gif";
import { categoryType } from "../../Constants/app.constants";
import { ALLOWED_IMAGE_MIME_TYPES } from '../../common/constants/file.constant';
import { FormFieldError } from '../../shared/components/FormFieldError';
import { config } from '../../common/config';
import { APP_ROUTES } from '../../common/app-routes';
import { createBreadcrumb, useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';

const editOrganizationSchema = yup.object().shape({
    name: yup.string().required("Organization name is required"),
    abbreviatedName: yup.string().required("Abbreviated name is required"),
    sector: yup.string().required("Sector is required"),
    provider: yup.string().required("Provider is required"),
    role: yup.string().required("Role is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    webURL: yup.string().url("Enter a valid website url").required("Website address is required"),
    teamSize: yup.string().required("Team size is required"),
    phase: yup.string().required("Phase is required"),
    isEnableGift: yup.boolean().required("isEnableGift is required").default(true),
    isEnableDisclaimer: yup.boolean().required("isEnableDisclaimer is required").default(true)
});

export function EditOrganization() {
    const { register, watch, handleSubmit, reset, formState: { errors, isSubmitting }, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editOrganizationSchema),
        defaultValues: {
            role: "",
            teamSize: "",
            charityName: "",
            phase: "",
            isEnableGift: true,
            isEnableDisclaimer: false
        },
    });
    const [searchParams] = useSearchParams();
    const routeLocation = useLocation();
    const organizationId = searchParams.get('id');
    const [organizationImg, setOrganizationImg] = useState();
    const [organizationImgPreview, setOrganizationImgPreview] = useState();
    const [organizationImgErr, setOrganizationImgErr] = useState("");
    const [bannerImg, setBannerImg] = useState();
    const [bannerImgPreview, setBannerImgPreview] = useState();
    const [bannerImgErr, setBannerImgErr] = useState("");
    const [location, setLocation] = useState("");
    const [locationErr, setLocationErr] = useState("");
    const [googlePlaceId, setGooglePlaceId] = useState("");
    const [charities, setCharities] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState();
    const [qrCodeUrl, setQrCodeUrl] = useState();
    const [testStartDate, setTestStartDate] = useState();
    const [testEndDate, setTestEndDate] = useState();
    const [pilotStartDate, setPilotStartDate] = useState();
    const [pilotEndDate, setPilotEndDate] = useState();
    const [programmeStartDate, setProgrammeStartDate] = useState();
    const [programmeEndDate, setProgrammeEndDate] = useState();
    const [charity, setCharity] = useState();

    useEffect(() => {
        getCharities();
        getOrganizationDetails();
    }, []);

    useSetBreadcrumbs([
        createBreadcrumb(APP_ROUTES.ORGANIZATION_LIST, routeLocation),
        createBreadcrumb(APP_ROUTES.EDIT_ORGANIZATION),
    ]);

    async function getCharities() {
        try {
            const params = {
                page: 1,
                limit: 100,
                isDelete: false,
                isCharityVerified: true,
            };
            const response = await Services.getCharity(params);

            if (response?.data?.status === 200) {
                if (response?.data?.data?.charity_count?.length) {
                    setCharities(response.data.data.charity_count);
                }
                return;
            }

            toast.error("Couldn't get charities");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get charities");
        }
    }

    async function getOrganizationDetails() {
        try {
            const response = await Services.getOrganizationById(organizationId, { page: 1, limit: 100 });

            if (response?.data?.status === 200) {
                const organizationData = response.data.data.response[0];
                if (organizationData.charities && organizationData.charities.length > 0) {
                    setCharity(organizationData.charities[0]);
                }
                setOrganizationDetails(organizationData);
                reset({
                    name: organizationData.name,
                    abbreviatedName: organizationData.abbreviatedName,
                    sector: organizationData.sector || "",
                    provider: organizationData.provider || "",
                    email: organizationData.email,
                    webURL: organizationData.webURL,
                    role: organizationData.role,
                    teamSize: organizationData.teamSize,
                    phase: organizationData.phase || "",
                    charityName: organizationData.charityName,
                    isEnableGift: organizationData.isEnableGift,
                    isEnableDisclaimer: organizationData.isEnableDisclaimer
                });
                setGooglePlaceId(organizationData.googlePlaceId);
                setLocation(organizationData.location);
                setQrCodeUrl(organizationData.qrCodePath);
                setTestStartDate(organizationData.testDate?.startDate ? new Date(organizationData.testDate?.startDate) : null);
                setTestEndDate(organizationData.testDate?.endDate ? new Date(organizationData.testDate?.endDate) : null);
                setPilotStartDate(organizationData.pilotDate?.startDate ? new Date(organizationData.pilotDate?.startDate) : null);
                setPilotEndDate(organizationData.pilotDate?.endDate ? new Date(organizationData.pilotDate?.endDate) : null);
                setProgrammeStartDate(organizationData.programmeDate?.startDate ? new Date(organizationData.programmeDate?.startDate) : null);
                setProgrammeEndDate(organizationData.programmeDate?.endDate ? new Date(organizationData.programmeDate?.endDate) : null);
                return;
            }

            toast.error("Couldn't get organization");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get organization");
        }
    }

    function handlePlaceChange(address) {
        setLocation(address);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    function handlePlaceSelect(address, placeId) {
        setLocation(address);
        setGooglePlaceId(placeId);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    function handleBannerImageChange(e) {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            setBannerImgErr("File is not allowed");
            return;
        }

        setBannerImgErr("");
        setBannerImgPreview(URL.createObjectURL(file));
        setBannerImg(file);
    }

    function handleOrganizationImageChange(e) {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            setOrganizationImgErr("File is not allowed");
            return;
        }

        setOrganizationImgErr("");
        setOrganizationImgPreview(URL.createObjectURL(file));
        setOrganizationImg(file);
    }

    async function onSubmit(data) {
        if (!organizationDetails?.image && !organizationImg) {
            setOrganizationImgErr("Organization image is required");
            return;
        }

        if (!organizationDetails?.banner && !bannerImg) {
            setBannerImgErr("Banner image is required");
            return;
        }

        try {
            let organizationImagePath = organizationDetails?.image;
            let bannerImagePath = organizationDetails?.banner;

            if (organizationImg) {
                organizationImagePath = await uploadImage(organizationImg);
            }

            if (bannerImg) {
                bannerImagePath = await uploadImage(bannerImg);
            }

            const editOrganizationData = {
                ...data,
                id: organizationId,
                image: organizationImagePath,
                banner: bannerImagePath,
                location: location,
                googlePlaceId: googlePlaceId,
                teamName: organizationDetails.teamName,
                isOrganizationVerified: true,
                isCharityVerified: true,
                testDate: {
                    startDate: testStartDate,
                    endDate: testEndDate
                },
                pilotDate: {
                    startDate: pilotStartDate,
                    endDate: pilotEndDate
                },
                programmeDate: {
                    startDate: programmeStartDate,
                    endDate: programmeEndDate
                }
            };

            await editOrganization(editOrganizationData);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update organization");
        }
    }

    async function editOrganization(organizationData) {
        try {
            const response = await Services.editOrganization(organizationData);

            if (response.data.status === 200) {
                toast.success("Successfully updated organization");
                return;
            }

            toast.error("Couldn't update organization");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update organization");
        }
    }

    async function uploadImage(image) {
        try {
            const formData = new FormData();
            formData.append("image", image);

            const response = await Services.orgimageGet(formData);

            if (response.status === 200) {
                const uploadedImagePath = response.data.data.images[0].location;
                return uploadedImagePath;
            }

            throw new Error("Couldnt upload image");
        } catch (err) {
            throw err;
        }
    }

    function viewQrCode() {
        window.open(`${config.AWS.S3_BASE_URL}/${qrCodeUrl}`, "_blank");
    }

    function changeGiftStatus(e) {
        let status = e.target.checked;

        if (!status) {
            setValue('isEnableDisclaimer', false);
        }
    }

    function showMessageDisabled() {
        if (charity) {
            toast.error(`Linked to Donate to Hospital Charity ${charity.title}`);
        }
    }

    async function generateQrCode() {
        const params = {
            id: organizationId,
            name: organizationDetails?.name,
            category: categoryType.Organisation,
        };

        try {
            const response = await Services.generateQrCodeAtAdmin(params);

            if (response.status === 200) {
                toast.success("Successfully generated QR code");
                const orgData = response.data.data;
                setQrCodeUrl(orgData.data.qrCodePath);
                return;
            }

            toast.error("Couldn't generate QR code");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't generate QR code");
        }
    }

    return (
        <SidebarLayoutContent headerTitle='Edit Organization'>
            <div className="row">
                <h4 className="mb-4">Edit Organization</h4>
                <div className="d-flex mb-2">
                    <Link
                        to={`/organization/${organizationId}/teams`}
                        className='cstm-btn lh-base ml-2'
                        state={{ breadcrumbPath: `/edit-organization/?id=${organizationId}` }}
                    >
                        View Teams
                    </Link>
                    <Link
                        to={`/organization/${organizationId}/teams/notification`}
                        className='cstm-btn lh-base ml-2'
                        state={{ breadcrumbPath: `/edit-organization/?id=${organizationId}` }}
                    >
                        Notifications
                    </Link>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card border-0 shadow rounded mb-4 p-4">
                    <div className="mb-4 col-md-7">
                        <PlacesAutocomplete
                            value={location}
                            onChange={handlePlaceChange}
                            onSelect={handlePlaceSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <i className="fi fi-rr-search cstm-search-ro"></i>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'cstm-input-seacrh location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}

                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                            const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={suggestion.placeId}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <FormFieldError message={locationErr} />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Place Id</label><br />
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Google place id"
                            value={googlePlaceId}
                            readOnly
                        />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Organisation QR Code</label>{" "}
                        <br />
                        <img
                            src={`${config.AWS.S3_BASE_URL}/${qrCodeUrl}`}
                            alt=""
                            style={{ width: 120, height: 120 }}
                        />
                        {/* <button
                                        type="button"
                                        variant="primary"
                                        className="cstm-btn"
                                        onClick={generateQrCode}
                                    >
                                        {qrCodeUrl ? "Regenerate" : "Generate QR"}
                                    </button>{" "} */}
                        {qrCodeUrl && (
                            <button
                                type="button"
                                variant="primary"
                                className="cstm-btn"
                                onClick={viewQrCode}
                            >
                                Download
                            </button>
                        )}
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Upload Banner Image</label><br />
                        <div className='cstm-Videoalign'>
                            <label htmlFor="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <input
                                accept="image/*"
                                type="file"
                                name="banner"
                                id="banner-image"
                                className='cstm-uploadHide'
                                onChange={handleBannerImageChange}
                            />
                            {bannerImgPreview && <img src={bannerImgPreview} alt='' className="AddOrganization-bannerImg" />}
                            {!bannerImgPreview && organizationDetails?.banner && (
                                <img src={`${config.AWS.S3_BASE_URL}/${organizationDetails?.banner}`} alt='' className="AddOrganization-bannerImg" />
                            )}
                        </div>
                        <FormFieldError message={bannerImgErr} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Upload Image</label><br />
                        <div className='cstm-Videoalign'>
                            <label htmlFor="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <input
                                accept="image/*"
                                type="file"
                                name="image"
                                id="org-image"
                                className='cstm-uploadHide'
                                onChange={handleOrganizationImageChange}
                            />
                            {organizationImgPreview && <img src={organizationImgPreview} alt='' className="AddOrganization-bannerImg" />}
                            {!organizationImgPreview && organizationDetails?.image && (
                                <img src={`${config.AWS.S3_BASE_URL}/${organizationDetails?.image}`} alt='' className="AddOrganization-bannerImg" />
                            )}
                        </div>
                        <FormFieldError message={organizationImgErr} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organization Name</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Organization Display Name"
                            {...register("name")}
                        />
                        <FormFieldError message={errors.name?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Abbreviated Name</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Abbreviated Name"
                            {...register("abbreviatedName")}
                        />
                        <FormFieldError message={errors.abbreviatedName?.message} />
                    </div>

                    <div className='mb-4'>
                        <label className="cstm-label">Sector</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="sector-public"
                                value="Public"
                                {...register("sector")}
                            />
                            <label className="form-check-label" htmlFor="sector-public">Public</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="sector-private"
                                value="Private"
                                {...register("sector")}
                            />
                            <label className="form-check-label" htmlFor="sector-private">Private</label>
                        </div>
                        <FormFieldError message={errors.sector?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Provider</label>
                        <select
                            className="form-select cstm-category"
                            {...register("provider")}
                        >
                            <option value="" hidden disabled>Select Provider</option>
                            <option value="PrimaryCare">Primary Care</option>
                            <option value="SecondaryCare">Secondary Care</option>
                            <option value="TertiaryCare">Tertiary Care</option>
                            <option value="Community">Community</option>
                        </select>
                        <FormFieldError message={errors.provider?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Role</label>
                        <select
                            className="form-select cstm-category"
                            {...register("role")}
                        >
                            <option value="" disabled>Your Role?</option>
                            <option value="Team Lead">Team Lead</option>
                            <option value="Manager">Manager</option>
                            <option value="Executive">Executive</option>
                            <option value="Other">Other</option>
                        </select>
                        <FormFieldError message={errors.role?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Team Size</label>
                        <select
                            className="form-select cstm-category"
                            {...register("teamSize")}
                        >
                            <option value="" disabled>Select team size</option>
                            <option value="1-50">1-50</option>
                            <option value="51-250">51-250</option>
                            <option value="250-1000">250-1000</option>
                            <option value="1001+">1001+</option>
                        </select>
                        <FormFieldError message={errors.teamSize?.message} />
                    </div>

                    <div className='mb-4'>
                        <label className="cstm-label">Phase</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-test"
                                value="Test"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-test">Test</label>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">Start Date</label>
                                <DatePicker
                                    selected={testStartDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setTestStartDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">End Date</label>
                                <DatePicker
                                    selected={testEndDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setTestEndDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>
                        </div>


                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-pilot"
                                value="Pilot"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-pilot">Pilot/Trial</label>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">Start Date</label>
                                <DatePicker
                                    selected={pilotStartDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setPilotStartDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">End Date</label>
                                <DatePicker
                                    selected={pilotEndDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setPilotEndDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="phase-programme"
                                value="Programme"
                                {...register("phase")}
                            />
                            <label className="form-check-label" htmlFor="phase-programme">Programme</label>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">Start Date</label>
                                <DatePicker
                                    selected={programmeStartDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setProgrammeStartDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>

                            <span style={{ marginLeft: '30px' }}>
                                <label className="cstm-label">End Date</label>
                                <DatePicker
                                    selected={programmeEndDate}
                                    placeholderText="DD/MM/YYYY"
                                    onChange={(date) => setProgrammeEndDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                    wrapperClassName="cstm-datepicker"
                                />
                            </span>
                        </div>
                        <FormFieldError message={errors.phase?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organisation Email</label>
                        <input
                            type="email"
                            className="cstm-input"
                            placeholder="Organization Email"
                            {...register("email")}
                        />
                        <FormFieldError message={errors.email?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Website Address</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Website Address"
                            {...register("webURL")}
                        />
                        <FormFieldError message={errors.webURL?.message} />
                    </div>

                    <div className="mb-4 col-md-7" >
                        <label className="cstm-label">Support Charity(Optional)</label>

                        <select
                            style={{ color: charity ? 'grey' : 'none', opacity: charity ? 0.7 : '' }}
                            // disabled={charity}
                            onClick={(e) => { showMessageDisabled() }}
                            className="form-select cstm-category"
                            {...register("charityName")}
                        >
                            <option value="" disabled>Select Charity</option>

                            {!charity && charities.map((charity) => <option key={charity._id} value={charity.title}>{charity.title}</option>)}
                        </select>
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Gifting Allowed: </label>
                        <input type="checkbox" {...register("isEnableGift", { onChange: ((e) => changeGiftStatus(e)) })} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Disclaimer On/Off: </label>
                        <input type="checkbox" disabled={!watch("isEnableGift")} {...register("isEnableDisclaimer")} />
                    </div>

                    <div className="submit-btnMain cstm-loader">
                        <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Edit Organization </button>
                        {isSubmitting && (
                            <div id="login-loader" className='loader-main'>
                                <img src={loader} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </SidebarLayoutContent>
    );
}
