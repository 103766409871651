import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Header from '../../NavMenuBar/Header';
import loader from '../../../assets/Images/loder.gif';
import EmailSettingService from '../../../Services/email-settings.service';
import videoQuickTipService from '../../../Services/video-quick-tip.service';
import storageService from '../../../Services/storage.service';
import { useLoaderStore } from '../../../stores/loader.store';

export function WelcomeEmailSetting() {
    const s3Url = process.env.REACT_APP_S3_BASE_URL;
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [id, setId] = useState('');
    const [emailVideos, setEmailVideos] = useState([]);
    const [videoQuickTips, setVideoQuickTips] = useState([]);

    useEffect(() => {
        getVideoTips();
        getWelcomeEmailSettings();
    }, []);

    const getVideoTips = async () => {
        try {
            const response = await videoQuickTipService.getVideoQuickTips({
                pageNumber: 1,
                pageSize: 50
            });

            if (response.status === 200) {
                setVideoQuickTips(response.data?.results);
                return;
            }

            toast.error("Couldn't fetch quick tip videos");
        } catch (err) {
            toast.error("Couldn't fetch quick tip videos");
        }
    }

    const getWelcomeEmailSettings = async () => {
        try {
            const response = await EmailSettingService.getWelcomeEmailSettings(id);

            if (response.status === 200) {
                let data = response.data;
                setEmailVideos(data.videoLinks);
                setId(data._id);
                return;
            }

            toast.error("Couldn't fetch welcome email settings");
        } catch (err) {
            toast.error("Couldn't fetch welcome email settings");
        }
    }

    const updateEmailTemplate = async () => {
        try {

            const res = await EmailSettingService.updateEmailTemplate(id, { videoLinks: emailVideos });

            if (res.status === 200) {
                toast.success("Successfully updated email template");
                return res.data;
            }
        }
        catch (err) {
            toast.error("Couldn't update video links");
            throw err;
        }
    }

    const handleVideoSelection = (videoQuickTipId, index) => {
        const value = videoQuickTipId;
        setEmailVideos((state) => state.map((val, i) => (i !== index ? val : setVideo(val, value))));
    };

    function setVideo(data, value) {
        data.link = value;
        return data;
    }


    function setImage(data, value) {
        data.thumbnailImage = value;
        return data;
    }

    const handleimageInput = (inputEv, index) => {
        const value = inputEv.target.value;
        // setEmailVideos((state) => state.map((val, i) => (i !== index ? val : setImage(val, value))));

        let newArr = [...emailVideos];

        newArr[index].thumbnailImage = value;
        setEmailVideos(newArr);
    };

    const handleImageChange = (e, data, index) => {
        e.preventDefault();
        console.log(index)
        setShowLoader(true);
        let files = e.target.files;

        let reader = new FileReader();
        handleImage(files, data, index);
        //if reading completed
        reader.onload = e => {

        };

        //read the file
        reader.readAsDataURL(files[0]);
    };

    const handleImage = async (files, data, index) => {
        let signedUrl = await getSignedUrlForUpload(data._id, files);
        await handleUpload(signedUrl.signedUrl, files);

        let newArr = [...emailVideos];

        newArr[index].thumbnailImage = signedUrl.inputPath;

        setEmailVideos(newArr);
        setShowLoader(false);
    }


    const getSignedUrlForUpload = async (id, file) => {
        try {
            let environment = process.env.REACT_APP_ENVIRONMENT;
            let path = `welcomeEmail/${environment}/${id}/${file[0].name}`

            let type = file[0].type;
            const res = await storageService.getSignedUrlForUpload(path, type);

            if (res.status === 200) {

                return {
                    'signedUrl': res.data.signedUrl,
                    'inputPath': path
                }
            }

            throw 'Failed';
        } catch (err) {
            toast.error("Couldn't get signed url");
            throw err;
        } finally {
        }
    }

    const handleUpload = async (signedUrl, file) => {
        try {
            await axios.put(signedUrl, file[0], {
                headers: {
                    "Content-Type": file[0].type
                },
                onUploadProgress: (e) => {
                    //  Show progress
                    let percentCompleted = Math.round((e.loaded * 100) / e.total);

                    if (percentCompleted == 100) {
                        return;
                    }
                },
            });
        }
        catch (err) {
            toast.error("Couldn't upload video");
            throw err;
        }
    };

    const getUrlImage = (url) => {
        if(url.includes(s3Url)){
            return url;
        }
        else {
            return s3Url + '/' + url;
        }
    } 

    function showVideos(data, index) {
        return (
            <div>
                <div>
                    <label>{index + 1}</label>
                </div>
                <label>Video link</label>
                <select
                    className="form-select cstm-category"
                    placeholder='Email Video'
                    value={emailVideos[index].link?._id}
                    onChange={(e) => {
                        handleVideoSelection(e.target.value, index);
                    }}
                >
                    <option value="">All Video Quicktips</option>

                    {videoQuickTips && videoQuickTips.map((videoQuickTip) => (
                        <option key={videoQuickTip._id} value={videoQuickTip._id}>{videoQuickTip.videoTitle}</option>
                    ))}
                </select>
                {/* <input
                    name="video_link"
                    id={data._id}
                    value={data.link}
                    type="text"
                    class="cstm-input"
                    placeholder="Enter Video link"
                    onChange={(e) => handleVideoLinkInput(e, index)}
                /> */}

                <label>Image link</label>
                <input
                    name="image_link"
                    readOnly
                    id={data._id}
                    value={data.thumbnailImage ? (s3Url + '/' + data.thumbnailImage) : ""}
                    type="text"
                    class="cstm-input"
                // placeholder="Enter Image link"
                // onChange={(e) => handleimageInput(e, index)}
                />

                <input
                    accept="image/*"
                    type="file"
                    name="image"
                    id={`o-image-${index}`}
                    className='cstm-uploadHide'
                    onChange={(e) => handleImageChange(e, data, index)}
                />

                <div className='cstm-video-image-upload'>
                    <label for={`o-image-${index}`} className='cstm-uploadbtn cstm-btn'>Upload Image</label>
                </div>

                < img src={getUrlImage(data.thumbnailImage)} />
            </div>
        );
    }

    return (
        <>
            <main class="page-content bg-light">
                <Header
                    title1="Welcome email settings"
                    title="Welcome email"
                    main_title="Welcome email settings"
                    title_link=""
                />


                <div class="container-fluid">
                    <div class="layout-specing">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card border-0 shadow rounded mb-4 ">
                                    <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-body p-4">
                                                <div>
                                                    {
                                                        emailVideos.map((data, index) => {
                                                            return showVideos(data, index)
                                                        })
                                                    }
                                                </div>
                                                <div className="submit-btnMain cstm-loader">
                                                    <button
                                                        onClick={updateEmailTemplate}
                                                        type="button"
                                                        className="btn-submit-login-form cstm-submitbutton"
                                                    >
                                                        {" "}
                                                        Save
                                                    </button>
                                                    <div
                                                        id="login-loader"
                                                        className="loader-main"
                                                        style={{ display: "none" }}
                                                    >
                                                        <img src={loader} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}