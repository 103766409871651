export function formatOrganizationForDropdown(organization) {
    return {
        value: organization._id,
        label: organization.name,
    };
}

export function formatTeamForDropdown(team) {
    return {
        value: team._id,
        label: team.name,
    };
}

export function formatTeamForMultiSelectDropdown(team) {
    return {
        id: team._id,
        label: team.name,
    };
}
