import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState,ContentState,convertToRaw} from "draft-js";
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';



export function ViewQuickTip(){
  const getid = useLocation().search;
  const tipsID = new URLSearchParams(getid).get("id");
  const [editVal, setEditVal] = useState({
    screen_name: "",
    title : "",
    description : "",
    description1 : EditorState.createEmpty(),
    button_name: "",
  });

  const [errEditVal, setErrEditVal] = useState({
    screen_name: "",
    title : "",
    description : "",
    button_name: "",
  });

  useEffect(()=>{
    Services.getQuicktipById(tipsID).then((response)=>{
      console.log(response.status,'response');
      if (response.status == 200) {
        var editorState = EditorState.createEmpty();
        if(response.data.data.description !== null){
          const contentBlock = htmlToDraft(response.data.data.description);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         editorState = EditorState.createWithContent(contentState);
        }
        

        console.log('response.data.data.screenName',response.data.data.screenName);
        setEditVal({screen_name:response.data.data.screenName,title:response.data.data.title,description:response.data.data.description,description1:editorState,button_name:response.data.data.buttonName});
      }
    })
  },[])

  const handleOnchangeUpdate = (e) => {
    console.log(e.target.value, e.target.name);
    const { value, name } = e.target;

    if (name == "title") {
      errEditVal.title = value.length > 0 ? "" : "Enter title";
    }

    if (name == "button_name") {
      errEditVal.button_name = value.length > 0 ? "" : "Enter button name";
    }

    setErrEditVal({ ...errEditVal });
    setEditVal({...editVal,[name]:value})
    // setCatVal(e.target.value)
  };
 

  const handleOnSubmitUpdate = async (e) => {
    // console.log("fgvcx", e);
    e.preventDefault();
    if (editVal.title == "") {
      errEditVal.title = "Enter title";
    } else {
      errEditVal.title = "";
    }

    if (editVal.button_name == "") {
      errEditVal.button_name = "Enter button name";
    } else {
      errEditVal.button_name = "";
   

    setErrEditVal({ ...errEditVal });
    if (errEditVal.title === '' && errEditVal.button_name === '' && errEditVal.description === '') {

        var bodyData = {
          id: tipsID,
          title: editVal.title,
          description: editVal.description,
          buttonName: editVal.button_name
        };
        Services.editQuicktip(bodyData).then((response) => {
          console.log(response, "response");
          if (response.data.status === 200) {
            swal("Success", response.data.message, "success");
          } else {
            swal("Failed", response.data.message, "error");
          }
        });
    }
  };
  }

  const onEditorStateChange = (editorState) => {
    setEditVal({...editVal,['description']:draftToHtml(convertToRaw(editorState.getCurrentContent())),['description1']:editorState})
  };
  return(
    <>
    <main className="page-content bg-light">
        <Header title="Quick Tip List" main_title="Announcements" title_link="quicktip" title1="Edit Tip"/>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 shadow rounded">
                  <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h4 className="mb-0">Quick Tip View</h4>
                  </div>
                  <div className="p-3 col-md-10 col-lg-8">
                    <form id="profile" onSubmit={handleOnSubmitUpdate}>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="cstm-label">Screen Name</label>
                            <input
                              name="screen_name"
                              value={editVal.screen_name}
                              id="screen_name"
                              // onChange={handleOnchange}
                              type="text"
                              className="cstm-input"
                              readOnly
                            />
                            {errEditVal.screen_name !== "" && (
                              <span style={{ color: "red" }}>
                                {errEditVal.screen_name}
                              </span>
                             ) }
                          </div>
                        </div>
                       
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="cstm-label">Title</label>
                            <input
                              name="title"
                              value={editVal.title}
                              id="title"
                              onChange={handleOnchangeUpdate}
                              type="text"
                              className="cstm-input"
                            />
                            {errEditVal.title !== "" && (
                              <span style={{ color: "red" }}>
                                {errEditVal.title}
                              </span>
                             ) }
                          </div>
                        </div>
                       
                      </div>
                      <Editor
                        editorState={editVal.description1}
                        onEditorStateChange={onEditorStateChange}    
                        toolbar={{
                        // options: ['inline', 'blockType', 'list', 'link', 'embedded', 'emoji', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        fontFamily: {
                          options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Roboto','condor', 'Roboto Condensed', 'Robot Condensed Light']
                        },
                        fontSize: {
                          options: [10,12, 14, 16, 18, 20, 24, 30, 36, 48, 60],
                         },
                      }}
                      />
                                    <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="cstm-label">Button Name</label>
                            <input
                              name="button_name"
                              value={editVal.button_name}
                              id="button_name"
                              onChange={handleOnchangeUpdate}
                              type="text"
                              className="cstm-input"
                            />
                            {errEditVal.button_name !== "" && (
                              <span style={{ color: "red" }}>
                                {errEditVal.button_name}
                              </span>
                             ) }
                          </div>
                        </div>
                       
                      </div>

                      <div className="d-flex">
                        <button className=" cstm-btn" type="submit">
                          Submit
                        </button>
                      </div>
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}


export default ViewQuickTip;