import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/Images/loder.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const AddEvent = () => {
  const history = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [showLatitude, setLatitude] = useState('');
  const [showLongitude, setLongitude] = useState('');

  // const [charData, setCharData] = useState([]);
  const [formFields, setFormFields] = useState({
    title: "",
    banner: "",
    image_video: [],
    category: "",
    postalCode: "",
    address: "",
    webURL: "",
    about: "",
    // date: "",
    // time: "",
  });
  const [err, seterr] = useState({
    title: "",
    banner: "",
    image_video: "",
    category: "",
    postalCode: "",
    webURL: "",
    about: "",
    // date: "",
    // time: "",
  });

  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [eventCategory, setCategory] = useState([]);
  const [file, setFile] = useState();
  const [bannerImg, setBannerImg] = useState("");
  const [bnImg, setBnImg] = useState("");

  var imgExtArray = [];
  const arrayImages = [];
  var imgArray = [];
  const DataArrayCat = [];

  useEffect((data, e) => {
    getCatData(1);
  }, []);

  //   get category for add charity
  const getCatData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getEventCat(queryVar)
      .then((response) => {
        console.log(response.data.data.category_count, "response cat");
        if (response.data.status === 200) {
          if (response.data.data.category_count.length !== 0) {
            var data = response.data.data.category_count;
            data.map((data1) => {
              DataArrayCat.push(data1);
            });
          }
          setCategory(DataArrayCat);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };
  //image onchange

  // const handleImageChangeBanner = (e) => {
  //   e.preventDefault();
  //   err.banner = e.target.value.length > 0 ? "" : "Select banner image";

  //   setFile(URL.createObjectURL(e.target.files[0]));
  //   let charImage = e.target.files[0];
  //   setBannerImg(charImage);
  // };

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs]);
    setImagesPrev([...s]);
  }

  const handleSelect = address => {
    setFormFields({ ...formFields, ['address']: address });

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
      }
      )
      .catch(error => console.error('Error', error));

  };

  const handleChange = address => {
    setFormFields({ ...formFields, ['address']: address });
  };

  const handleImageChangeorg = (e) => {
    e.preventDefault();
    err.image_video = e.target.value.length > 0 ? "" : "Select image or video";

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);

    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];
    });
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    console.log(imgArray, "imgArraydatatata");
    imgArray.forEach(function (f, index) {
      console.log(f, "f");
      // fileArray.push(f);
      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split(".").pop();
      var imageExtension = [
        "png",
        "jpg",
        "jpeg",
        "apng",
        ".avif",
        "jfif",
        "pjpeg",
        "pjp",
        "svg",
        "webp",
      ];
      var videoExtension = [
        "mp4",
        "mov",
        "wmv",
        "avi",
        "avchd",
        "flv",
        "f4v",
        "swf",
        "mkv",
        "webm",
        "html5",
        "mpeg-2",
      ];
      imageExtension.includes(filesplit) &&
        imgExtArray.push({ url: u, type: "image" });
      videoExtension.includes(filesplit) &&
        imgExtArray.push({ url: u, type: "video" });
    });

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);
  };

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "title":
        err.title = value.length > 0 ? "" : "Enter event name";
        break;
      case "category":
        err.category = value.length > 0 ? "" : "Enter category";
        break;
      // case "postalCode":
      //   err.postalCode = value.length > 0 ? "" : "Enter postalcode";
      //   break;
      case "webURL":
        err.webURL =
          e.target.value.length > 0 &&
            !new RegExp(
              "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
            ).test(value)
            ? "Enter a valid website url"
            : "";
        break;

      case "about":
        err.about = value.length > 0 ? "" : "Write about";
        break;
      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (formFields.title === "") {
      err.title = "Enter event name";
    } else {
      err.title = "";
    }
    if (formFields.category === "") {
      err.category = "Enter category";
    } else {
      err.category = "";
    }
    // if (formFields.postalCode === "") {
    //   err.postalCode = "Enter postalCode";
    // } else {
    //   err.postalCode = "";
    // }
    if (finalImages.length === 0) {
      err.image_video = "Select image or video";
    } else {
      err.image_video = "";
    }
    // if (bannerImg === "") {
    //   err.banner = "Select banner image";
    // } else {
    //   err.banner = "";
    // }

    if (formFields.webURL !== "") {
      if (
        !new RegExp(
          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
        ).test(formFields.webURL)
      ) {
        err.webURL = "Enter a valid website url";
      } else {
        err.webURL = "";
      }
    }

    if (formFields.about === "") {
      err.about = "Write about";
    } else {
      err.about = "";
    }
    seterr({ ...err });
    console.log(err, "err");
    console.log(finalImages, "finalImages");

    if (
      err.title === "" &&
      // err.banner === "" &&
      err.image_video === "" &&
      err.category === "" &&
      // err.postalCode === "" &&
      err.webURL === "" &&
      err.about === ""
    ) {
      $(".loader-main").show();
      // var imgbanner = "";
      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append("image", file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {
            console.log(response, "send data multi file");

            if (response.status === 200) {
              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              });
              AddEventData(arrayImages);

              // if (bannerImg) {
              //   let formData = new FormData();
              //   formData.append("image", bannerImg);
              //   //banner image upload
              //   Services.charimageGet(formData)
              //     .then((response) => {
              //       console.log(response, "ressss");
              //       if (response.status === 200) {
              //         formFields.banner = response.data.data.images[0].location;
              //         // setBnImg(`${response.data.data.images[0].location}`);
              //         imgbanner = response.data.data.images[0].location;
              //         AddEventData(arrayImages, imgbanner);
              //       }
              //     })
              //     .catch(function (err) {
              //       console.log(err, "err");
              //     });
              // }
            }
          })
          .catch(function (err) {
            console.log(err, "err");
          });
      }

      let tempStartTime = new Date(startDate);
      let tempTime = startTime.split(':');
      tempStartTime.setHours(tempTime[0], tempTime[1], 0, 0);

      let tempEndTime = new Date(endDate);
      let tempETime = endTime.split(':');
      tempEndTime.setHours(tempETime[0], tempETime[1], 0, 0);


      function AddEventData(arrayImages) {
        var addEvent = {
          title: formFields.title,
          // banner: bnImg,
          image_video: arrayImages,
          // date: startDate,
          startDate: tempStartTime,
          endDate: tempEndTime,
          // startTime:startTime,
          // endTime:endTime,
          postalCode: formFields.postalCode,
          address: formFields.address,
          webURL: formFields.webURL,
          description: formFields.about,
          categoryId: formFields.category,
          latitude: showLatitude,
          longitude: showLongitude,
        };

        Services.addEvent(addEvent)
          .then((response) => {
            $(".loader-main").hide();

            if (response.data.status == 200) {
              swal("Success", response.data.message, "success");
              $("#add-event")[0].reset();
              $("#b-img").remove();
              $("#o-img").remove();
              $(".uploded-video").remove();
              $(".multi-images").remove();
              $(".multi-video").remove();
              // history("/event");
            } else {
              swal("Failed", response.data.message, "error");
            }
          })
          .catch((err) => {
            swal("Failed", err.response.data.message, "error");
          });
      }
    }
  };

  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Add Event"
          title="Event List"
          main_title="Event"
          title_link="event"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Add Event</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-4">
                  <form id="add-charity" onSubmit={handleOnSubmit}>
                    {/* <div class="mb-4">
                            <label class="cstm-label">
                              Upload Banner Image
                            </label>
                            <br />

                            <label
                              for="banner-image"
                              className="cstm-uploadbtn cstm-btn"
                            >
                              Choose File
                            </label>
                            
                            {file && file.length > 0 && (
                              <img
                                id="b-img"
                                className="AddOrganization-bannerImg"
                                src={file}
                              />
                            )}

                            <input
                              accept="image/*"
                              type="file"
                              name="banner"
                              id="banner-image"
                              className="cstm-uploadHide"
                              // style={{ opacity: 0, cursor: 'pointer' }}
                              onChange={handleImageChangeBanner}
                            />
                            {err.banner !== "" && (
                              <span style={{ color: "red" }} className="ml-3">
                                {err.banner}
                              </span>
                            )}
                          </div> */}

                    <div className="upload__img-wrap"></div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Upload Image Or Video
                      </label>
                      <br />
                      {/* {filevideo && <img id="o-img" src={filevideo} width="112px" height="112px" />} */}

                      <div className="cstm-video-image-upload">
                        <label
                          for="o-image"
                          className="cstm-uploadbtn cstm-btn"
                        >
                          Choose File
                        </label>
                        <div className='cstm-vi-in'>
                          {imagesPrev.map((data, index) =>
                            data.type === "image" ? (
                              <>
                                <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">
                                    {" "}
                                    <img
                                      class="multi-images"

                                      controls
                                      src={data.url}
                                      alt="..."
                                    />
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => deleteImages(index)}
                                  >
                                    ✖
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">
                                    {" "}
                                    <video
                                      class="multi-videos"

                                      controls
                                      src={data.url}
                                      alt="..."
                                    />
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => deleteImages(index)}
                                  >
                                    ✖
                                  </button>
                                </div>
                              </>
                            )
                          )}
                        </div>
                        <input
                          // accept=".mov,.mp4"
                          multiple
                          type="file"
                          name="image_video"
                          id="o-image"
                          className="cstm-uploadHide"
                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeorg}
                        />
                      </div>

                      {err.image_video !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {err.image_video}
                        </span>
                      )}
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event Name</label>
                      <input
                        name="title"
                        id="char-name"
                        type="text"
                        class="cstm-input"
                        placeholder="Event Name"
                        onChange={handleOnchange}
                      />
                      {err.title !== "" && (
                        <span style={{ color: "red" }}>{err.title}</span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Category</label>
                      <select
                        name="category"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchange}
                      >
                        <option value="" selected>
                          Category
                        </option>

                        {eventCategory.map((cat) => {
                          return (
                            <option value={cat._id}>{cat.title}</option>
                          );
                          // console.log(cat.title,"single cat")
                        })}
                      </select>

                      {err.category !== "" && (
                        <span style={{ color: "red" }}>
                          {err.category}
                        </span>
                      )}
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Event Address(Optional)
                      </label>
                      {/* <input
                              name="address"
                              id="address"
                              type="text"
                              class="cstm-input"
                              placeholder="Event Address"
                              onChange={handleOnchange}
                            /> */}

                      <PlacesAutocomplete
                        value={formFields.address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <i class="fi fi-rr-search cstm-search-ro"></i>
                            <input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'cstm-input-seacrh location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Event Website Link (Optional)
                      </label>
                      <input
                        name="webURL"
                        id="webURL"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        onChange={handleOnchange}
                      />
                      {err.webURL !== "" && (
                        <span style={{ color: "red" }}>{err.webURL}</span>
                      )}
                    </div>

                    {/* <div class="mb-4 col-md-7">
                            <label class="cstm-label">Event Postcode</label>
                            <input
                              name="postalCode"
                              id="postalCode"
                              type="text"
                              class="cstm-input"
                              placeholder="Event Postcode"
                              onChange={handleOnchange}
                            />
                            {err.postalCode !== "" && (
                              <span style={{ color: "red" }}>
                                {err.postalCode}
                              </span>
                            )}
                          </div> */}
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event Start Date</label>
                      <DatePicker
                        selected={startDate}
                        placeholderText="DD/MM/YYYY"
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                      />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Start Time</label>
                      <input type="time" onChange={ev => setStartTime(ev.target.value)} class="cstm-input" id="start-time" name="start-time" />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Event End Date</label>
                      <DatePicker
                        selected={endDate}
                        placeholderText="DD/MM/YYYY"
                        onChange={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="cstm-datepicker"
                      />
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">End Time</label>
                      <input type="time" onChange={ev => setEndTime(ev.target.value)} class="cstm-input" id="end-end-time" name="time" />
                    </div>



                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">About</label>
                      <textarea
                        name="about"
                        id="about"
                        type="textarea"
                        rows="5"
                        class="cstm-input cstm-textarea"
                        placeholder="About..."
                        onChange={handleOnchange}
                      />
                      {err.about !== "" && (
                        <span style={{ color: "red" }}>{err.about}</span>
                      )}
                    </div>
                    {/* <div class="d-flex">
                                                        <button class=" cstm-btn">
                                                            Add Charity
                                                        </button>
                                                    </div> */}
                    <div className="submit-btnMain cstm-loader">
                      <button
                        type="submit"
                        className="btn-submit-login-form cstm-submitbutton btn-disable"
                      >
                        Add Event{" "}
                      </button>
                      <div
                        id="login-loader"
                        className="loader-main"
                        style={{ display: "none" }}
                      >
                        <img src={loader} />
                      </div>
                    </div>
                  </form>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddEvent;
