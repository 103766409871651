import React, { useState } from "react";
import { toast } from 'react-toastify';
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import $ from "jquery";
import loader from "../../assets/Images/loder.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete from 'react-places-autocomplete';
import { ALLOWED_IMAGE_MIME_TYPES, ALLOWED_VIDEO_MIME_TYPES } from "../../common/constants/file.constant";

const AddNews = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formFields, setFormFields] = useState({
    news_title: "",
    banner: "",
    image_video: [],
    description: "",
    location: "",
    webURL: "",
    uploadDate: "",
    source: "",
  });

  const [err, seterr] = useState({
    title: "",
    banner: "",
    image_video: "",
    description: "",
    location: "",
    webURL: "",
    uploadDate: "",
  });

  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);

  let imgExtArray = [];
  const arrayImages = [];
  let imgArray = [];

  const handleChange = address => {
    setFormFields({ ...formFields, ['location']: address });
  };

  const handleSelect = address => {
    setFormFields({ ...formFields, ['location']: address });
  };

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs]);
    setImagesPrev([...s]);
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    err.image_video = e.target.value.length > 0 ? "" : "Select image or video";
    let files = e.target.files;
    let filesArr = Array.prototype.slice.call(files);

    filesArr.forEach((f) => {
      imgArray = [...imgArray, f];
    });
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    console.log(imgArray, "imgArraydatatata");
    imgArray.forEach((f) => {
      console.log(f, "f");
      let u = URL.createObjectURL(f);
      arr.push(u);
      let fileExt = f.name.split(".").pop();

      if (ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
        imgExtArray.push({ url: u, type: "image" });
      }

      if (ALLOWED_VIDEO_MIME_TYPES.includes(fileExt)) {
        imgExtArray.push({ url: u, type: "video" });
      }
    });

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);
  };

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "news_title":
        err.news_title = value.length > 0 ? "" : "Enter news title";
        break;
      case "description":
        err.description = value.length > 0 ? "" : "Write about";
        break;
      case "location":
        err.location = value.length > 0 ? "" : "Select location";
        break;
      case "webURL":
        err.webURL =
          e.target.value.length > 0 &&
            !new RegExp(
              "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
            ).test(value)
            ? "Enter a valid website url"
            : "";
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (formFields.news_title === "") {
      err.news_title = "Enter news  title ";
    } else {
      err.news_title = "";
    }
    if (finalImages.length === 0) {
      err.image_video = "Select image or video";
    } else {
      err.image_video = "";
    }
    if (formFields.location === "") {
      err.location = "Select your location";
    } else {
      err.location = "";
    }
    if (formFields.description === "") {
      err.description = "Write description";
    } else {
      err.description = "";
    }
    seterr({ ...err });
    console.log(err, "err");
    if (
      err.news_title === "" &&
      err.uploadDate === "" &&
      err.image_video === "" &&
      err.location === "" &&
      err.description === ""
      // err.location ===""
    ) {
      $(".loader-main").show();
      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {
            console.log(response, "send data multi file");

            if (response.status === 200) {

              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              AddNewsData(arrayImages);
            }
          })
          .catch(function (err) {
            console.log(err, "err");
          });
      }
      function AddNewsData(image_video) {
        let addNews = {
          news_title: formFields.news_title,
          startDate: startDate,
          endDate: endDate,
          location: formFields.location,
          description: formFields.description,
          image_video: image_video,
          webURL: formFields.webURL,
          source: formFields.source,
        };

        Services.addNews(addNews)
          .then((response) => {
            $(".loader-main").hide();

            if (response.data.status == 200) {
              toast.success(response.data.message);
              $("#add-news")[0].reset();
              $("#b-img").remove();
            } else {
              $(".loader-main").hide();
              toast.error(response.data.message);
            }
          })
          .catch((err) => {
            $(".loader-main").hide();
            toast.error(err.response.data.message);
          });
      }
    }
  };

  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Create News"
          title="News and Stories"
          main_title="News"
          title_link="news-stories"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Create News</h4>
            <div class="row">

              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 ">
                  <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body p-4">
                        <form id="add-news" onSubmit={handleOnSubmit}>
                          <div class="mb-4">
                            <label class="cstm-label">Upload Image Or Video</label><br />

                            <div className='cstm-video-image-upload'>
                              <label for="o-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                              <div className='cstm-vi-in'>
                                {imagesPrev.map((data, index) =>

                                  data.type === 'image' ?

                                    (
                                      <div className='cstm-vi-box' key={index}>
                                        <span className="cstm-vi-bx"> <img class="multi-images" controls src={data.url} alt="..." /></span>
                                        <button type="button" onClick={() => deleteImages(index)}>
                                          ✖
                                        </button>
                                      </div>
                                    ) : (
                                      <div className='cstm-vi-box' key={index}>
                                        <span className="cstm-vi-bx"> <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                        <button type="button" onClick={() => deleteImages(index)}>
                                          ✖
                                        </button>
                                      </div>
                                    )
                                )}
                              </div>


                              <input
                                // accept=".mov,.mp4"
                                multiple
                                type="file"
                                name="image_video"
                                id="o-image"
                                className='cstm-uploadHide'
                                // style={{ opacity: 0, cursor: 'pointer' }}
                                onChange={handleImageChange}
                              />
                            </div>

                            {err.image_video !== "" && (
                              <span style={{ color: "red" }} className="ml-3">
                                {err.image_video}
                              </span>
                            )
                            }

                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">News Title</label>
                            <input
                              name="news_title"
                              id="char-name"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter News Name"
                              onChange={handleOnchange}
                            />
                            {err.news_title !== "" && (
                              <span style={{ color: "red" }}>
                                {err.news_title}
                              </span>
                            )}
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Start Date</label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MM/dd/yyyy"
                              wrapperClassName="cstm-datepicker"
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">End Date</label>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="MM/dd/yyyy"
                              wrapperClassName="cstm-datepicker"
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Publisher/Source</label>
                            <input
                              name="source"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter Publisher/Source"
                              value={formFields.source}
                              onChange={handleOnchange}
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Location</label>
                            <PlacesAutocomplete
                              value={formFields.location}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <i class="fi fi-rr-search cstm-search-ro"></i>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'cstm-input-seacrh location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>

                            {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                {err.location}
                              </span>
                            )}
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Description</label>
                            <textarea
                              name="description"
                              rows="5"
                              id="description"
                              class="cstm-input cstm-textarea"
                              placeholder="Write a description..."
                              onChange={handleOnchange}
                            />
                            {err.description !== "" && (
                              <span style={{ color: "red" }}>
                                {err.description}
                              </span>
                            )}
                          </div>

                          <div class="mb-4 col-md-7">
                            <label class="cstm-label">
                              News Website Link (Optional)
                            </label>
                            <input
                              name="webURL"
                              id="webURL"
                              type="text"
                              class="cstm-input"
                              placeholder="Website Address"
                              onChange={handleOnchange}
                            />
                            {err.webURL !== "" && (
                              <span style={{ color: "red" }}>{err.webURL}</span>
                            )}
                          </div>
                          <div className="submit-btnMain cstm-loader">
                            <button
                              type="submit"
                              className="btn-submit-login-form cstm-submitbutton"
                            >
                              {" "}
                              Publish Now
                            </button>
                            <div
                              id="login-loader"
                              className="loader-main"
                              style={{ display: "none" }}
                            >
                              <img src={loader} />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddNews;
