import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { SidebarLayoutHeader } from '../../shared/components/SidebarLayoutHeader';
import userService from '../../Services/user.service';
import thankYouService from '../../Services/thank-you.service';
import { Pagination } from '../shared/Pagination';
import { useDebounceSearchText } from '../../common/hooks/use-debounce.hook';
import { useLoaderStore } from '../../stores/loader.store';
import { useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';

export function SendThankyouToUser() {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [users, setUsers] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const debouncedSearchText = useDebounceSearchText(searchText);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [sharedUserData, setSharedUserData] = useState([]);

    const appreciationId = searchParams.get('id');
    const type = searchParams.get('type');
    const teamIdsData = searchParams.get('teamIds');
    const senderUserId = searchParams.get('senderUserId');
    // let sharedUserData = [];

    useSetBreadcrumbs([
        { label: 'List', path: location.state?.breadcrumbPath },
        { label: 'Send To User', path: '' },
    ]);

    useEffect(() => {
        getSharedUserDetails();
    }, []);

    useEffect(() => {

        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getUsers(pageNumber, pagination.pageSize);

    }, [debouncedSearchText]);

    async function getSharedUserDetails() {
        try {
            const res = await thankYouService.getSharedThankyouDetailsByTypeAndId(type, appreciationId);

            if (res.status === 200) {
                setSharedUserData(res.data);
                let pageNumber = 1;
                setPagination({
                    pageNumber: pageNumber,
                    pageSize: pagination.pageSize,
                });
                await getUsers(pageNumber, pagination.pageSize, res.data);
                return;
            }

            toast.error("Couldn't get shared users");
            return;
        }
        catch (err) {
            toast.error("Couldn't get shared users");
        }
    }

    async function getUsers(pageNumber, pageSize, shareDetails = []) {
        setShowLoader(true);

        try {
            const res = await userService.getUsers({
                search: debouncedSearchText,
                page: pageNumber,
                limit: pageSize,
                organizationIds: [],
                teamIds: teamIdsData ? teamIdsData : [],
                isActive: true,
                nullFields: [],
            });

            if (res.status === 200) {
                // setUsers(res.data.results);
                setResultsCount(res.data.count);
                formatUserListByShare(res.data.results, shareDetails);
                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    function formatUserListByShare(users, sharedUserDataL) {
        if (sharedUserDataL && sharedUserDataL.sharedUsers) {
            let usersData = users.map((userData) => {
                if (sharedUserDataL.sharedUsers.indexOf(userData._id) != -1) {
                    userData.isShared = true;
                }
                else {
                    userData.isShared = false;
                }
                return userData;
            });
            console.log(usersData);
            setUsers(users);
        }
        else {
            setUsers(users);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getUsers(pageNumber, pagination.pageSize);
    }

    async function handleSendToUser() {
        setShowLoader(true);
        try {
            for (let i = 0; i < selectedUsers.length; i++) {
                let data = {
                    "appreciationType": type,
                    "type": "individual",
                    "userId": selectedUsers[i],
                    "appreciationId": appreciationId
                }
                const res = await thankYouService.sendMultipleThankyou(data);

                if (res.status == 200) {
                    let filteredData = users.map((data) => {
                        if (data._id == selectedUsers[i]._id) {
                            data.isShared = true;
                        }
                        return data;
                    });

                    setUsers(filteredData);
                    return;
                }
            }
            setSelectedUsers([]);

            toast.success("Successfully sent the thank you");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't send thank you");
        }
        finally {
            setShowLoader(false);
            handleSendUserCancel();
        }
    }

    function handleUserCheckboxChange(e, userId) {
        if (e.target.checked) {
            setSelectedUsers([...selectedUsers, userId]);
        } else {
            setSelectedUsers(selectedUsers.filter(id => id !== userId));
        }
    }

    function handleSendUserCancel() {
        // setSelectedUsers([]);
        document.querySelector('#sendUserConfirmation').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title="List" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className="p-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0">User</h4>


                                    <div style={{ marginRight: '100px' }} className="justify-content-end align-items-center">
                                        {selectedUsers.length > 0 && `Selected ${selectedUsers.length}`}
                                    </div>
                                </div>

                                <div className="row justify-content-end align-items-center">
                                    <div className="col-3 px-1">
                                        <i className="fi fi-rr-search cstm-search-ro"></i>
                                        <input
                                            name="search-user"
                                            id="search-user"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            type="text"
                                            className="cstm-input-seacrh"
                                            placeholder="Search Users"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-auto">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Name</th>
                                            <th className="border-bottom w-4 cstm-userheading">Email</th>
                                            <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                            <th className="border-bottom w-4 cstm-userheading">Job Title</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions
                                                <button
                                                    type='button'
                                                    className={`${selectedUsers.length == 0 ? 'disabled-send-button  mrn-rt' : 'cstm-delete cstm-eye mrn-rt'}`}
                                                    disabled={selectedUsers.length == 0}
                                                    // onClick={() => setSelectedUser(user)}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#sendUserConfirmation"
                                                >
                                                    <i className="fi fi-rr-paper-plane-top"></i>

                                                </button>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {users?.map((user, index) => (
                                            <tr
                                                key={user._id}
                                                className="cstm-Tabledesign cstm-usertable-design"
                                            >
                                                <td>{user.firstName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.organizations ? user.organizations[0]?.name : ''}</td>
                                                <td>{user.occupation}</td>
                                                <td>
                                                    {!user.isShared && <div>
                                                        <div className="mb-4 col-md-7">
                                                            <input disabled={user._id == senderUserId} type="checkbox" checked={selectedUsers.includes(user._id)} onClick={(e) => handleUserCheckboxChange(e, user._id)} />
                                                        </div>
                                                        {/* <button
                                                            type='button'
                                                            className="cstm-eye"
                                                            onClick={() => setSelectedUser(user)}
                                                            class="cstm-delete mrn-rt"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#sendUserConfirmation"
                                                        >
                                                            <i className="fi fi-rr-paper-plane-top"></i>

                                                        </button> */}

                                                    </div>}
                                                    {user.isShared && <div>shared</div>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {!users?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="sendUserConfirmation"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body py-5">
                                <div className="text-center">
                                    <div className="d-flex justify-content-center">
                                        <img src="" />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ display: "none" }}>
                                    </button>
                                    <h4 className="mt-4">
                                        Are you sure want to share this message to
                                        <br /> {selectedUsers?.length} users
                                    </h4>
                                    <div className="mt-4">
                                        <button
                                            className="mr-3 cstm-btn cstn-discard"
                                            onClick={handleSendToUser}
                                        >
                                            Share
                                        </button>
                                        <button className="cstm-btn2 cstn-discard" onClick={handleSendUserCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
