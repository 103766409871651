import { LOCAL_STORAGE_KEYS } from "../common/constants/local-storage.constant";

export default function authHeader() {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

  if (token) {
    return { Authorization: token };
  } else {
    return {};
  }

}
