import { toast } from "react-toastify";
import "./CopyToClipboard.css";

/**
 * @param {Object} props
 * @param {string} props.text
 */
export function CopyToClipboard({ text }) {
  async function onCopyClick() {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy to clipboard", err);
      toast.error("Error copying to clipboard");
    }
  }

  return (
    <button
      type="button"
      className="copy-to-clipboard-icon-btn"
      title="Copy to clipboard"
      onClick={onCopyClick}
    >
      <i className="fi fi-rr-copy-alt"></i>
    </button>
  );
}
