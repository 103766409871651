import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import $ from 'jquery';
import { Link } from "react-router-dom";
import deleteImg from "../../assets/Images/delete.png";


const Faq = () =>{
    var allFaqData = [];
    const [faqData,setFaqData] = useState([]);
    const [selectedFaqId,setSelectedFaqId] = useState();

    const [addVal, setAddVal] = useState({
       question:'',
       answer:''
    });

    const [errAddVal, setErrAddVal] = useState({
        question:'',
        answer:''
    });

    const [editVal, setEditVal] = useState({
        question1:'',
        answer1:'',
        faqId:''
    });
 
    const [errEditVal, setErrEditVal] = useState({
        question1:'',
        answer1:''
    });

  useEffect(()=>{
    getData(1);
  },[])

  async function getData(page){
    var bodyData ={
        "page":page,
        "limit":100
      }

      var res = await Services.getFaq(bodyData);
      
      if (res.status === 200) {
        console.log(res, "res");
        if (res.data.data.message_data.length !== 0) {
          var data = res.data.data.message_data;
          data.map((data1) => {
            allFaqData = [...allFaqData,data1]

          });
  
          if (res.data.data.state) {
            getData(res.data.data.state.page + 1);
          }
        }
      }

    setFaqData(allFaqData);
  }

  const onclickAdd = () =>{
    setAddVal({...addVal,["question"]: "" ,["answer"]: ""});
    $('#addfaqform')[0].reset();
    $('#answer').val('');
    $('.error-msg').hide();
  }

  const handleOnchangeAdd = (e) => {
    console.log(e.target.value, e.target.name);
    const { value, name } = e.target;
    if (name == "question") {
      errAddVal.question = value.length > 0 ? "" : "Enter question";
    }

    if (name == "answer") {
      errAddVal.answer = value.length > 0 ? "" : "Enter answer";
    }

    setAddVal({ ...addVal, [name]: value });
    setErrAddVal({ ...errAddVal });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    $('.error-msg').show();
    if (addVal.question == "") {
      errAddVal.question = "Enter question";
    }else{
      errAddVal.question = "";
    }

    if (addVal.answer == "") {
      errAddVal.answer = "Enter answer";
    }else{
      errAddVal.answer = '';
    }

    setErrAddVal({ ...errAddVal });

    if (errAddVal.question === '' && errAddVal.answer === '') {
        var bodyData = {
            question: addVal.question,
            answer: addVal.answer
        };

        console.log(bodyData,'bodyData');
        // return false
        Services.addFaq(bodyData).then((response) => {
          console.log(response, "response--");
          if (response.data.status === 200) {
            swal("Success", response.data.message, "success");
            getData(1);
            document.querySelector(".modal-backdrop").remove();
            // document.querySelector("#addgreetingmodel").classList.remove("show");
            document.querySelector("#addfaqmodel .btn-close").click();

          } else {
            swal("Failed", response.data.message, "error");
          }
        }).catch((err)=>{
          swal("Failed", err.response.data.message, "error");
        });
      
    }
  };

  const onClickCancel = ()=>{
    document.querySelector('.modal-backdrop').remove();
    document.querySelector("#cancelappointment .btn-close").click();
  }

  const onClickDelete = () => {
    Services.deleteFaq(selectedFaqId)
      .then((response) => {
        if (response.status === 200) {
          getData(1);
          swal("Success", response.data.message, "success");
          document.querySelector('.modal-backdrop').remove();
          document.querySelector("#cancelappointment .btn-close").click();
        } else {
          swal("Failed", response.data.message, "error");

        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  const setEditData = (_id,question,answer) =>{
    setEditVal({...editVal,faqId:_id,question1:question,answer1:answer})
  }

  const handleOnchangeEdit = (e) => {
    console.log(e.target.value, e.target.name);
    const { value, name } = e.target;
    if (name == "question1") {
      errEditVal.question1 = value.length > 0 ? "" : "Enter question";
    }

    if (name == "answer1") {
        errEditVal.answer1 = value.length > 0 ? "" : "Enter answer";
    }

    setEditVal({ ...editVal, [name]: value });
    setErrEditVal({ ...errEditVal });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    $('.error-msg').show();
    if (editVal.question1 == "") {
      errEditVal.question1 = "Enter question";
    }else{
        errEditVal.question1 = "";
    }

    if (editVal.answer1 == "") {
        errEditVal.answer1 = "Enter answer";
    }else{
        errEditVal.answer1 = '';
    }

    setErrEditVal({ ...errEditVal });

    if (errEditVal.question1 === '' && errEditVal.answer1 === '') {
        var bodyData = {
            question: editVal.question1,
            answer: editVal.answer1,
            id:editVal.faqId
        };

        console.log(bodyData,'bodyData');
        // return false
        Services.editFaq(bodyData).then((response) => {
          console.log(response, "response--");
          if (response.data.status === 200) {
            swal("Success", response.data.message, "success");
            getData(1);
            document.querySelector(".modal-backdrop").remove();
            // document.querySelector("#addgreetingmodel").classList.remove("show");
            document.querySelector("#editfaqmodel .btn-close").click();

          } else {
            swal("Failed", response.data.message, "error");
          }
        }).catch((err)=>{
          swal("Failed", err.response.data.message, "error");
        });
      
    }
  };
  
  return(
    <>
    <main className="page-content bg-light">
    <Header title="FAQs" main_title="Help Center/Support" />
    <div className="container-fluid">
      <div className="layout-specing">
        <div className="row">
          <div className="col-md-12">
            <div className="card border-0 shadow rounded">
              <div className="d-flex justify-content-between align-items-center p-3">
                <div class="col-md-2">
                    <h4 className="mb-0">FAQ's</h4>
                </div>
                <div class="col-md-10">
                      <div class="row row justify-content-end">
                        <div class="col-all-auto">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addfaqmodel"
                            onClick={onclickAdd}
                          >
                            <button class="cstm-btn">Add FAQs</button>
                          </a>
                        </div>
                      </div>
                    </div>
              </div>
              {/* <div className="col-all-auto">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#viewappointment"
                          >
                            <button className="cstm-btn">Add Tip</button>
                          </a>
                        </div> */}
              <div className="p-3 col-md-10 col-lg-12">
              <Accordion>
                { faqData.length !== 0 && faqData.map((faq)=>(
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <div className="cstm-faq-main">
                            <div className="cstm-faq-question">
                            {faq.question}
                            </div>
                           <div className="cstm-edit-delete-faq-icon">
                           <Link
                                class="cstm-eye"
                                data-bs-toggle="modal"
                                data-bs-target="#editfaqmodel"
                                to={" "}
                                onClick={() =>
                                    setEditData(faq._id,faq.question,faq.answer)
                                }
                                >
                                <i class="fi fi-rr-pencil"></i>
                            </Link>

                            <Link
                                to={" "}
                                onClick={() => setSelectedFaqId(faq._id)}
                                className="cstm-delete mrn-rt"
                                data-bs-toggle="modal"
                                data-bs-target="#cancelappointment"
                                >
                                <i className="fi fi-rr-trash"></i>
                            </Link>
                           </div>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                          {faq.answer}
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
               ))}
            </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

    <div
        class="modal fade"
        id="addfaqmodel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
        // onHide={()=>alert('dsf')}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Add Question
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form id="addfaqform" onSubmit={onSubmitAdd}>
              <div class="modal-body p-4 pt-0">
                <div class="mb-4">
                    <label class="cstm-label">Question</label>
                    <input type="text" 
                    onChange={handleOnchangeAdd}
                    className="cstm-input"
                    placeholder="Write a question"
                    name="question"
                    value={addVal.question}
                    />
                    {errAddVal.question !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.question}
                      </span>
                    )}
                </div>
                <div class="mb-4">
                  <label class="cstm-label">Answer</label>
                  <textarea
                    onChange={handleOnchangeAdd}
                    name="answer"
                    id="answer"
                    rows="3"
                    class="cstm-textarea px-3 py-2"
                    placeholder="Write a answer"
                    value={addVal.answer}
                  ></textarea>
                   {errAddVal.answer !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errAddVal.answer}
                      </span>
                    )}
                </div>
                <div class="d-flex">
                  <button class=" cstm-btn" type="submit">
                    Add Question
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* editval */}
      <div
        class="modal fade"
        id="editfaqmodel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 p-4">
              <h4 class="modal-title" id="exampleModalLabel1">
                Edit Question
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form id="editfaqform" onSubmit={onSubmitEdit}>
              <div class="modal-body p-4 pt-0">
                <div class="mb-4">
                    <label class="cstm-label">Question</label>
                    <input type="text" 
                    onChange={handleOnchangeEdit}
                    className="cstm-input"
                    placeholder="Write a question"
                    name="question1"
                    value={editVal.question1}
                    />
                    {errEditVal.question1 !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errEditVal.question1}
                      </span>
                    )}
                </div>
                <div class="mb-4">
                  <label class="cstm-label">Answer</label>
                  <textarea
                    onChange={handleOnchangeEdit}
                    name="answer1"
                    id="answer1"
                    rows="3"
                    class="cstm-textarea px-3 py-2"
                    placeholder="Write a answer"
                    value={editVal.answer1}
                  ></textarea>
                   {errEditVal.answer !== "" && (
                      <span class="error-msg" style={{ color: "red" }}>
                        {errEditVal.answer1}
                      </span>
                    )}
                </div>
                <div class="d-flex">
                  <button class=" cstm-btn" type="submit">
                    Edit Question
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      {/* Delete Tips  */}
      <div
        className="modal fade"
        id="cancelappointment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5">
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <img src={deleteImg} />
                </div>
                <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{display:"none"}}>
              </button>
                <h4 className="mt-4">
                  Are sure you want to delete
                  <br /> this question?
                </h4>
                <div className="mt-4">
                  <button
                    className="mr-3 cstm-btn cstn-discard"
                    onClick={onClickDelete}
                  >
                    Delete
                  </button>
                  <button className="cstm-btn2 cstn-discard" onClick={onClickCancel}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq;