import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Header from "../NavMenuBar/Header";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Services from "../../Services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import $ from 'jquery';
import loader from "../../assets/Images/loder.gif";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import organizationService from "../../Services/organization.service";

const EditCharity = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const cahrID = new URLSearchParams(getid).get("id");
  const [editcharityVal, setEditCharityVal] = useState({
    title: "",
    banner: "",
    image_video: [],
    category: "",
    categoryId: "",
    registrationNumber: "",
    webURL: "",
    mediaLink: "",
    about: "",
    location: "",
    isCharityVerified: true,
    allowedInPx: false
  });
  const [errEditCharityVal, setEditErrCharityVal] = useState({
    title: "",
    banner: "",
    image_video: "",
    category: "",
    mediaLink: "",
    webURL: "",
    about: "",
    location: "",
  });


  var imgExtArray = [];
  var imgArray = [];
  var bannimg = "";
  var imgExtArray = [];
  var videoExtArray = [];
  var videoArray = [];
  const DataArrayCat = [];
  const arrayImages = [];

  const [showCategoryIds, setShowCategoryIds] = useState("");
  const [charCategory, setCharCategory] = useState([]);
  const [videoPrev, setVideoPrev] = useState([]);
  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [arrayOfGetData, setArrayOfGetData] = useState([]);
  const [getBannerImg, setGetBannerImg] = useState("");
  const [getMultiFile, setMultiFile] = useState([]);
  const [organizationSelectionOptions, setOrganizationSelectionOptions] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState();
  const [organizations, setOrganizations] = useState();


  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");

    Services.getCharityById(cahrID)
      .then((response) => {
        if (response.data.status === 200) {
          var arrayImages = response.data.data.image_video;
          arrayImages.map(multifile => {
            var filesplit = multifile.split('.').pop();
            var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
            var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
            imageExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'image' });
            videoExtension.includes(filesplit) && videoExtArray.push({ url: multifile, type: 'video' });
          })

          setEditCharityVal({

            title: response.data.data.title,
            banner: response.data.data.banner ? response.data.data.banner : '',
            image_video: arrayImages,
            registrationNumber: response.data.data.registrationNumber,
            webURL: response.data.data.webURL,
            about: response.data.data.about,
            location: response.data.data.location,
            categoryId: response.data.data.categoryId,
            mediaLink: response.data.data.mediaLink,
            allowedInPx: response.data.data.allowedInPx

          });

          setVideoPrev([...videoPrev, ...videoExtArray]);
          setMultiFile([...arrayImages]);
          setShowCategoryIds(response.data.data.categoryId);
          setGetBannerImg(response.data.data.banner);
          
        getOrganizations(response.data.data.organizationIds);
          

        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
    getCatData(1);
  }, []);

  function changeOrganziationSelection(e, value) {
    console.log(e);
    let found = organizations.find((x) => {
      return x._id == e.option.value && x.charities.length > 0 && x.charities[0]._id != cahrID;
    });
    console.log(found)
    if(found && e.action != 'deselect-option') {
      toast.error(`This organization is already linked to chairty: ${found.charities[0].title}` );
      return;
    }
    setSelectedOrganizations(value);
  }


  async function getOrganizations(organizationIds) {
    setOrganizationSelectionOptions([]);

    try {
      const res = await organizationService.getOrganizations(1, 50);

      if (res.status == 200) {
        let organizationsData = res.data?.results;

        if (organizationsData?.length) {
          setOrganizations(organizationsData);
          const organizationSelectionOptionsData = organizationsData.map((organization) => formatOrganizationForDropdown(organization));

          setOrganizationSelectionOptions(organizationSelectionOptionsData);

          let selectedOrganizationsData = [];

          if (organizationIds && organizationIds.length > 0) {
            organizationsData.forEach((organization) => {
              const userOrganization = organizationIds.find((organizationId) => {
                return organizationId === organization._id
              });

              if (userOrganization) {
                selectedOrganizationsData.push(formatOrganizationForDropdown(organization));
              }
            });

            setSelectedOrganizations(selectedOrganizationsData);
          }
        }

        return;
      }

      toast.error("Couldn't get organizations");
    } catch (err) {
      toast.error("Couldn't get organizations");
    }
  }

  function formatOrganizationForDropdown(organization) {
    return {
      value: organization._id,
      label: organization.name,
    };
  }



  //   get category for add charity
  const getCatData = (pageno) => {
    const queryVar = {
      page: pageno,
      limit: 100,
      type: 0,
    };

    Services.getCharityCat(queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.data.category_count.length !== 0) {
            var data = response.data.data.category_count;
            data.map((data1) => {
              DataArrayCat.push(data1);
            });
          }
          setCharCategory(DataArrayCat);
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "erron api");
      });
  };

  const handleOnchangeUpdateCheckbox = (e) => {
    const { checked, name } = e.target;

    setEditCharityVal({ ...editcharityVal, [name]: checked });
  }

  // console.log(editcharityVal, "editcharityVal");
  const handleOnchangeUpdate = (e) => {
    const { value, name } = e.target;
    console.log(e.target.value !== "", "target")

    if (e.target.name == "title") {
      errEditCharityVal.title = value.length > 0 ? "" : "Enter charity name";
    }
    if (e.target.name == "category") {
      errEditCharityVal.category = value.length > 0 ? "" : "Enter category";
    }
    // if (e.target.name == "registrationNumber") {
    //   errEditCharityVal.registrationNumber =
    //     value.length > 0 ? "" : "Enter registration number";
    // }
    if (e.target.name == "webURL") {
      errEditCharityVal.webURL =
        value.length > 0 ? "" : "Enter your website address";
      errEditCharityVal.webURL = !new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      ).test(value)
        ? "Enter a valid website url"
        : "";
    }
    if (e.target.name == "mediaLink") {
      errEditCharityVal.mediaLink =
        value.length > 0 && !new RegExp(
          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
        ).test(value)
          ? "Enter a valid media link"
          : "";
    }

    if (e.target.name == "about") {
      errEditCharityVal.about = value.length > 0 ? "" : "enter about";
    }
    setEditCharityVal({ ...editcharityVal, [name]: value });
    setEditErrCharityVal({ ...errEditCharityVal });
    setShowCategoryIds(editcharityVal.categoryId = e.target.value);
  };
  //image onchange

  const [file, setFile] = useState();
  const [bannerImg, setBannerImg] = useState("")
  const handleImageChangeBanner = (e) => {
    e.preventDefault();
    errEditCharityVal.banner = e.target.value.length > 0 ? "" : "Select banner image";

    setFile(URL.createObjectURL(e.target.files[0]));
    var charImage = e.target.files[0];
    setBannerImg(charImage);
    console.log(charImage, "charImage");
    setEditCharityVal({ ...editcharityVal, banner: '' })

  }

  function deleteImagesGet(e) {

    const vp = videoPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setVideoPrev([...vp]);

  }

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs])
    setImagesPrev([...s]);

  }

  const handleImageChangeorg = (e) => {
    e.preventDefault();
    errEditCharityVal.image_video = e.target.value.length > 0 ? "" : "Select image or video";


    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];

    })
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    imgArray.forEach(function (f, index) {

      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split('.').pop();
      var imageExtension = ["png", "jpg", "jpeg", "apng", ".avif", "jfif", "pjpeg", "pjp", "svg", "webp"];
      var videoExtension = ["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm", "html5", "mpeg-2"];
      imageExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'image' });
      videoExtension.includes(filesplit) && imgExtArray.push({ url: u, type: 'video' });

    })

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);

  };

  const handleChange = address => {
    setEditCharityVal({ ...editcharityVal, ['location']: address });
  };

  const handleSelect = address => {
    setEditCharityVal({ ...editcharityVal, ['location']: address });
  };


  const handleOnSubmitUpdate = async (e) => {
    e.preventDefault();

    if (editcharityVal.title === "") {
      errEditCharityVal.title = "Enter charity name";
    } else {
      errEditCharityVal.title = "";
    }
    if (editcharityVal.categoryId === "") {
      errEditCharityVal.category = "Enter category";
    } else {
      errEditCharityVal.category = "";
    }
    // if (editcharityVal.image_video ==="") {
    //   errEditCharityVal.image_video = "Select image or video";
    // }else if(finalImages.length ===0){
    //   errEditCharityVal.image_video = "Select image or video";
    // } else {
    //   errEditCharityVal.image_video = "";
    // }
    // if (editcharityVal.banner === "") {
    //   errEditCharityVal.banner = "Select banner image";
    // } else {
    //   errEditCharityVal.banner = "";
    // }
    if (editcharityVal.webURL === "") {
      errEditCharityVal.webURL = "Enter your website address";
    }
    else if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(editcharityVal.webURL)) {

      errEditCharityVal.webURL = "Enter a valid website url";
    }
    else {
      errEditCharityVal.webURL = "";
    }

    if (editcharityVal.mediaLink !== "") {
      if (!new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(editcharityVal.mediaLink)) {

        errEditCharityVal.mediaLink = "Enter a valid media link";
      }
      else {
        errEditCharityVal.mediaLink = "";
      }

    }
    if (editcharityVal.about === "") {
      errEditCharityVal.about = "Write about";
    } else {
      errEditCharityVal.about = "";
    }
    setEditErrCharityVal({ ...errEditCharityVal });
    setEditCharityVal({ ...editcharityVal });


    if (videoPrev) {

      videoPrev.map(data => {
        videoArray.push(data.url);
      })
      setArrayOfGetData(videoArray)
    }

    if(editcharityVal.allowedInPx && (!selectedOrganizations || selectedOrganizations.length === 0)){
      swal("Validation Error", "Please select a organization to allow the charity in PX", "error");
      return;
    }

    if (
      errEditCharityVal.title === "" &&
      errEditCharityVal.banner === "" &&
      errEditCharityVal.image_video === "" &&
      errEditCharityVal.category === "" &&
      // errEditCharityVal.registrationNumber === "" &&
      errEditCharityVal.webURL === "" &&
      errEditCharityVal.about === ""
    ) {
      $(".loader-main").show();

      if (bannerImg !== "")
        await UploadBannerImage()

      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {

            if (response.status === 200) {
              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              var finalImagesData = [];
              finalImagesData = [...arrayImages, ...videoArray]

              // EditCharityData(finalImagesData, getBannerImg);

              if (bannimg) {
                EditCharityData(finalImagesData, bannimg);

              } else {
                EditCharityData(finalImagesData, getBannerImg);

              }


            }

          })
          .catch(function (err) {
            console.log(err, "err");

          });
      } else {

        if (bannimg) {
          EditCharityData(videoArray, bannimg);

        } else {
          EditCharityData(videoArray, getBannerImg);

        }

      }

      function EditCharityData(finalImagesData, getBannerImg) {

        if (editcharityVal) {

          var editCharityVal = {
            title: editcharityVal.title,
            banner: getBannerImg,
            image_video: finalImagesData,
            registrationNumber: editcharityVal.registrationNumber,
            webURL: editcharityVal.webURL,
            about: editcharityVal.about,
            location: editcharityVal.location,
            isCharityVerified: editcharityVal.isCharityVerified,
            categoryId: editcharityVal.categoryId,
            mediaLink: editcharityVal.mediaLink,
            allowedInPx: editcharityVal.allowedInPx,
            organizationIds: selectedOrganizations?.map((organization) => organization.value) || [],
            id: cahrID,
          };

          Services.editCharity(editCharityVal)
            .then((response) => {
              $(".loader-main").hide();

              if (response.data.status == 200) {
                swal("Success", response.data.message, "success");

              } else {
                swal("Failed", response.data.message, "error");
              }
            })
            .catch((err) => {
              swal("Failed", err.response.data.message, "error");
            });
        }
      }

    }
  };


  async function UploadBannerImage() {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("image", bannerImg);
      //banner image upload
      Services.charimageGet(formData)
        .then((response) => {
          console.log(response, "response");
          if (response.status === 200) {
            bannimg = response.data.data.images[0].location;
            editcharityVal.banner = response.data.data.images[0].location;
            resolve("success")
          } else {
            reject("failed")
          }
        })
        .catch(function (err) {
          reject("failed")
          console.log(err, "err");
        });
    })


  }



  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Edit Charity"
          title="Charity List"
          main_title="Charity"
          title_link="charity"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Edit Charity</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-4">
                  <form id="edit-charity" onSubmit={handleOnSubmitUpdate}>
                    <div class="mb-4 col-md-7">
                      <PlacesAutocomplete
                        value={editcharityVal.location}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <i class="fi fi-rr-search cstm-search-ro"></i>
                            <input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'cstm-input-seacrh location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {errEditCharityVal.location !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.location}
                        </span>
                      )}
                    </div>

                    {/* <div class="mb-4 row">
                            <i class="fi fi-rr-search cstm-search-ro cstm-search-icon"></i>
                            <input
                              name="location"
                              id="location"
                              type="text"
                              class="cstm-input-seacrh cstm-input-screen2"
                              // onChange={handleOnchangeForSearch}
                              ref={ref}
                              placeholder="Find & Select your Organization on Google"
                            />
                            {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                {err.location}
                              </span>
                            )
                            }
                          </div> */}
                    <div class="mb-4">
                      <label class="cstm-label">
                        Upload Logo
                      </label><br />
                      <div className="cstm-Videoalign">
                        <label for="banner-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                        {/* <div id="login-loader" className='loader-main uploaded-banner' style={{ display: 'none' }}>
                                <img src={loader} />
                              </div> */}
                        {
                          editcharityVal.banner !== undefined &&
                          editcharityVal.banner !== "" &&
                          <img
                            id="imageB"
                            className="AddOrganization-bannerImg"
                            src={`${process.env.REACT_APP_S3_BASE_URL}/` + editcharityVal.banner}
                          />
                        }
                        {file && file.length > 0 && <img
                          id="b-img"
                          className="AddOrganization-bannerImg"
                          src={file}
                        />}
                        {/* <img
                                id="imageB"
                                className="AddOrganization-bannerImg"
                                src={
                                  editcharityVal.banner !== undefined &&
                                    editcharityVal.banner !== null
                                    ? "https://human-backend-data.s3.eu-west-2.amazonaws.com/" +
                                    editcharityVal.banner
                                    : ''
                                }

                              /> */}
                        {/* {file && errEditCharityVal.banner === "" && (
                                <img
                                  id="b-img"
                                  src={file}
                                  className="AddOrganization-bannerImg"
                                />
                              )} */}

                        <input
                          // accept="image/*"
                          type="file"
                          name="banner"
                          id="banner-image"
                          className='cstm-uploadHide'
                          // value={"https://human-backend-data.s3.eu-west-2.amazonaws.com/"+editcharityVal.banner}

                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeBanner}
                        />
                      </div>
                      {errEditCharityVal.banner !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.banner}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 ">
                      <label class="cstm-label">Upload Image or Video</label><br />


                      <div className='cstm-video-image-upload'>
                        <label for="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                        <div className='cstm-vi-in'>
                          {imagesPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<>                 <div className='cstm-vi-box'>
                                <span className="cstm-vi-bx">  <img class="multi-images" controls src={data.url} alt="..." /></span>
                                <button type="button" onClick={() => deleteImages(index)}>
                                  ✖
                                </button></div>
                              </>
                              ) : (
                                <>                 <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">  <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImages(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )

                          )}
                          {/* </div>

                              <div className='cstm-vi-in'> */}
                          {videoPrev.map((data, index) =>

                            data.type === 'image' ?

                              (<> <div className='cstm-vi-box'>
                                <span className="cstm-vi-bx">  <img class="multi-images getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                <button type="button" onClick={() => deleteImagesGet(index)}>
                                  ✖
                                </button>
                              </div>
                              </>
                              ) : (
                                <> <div className='cstm-vi-box'>
                                  <span className="cstm-vi-bx">  <video class="multi-videos getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                  <button type="button" onClick={() => deleteImagesGet(index)}>
                                    ✖
                                  </button>
                                </div>
                                </>
                              )

                          )}
                        </div>


                        <input
                          // accept=".mov,.mp4"
                          multiple
                          type="file"
                          name="image_video"
                          id="org-image"
                          className='cstm-uploadHide'
                          // value={editcharityVal.image_video}
                          // style={{ opacity: 0, cursor: 'pointer' }}
                          onChange={handleImageChangeorg}
                        />
                        {errEditCharityVal.image_video !== "" && (
                          <span style={{ color: "red" }} className="ml-3">
                            {errEditCharityVal.image_video}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Charity Display Name
                      </label>
                      <input
                        name="title"
                        id="char-name"
                        type="text"
                        class="cstm-input"
                        placeholder="Charity Display Name"
                        value={editcharityVal.title}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.title !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.title}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Category</label>
                      <select
                        name="categoryId"
                        class="form-select cstm-category"
                        onChange={handleOnchangeUpdate}
                        value={showCategoryIds}
                      >
                        {/* <option value="">Category</option> */}

                        {charCategory.map((cat) => {
                          return (
                            <option value={cat._id}>{cat.title}</option>
                          );
                          // console.log(cat.title,"single cat")
                        })}
                      </select>

                      {errEditCharityVal.category !== "" && (
                        <span style={{ color: "red" }} className="ml-3">
                          {errEditCharityVal.category}
                        </span>
                      )}
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Charity Donation URL</label>
                      <input
                        name="webURL"
                        id="webURL"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        value={editcharityVal.webURL}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.webURL !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.webURL}
                        </span>
                      )}
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Media Link</label>
                      <input
                        name="mediaLink"
                        id="mediaLink"
                        type="text"
                        class="cstm-input"
                        placeholder="Media Link"
                        value={editcharityVal.mediaLink}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.mediaLink !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.mediaLink}
                        </span>
                      )
                      }
                    </div>
                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        Registration Number
                      </label>
                      <input
                        name="registrationNumber"
                        id="webUregistrationNumberRL"
                        type="text"
                        class="cstm-input"
                        placeholder="Charity Registration Number"
                        value={editcharityVal.registrationNumber}
                        onChange={handleOnchangeUpdate}
                      />
                      {/* {errEditCharityVal.registrationNumber !== "" && (
                              <span style={{ color: "red" }}>
                                {errEditCharityVal.registrationNumber}
                              </span>
                            )} */}
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">About</label>
                      <textarea
                        name="about"
                        id="about"
                        type="textarea"
                        rows="5"
                        class="cstm-input cstm-textarea"
                        placeholder="About..."
                        value={editcharityVal.about ? editcharityVal.about : ''}
                        onChange={handleOnchangeUpdate}
                      />
                      {errEditCharityVal.about !== "" && (
                        <span style={{ color: "red" }}>
                          {errEditCharityVal.about}
                        </span>)}
                    </div>

                    {/* <div class="d-flex">
                            <button class=" cstm-btn">Edit Charity</button>
                          </div> */}
                    <div className="mb-4 col-md-7">
                      <input name="allowedInPx" id="allowedInPx" type="checkbox" checked={editcharityVal.allowedInPx} onChange={handleOnchangeUpdateCheckbox} />
                      <label className="cstm-label">Available in PX</label>
                    </div>

                    {organizationSelectionOptions && organizationSelectionOptions.length > 0 && <div className="mb-4">
                      <label className="cstm-label">Organization Association</label>

                      <ReactMultiSelectCheckboxes
                        className="form-select"
                        options={organizationSelectionOptions}
                        placeholderButtonLabel="Select organizations"
                        value={selectedOrganizations}
                        onChange={(value, e) => changeOrganziationSelection(e, value)}
                        getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${value?.length ? value.length : 0} organization(s) selected`}
                      />
                    </div>}

                    <div className="submit-btnMain cstm-loader">
                      <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">UPDATE & SAVE</button>
                      <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                        <img src={loader} />
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditCharity;