import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../../stores/loader.store';
import { SidebarLayoutHeader } from '../../../shared/components/SidebarLayoutHeader';
import settingService from '../../../Services/settings.service';

export function AddEditCrowdFundSetting() {
    const history = useNavigate();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const { id } = useParams();
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        if (id) {
            fetchCrowdFundSetting(id);
        }
    }, [])

    async function fetchCrowdFundSetting(id) {
        setShowLoader(true);
        try {
            let res = await settingService.getCrowdFundById(id);

            if (res.status == 200) {
                let data = res.data;
                setValue('countryCode', data.countryCode);
                setValue('countryName', data.countryName);
                setValue('crowdFundUrl', data.crowdFundUrl);
                return;
            }
        }
        catch (err) {
            toast.error("Couldn't fetch crowd fund setting");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function onSubmit() {
        if (id) {
            await updateCrowdFundSetting();
            return;
        }
        createCrowdFundSetting();
    }

    async function updateCrowdFundSetting() {
        setShowLoader(true);
        try {
            let data = {
                countryCode: getValues('countryCode'),
                countryName: getValues('countryName'),
                crowdFundUrl: getValues('crowdFundUrl')
            }

            let res = await settingService.updateCrowdFund(id, data);

            if (res.status == 200) {
                toast.success("Successfully updated crowd fund settings");
                history('/crowd-fund-settings');
                setShowLoader(false);
                return;
            }
        }
        catch (err) {
            toast.error("Couldn't update crowd fund settings");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function createCrowdFundSetting() {
        setShowLoader(true);
        try {
            let data = {
                countryCode: getValues('countryCode'),
                countryName: getValues('countryName'),
                crowdFundUrl: getValues('crowdFundUrl')
            }

            let res = await settingService.createCrowdFund(data);

            if (res.status == 200) {
                toast.success("Successfully saved crowd fund settings");
                history('/crowd-fund-settings');
                setShowLoader(false);
                return;
            }
        }
        catch (err) {
            toast.error("Couldn't save crowd fund settings");
        }
        finally {
            setShowLoader(false);
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title="Add/Edit Crowd Fund Setting" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <h4 className="mb-4">Add/Edit Crowd Fund Setting</h4>

                        <form onSubmit={handleSubmit(onSubmit)}
                            className="card border-0 shadow rounded mb-4 p-4">

                            <div className="mb-4">
                                <label className="cstm-label">Country Code (Add without +)</label>

                                <input
                                    className="cstm-input"
                                    type="text"
                                    placeholder="Enter Country Code"
                                    {...register("countryCode", {
                                        required: true
                                    })}
                                />
                                {errors.countryCode && errors.countryCode.type === 'required' && <span className="alert-font af">Country code is required.</span>}

                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Country name</label>

                                <input
                                    className="cstm-input"
                                    type="text"
                                    placeholder="Enter Country Name"
                                    {...register("countryName", {
                                        required: true
                                    })}
                                />
                                {errors.countryName && errors.countryName.type === 'required' && <span className="alert-font af">Country name is required.</span>}

                            </div>

                            <div className="mb-4">
                                <label className="cstm-label">Crowd Fund Url</label>

                                <input
                                    className="cstm-input"
                                    type="text"
                                    placeholder="Enter Crowd Fund Url"
                                    {...register("crowdFundUrl", {
                                        required: true
                                    })}
                                />
                                {errors.crowdFundUrl && errors.crowdFundUrl.type === 'required' && <span className="alert-font af">Crowd Fund Url is required.</span>}

                            </div>

                            <div className="submit-btnMain cstm-loader">
                                <button type="submit" className="btn-submit-login-form cstm-submitbutton">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </>

    )
}