import React, { useState, useEffect } from "react";
import { Link,NavLink, useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import Services from "../../Services/auth.service";
import { LOCAL_STORAGE_KEYS } from "../../common/constants/local-storage.constant";

function Header(props) {
  const navigate = useNavigate();
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const { data: profileRes } = useQuery({
    queryKey: ['profile', userId],
    queryFn: Services.getAdminprofile,
    staleTime: Infinity,
    enabled: !!userId,
  });

  useEffect(() => {
    if (!profileRes) {
      return;
    }

    if (profileRes.status === 200) {
      setProfileImageUrl(`${process.env.REACT_APP_S3_BASE_URL}/${profileRes.data.data.image}`);
    }
  }, [profileRes]);

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    navigate("/");
  };

  return (
    <>
      <div className="top-header">
        <div className="header-bar d-flex justify-content-between border-bottom cstm-header-image">
          <div className="d-flex align-items-center">
            <h6 className="mb-0">
              {props.title}
              {props.main_title && (
                <>
                  <br />
                  <span className="page-nav-list">
                    <a href="#">{props.main_title + " > "}</a> 
                    {props.title1 ? <Link to={"/"+props.title_link}>{props.title}</Link> :  props.title}
                    
                    {props.title1 && " > "}{props.title1}
                  </span>
                </>
              )}
            </h6>
          </div>
          <ul className="list-unstyled mb-0">
            <li className="list-inline-item mb-0 ms-1">
              <div className="dropdown dropdown-primary">
                <button
                  type="button"
                  className="btn btn-pills dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                   <img
                      src={props.profileimage ? props.profileimage : profileImageUrl}
                      height="40px"
                      width="40px"
                    />{" "}
                  <i className="fi fi-rr-caret-down cstm-icon-mn"></i>
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end csrm-user-dv">
                  <a href="#" className="active-us">
                    Dashboard
                  </a>
                  <NavLink
                    to="/profile"
                    className={({ isActive }) => (isActive ? "active-us" : "")}
                  >
                    Profile
                  </NavLink>
                  <NavLink
                    to="/account-setting"
                    className={({ isActive }) => (isActive ? "active-us" : "")}
                  >
                    Account Setting
                  </NavLink>
                  <a href="" onClick={logout} className="cstm-logout">
                    Logout
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
