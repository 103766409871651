import { createPortal } from "react-dom";
import { useLoaderStore } from "../../../stores/loader.store";

import './FullScreenLoader.css';

export function FullScreenLoader() {
    const showLoader = useLoaderStore((state) => state.showLoader);
    const loadingText = useLoaderStore((state) => state.loadingText);

    if (!showLoader) {
        return null;
    }

    return createPortal(
        <div className="full-screen-loader">
            <div className="full-screen-loader-spinner"></div>

            <p className="full-screen-loader-text">{loadingText || "Loading..."}</p>
        </div>,
        document.getElementById("full-screen-loader-portal")
    );
}
