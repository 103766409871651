import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState,ContentState} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import { convertToHTML } from 'draft-convert';
import swal from "sweetalert";

const PrivacySetting = () =>{
  const [Id,setId] = useState();
  let titlesArr=[1,2,3,4,5,6];
  
    const [formFields, setFormFields] = useState({
        title1:'',
        title2:'',
        title3:'',
        title4:'',
        title5:'',
        title6:'',
        link1:'',
        link2:'',
        link3:'',
        link4:'',
        link5:'',
        link6:'' 
    });

    const [err, setErr] = useState({
        title1:'',
        title2:'',
        title3:'',
        title4:'',
        title5:'',
        title6:'',
        link1:'',
        link2:'',
        link3:'',
        link4:'',
        link5:'',
        link6:''
    });
    
  useEffect(()=>{
    
    Services.getPrivacySetting().then((response)=>{
      console.log(response.data.data.data[0],'response');
      if(response.status === 200){
        var resData = response.data.data.data[0];
        //setContent(response.data.data.message_data.description);
        setId(resData._id);
        setFormFields({...setFormFields,title1:resData.title1,title2:resData.title2,title3:resData.title3,title4:resData.title4,title5:resData.title5,title6:resData.title6,link1:resData.title_link_1,link2:resData.title_link_2,link3:resData.title_link_3,link4:resData.title_link_4,link5:resData.title_link_5,link6:resData.title_link_6})
      }
    }).
    catch((err)=>{
      console.log(err,'err');
    })
  },[])

  const handleOnSubmitUpdate = (e) =>{
  e.preventDefault();

  console.log(formFields);
  titlesArr.map((data)=>{
      if(formFields['title'+data] == ''){
        err['title'+data] = 'Please enter title'+data;
      }else{
        err['title'+data] = '';
      }
   
      if(formFields['link'+data] == ''){
        err['link'+data] = 'Please enter link'+data;
      }else{
        err['link'+data] = !new RegExp(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ).test(formFields['link'+data])
          ? "Enter a valid link"+data
          : '';
      }
    
  });

  
  setErr({...err})

  if(err.title1 == '' && err.title2 == '' && err.title3 == '' && err.title4 == ''  && err.title5 == '' && err.title6 == '' && err.link1 == '' && err.link2 == '' && err.link3 == '' && err.link4 == '' && err.link5 == '' && err.link6 == '' ) {

  console.log(formFields,'formFields');
  
    var bodyData = {
      "id": Id,
      "advertising_or_product_title": formFields.title1,
      "advertising_or_product_link": formFields.link1,
      "data_collection_or_sale_title": formFields.title2,
      "data_collection_or_sale_link": formFields.link2,
      "inappropriate_content_title": formFields.title3,
      "inappropriate_content_link": formFields.link3,
      "only_your_creed_member_title": formFields.title4,
      "only_your_creed_member_link": formFields.link4,
      "search_engine_index_title":formFields.title5,
      "search_engine_index_link": formFields.link5,
      "tracking_use_pattern_title": formFields.title6,
      "tracking_use_pattern_link": formFields.link6
    }
    Services.updatePrivacySetting(bodyData).then((response)=>{
      if (response.data.status === 200) {
        swal("Success", response.data.message, "success");
      } else {
        swal("Failed", response.data.message, "error");
      }
    }).catch((err)=>{
      swal("Failed",err.response.data.message, "error");

      console.log(err,'err');
    })
  }
  }

  const onChangeHandle = (e,data) =>{
    // console.log(e.target.name,e.target.value);
    setFormFields({...formFields,[e.target.name]: e.target.value });

    if(e.target.name.includes('title')){
      if(e.target.value === ''){
        err['title'+data] = 'Please enter title'+data;
      }else{
        err['title'+data] = '';
      }
    }else{
      if(e.target.value === ''){
        err['link'+data] = 'Please enter link'+data;
      }else{
        err['link'+data] = !new RegExp(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ).test(e.target.value)
          ? "Enter a valid link"+data
          : '';
      }
    }

    setErr({ ...err});

   
  }

  return(
    <>
    <main className="page-content bg-light">
    <Header title="Privacy Setting" main_title="Help Center/Support" />
    <div className="container-fluid">
      <div className="layout-specing">
        <div className="row">
          <div className="col-md-12">
            <div className="card border-0 shadow rounded">
              <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h4 className="mb-0">Privacy Setting</h4>
              </div>
              <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                <form id="" onSubmit={handleOnSubmitUpdate}>
                    {titlesArr.map((data)=>(
                    <>
                    <div className="row" key={`${data}`}>
                        <div class="col-md-6">
                            <label class="cstm-label">Title {data}</label>
                            <input type="text" 
                            onChange={(e)=>onChangeHandle(e,data)}
                            className="cstm-input"
                            placeholder="Enter title"
                            name={`title${data}`}
                            id={`title${data}`}
                            value={formFields['title'+data]}
                            />
                            {err['title'+data] !== "" && (
                            <span class="error-msg" style={{ color: "red" }}>
                                {err['title'+data]}
                            </span>
                            )}
                        </div>
                        <div class="col-md-6">
                            <label class="cstm-label">Link {data}</label>
                            <input type="text" 
                            onChange={(e)=>onChangeHandle(e,data)}
                            className="cstm-input"
                            placeholder="Enter link"
                            name={`link${data}`}
                            id={`link${data}`}
                            value={formFields['link'+data]}
                            />
                            {err['link'+data] !== "" && (
                            <span class="error-msg" style={{ color: "red" }}>
                               {err['link'+data]}
                            </span>
                            )}
                        </div>
                        
                    </div>
                    <br/>
                    </>
                  ))}
                  <div className="d-flex">
                    <button className=" cstm-btn" type="submit">
                      Submit
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
    </>
  )
}

export default PrivacySetting;