import { useState } from 'react';
import './MultiSelectDropdown.css';

export function MultiSelectDropdown({ label, items, selectedIds, disabled, onChange }) {
    const [showDropdown, setShowDropdown] = useState(false);

    function onItemClick(item) {
        if (selectedIds.includes(item.id)) {
            const newSelectedIds = selectedIds.filter((id) => id !== item.id);
            onChange(newSelectedIds);
        } else {
            const newSelectedIds = [...selectedIds, item.id];
            onChange(newSelectedIds);
        }
    }

    if (disabled && showDropdown) {
        setShowDropdown(false);
    }

    return (
        <div className='custom-multiselect-container'>
            <div
                className={`custom-multiselect-header ${disabled ? 'disabled' : ''}`}
                onClick={() => {
                    if (disabled) {
                        return;
                    }

                    setShowDropdown(!showDropdown);
                }}
            >
                <div>{label}: {selectedIds.length} selected</div>
                <i className="fi fi-rr-angle-small-down"></i>
            </div>
            <ul
                className={`custom-multiselect-dropdown ${(!disabled && showDropdown) ? '' : 'd-none'}`}
            >
                {items.map((item) => (
                    <li key={item.id} onClick={() => onItemClick(item)}>
                        <input type="checkbox" checked={selectedIds.includes(item.id)} readOnly />
                        <span>{item.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
