import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import loader from '../../assets/Images/loder.gif';
import userService from '../../Services/user.service';
import { Pagination } from '../shared/Pagination';
import { formatDateForTable } from '../../common/utils/date.util';

export function PendingEmailVerificationUserList() {
    const [users, setUsers] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [selectedUserIdForApprove, setSelectedUserIdForApprove] = useState(null);

    useEffect(() => {
        getUsers(pagination.pageNumber, pagination.pageSize);
    }, []);

    async function getUsers(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await userService.getPendingEmailVerificationUsers({
                pageNumber: pageNumber,
                pageSize: pageSize,
            });

            if (res.status === 200) {
                setUsers(res.data.results);
                setResultsCount(res.data.count);
                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getUsers(pageNumber, pagination.pageSize);
    }

    async function verifyUser(userId) {
        try {
            const res = await userService.verifyUserEmail(userId);

            if (res.status == 200) {
                toast.success("Successfully verified user");
                getUsers(pagination.pageNumber, pagination.pageSize);
                return;
            }

            toast.error("Couldn't verify user");
        } catch (err) {
            toast.error("Couldn't verify user");
        }
    }

    async function sendverifyEmail(userId) {
        try {
            const res = await userService.sendVerifyUserEmail(userId);

            if (res.status == 200) {
                toast.success("Successfully send verification email to user");
                getUsers(pagination.pageNumber, pagination.pageSize);
                return;
            }

            toast.error("Couldn't send verification email");
        } catch (err) {
            toast.error("Couldn't send verification email");
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <Header title="Pending Email Verifications" main_title="Admin Users" />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className='cstm-table-main'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow rounded">
                                        <div className="row justify-content-between align-items-center p-3 ">
                                            <h4 className="mb-0">Pending Email Verifications</h4>
                                        </div>
                                        <div className="col-md-12 col-lg-12">
                                            <table className="table mb-0 table-center">
                                                <thead>
                                                    <tr className="border-top">
                                                        <th className="border-bottom w-4 cstm-userheading">Name</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Contact</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Email</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Joined Date</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Teams</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {users?.map((user) => (
                                                        <tr
                                                            key={user._id}
                                                            className="cstm-Tabledesign cstm-usertable-design"
                                                        >
                                                            <td>{user.firstName}</td>
                                                            <td>{user.phoneNumber}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.createdAt ? formatDateForTable(user.createdAt) : null}</td>
                                                            <td>
                                                                {user.organizations?.length &&
                                                                    user.organizationData.map(data => {
                                                                        return data.name
                                                                    }).join(",")}
                                                            </td>
                                                            <td>
                                                                {user.teams?.map((team) => (
                                                                    <div>{team.name}</div>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <Link
                                                                        onClick={() => setSelectedUserIdForApprove(user._id)}
                                                                        className="cstm-chekmank"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#verifyUserEmailModal"
                                                                    >
                                                                        <i className="fi fi-rr-check"></i>
                                                                    </Link>
                                                                </div>
                                                                <div>
                                                                    <Link
                                                                        onClick={() => setSelectedUserIdForApprove(user._id)}
                                                                        className="cstm-chekmank"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#sendVerifyUserEmailModal"
                                                                    >
                                                                        <i className="fi fi-rr-envelope"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {!users?.length && (
                                                <div className="NoRecord-cstm">No records found!</div>
                                            )}

                                            {showLoader && (
                                                <div className="NoRecord-cstm">
                                                    <img className="pageloader" src={loader} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="verifyUserEmailModal"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body py-5">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ display: "none" }}>
                                    </button>
                                    <h4 className="mt-4">
                                        Are sure you want to verify the user?
                                    </h4>
                                    <div className="mt-4">
                                        <button
                                            className="mr-3 cstm-btn cstn-discard"
                                            onClick={() => {
                                                verifyUser(selectedUserIdForApprove);
                                                setSelectedUserIdForApprove(null);
                                                document.querySelector('.modal-backdrop').remove();
                                                document.querySelector('#verifyUserEmailModal').classList.remove('show');
                                            }}
                                        >
                                            Send
                                        </button>
                                        <button
                                            className="cstm-btn2 cstn-discard"
                                            onClick={() => {
                                                setSelectedUserIdForApprove(null);
                                                document.querySelector('#verifyUserEmailModal').classList.remove('show');
                                                document.querySelector('.modal-backdrop').remove();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Send Verification email confirmation */}
                <div
                    className="modal fade"
                    id="sendVerifyUserEmailModal"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body py-5">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ display: "none" }}>
                                    </button>
                                    <h4 className="mt-4">
                                        Are you sure that you want to resend the verification email?
                                    </h4>
                                    <div className="mt-4">
                                        <button
                                            className="mr-3 cstm-btn cstn-discard"
                                            onClick={() => {
                                                sendverifyEmail(selectedUserIdForApprove);
                                                setSelectedUserIdForApprove(null);
                                                document.querySelector('.modal-backdrop').remove();
                                                document.querySelector('#sendVerifyUserEmailModal').classList.remove('show');
                                            }}
                                        >
                                            Verify
                                        </button>
                                        <button
                                            className="cstm-btn2 cstn-discard"
                                            onClick={() => {
                                                setSelectedUserIdForApprove(null);
                                                document.querySelector('#sendVerifyUserEmailModal').classList.remove('show');
                                                document.querySelector('.modal-backdrop').remove();
                                            }}
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
