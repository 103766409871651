import { config } from "../config";

export function sortObjectArrayByStringField(arr, field) {
    arr.sort((a, b) => {
        if (a[field] < b[field]) {
            return -1;
        }
        if (a[field] > b[field]) {
            return 1;
        }
        return 0;
    });
}

/**
 * @param {string} path 
 */
export function getFullImageUrl(path) {
    return `${config.AWS.S3_BASE_URL}/${path}`;
}

/**
 * @param {string} qrCodeUrl 
 */
export function downloadQrCode(qrCodeUrl) {
    window.open(qrCodeUrl, "_blank");
}
