import baseApi from "./base-api";

function addBlackListWord(data) {
    return baseApi.post(`admin/settings/blacklist/word`, data);
}

function getBlackListWords(type) {
    return baseApi.get(`admin/settings/blacklist/word/type/${type}`);
}

function deleteBlackListWord(type, word) {
    return baseApi.delete(`admin/settings/blacklist/word/type/${type}/word/${word}`);
}

const moderationSettingService = {
    addBlackListWord: addBlackListWord,
    getBlackListWords: getBlackListWords,
    deleteBlackListWord: deleteBlackListWord
};

export default moderationSettingService;