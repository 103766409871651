import baseApi from "./base-api";

function getHelperInfos() {
    return baseApi.get("webadmin-content/helper-infos");
}

/**
 * @param {string} code
 * @param {{ description: string }} data
 */
function updateHelperInfo(code, data) {
    return baseApi.put(`webadmin-content/helper-infos/${code}`, data);
}

const webadminContentService = {
    getHelperInfos: getHelperInfos,
    updateHelperInfo: updateHelperInfo,
};

export default webadminContentService;
