import { useNavigate } from 'react-router-dom';
import './Breadcrumbs.css';
import { useBreadcrumbStore } from '../../../stores/breadcrumb.store';

export function Breadcrumbs() {
    const navigate = useNavigate();
    const breadcrumbs = useBreadcrumbStore((state) => state.breadcrumbs);

    /**
     * @param {import('../../../typedefs').BreadcrumbItem} breadcrumb
     * @param {number} index
     */
    function onBreadcrumbClick(breadcrumb, index) {
        // dont do anything if clicked on last breadcrumb (last breadcrumb is current page)
        if (index === breadcrumbs.length - 1) {
            return;
        }

        navigate(breadcrumb.path);
    }

    return (
        <ul className='breadcrumbs-list'>
            {breadcrumbs.map((breadcrumb, index) => (
                <li
                    key={index}
                    onClick={() => onBreadcrumbClick(breadcrumb, index)}
                >
                    {index > 0 && <span className='ml-1 mr-1'>&gt;</span>}
                    {breadcrumb.label}
                </li>
            ))}
        </ul>
    );
}
