import { SidebarLayoutHeader } from "./SidebarLayoutHeader";

/**
 * @param {Object} props
 * @param {string} props.headerTitle
 * @param {boolean} props.showHeaderBreadcrumbs
 * @param {React.ReactNode} props.children
 */
export function SidebarLayoutContent({
  headerTitle,
  showHeaderBreadcrumbs,
  children,
}) {
  return (
    <main className="page-content bg-light">
      <SidebarLayoutHeader
        title={headerTitle}
        showBreadcrumbs={showHeaderBreadcrumbs}
      />

      <div className="container-fluid">
        <div className="layout-specing">{children}</div>
      </div>
    </main>
  );
}
