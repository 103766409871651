import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { SidebarLayoutHeader } from "../../../shared/components/SidebarLayoutHeader";
import loader from "../../../assets/Images/loder.gif";
import Services from "../../../Services/auth.service";
import userService from "../../../Services/user.service";
import { mainSpinner } from "../../../utils/spinner.util";
import { ALLOWED_IMAGE_MIME_TYPES } from "../../../common/constants/file.constant";
import { DATES, MONTHS } from "../../../common/constants/date.constant";
import teamService from "../../../Services/team.service";
import { APP_ROUTES } from "../../../common/app-routes";
import { useSetBreadcrumbs } from "../../../shared/hooks/use-set-breadcrumbs.hook";

export function EditUser() {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const userId = searchParams.get('id');
    const [userDetails, setUserDetails] = useState();
    const [formFields, setFormFields] = useState({
        firstName: "",
        email: "",
        image: null,
        bio: "",
        pronouns: "",
        DOB: "",
        MOB: "",
        occupation: "",
    });
    const [tempProfileImage, setTempProfileImage] = useState();
    const [userOrganizations, setUserOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [teamSelectionOptions, setTeamSelectionOptions] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);

    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        if (!selectedOrganization && userOrganizations?.length) {
            console.log(userOrganizations)
            setSelectedOrganization(userOrganizations[0]._id);
        }
    }, [userOrganizations]);

    useEffect(() => {
        if (selectedOrganization) {
            // const selectedTeamsData = [];

            // get only approved teams for user
            // userDetails.teams.forEach((team) => {
            //     const userTeam = userDetails.userTeams?.find((userTeam) => {
            //         userTeam.teamId === team._id
            //     });

            //     if (userTeam && userTeam.requestStatus === 1 && userTeam.isBlock === false) {
            //         selectedTeamsData.push(formatTeamForDropdown(team));
            //     }
            // });


            if (userDetails.organizations && userDetails.organizations.length > 0) {

                userDetails.occupation = userDetails.organizations.find((data) => {
                    return data.organizationId == selectedOrganization;
                });

                if (userDetails.occupation && userDetails.occupation?.occupation) {
                    userDetails.occupation = userDetails.occupation?.occupation ? userDetails.occupation?.occupation : "";
                } else {
                    userDetails.occupation = "";
                }

            }
            setFormFields({
                firstName: userDetails.firstName || "",
                email: userDetails.email || "",
                image: userDetails.image || null,
                bio: userDetails.bio || "",
                occupation: userDetails.occupation || "",
                pronouns: userDetails.pronouns || "",
                DOB: userDetails.DOB || "",
                MOB: userDetails.MOB || "",
            });
            // setSelectedTeams(selectedTeamsData);
            getTeams(selectedOrganization);
            // let teams = userDetails.teams.filter(team => {
            //     return team.organizationId == selectedOrganization;
            // });
            // setSelectedTeams(userOrganizations[0].organizationId);
        }
    }, [selectedOrganization]);

    useEffect(() => {
        if (teamSelectionOptions && teamSelectionOptions.length > 0) {
            let selectedTeamsData = [];

            userDetails.teams.forEach((team) => {
                const userTeam = teamSelectionOptions?.find((userTeam) => {
                    return userTeam.value === team._id
                });

                // if (userTeam && userTeam.requestStatus === 1 && userTeam.isBlock === false && selectedOrganization == userTeam.organizationId) {
                    if(userTeam){
                        selectedTeamsData.push(userTeam);
                    }
                    console.log();
                // }
            });

            setSelectedTeams(selectedTeamsData);

        }
    }, [teamSelectionOptions]);



    useSetBreadcrumbs([
        { label: APP_ROUTES.USER_LIST.breadcrumb.label, path: location.state?.breadcrumbPath || APP_ROUTES.USER_LIST.route },
        { label: APP_ROUTES.EDIT_USER.breadcrumb.label, path: APP_ROUTES.EDIT_USER.route },
    ]);

    async function getUserDetails() {
        mainSpinner.show();

        try {
            const res = await userService.getUserById(userId);

            if (res.status == 200) {
                const userDetails = res.data;

                if (userDetails.organizations) {
                    userDetails.occupation = userDetails.organizations.find((data) => {
                        return data.organizationId == userDetails.organizations[0].organizationId;
                    });

                    if (userDetails.occupation && userDetails.occupation?.occupation) {
                        userDetails.occupation = userDetails.occupation?.occupation;
                    }
                }

                setUserDetails(userDetails);
                setFormFields({
                    firstName: userDetails.firstName || "",
                    email: userDetails.email || "",
                    image: userDetails.image || null,
                    bio: userDetails.bio || "",
                    occupation: userDetails.occupation || "",
                    pronouns: userDetails.pronouns || "",
                    DOB: userDetails.DOB || "",
                    MOB: userDetails.MOB || "",
                });

                if (userDetails.organizations?.length) {
                    filterOrganziationOptions(userDetails.organizationData);
                }

                if (userDetails.teams?.length) {
                    const selectedTeamsData = [];
                    
                    let organizationId = userDetails.organizations[0]?._id
                    // get only approved teams for user
                    userDetails.teams.forEach((team) => {
                        const userTeam = userDetails.userTeams?.find((userTeam) => userTeam.teamId === team._id);

                        if (userTeam && userTeam.requestStatus === 1 && userTeam.isBlock === false && userTeam.organizationId == organizationId) {
                            selectedTeamsData.push(formatTeamForDropdown(team));
                        }
                    });

                    setSelectedTeams(selectedTeamsData);
                }

                // if (userDetails.company) {
                //     getTeams(userDetails.company);
                // }

                return;
            }

            toast.error("Couldn't get user details");
        } catch (err) {
            toast.error("Couldn't get user details");
        } finally {
            mainSpinner.hide();
        }
    }

    function filterOrganziationOptions(organizations) {
        setUserOrganizations(organizations);
    }

    async function getTeams(organizationId) {
        setTeamSelectionOptions([]);

        try {
            const res = await teamService.getTeams({
                organizationIds: [organizationId],
            });

            if (res.status == 200) {
                let teamsData = res.data?.results;

                if (teamsData?.length) {
                    const teamSelectionOptionsData = teamsData.map((team) => formatTeamForDropdown(team));

                    setTeamSelectionOptions(teamSelectionOptionsData);
                }

                return;
            }

            toast.error("Couldn't get teams");
        } catch (err) {
            toast.error("Couldn't get teams");
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormFields({
            ...formFields,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(fileExt)) {
            return;
        }

        setTempProfileImage(URL.createObjectURL(file));

        uploadProfileImage(file);
    };

    async function uploadProfileImage(file) {
        mainSpinner.show();

        try {
            const formData = new FormData();
            formData.append("image", file);

            const res = await Services.imageGet(formData);

            if (res.status == 200) {
                setFormFields({
                    ...formFields,
                    image: res.data.data.images[0].location,
                });

                return;
            }

            toast.error("Couldn't upload profile image");
        } catch (err) {
            toast.error("Couldn't upload profile image");
        } finally {
            mainSpinner.hide();
        }
    }

    function removeProfileImage() {
        setTempProfileImage(null);
        setFormFields({
            ...formFields,
            image: null,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let userUpdateData = {
            ...formFields,
            organizationId: selectedOrganization,
            teamIds: selectedTeams?.map((team) => team.value) || [],
        };

        updateUser(userUpdateData);
    };

    async function updateUser(userUpdateData) {
        mainSpinner.show();

        try {
            const res = await userService.updateUser(userId, userUpdateData);

            if (res.status == 200) {
                toast.success("Successfully updated user details");
                getUserDetails();
                return;
            }

            toast.error("Couldn't update user details");
        } catch (err) {
            toast.error("Couldn't update user details");
        } finally {
            mainSpinner.hide();
        }
    }

    async function enableAccount() {
        mainSpinner.show();

        try {
            const res = await userService.enableUserAccount(userId);

            if (res.status == 200) {
                toast.success("Successfully enabled user account");
                getUserDetails();
                return;
            }

            toast.error("Couldn't enable user account");
        } catch (err) {
            toast.error("Couldn't enable user account");
        } finally {
            mainSpinner.hide();
        }
    }

    async function disableAccount() {
        mainSpinner.show();

        try {
            const res = await userService.disableUserAccount(userId);

            if (res.status == 200) {
                toast.success("Successfully disabled user account");
                getUserDetails();
                return;
            }

            toast.error("Couldn't disable user account");
        } catch (err) {
            toast.error("Couldn't disable user account");
        } finally {
            mainSpinner.hide();
        }
    }

    function formatTeamForDropdown(team) {
        return {
            value: team._id,
            label: team.name,
        };
    }

    return (
        <main className="page-content bg-light">
            <SidebarLayoutHeader title="Edit User" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <h4 className="mb-4">Edit User</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card border-0 shadow rounded mb-4 p-2">
                                {userDetails && (
                                    <div className="mb-2">
                                        {(userDetails?.isBlock === true) && (
                                            <button
                                                type="button"
                                                className="btn-submit-login-form cstm-submitbutton"
                                                style={{
                                                    backgroundColor: '#198754'
                                                }}
                                                onClick={enableAccount}
                                            >
                                                Enable Account
                                            </button>
                                        )}

                                        {(userDetails?.isBlock === false) && (
                                            <button
                                                type="button"
                                                className="btn-submit-login-form cstm-submitbutton"
                                                style={{
                                                    backgroundColor: '#dc3545'
                                                }}
                                                onClick={disableAccount}
                                            >
                                                Disable Account
                                            </button>
                                        )}
                                    </div>
                                )}

                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 cstm-profile-img cstm-AdminProfile-img d-flex align-items-center ">
                                        <div className="mr-5 items-center">
                                            <div className="mb-2">
                                                {tempProfileImage && (
                                                    <img
                                                        src={tempProfileImage}
                                                    />
                                                )}

                                                {(!tempProfileImage && formFields.image) && (
                                                    <img
                                                        id="image"
                                                        src={`${process.env.REACT_APP_S3_BASE_URL}/${formFields.image}`}
                                                    />
                                                )}

                                                {/* place holder when profile image not available */}
                                                {(!tempProfileImage && !formFields.image) && (
                                                    <div
                                                        style={{
                                                            width: "120px",
                                                            height: "120px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#00CCCC"
                                                        }}
                                                    ></div>
                                                )}
                                            </div>

                                            <div>
                                                <label htmlFor="select-image" className='cstm-uploadbtn cstm-btn mr-2'>Choose File</label>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    name="image"
                                                    id="select-image"
                                                    className='cstm-uploadHide'
                                                    onChange={handleImageChange}
                                                />

                                                <button
                                                    type="button"
                                                    onClick={removeProfileImage}
                                                    disabled={formFields.image === null}
                                                    className="cstm-btn"
                                                >
                                                    Remove Image
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p>User ID: {userDetails?._id}</p>
                                        <p>Organization: {userDetails?.organizations?.length ? userDetails?.organizations.map((list) => list.name).join(",") : null}</p>
                                        {/* <p>Email: {userDetails?.email}</p> */}
                                        <p>Contact No: {userDetails?.phoneNumber}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">First Name</label>
                                        <input
                                            name="firstName"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="First Name"
                                            value={formFields.firstName}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Email (Note: This will be marked as verified email address)</label>
                                        <input
                                            name="email"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Email"
                                            value={formFields.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Date of birth</label>

                                        <div className="d-flex">
                                            <select
                                                name="MOB"
                                                className="form-select cstm-category mr-1"
                                                value={formFields.MOB}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" disabled>Month</option>

                                                {MONTHS.map((month, index) => (
                                                    <option key={month} value={index + 1}>{month}</option>
                                                ))}
                                            </select>

                                            <select
                                                name="DOB"
                                                className="form-select cstm-category ml-1"
                                                value={formFields.DOB}
                                                onChange={handleInputChange}
                                            >
                                                <option value="" disabled>Date</option>

                                                {DATES.map((date) => (
                                                    <option key={date} value={date}>{date}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Organizations</label>

                                        <select
                                            name="Organizations"
                                            className="form-select cstm-category mr-1"
                                            value={selectedOrganization}
                                            onChange={(e) => { setSelectedOrganization(e.target.value) }}
                                        >

                                            {userOrganizations.map((organization, index) => (
                                                <option key={organization._id} value={organization._id}>{organization.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Teams</label>

                                        <ReactMultiSelectCheckboxes
                                            className="form-select"
                                            options={teamSelectionOptions}
                                            placeholderButtonLabel="Select teams for user"
                                            value={selectedTeams}
                                            onChange={(value, e) => setSelectedTeams(value)}
                                            getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${value.length} team(s) selected`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Pronouns</label>
                                        <select
                                            name="pronouns"
                                            className="form-select cstm-category"
                                            value={formFields.pronouns}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select Pronouns</option>
                                            <option value="I/Me">I/Me</option>
                                            <option value="He/Him">He/Him</option>
                                            <option value="She/Her">She/Her</option>
                                            <option value="Them/This">Them/This</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Job Title</label>
                                        <input
                                            name="occupation"
                                            type="text"
                                            className="cstm-input"
                                            placeholder="Job Title"
                                            value={formFields.occupation}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="cstm-label">Bio</label>
                                        <textarea
                                            name="bio"
                                            rows="5"
                                            value={formFields.bio}
                                            onChange={handleInputChange}
                                            className="cstm-textarea"
                                            placeholder="Bio"
                                        ></textarea>
                                    </div>

                                    <div className="submit-btnMain cstm-loader">
                                        <button type="submit" className="btn-submit-login-form cstm-submitbutton">Update User</button>
                                        <div id="login-loader" className='loader-main' style={{ display: 'none' }}>
                                            <img src={loader} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
