import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import thankYouService from '../../../Services/thank-you.service';
import { SidebarLayoutHeader } from '../../../shared/components/SidebarLayoutHeader';
import { Pagination } from '../../shared/Pagination';
import { formatDateForTable } from '../../../common/utils/date.util';
import { useLoaderStore } from '../../../stores/loader.store';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { useSetBreadcrumbs } from '../../../shared/hooks/use-set-breadcrumbs.hook';
import { Modal } from '../../../shared/components/Modal/Modal';
import { EditModerationFailedThankYou } from './EditModerationFailedThankYou';
import { ReleaseModerationFailedThankYou } from './ReleaseModerationFailedThankYou';
import moderationFailedThankYouService from '../../../Services/moderation-failed-thankyou.service';
import { downloadCsvFromData, generateCsvFileName } from '../../../common/utils/csv.util';

const MODERATION_FAILED_MESSAGE_ACTION = Object.freeze({
    DELETE: "DELETE",
    EDIT: "EDIT",
    RELEASE: "RELEASE"
});

/**
 * @typedef SelectedRow
 * @property {string} moderationFailedMessage
 * @property {keyof typeof MODERATION_FAILED_MESSAGE_ACTION} action
 */

export function ModerationFailedThankYouList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [draftThankYous, setDraftThankYous] = useState([]);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [resultsCount, setResultsCount] = useState(0);

    /** @type {[SelectedRow, React.Dispatch<SelectedRow>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useSetBreadcrumbs([{ label: 'Moderation Failed Messages', path: '' }]);

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getThankYous(pageNumber, pagination.pageSize);
    }, []);

    async function getThankYous(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await thankYouService.getModerationFailedThankyous({
                pageNumber: pageNumber,
                pageSize: pageSize
            });

            if (res.status === 200) {
                setDraftThankYous(res.data.results);
                setResultsCount(res.data.count);

                return;
            }

            toast.error("Couldn't get thank yous");
        } catch (err) {
            toast.error("Couldn't get thank yous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getThankYous(pageNumber, pagination.pageSize);
    }

    async function handleDeleteMessage() {
        try {
            const moderationFailedMessage = selectedRow.moderationFailedMessage;
            const res = await thankYouService.deleteModerationFailedThankyou(moderationFailedMessage._id, moderationFailedMessage.type);

            if (res.status === 200) {
                toast.success("Successfully deleted message");
                setDraftThankYous((current) => current.filter((data) => data._id !== moderationFailedMessage._id));
                return;
            }

            toast.error("Couldn't delete message");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete message");
        } finally {
            setSelectedRow(null);
        }
    }

    async function downloadAsCsv() {
        setShowLoader(true);

        try {
            const res = await moderationFailedThankYouService.downloadAsCsv({});

            const csvName = generateCsvFileName('moderation-failed-thankyous');
            downloadCsvFromData(res, csvName);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download csv");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title="No Contact Patient Thank you List" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className='d-flex justify-content-end align-items-center p-2'>
                                <button
                                    type='button'
                                    className="cstm-eye m-0"
                                    onClick={downloadAsCsv}
                                >
                                    <i className="fi fi-rr-download"></i>
                                </button>
                            </div>

                            <div className="col-md-12 col-lg-12">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Message</th>
                                            <th className="border-bottom w-4 cstm-userheading">From</th>
                                            <th className="border-bottom w-4 cstm-userheading">To</th>
                                            <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {draftThankYous?.map((thankYou) => {
                                            return (
                                                <tr
                                                    key={thankYou._id}
                                                    className="cstm-Tabledesign cstm-usertable-design"
                                                >
                                                    <td>{thankYou.description}</td>
                                                    <td>
                                                        <div>{thankYou.createdUserData?.firstName}</div>
                                                    </td>
                                                    <td>
                                                        <div>{thankYou.users ? thankYou.users[0]?.firstName : null}</div>
                                                        <div>{thankYou.users ? thankYou.users[0]?.email : null}</div>
                                                        <div>{thankYou.users ? thankYou.users[0]?.phoneNumber : null}</div>
                                                    </td>
                                                    <td>{thankYou.createdAt ? formatDateForTable(thankYou.createdAt) : null}</td>
                                                    <td>
                                                        <div>
                                                            <Link
                                                                to={""}
                                                                onClick={() => setSelectedRow({ moderationFailedMessage: thankYou, action: MODERATION_FAILED_MESSAGE_ACTION.EDIT })}
                                                                className="cstm-eye"
                                                            >
                                                                <i className="fi fi-rr-pencil"></i>
                                                            </Link>
                                                            <Link
                                                                to={""}
                                                                onClick={() => setSelectedRow({ moderationFailedMessage: thankYou, action: MODERATION_FAILED_MESSAGE_ACTION.DELETE })}
                                                                className="cstm-delete mrn-rt"
                                                            >
                                                                <i className="fi fi-rr-trash"></i>
                                                            </Link>

                                                            {thankYou.isReleased ? 'Released' :
                                                                <button
                                                                    type='button'
                                                                    className="cstm-eye"
                                                                    onClick={() => setSelectedRow({ moderationFailedMessage: thankYou, action: MODERATION_FAILED_MESSAGE_ACTION.RELEASE })}
                                                                    class="cstm-delete mrn-rt"
                                                                >
                                                                    <i className="fi fi-rr-cloud-upload"></i>

                                                                </button>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {!draftThankYous?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>
            </main>

            {(selectedRow && selectedRow.action === MODERATION_FAILED_MESSAGE_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this message?'
                    confirmBtnText='Delete'
                    type='delete'
                    onConfirm={handleDeleteMessage}
                    onCancel={() => setSelectedRow(null)}
                />
            )}

            {(selectedRow && selectedRow.action === MODERATION_FAILED_MESSAGE_ACTION.EDIT) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <EditModerationFailedThankYou
                        moderationFailedThankyou={selectedRow.moderationFailedMessage}
                        onEditSuccess={() => {
                            setSelectedRow(null);
                            getThankYous(pagination.pageNumber, pagination.pageSize);
                        }}
                    />
                </Modal>
            )}

            {(selectedRow && selectedRow.action === MODERATION_FAILED_MESSAGE_ACTION.RELEASE) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <ReleaseModerationFailedThankYou
                        moderationFailedThankyou={selectedRow.moderationFailedMessage}
                        onReleaseSuccess={() => {
                            setSelectedRow(null);
                        }}
                    />
                </Modal>
            )}
        </>
    );
}