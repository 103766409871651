// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-list {
    line-height: 1.2;
    list-style: none;
    display: flex;
    font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #8492A6;
    margin: 0;
    padding: 0;
}

.breadcrumbs-list li {
    cursor: pointer;
    color: #00cccc;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;CACnB,eAAe;CACf,iBAAiB;CACjB,cAAc;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".breadcrumbs-list {\n    line-height: 1.2;\n    list-style: none;\n    display: flex;\n    font-weight: 400;\n\tfont-size: 12px;\n\tline-height: 15px;\n\tcolor: #8492A6;\n    margin: 0;\n    padding: 0;\n}\n\n.breadcrumbs-list li {\n    cursor: pointer;\n    color: #00cccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
