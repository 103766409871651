import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { toast } from 'react-toastify';
import { SidebarLayoutHeader } from '../../shared/components/SidebarLayoutHeader';
import { useLoaderStore } from '../../stores/loader.store';
import Services from '../../Services/auth.service';
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';
import userService from "../../Services/user.service";
import { useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';
import { APP_ROUTES } from "../../common/app-routes";
import nonUserService from "../../Services/non-user.service";
import { OrganizationTeamNotificationCommon } from "./OrganizationTeamNotifcationCommon";

const TEAM_NOTIFICATION_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function OrganizationTeamNotification() {

    const { organizationId } = useParams();

    useEffect(() => {
    }, []);

    
    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title='Notification Settings' />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div>
                                <h4 className="mb-0">Notification Settings</h4>
                            </div>
                            <div className="row">
                                <div className="d-flex mb-2">
                                    <Link
                                        to={`/organization/${organizationId}/teams`}
                                        className='cstm-btn lh-base ml-2'
                                        state={{ breadcrumbPath: `/edit-organization/?id=${organizationId}` }}
                                    >
                                        View Teams
                                    </Link>
                                    <Link
                                        to={`/organization/${organizationId}/teams/notification`}
                                        className='cstm-btn lh-base ml-2'
                                        state={{ breadcrumbPath: `/edit-organization/?id=${organizationId}` }}
                                    >
                                        Notifications
                                    </Link>
                                </div>
                            </div>
                            <OrganizationTeamNotificationCommon type="UnregisteredUser" />
                            <OrganizationTeamNotificationCommon type="SignUpApproval" />
                            
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}