import React, { useState, useEffect } from "react";
import Header from "../NavMenuBar/Header";
import Services from "../../Services/auth.service";
import { Navigate, useLocation, useNavigate, Router } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/Images/loder.gif";
import DatePicker from "react-datepicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css";
// import { async } from 'radar-sdk-js';

const AddNews = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const newsID = new URLSearchParams(getid).get("id");

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getData();
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [location, setLocation] = useState();

  const [newsData, setNewsData] = useState({
    news_title: "",
    banner: "",
    image_video: [],
    description: "",
    source: "",
    webURL: "",
    location: "",
    // uploadDate: "",
  });
  const [err, seterr] = useState({
    title: "",
    banner: "",
    image_video: "",
    description: "",
    webURL: "",
    location: "",
    uploadDate: "",
  });

  ///image and videos
  var imgExtArray = [];
  var imgArray = [];
  var imgExtArray = [];
  var videoExtArray = [];
  var videoArray = [];
  const arrayImages = [];

  const [videoPrev, setVideoPrev] = useState([]);
  const [imagesPrev, setImagesPrev] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [multiData, setMultiData] = useState([]);
  const [getMultiFile, setMultiFile] = useState([]);
  const [arrayOfGetData, setArrayOfGetData] = useState([]);

  const handleChange = address => {
    setNewsData({ ...newsData, ['location']: address });
  };

  const handleSelect = address => {
    setNewsData({ ...newsData, ['location']: address });
  };

  // ---------------------
  // var display = [];
  const getData = () => {
    var queryVar = {
      page: 1,
      limit: 100,
    };

    Services.getNewsById(newsID, queryVar)
      .then((response) => {
        if (response.data.status === 200) {
          // console.log(response, "respone");
          var arrayImages = response.data.data.image_video;
          arrayImages.map((multifile) => {
            var filesplit = multifile.split(".").pop();
            var imageExtension = [
              "png",
              "jpg",
              "jpeg",
              "apng",
              ".avif",
              "jfif",
              "pjpeg",
              "pjp",
              "svg",
              "webp",
            ];
            var videoExtension = [
              "mp4",
              "mov",
              "wmv",
              "avi",
              "avchd",
              "flv",
              "f4v",
              "swf",
              "mkv",
              "webm",
              "html5",
              "mpeg-2",
            ];
            imageExtension.includes(filesplit) &&
              videoExtArray.push({ url: multifile, type: "image" });
            videoExtension.includes(filesplit) &&
              videoExtArray.push({ url: multifile, type: "video" });
          });

          setNewsData({
            news_title: response.data.data.news_title,
            image_video: arrayImages,
            description: response.data.data.description,
            source: response.data.data.source,
            location: response.data.data.location,
            webURL: response.data.data.webURL
          });
          setVideoPrev([...videoPrev, ...videoExtArray]);
          setMultiFile([...arrayImages]);

          if (response.data.data.startDate) {
            setStartDate(new Date(response.data.data.startDate));
          }

          if (response.data.data.endDate) {
            setEndDate(new Date(response.data.data.endDate));
          }
        } else {
          console.log("error");
        }
      })
      .catch(function (err) {
        console.log(err, "err");
        swal("Failed", err?.response?.data?.message || '', "error");
      });
  };

  //image onchange
  function deleteImagesGet(e) {
    const vp = videoPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setVideoPrev([...vp]);
  }

  function deleteImages(e) {
    const s = imagesPrev.filter((item, index) => index !== e);
    const fs = finalImages.filter((item, index) => index !== e);

    setFinalImages([...fs]);
    setImagesPrev([...s]);
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    err.image_video = e.target.value.length > 0 ? "" : "Select image or video";

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    filesArr.forEach(function (f, index) {
      imgArray = [...imgArray, f];
    });
    setFinalImages([...finalImages, ...imgArray]);
    const arr = [];
    imgArray.forEach(function (f, index) {
      var u = URL.createObjectURL(f);
      arr.push(u);
      var filesplit = f.name.split(".").pop();
      var imageExtension = [
        "png",
        "jpg",
        "jpeg",
        "apng",
        ".avif",
        "jfif",
        "pjpeg",
        "pjp",
        "svg",
        "webp",
      ];
      var videoExtension = [
        "mp4",
        "mov",
        "wmv",
        "avi",
        "avchd",
        "flv",
        "f4v",
        "swf",
        "mkv",
        "webm",
        "html5",
        "mpeg-2",
      ];
      imageExtension.includes(filesplit) &&
        imgExtArray.push({ url: u, type: "image" });
      videoExtension.includes(filesplit) &&
        imgExtArray.push({ url: u, type: "video" });
    });

    setImagesPrev([...imagesPrev, ...imgExtArray]);
    setMultiData([...multiData, ...arr]);
  };


  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "news_title":
        err.news_title = value.length > 0 ? "" : "Enter news title";
        break;
      case "description":
        err.description = value.length > 0 ? "" : "Write about";
        break;
      case "location":
        err.location = value.length > 0 ? "" : "Select location";
        break;
      case "webURL":
        err.webURL =
          e.target.value.length > 0 &&
            !new RegExp(
              "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
            ).test(value)
            ? "Enter a valid website url"
            : "";
        break;
      default:
        break;
    }

    setNewsData({ ...newsData, [name]: value });
    seterr({ ...err });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    // console.log(e.target.value, "values")

    if (newsData.news_title === "") {
      err.news_title = "Enter news  title ";
    } else {
      err.news_title = "";
    }

    // if (finalImages.length === 0) {
    //   err.image_video = "Select image or video";
    // }else if(newsData.image_video===""){
    //   err.image_video = "Select image or video"; 
    // } else {
    //   err.image_video = "";
    // }
    if (newsData.location === "") {
      err.location = "Select your location";
    } else {
      err.location = "";
    }
    if (newsData.description === "") {
      err.description = "Write description";
    } else {
      err.description = "";
    }
    seterr({ ...err });
    console.log(err, "err");

    if (videoPrev) {

      videoPrev.map(data => {
        videoArray.push(data.url);
      })
      setArrayOfGetData(videoArray)
    }

    if (
      err.news_title === "" &&
      // err.banner === "" &&
      err.image_video === "" &&
      err.location === "" &&
      err.description === ""
    ) {
      $(".loader-main").show();

      if (finalImages.length > 0) {
        const fileData = new FormData();
        finalImages.forEach((file) => fileData.append('image', file));
        // images and videos multiple uploaded
        Services.charimageGet(fileData)
          .then((response) => {

            if (response.status === 200) {
              const ImagesDataArrar = response.data.data.images;
              ImagesDataArrar.map((image) => {
                arrayImages.push(image.location);
              })
              var finalImagesData = [];
              finalImagesData = [...arrayImages, ...videoArray]

              EditNewsData(finalImagesData);
            }

          })
          .catch(function (err) {
            console.log(err, "err");

          });
      } else {
        EditNewsData(videoArray);
      }


      function EditNewsData(finalImagesData) {
        var editNews = {
          id: newsID,
          news_title: newsData.news_title,
          startDate: startDate,
          endDate: endDate,
          location: location ? location : newsData.location,
          image_video: finalImagesData,
          description: newsData.description,
          source: newsData.source,
          webURL: newsData.webURL
        };

        Services.editNews(editNews)
          .then((response) => {
            $(".loader-main").hide();

            if (response.data.status == 200) {
              swal("Success", response.data.message, "success");
            } else {
              swal("Failed", response.data.message, "error");
            }
          })
          .catch((err) => {
            $(".loader-main").hide();
            swal("Failed", err?.response?.data?.message || '', "error");
          });
      }
    }
  };

  return (
    <>
      <main class="page-content bg-light">
        <Header
          title1="Edit News"
          title="News and Stories"
          main_title="News"
          title_link="news-stories"
        />
        <div class="container-fluid">
          <div class="layout-specing">
            <h4 class="mb-4">Edit News</h4>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}

              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 ">
                  <div class="modal-dialog cstm-modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body p-4">
                        <form id="edit-news" onSubmit={handleOnSubmit}>
                          <div class="mb-4 ">
                            <label class="cstm-label">Upload Image or Video</label><br />


                            <div className='cstm-video-image-upload'>
                              <label for="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>

                              <div className='cstm-vi-in'>
                                {imagesPrev.map((data, index) =>

                                  data.type === 'image' ?

                                    (<div className='cstm-vi-box' key={index}>
                                      <span className="cstm-vi-bx">  <img class="multi-images" controls src={data.url} alt="..." /></span>
                                      <button type="button" onClick={() => deleteImages(index)}>
                                        ✖
                                      </button></div>
                                    ) : (
                                      <div className='cstm-vi-box' key={index}>
                                        <span className="cstm-vi-bx">  <video class="multi-videos" controls src={data.url} alt="..." /></span>
                                        <button type="button" onClick={() => deleteImages(index)}>
                                          ✖
                                        </button>
                                      </div>
                                    )

                                )}
                                {/* </div>

                              <div className='cstm-vi-in'> */}
                                {videoPrev.map((data, index) =>

                                  data.type === 'image' ?

                                    (<div className='cstm-vi-box' key={index}>
                                      <span className="cstm-vi-bx">  <img class="multi-images getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                      <button type="button" onClick={() => deleteImagesGet(index)}>
                                        ✖
                                      </button>
                                    </div>
                                    ) : (
                                      <div className='cstm-vi-box' key={index}>
                                        <span className="cstm-vi-bx">  <video class="multi-videos getbyid" controls src={`${process.env.REACT_APP_S3_BASE_URL}/` + data.url} alt="..." /></span>
                                        <button type="button" onClick={() => deleteImagesGet(index)}>
                                          ✖
                                        </button>
                                      </div>
                                    )

                                )}
                              </div>


                              <input
                                // accept=".mov,.mp4"
                                multiple
                                type="file"
                                name="image_video"
                                id="org-image"
                                className='cstm-uploadHide'
                                // value={editcharityVal.image_video}
                                // style={{ opacity: 0, cursor: 'pointer' }}
                                onChange={handleImageChange}
                              />
                              {err.image_video !== "" && (
                                <span style={{ color: "red" }} className="ml-3">
                                  {err.image_video}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* <div class="mb-4">
                            <label class="cstm-label">
                              Upload Video or Image
                            </label>
                            <br />

                            <div className="cstm-imagevideo">
                              <label
                                for="banner-image"
                                className="cstm-uploadbtn cstm-btn"
                              >
                                Choose File
                              </label>
                              {imagesPrev.map((data, index) =>
                                data.type === "image" ? (
                                  <>
                                    <span className="ml-3">
                                      {" "}
                                      <img
                                        class="multi-images"
                                        width="200px"
                                        height="200px"
                                        controls
                                        src={data.url}
                                        alt="..."
                                      />
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => deleteImages(index)}
                                    >
                                      X
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className="ml-3">
                                      {" "}
                                      <video
                                        class="multi-videos"
                                        width="200px"
                                        height="200px"
                                        controls
                                        src={data.url}
                                        alt="..."
                                      />
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => deleteImages(index)}
                                    >
                                      X
                                    </button>
                                  </>
                                )
                              )}

                              {videoPrev.map((data, index) =>
                                data.type === "image" ? (
                                  <>
                                    <span className="ml-3">
                                      {" "}
                                      <img
                                        class="multi-images getbyid"
                                        width="200px"
                                        height="200px"
                                        controls
                                        src={
                                          "https://human-backend-data.s3.eu-west-2.amazonaws.com/" +
                                          data.url
                                        }
                                        alt="..."
                                      />
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => deleteImagesGet(index)}
                                    >
                                      X
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className="ml-3">
                                      {" "}
                                      <video
                                        class="multi-videos getbyid"
                                        width="200px"
                                        height="200px"
                                        controls
                                        src={
                                          "https://human-backend-data.s3.eu-west-2.amazonaws.com/" +
                                          data.url
                                        }
                                        alt="..."
                                      />
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => deleteImagesGet(index)}
                                    >
                                      X
                                    </button>
                                  </>
                                )
                              )}

                              <input
                                multiple
                                type="file"
                                name="image_video"
                                id="banner-image"
                                className="cstm-uploadHide"
                                onChange={handleImageChange}
                              />
                              
                              {err.image_video !== "" && (
                                <span style={{ color: "red" }}>
                                  {err.image_video}
                                </span>
                              )}
                            </div>
                          </div> */}
                          <div class="mb-4">
                            <label class="cstm-label">News Title</label>
                            <input
                              name="news_title"
                              id="char-name"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter News Name"
                              value={newsData.news_title}
                              onChange={handleOnchange}
                            />
                            {err.news_title !== "" && (
                              <span style={{ color: "red" }}>
                                {err.news_title}
                              </span>
                            )}
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Start Date</label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="MM/dd/yyyy"
                              wrapperClassName="cstm-datepicker"
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">End Date</label>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="MM/dd/yyyy"
                              wrapperClassName="cstm-datepicker"
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Publisher/Source</label>
                            <input
                              name="source"
                              type="text"
                              class="cstm-input"
                              placeholder="Enter Publisher/Source"
                              value={newsData.source}
                              onChange={handleOnchange}
                            />
                          </div>

                          <div class="mb-4">
                            <label class="cstm-label">Location</label>
                            <PlacesAutocomplete
                              value={newsData.location}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <i class="fi fi-rr-search cstm-search-ro"></i>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'cstm-input-seacrh location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {err.location !== "" && (
                              <span style={{ color: "red" }}>
                                {err.location}
                              </span>
                            )}
                          </div>
                          <div class="mb-4">
                            <label class="cstm-label">Description</label>
                            <textarea
                              name="description"
                              rows="5"
                              cols="50"
                              id="description"
                              value={newsData.description}
                              class="cstm-input cstm-textarea"
                              placeholder="write a description..."
                              onChange={handleOnchange}
                            />
                            {err.about !== "" && (
                              <span style={{ color: "red" }}>{err.about}</span>
                            )}
                          </div>

                          <div class="mb-4 col-md-7">
                      <label class="cstm-label">
                        News Website Link (Optional)
                      </label>
                      <input
                        name="webURL"
                        id="webURL"
                        type="text"
                        class="cstm-input"
                        placeholder="Website Address"
                        value={newsData.webURL}
                        onChange={handleOnchange}
                      />
                      {err.webURL !== "" && (
                        <span style={{ color: "red" }}>{err.webURL}</span>
                      )}
                    </div>

                          <div className="submit-btnMain cstm-loader">
                            <button
                              type="submit"
                              className="btn-submit-login-form cstm-submitbutton"
                            >
                              {" "}
                              Save Changes
                            </button>
                            <div
                              id="login-loader"
                              className="loader-main"
                              style={{ display: "none" }}
                            >
                              <img src={loader} />
                            </div>
                          </div>

                          {/* <div class="d-flex">
                            <button class=" cstm-btn">
                              Save changes
                            </button>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddNews;
