import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useLocation, useParams } from "react-router-dom";
import { SidebarLayoutContent } from "../../shared/components/SidebarLayoutContent";
import { APP_ROUTES } from "../../common/app-routes";
import {
  createBreadcrumb,
  useSetBreadcrumbs,
} from "../../shared/hooks/use-set-breadcrumbs.hook";
import { Pagination } from "../shared/Pagination";
import { useGetOrganizationById } from "../../hooks/organization/organization.hook";
import { getFullImageUrl, downloadQrCode } from "../../common/utils/utils";
import { useGetTeamsByOrganizationId } from "../../hooks/team/team.hook";
import { useGetUsers } from "../../hooks/user.hook";
import { usePagination } from "../../common/hooks/use-pagination.hook";
import { useDebounceSearchText } from "../../common/hooks/use-debounce.hook";
import { CopyToClipboard } from "../../shared/components/CopyToClipboard/CopyToClipboard";
import organizationService from "../../Services/organization.service";
import { useLoaderStore } from '../../stores/loader.store';

const VIEW_ORGANIZATION_TABS = Object.freeze({
  ORGANIZATION_TEAMS: "ORGANIZATION_TEAMS",
  ORGANIZATION_USERS: "ORGANIZATION_USERS",
});

export function ViewOrganization() {
  const { organizationId } = useParams();
  const location = useLocation();
  const { data: organizationDetails } = useGetOrganizationById(organizationId, {
    includeTeamCount: "true",
    includeUserCount: "true",
  });
  const [selectedTab, setSelectedTab] = useState(
    VIEW_ORGANIZATION_TABS.ORGANIZATION_TEAMS
  );

  useSetBreadcrumbs([
    createBreadcrumb(APP_ROUTES.ORGANIZATION_LIST, location),
    createBreadcrumb(APP_ROUTES.VIEW_ORGANIZATION, null, {
      organizationId: organizationId,
    }),
  ]);

  return (
    <SidebarLayoutContent headerTitle="Organization Details">
      <h4 className="mb-4">Organization Details</h4>

      <div className="row">
        <div className="col-md-4">
          <OrganizationDetails organizationDetails={organizationDetails} />
        </div>

        <div className="col-md-8">
          <div className="card border-0 shadow rounded mb-4 cstm-tabs-pf">
            <ul className="nav" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${selectedTab === VIEW_ORGANIZATION_TABS.ORGANIZATION_TEAMS
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  role="tab"
                  onClick={() =>
                    setSelectedTab(VIEW_ORGANIZATION_TABS.ORGANIZATION_TEAMS)
                  }
                >
                  Teams
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${selectedTab === VIEW_ORGANIZATION_TABS.ORGANIZATION_USERS
                    ? "active"
                    : ""
                    }`}
                  href="#"
                  role="tab"
                  onClick={() =>
                    setSelectedTab(VIEW_ORGANIZATION_TABS.ORGANIZATION_USERS)
                  }
                >
                  Members
                </a>
              </li>
            </ul>

            <div className="tab-content mt-2">
              {selectedTab === VIEW_ORGANIZATION_TABS.ORGANIZATION_TEAMS && (
                <OrganizationTeams organizationId={organizationId} />
              )}
              {selectedTab === VIEW_ORGANIZATION_TABS.ORGANIZATION_USERS && (
                <OrganizationUsers organizationId={organizationId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </SidebarLayoutContent>
  );
}



const handleCopy = async (organizationDetails) => {
  // The HTML email signature you want to copy
  const emailSignature = `
    <table cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td>
        <a href=${organizationDetails?.qrCodeLink} target="_blank">
          <img src=${getFullImageUrl(organizationDetails?.emailBanner?.emailBannerPath)} alt="Logo" style="border: none; display: block;" width="272" />
        </a>
      </td>
    </tr>
  </table>`;

  // const textArea = document.createElement('textarea');
  // textArea.value = emailSignature;
  // document.body.appendChild(textArea);
  // textArea.select();
  // textArea.setSelectionRange(0, textArea.value.length); // For mobile support

  // try {
  //   document.execCommand('copy');
  //   setIsCopied(true);
  //   setTimeout(() => setIsCopied(false), 2000);
  // } catch (error) {
  //   console.error('Failed to copy:', error);
  // }

  // document.body.removeChild(textArea);

  await navigator.clipboard.write([
    new ClipboardItem({
      'text/html': new Blob([emailSignature], { type: 'text/html' }),
    }),
  ]);

  alert('copied');
};


function OrganizationDetails({ organizationDetails }) {
  const setShowLoader = useLoaderStore((state) => state.setShowLoader);

  const generateEmailBanner = async () => {
    setShowLoader(true);
    try {

      const res = await organizationService.generateEmailBannerByOrganisationId(organizationDetails._id);

      if (res.status === 200) {
        toast.success("Successfully generated email banner");
        window.location.reload(false);
        return;
      }

      toast.error("Couldn't generate email banner");
    }
    catch (err) {
      toast.error("Couldn't generate email banner");
    }
    finally {
      setShowLoader(false);
    }
  }

  function onDownloadQRClick() {
    downloadQrCode(getFullImageUrl(organizationDetails?.qrCodePath));
  }

  function downloadEmailBannerImage() {
    downloadQrCode(getFullImageUrl(organizationDetails?.emailBanner?.emailBannerPath));
  }

  function htmlContentBanner() {
    return `<table cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td>
        <a href=${organizationDetails?.qrCodeLink} target="_blank">
          <img src=${getFullImageUrl(organizationDetails?.emailBanner?.emailBannerPath)} alt="Logo" style="border: none; display: block;" width="272" />
        </a>
      </td>
    </tr>
  </table>`
  }

  function downloadEmailBannerHtml() {
    // Create the HTML content you want to download
    const htmlContent = `<table cellpadding="0" cellspacing="0" border="0">
    <tr>
      <td>
        <a href=${organizationDetails?.qrCodeLink} target="_blank">
          <img src=${getFullImageUrl(organizationDetails?.emailBanner?.emailBannerPath)} alt="Logo" style="border: none; display: block;" width="272" />
        </a>
      </td>
    </tr>
  </table>`;

    // Create a Blob from the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired file name
    link.download = `${organizationDetails.name}.html`;

    // Append the link to the document body (it won't be visible)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link after the download is triggered
    document.body.removeChild(link);
  }

  return (
    <div className="card border-0 shadow rounded p-3 mb-4">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          className="csatm-od-photo"
          style={{ width: 150, height: 150 }}
          src={getFullImageUrl(organizationDetails?.image)}
          alt=""
        />
        <h5 className="mt-2">{organizationDetails?.name}</h5>
      </div>

      <hr />

      <div>
        <label className="cstm-label">QR Code</label>
        <br />
        <img
          alt=""
          style={{ width: 140, height: 140 }}
          className="mb-2"
          src={getFullImageUrl(organizationDetails?.qrCodePath)}
        />
        <div className="d-flex align-items-center">
          {organizationDetails?.qrCodePath && (
            <button
              variant="primary"
              className="cstm-btn mr-2"
              onClick={onDownloadQRClick}
            >
              Download
            </button>
          )}
          {organizationDetails?.qrCodeLink && (
            <CopyToClipboard text={organizationDetails?.qrCodeLink} />
          )}
        </div>
      </div>

      <hr />


      <div>
        <label className="cstm-label">Email Banner</label>
        <br />
        <img
          alt=""
          style={{ width: 544 / 2, height: 237 / 2 }}
          className="mb-2"
          src={getFullImageUrl(organizationDetails?.emailBanner?.emailBannerPath)}
        />
        <div className="d-flex align-items-center">
          <button
            variant="primary"
            className="cstm-btn-red mr-2"
            onClick={(e) => generateEmailBanner()}
          >
            Regenerate Banner
          </button>

          {organizationDetails?.emailBanner && organizationDetails?.emailBanner?.emailBannerPath && (
            <button
              variant="primary"
              className="cstm-btn mr-2"
              onClick={downloadEmailBannerImage}
            >
              Download Image
            </button>
          )}
        </div>

        <br />
        <div className="d-flex align-items-center">
          {organizationDetails?.emailBanner && organizationDetails?.emailBanner?.emailBannerPath && (
            <button
              variant="primary"
              className="cstm-btn mr-2"
              onClick={downloadEmailBannerHtml}
            >
              Download Html
            </button>
          )}

          {organizationDetails?.emailBanner && organizationDetails?.emailBanner?.emailBannerPath && (
            <button
              variant="primary"
              className="cstm-btn mr-2"
              onClick={(e) => handleCopy(organizationDetails)}
            >
              Copy Html
            </button>)}

        </div>
      </div>

      <hr />

      <div className="title2">Website</div>
      <div className="cstm-website-link">{organizationDetails?.webURL}</div>

      <hr />

      <div>
        <span className="title2 mr-1">Total Departments:</span>
        <span>{organizationDetails?.teamCount}</span>
      </div>
      <div>
        <span className="title2 mr-1">Total Members:</span>
        <span>{organizationDetails?.userCount}</span>
      </div>
    </div>
  );
}

function OrganizationTeams({ organizationId }) {
  const [searchText, setSearchText] = useState("");
  const { data: teams } = useGetTeamsByOrganizationId(organizationId);
  const filteredTeams = teams?.results?.filter((team) =>
    team.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="tab-pane fade show active">
      <div className="py-2 px-3 border-bottom">
        <h5 className="mb-3">Organization Team List</h5>

        <div className="row mb-2">
          <div className="col-md-6">
            <i className="fi fi-rr-search cstm-search-ro"></i>
            <input
              type="text"
              className="cstm-input-seacrh"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search teams"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white rounded">
        <div className="overflow-auto">
          <table className="table mb-0 table-center">
            <thead>
              <tr className="border-top">
                <th className="border-bottom w-4 cstm-userheading">Name</th>
                <th className="border-bottom w-4 cstm-userheading">
                  Total Members
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredTeams?.map((team) => {
                return (
                  <tr
                    key={team._id}
                    className="cstm-Tabledesign cstm-usertable-design"
                  >
                    <td>{team.name}</td>
                    <td>{team.usersCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!filteredTeams?.length && (
            <div className="NoRecord-cstm">No records found!</div>
          )}
        </div>
      </div>
    </div>
  );
}

function OrganizationUsers({ organizationId }) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useDebounceSearchText(searchText);
  const { pageNumber, pageSize, setPageNumber } = usePagination();
  const { data: users } = useGetUsers({
    page: pageNumber,
    limit: pageSize,
    organizationIds: [organizationId],
    search: debouncedSearch,
  });

  return (
    <div className="tab-pane fade show active">
      <div className="py-2 px-3 border-bottom">
        <h5 className="mb-3">Organization Members</h5>

        <div className="row mb-2">
          <div className="col-md-6">
            <i className="fi fi-rr-search cstm-search-ro"></i>
            <input
              type="text"
              className="cstm-input-seacrh"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search members"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white rounded">
        <div className="overflow-auto">
          <table className="table mb-0 table-center">
            <thead>
              <tr className="border-top">
                <th className="border-bottom w-4 cstm-userheading">Name</th>
                <th className="border-bottom w-4 cstm-userheading">Email</th>
                <th className="border-bottom w-4 cstm-userheading">
                  Occupation
                </th>
              </tr>
            </thead>

            <tbody>
              {users?.results?.map((user) => {
                const userOrganizationDetails = user.organizations?.find(
                  (o) => o.organizationId === organizationId
                );
                return (
                  <tr
                    key={user._id}
                    className="cstm-Tabledesign cstm-usertable-design"
                  >
                    <td>{user.firstName}</td>
                    <td>{userOrganizationDetails?.email}</td>
                    <td>{userOrganizationDetails?.occupation}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!users?.results?.length && (
            <div className="NoRecord-cstm">No records found!</div>
          )}
        </div>
      </div>
      <div className="pb-2 px-3">
        <Pagination
          totalRows={users?.count ?? 0}
          currentPage={pageNumber}
          rowsPerPage={pageSize}
          onPaginationChange={(page) => setPageNumber(page)}
        />
      </div>
    </div>
  );
}
