import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useLoaderStore } from '../../../stores/loader.store';
import Header from "../../NavMenuBar/Header";
import { formatOrganizationForDropdown, formatTeamForMultiSelectDropdown } from '../../../utils/filter.util';
import { useGetOrganizations } from '../../../hooks/organization/organization.hook';
import { useGetTeamsByOrganizationId } from '../../../hooks/team/team.hook';
import { MultiSelectDropdown } from '../../../shared/components/MultiSelectDropdown/MultiSelectDropdown';
import smsService from "../../../Services/sms.service";
import userService from "../../../Services/user.service";
import { Button } from "bootstrap";

export function SendSmsToUsers() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [organizationFilterOptions, setOrganizationFilterOptions] = useState([]);
    const [teamFilterOptions, setTeamFilterOptions] = useState([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);
    const [smsMessage, setSmsMessage] = useState("");
    const [smsMessageCharacterLength, setSmsMessageCharacterLength] = useState(0);
    const [url, setUrl] = useState("");
    const [shortUrl, setShortUrl] = useState("");
    const [users, setUsers] = useState([]);
    const [usersUnFiltered, setUnFilteredUsers] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [searchText, setSearchText] = useState("");


    const { data: organizationsData } = useGetOrganizations();
    const { data: teamsData } = useGetTeamsByOrganizationId(selectedOrganizationId);

    useEffect(() => {
        if (organizationsData?.results) {
            const organizationFilterOptionsData = organizationsData?.results?.map((organization) => formatOrganizationForDropdown(organization));
            setOrganizationFilterOptions(organizationFilterOptionsData);
        }
    }, [organizationsData]);

    useEffect(() => {
        if (teamsData?.results) {
            const teamFilterOptionsData = teamsData?.results?.map((team) => formatTeamForMultiSelectDropdown(team));
            setTeamFilterOptions(teamFilterOptionsData);
            getUsers();

        }
    }, [teamsData]);

    useEffect(() => {
        setSmsMessageCharacterLength(smsMessage.length);

    }, [smsMessage]);

    useEffect(() => {
        if (selectedTeamIds) {
            getUsers();
        }

    }, [selectedTeamIds]);

    async function generateShortUrl() {
        setShowLoader(true);

        try {
            const res = await smsService.shortUrlRequest(url);

            setShortUrl(res.data.link);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't short url");
        } finally {
            setShowLoader(false);
        }
    }

    async function sendSms() {
        setShowLoader(true);

        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#cancelappointment').classList.remove('show');
        document.querySelector("#cancelappointment .btn-close").click();

        try {

            let phoneNumbers = [];

            // selectedUserIds.forEach((data) => {
            //     for(let i = 0; i < users)
            //     phoneNumbers.push('+' + data.countryCode.toString() + data.phoneNumber.toString());
            // });
            if(!smsMessage) {
                toast.error("Please add message");
                return;
            }

            if(!selectedUserIds.length) {
                toast.error("Please select users");
                return;
            }

            usersUnFiltered.forEach((data) => {
                if (selectedUserIds.includes(data._id)) {
                    phoneNumbers.push('+' + data.countryCode.toString() + data.phoneNumber.toString());
                }
            });
            const res = await smsService.sendSms(phoneNumbers, smsMessage);

            if (res.data) {
                toast.success("Successfully send sms");
            }
        } catch (err) {
            console.log(err);
            toast.error("Couldn't send sms");
        } finally {
            setShowLoader(false);
        }
    }

    async function getUsers() {
        setShowLoader(true);

        try {
            const res = await userService.getUsers({
                search: "",
                page: 1,
                limit: 300,
                organizationIds: selectedOrganizationId ? [selectedOrganizationId] : [],
                teamIds: selectedTeamIds,
                isActive: true
            });

            if (res.status === 200) {
                setUsers(res.data.results);
                setUnFilteredUsers(res.data.results);
                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    function removeUser(index) {
        setUsers([
            ...users.slice(0, index),
            ...users.slice(index + 1, users.length)
        ]);
    }

    function onCancelSms() {
        document.querySelector('#cancelappointment').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
    }

    function smsMessageEvent(text) {
        setSmsMessage(text);
    }

    function searchFilter(text) {
        setSearchText(text);

        let newUsers = usersUnFiltered.filter((user) => {
            if ((user.firstName.toLowerCase().indexOf(text.toLowerCase()) >= 0) ||
                (user.phoneNumber.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0)) {
                return user;
            }
        });

        setUsers(newUsers);
    }

    function onUserCheckBoxClick(item) {
        if (item == 'all') {
            if (selectedUserIds.length == users.length) {
                setSelectedUserIds([]);
            }
            else {
                const newSelectedIds = users.map((user) => { return user._id });
                setSelectedUserIds(newSelectedIds);
            }
            return;
        }

        if (selectedUserIds.includes(item._id)) {
            const newSelectedIds = selectedUserIds.filter((id) => id !== item._id);
            setSelectedUserIds(newSelectedIds);
        } else {
            const newSelectedIds = [...selectedUserIds, item._id];
            setSelectedUserIds(newSelectedIds);
        }
    }

    return (<main className="page-content bg-light">
        <Header
            title1="Sms User"
            main_title="User"
            title_link="user"
        />
        <div className="container-fluid">
            <div className="layout-specing">
                <h4 className="mb-4">SMS User</h4>
            </div>

            <div className="card border-0 shadow rounded mb-4 p-4">
                <div className="mb-4 col-md-7">
                    <label class="cstm-label">SMS message: </label>
                    <textarea
                        name="Message"
                        id="sms-message"
                        value={smsMessage}
                        onChange={(e) => smsMessageEvent(e.target.value)}
                        type="text"
                        className="cstm-input-seacrh"
                        placeholder="SMS Message"
                        maxLength={160}
                    />
                    <label className="cstm-label">Characters: {smsMessageCharacterLength} / 160 <span style={{ color: 'red' }}>{smsMessageCharacterLength >= 160 ? "max length reached" : ""}</span></label>
                </div>

                <div className="mb-4 col-md-7">
                    <label class="cstm-label">URL: </label>
                    <input
                        name="Message"
                        id="sms-message"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        type="text"
                        className="cstm-input-seacrh"
                        placeholder="URL"
                    />
                    <button className="cstm-btn" onClick={generateShortUrl}>Generate short url</button>
                </div>
                <div className="mb-4 col-md-7">
                    <label class="cstm-label">Short Url: </label>{shortUrl}
                </div>
                <div className="mb-4 col-md-7">
                    <select
                        className="form-select cstm-category"
                        placeholder='Select Organization'
                        value={selectedOrganizationId}
                        onChange={(e) => {
                            setSelectedOrganizationId(e.target.value);
                            setTeamFilterOptions([]);
                            setSelectedTeamIds([]);
                        }}
                    >
                        <option value="">All Organizations</option>

                        {organizationFilterOptions.map((organization) => (
                            <option key={organization.value} value={organization.value}>{organization.label}</option>
                        ))}
                    </select>
                </div>
                <div className="row mb-4 col-md-7">
                    <MultiSelectDropdown
                        label="Teams"
                        items={teamFilterOptions}
                        selectedIds={selectedTeamIds}
                        disabled={!selectedOrganizationId}
                        onChange={(selectedIds) => {
                            setSelectedTeamIds(selectedIds);
                        }}
                    />
                </div>

                <div className="d-flex justify-content-end align-items-center mt-2">
                    <div className="mr-1">
                        <button
                            className="cstm-btn"
                            to={" "}
                            data-bs-toggle="modal"
                            data-bs-target="#cancelappointment"
                        >
                            Send SMS
                        </button>
                    </div>
                    <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            name="search-user"
                            id="search-user"
                            value={searchText}
                            onChange={(e) => searchFilter(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search Users"
                        />
                    </div>
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading"> <input type="checkbox" onClick={(e) => onUserCheckBoxClick('all')} checked={selectedUserIds.length == users.length} readOnly /> {selectedUserIds.length}</th>
                                <th className="border-bottom w-4 cstm-userheading">Name</th>
                                <th className="border-bottom w-4 cstm-userheading">Contact</th>
                                <th className="border-bottom w-4 cstm-userheading">Email</th>
                                <th className="border-bottom w-4 cstm-userheading"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {users?.map((user, index) => (
                                <tr
                                    key={user._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td><input type="checkbox" onClick={(e) => onUserCheckBoxClick(user)} checked={selectedUserIds.includes(user._id)} readOnly /></td>
                                    <td>{user.firstName}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{user.email}</td>

                                    {/* <td>
                                        <div>
                                            <button
                                                onClick={() => removeUser(index)}
                                                className="cstm-delete"
                                            >
                                                <i className="fi fi-rr-delete"></i>
                                            </button>
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* <button onClick={sendSms}>Send Sms</button> */}
            </div>
        </div>

        <div
            className="modal fade"
            id="cancelappointment"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-5">
                        <div className="text-center">
                            <div className="d-flex justify-content-center">
                                {/* <img src={deleteImg} /> */}
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ display: "none" }}>
                            </button>
                            <h4 className="mt-4">
                                Are sure you want to send sms?
                            </h4>
                            <div className="mt-4">
                                <button
                                    className="mr-3 cstm-btn cstn-discard"
                                    onClick={sendSms}
                                >
                                    Send
                                </button>
                                <button className="cstm-btn2 cstn-discard" onClick={onCancelSms}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    );
}