import baseApi from "./base-api";

function createVideoQuickTip(videoTitle, sourcePath, encodedPath) {
    return baseApi.post(`video-quick-tip`, {
        'videoTitle': videoTitle,
        'videoLink': {
            'originalSource': sourcePath,
            'encoded480p': encodedPath
        }
    });
}

function updateVideoQuickTip(videoTitle, id, sourcePath, encodedPath) {
    return baseApi.put(`video-quick-tip/${id}`, {
        'videoTitle': videoTitle,
        'videoLink': {
            'originalSource': sourcePath,
            'encoded480p': encodedPath
        }
    });
}

function getVideoQuickTips({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("video-quick-tip", {
        page: pageNumber,
        limit: pageSize
    });
}

function getVideoQuickTipById(id)  {
    return baseApi.get(`video-quick-tip/${id}`);
} 

function deleteVideoQuickTipById(id)  {
    return baseApi.delete(`video-quick-tip/${id}`);
} 

function deleteVideoOnlyQuickTip(id)  {
    return baseApi.delete(`video-quick-tip/${id}/video`);
} 

function changeVideoQuickTipOrder(data) {
    return baseApi.put(`video-quick-tip/change-order`, data);
}


const videoQuickTipService = {
    createVideoQuickTip: createVideoQuickTip,
    getVideoQuickTips: getVideoQuickTips,
    deleteVideoQuickTipById: deleteVideoQuickTipById,
    getVideoQuickTipById: getVideoQuickTipById,
    updateVideoQuickTip: updateVideoQuickTip,
    deleteVideoOnlyQuickTip: deleteVideoOnlyQuickTip,
    changeVideoQuickTipOrder: changeVideoQuickTipOrder
};

export default videoQuickTipService;