import { useEffect, useState, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import loader from '../../assets/Images/loder.gif';
import { Modal } from '../../shared/components/Modal/Modal';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../utils/list-state.util';
import partiallyRegisteredUserService from '../../Services/partially-registered-user.service';
import { Pagination } from '../shared/Pagination';
import { DEFAULT_DEBOUNCE_TIME } from '../../common/constants/filter.constant';
import { useDebounce } from '../../common/hooks/use-debounce.hook';
import { formatDateForTable } from '../../common/utils/date.util';
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';
import { downloadCsvFromData, generateCsvFileName } from '../../common/utils/csv.util';
import { PartialRegisteredUserListFilters } from './PartialRegisteredUserListFilters';

const PARTIALLY_REGISTERED_USER_ACTION = Object.freeze({
    DELETE: "DELETE",
});

export function PartiallyRegisteredUserList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const state = useMemo(() => getUserListStateFromSearchParams(searchParams), [searchParams]);
    const filters = state.filters;
    const debouncedSearchText = useDebounce(searchText, DEFAULT_DEBOUNCE_TIME);
    const [showLoader, setShowLoader] = useState(false);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });

    /** @type {[{ userId: string, action: string }, React.Dispatch<{ userId: string, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getPartiallyRegisteredUsers(pageNumber, pagination.pageSize);
    }, [debouncedSearchText, searchParams]);

    // useEffect(() => {
    //     let pageNumber = 1;
    //     setPagination({
    //         pageNumber: pageNumber,
    //         pageSize: pagination.pageSize,
    //     });

    //     getPartiallyRegisteredUsers(pageNumber, pagination.pageSize);
    // }, [searchParams])

    async function getPartiallyRegisteredUsers(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await partiallyRegisteredUserService.getPartiallyRegisteredUsers({
                search: debouncedSearchText,
                pageNumber: pageNumber,
                pageSize: pageSize,
                signupStatuses: filters.signupStatuses
            });

            if (res.status === 200) {
                setUsers(res.data.results);
                setResultsCount(res.data.count);

                return;
            }

            toast.error("Couldn't get users");
        } catch (err) {
            toast.error("Couldn't get users");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getPartiallyRegisteredUsers(pageNumber, pagination.pageSize);
    }

    async function handleDeleteUser() {
        try {
            const userId = selectedRow.userId;
            const res = await partiallyRegisteredUserService.deletePartiallyRegisteredUser(userId);

            if (res.status === 200) {
                toast.success("Successfully deleted user");
                setUsers((users) => users.filter((user) => user._id !== userId));
                return;
            }

            toast.error("Couldn't delete user");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete user");
        } finally {
            setSelectedRow(null);
        }
    }

    async function downloadAsCsv() {
        setShowLoader(true);

        try {
            const res = await partiallyRegisteredUserService.downloadAsCsv({
                search: debouncedSearchText,
                signupStatuses: filters.signupStatuses
            });

            const csvName = generateCsvFileName('partially-registered-users');
            downloadCsvFromData(res, csvName);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download csv");
        } finally {
            setShowLoader(false);
        }
    }

    function onConfirmationCancelClick() {
        setSelectedRow(null);
    }

    function onApplyFilters(newFilters) {
        setShowFilters(false);
        setSearchParams(createUserListSearchParams(newFilters, state.searchText));
    }

    return (
        <>
            <main className="page-content bg-light">
                <Header title="User List" main_title="Admin Users" />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className='cstm-table-main'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow rounded">
                                        <div className="row justify-content-between align-items-center p-3 ">
                                            <div className="col-md-4"><h4 className="mb-0">Partially Registered Users</h4></div>
                                            <div className="col-md-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-md-4">
                                                        <i className="fi fi-rr-search cstm-search-ro"></i>
                                                        <input
                                                            name="search-user"
                                                            id="search-user"
                                                            value={searchText}
                                                            onChange={(e) => setSearchText(e.target.value)}
                                                            type="text"
                                                            className="cstm-input-seacrh"
                                                            placeholder="Search Users"
                                                        />
                                                    </div>

                                                    <button type='button' className='custom-filter-icon-btn' onClick={() => setShowFilters(true)}>
                                                        <i className="fi fi-rr-settings-sliders"></i>
                                                    </button>

                                                    <button
                                                        type='button'
                                                        className="cstm-eye m-0 mr-2"
                                                        onClick={downloadAsCsv}
                                                    >
                                                        <i className="fi fi-rr-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-12">
                                            <table className="table mb-0 table-center">
                                                <thead>
                                                    <tr className="border-top">
                                                        <th className="border-bottom w-4 cstm-userheading">Country Code</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Contact</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Joined Date</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Status</th>
                                                        <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {users?.map((user) => (
                                                        <tr
                                                            key={user._id}
                                                            className="cstm-Tabledesign cstm-usertable-design"
                                                        >
                                                            <td>{user.countryCode}</td>
                                                            <td>{user.phoneNumber}</td>
                                                            <td>{user.userData[0]?.companyData[0]?.name ? user.userData[0]?.companyData[0]?.name : ( user.organizations[0]?.name ? user.organizations[0]?.name : 'N/A')}</td>
                                                            <td>{user.createdAt ? formatDateForTable(user.createdAt) : null}</td>
                                                            <td>{user.signupStatus ? user.signupStatus.replace(/([A-Z])/g, ' $1').trim() : 'N/A'}</td>
                                                            <td>
                                                                <div>
                                                                    <Link
                                                                        to={`/edit-partially-registered-user/?countryCode=${user.countryCode}&phoneNumber=${user.phoneNumber}`}
                                                                        className="cstm-eye"
                                                                    >
                                                                        <i className="fi fi-rr-pencil"></i>
                                                                    </Link>

                                                                    {user.signupStatus && <Link
                                                                        to={`/user-signup-request-list/?userId=${user._id}`}
                                                                        className="cstm-eye"
                                                                    >
                                                                        <i className="fi fi-rr-eye"></i>
                                                                    </Link>}

                                                                    <Link
                                                                        to={""}
                                                                        onClick={() => setSelectedRow({ userId: user._id, action: PARTIALLY_REGISTERED_USER_ACTION.DELETE })}
                                                                        className="cstm-delete mrn-rt"
                                                                    >
                                                                        <i className="fi fi-rr-trash"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {!users?.length && (
                                                <div className="NoRecord-cstm">No records found!</div>
                                            )}

                                            {showLoader && (
                                                <div className="NoRecord-cstm">
                                                    <img className="pageloader" src={loader} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>
            </main>

            {(selectedRow && selectedRow.action === PARTIALLY_REGISTERED_USER_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this user?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteUser}
                    onCancel={onConfirmationCancelClick}
                />
            )}

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <PartialRegisteredUserListFilters
                    filters={filters}
                    onApplyFilters={onApplyFilters}
                />
            </Modal>
        </>
    );
}

const singleFilters = [];
const multiFilters = ['signupStatuses'];

function createUserListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber, singleFilters, multiFilters);
}

function getUserListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams, singleFilters, multiFilters);
}
