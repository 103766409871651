import { useState } from "react";
import thankyouPostImg from "../../assets/Images/thank-you-post.png";
import charityPostImg from "../../assets/Images/charity-post.png";
import giftPostImg from "../../assets/Images/gift-post.png";
import joinedOrganizationImg from "../../assets/Images/joined-organization.png";
import joniedUsersImg from "../../assets/Images/jonied-users.png";
import { EngagementStats } from './EngagementStats';
import { HelperInfoTooltip } from "../../shared/components/Tooltip/HelperInfoTooltip";
import { HelperInfoCode } from "../../common/models/helper-info-code.enum";

export function DashboardStats({ stats, filters, isLoading }) {
    let totalInteractionsCount = 0;
    let totalThankyouMessagesCount = 0;
    let thankyouReplyPercentage = 0;
    let giftReplyPercentage = 0;

    // total replies = replies + auto replies
    let totalThankyouRepliesCount = 0;
    let totalGiftRepliesCount = 0;
    let totalThankyouReplyPercentage = 0;
    let totalGiftReplyPercentage = 0;

    if (stats) {
        totalInteractionsCount = (
            (stats?.thankyous?.count || 0) +
            (stats?.thankyous?.repliesCount || 0) +
            (stats?.thankyous?.autoRepliesCount || 0) +
            (stats?.gifts?.count || 0) +
            (stats?.gifts?.repliesCount || 0) +
            (stats?.gifts?.autoRepliesCount || 0) +
            (stats?.shared?.totalCount || 0) +
            (stats?.thankyous?.nonUserThankyousCount || 0)
        );
        totalThankyouMessagesCount = (
            (stats?.thankyous?.count || 0) +
            (stats?.gifts?.count || 0) +
            (stats?.shared?.totalCount || 0) +
            (stats?.thankyous?.nonUserThankyousCount || 0)
        );

        totalThankyouRepliesCount = (stats?.thankyous?.repliesCount || 0) + (stats?.thankyous?.autoRepliesCount || 0);
        totalGiftRepliesCount = (stats?.gifts?.repliesCount || 0) + (stats?.gifts?.autoRepliesCount || 0);

        if (stats?.thankyous?.canReplyCount && stats?.thankyous?.repliesCount) {
            thankyouReplyPercentage = (stats?.thankyous?.repliesCount / stats?.thankyous?.canReplyCount) * 100;
            thankyouReplyPercentage = Math.min(thankyouReplyPercentage, 100);
        }

        if (stats?.gifts?.canReplyCount && stats?.gifts?.repliesCount) {
            giftReplyPercentage = (stats?.gifts?.repliesCount / stats?.gifts?.canReplyCount) * 100;
            giftReplyPercentage = Math.min(giftReplyPercentage, 100);
        }

        if (stats?.thankyous?.canReplyCount && stats?.thankyous?.canAndHasAnyReplyCount) {
            totalThankyouReplyPercentage = (stats?.thankyous?.canAndHasAnyReplyCount / stats?.thankyous?.canReplyCount) * 100;
            totalThankyouReplyPercentage = Math.min(totalThankyouReplyPercentage, 100);
        }

        if (stats?.gifts?.canReplyCount && stats?.gifts?.canAndHasAnyReplyCount) {
            totalGiftReplyPercentage = (stats?.gifts?.canAndHasAnyReplyCount / stats?.gifts?.canReplyCount) * 100;
            totalGiftReplyPercentage = Math.min(totalGiftReplyPercentage, 100);
        }
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-3">
                    <StatsCard
                        title="Users"
                        content={stats?.userCount || 0}
                        imgSrc={joniedUsersImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_USERS}
                        isLoading={isLoading}
                    />
                </div>

                <div className="col-3">
                    <StatsCard
                        title="Teams"
                        content={filters.teamIds?.length ? filters.teamIds?.length : (stats?.teamCount || 0)}
                        imgSrc={joinedOrganizationImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_TEAMS}
                        isLoading={isLoading}
                    />
                </div>

                <div className="col-3">
                    <StatsCard
                        title="Interactions"
                        content={totalInteractionsCount}
                        imgSrc={thankyouPostImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_INTERACTIONS}
                        isLoading={isLoading}
                        expandContent={
                            <>
                                <StatsCardExpandItem
                                    label="Thank you messages"
                                    content={stats?.thankyous?.count || 0}
                                />
                                <StatsCardExpandItem
                                    label="Gift messages"
                                    content={stats?.gifts?.count || 0}
                                />
                                <StatsCardExpandItem
                                    label="Shared messages"
                                    content={stats?.shared?.totalCount || 0}
                                />
                                <StatsCardExpandItem
                                    label="Replies"
                                    content={totalThankyouRepliesCount + totalGiftRepliesCount}
                                />
                                <StatsCardExpandItem
                                    label="Unregistered user thankyous"
                                    content={stats?.thankyous?.nonUserThankyousCount || 0}
                                />
                            </>
                        }
                    />
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-3">
                    <StatsCard
                        title="Thank Yous"
                        content={totalThankyouMessagesCount}
                        imgSrc={thankyouPostImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_THANKYOUS}
                        isLoading={isLoading}
                        expandContent={
                            <>
                                <StatsCardExpandItem
                                    label="Thank you messages"
                                    content={stats?.thankyous?.count || 0}
                                />
                                <StatsCardExpandItem
                                    label="Gift messages"
                                    content={stats?.gifts?.count || 0}
                                />
                                <StatsCardExpandItem
                                    label="Shared messages"
                                    content={stats?.shared?.totalCount || 0}
                                />
                                <StatsCardExpandItem
                                    label="Unregistered user thankyous"
                                    content={stats?.thankyous?.nonUserThankyousCount || 0}
                                />
                            </>
                        }
                    />
                </div>

                <div className="col-3">
                    <StatsCard
                        title="Gifts"
                        content={stats?.gifts?.count || 0}
                        imgSrc={giftPostImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_GIFTS}
                        isLoading={isLoading}
                        expandContent={
                            <>
                                <StatsCardExpandItem
                                    label="Replies"
                                    content={`${stats?.gifts?.repliesCount || 0} (${giftReplyPercentage.toFixed(1)}%)`}
                                />
                                <StatsCardExpandItem
                                    label="Auto replies"
                                    content={stats?.gifts?.autoRepliesCount || 0}
                                />
                                <StatsCardExpandItem
                                    label="Total replies"
                                    content={`${totalGiftRepliesCount} (${totalGiftReplyPercentage.toFixed(1)}%)`}
                                />
                            </>
                        }
                    />
                </div>

                <div className="col-3">
                    <StatsCard
                        title="Replies"
                        content={totalThankyouRepliesCount + totalGiftRepliesCount}
                        imgSrc={charityPostImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_REPLIES}
                        isLoading={isLoading}
                        expandContent={
                            <>
                                <StatsCardExpandItem
                                    label="Thank you replies"
                                    content={`${stats?.thankyous?.repliesCount || 0} (${thankyouReplyPercentage.toFixed(1)}%)`}
                                />
                                <StatsCardExpandItem
                                    label="Thank you auto replies"
                                    content={stats?.thankyous?.autoRepliesCount || 0}
                                />
                                <StatsCardExpandItem
                                    label="Thank you total replies"
                                    content={`${totalThankyouRepliesCount} (${totalThankyouReplyPercentage.toFixed(1)}%)`}
                                />
                                <StatsCardExpandItem
                                    label="Gift replies"
                                    content={`${stats?.gifts?.repliesCount || 0} (${giftReplyPercentage.toFixed(1)}%)`}
                                />
                                <StatsCardExpandItem
                                    label="Gift auto replies"
                                    content={stats?.gifts?.autoRepliesCount || 0}
                                />
                                <StatsCardExpandItem
                                    label="Gift total replies"
                                    content={`${totalGiftRepliesCount} (${totalGiftReplyPercentage.toFixed(1)}%)`}
                                />
                            </>
                        }
                    />
                </div>

                <div className="col-3">
                    <StatsCard
                        title="Shared Messages"
                        content={stats?.shared?.totalCount || 0}
                        imgSrc={thankyouPostImg}
                        helperInfoCode={HelperInfoCode.DASHBOARD_STATS_SHARED_MESSAGES}
                        isLoading={isLoading}
                        expandContent={
                            <>
                                <StatsCardExpandItem
                                    label="Shared"
                                    content={`${stats?.shared?.totalCount || 0} from ${stats?.shared?.count || 0} messages`}
                                />
                            </>
                        }
                    />
                </div>
            </div>

            <EngagementStats filters={filters} />
        </>
    );
}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.content
 * @param {React.ReactNode} props.expandContent
 * @param {any} props.imgSrc
 * @param {string} props.helperInfoCode
 * @param {boolean} props.isLoading
 */
function StatsCard({ title, content, expandContent, imgSrc, helperInfoCode, isLoading }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={`card position-relative features feature-primary rounded border-0 shadow p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
            <div className="position-absolute top-0 end-0 p-2">
                <HelperInfoTooltip code={helperInfoCode} />
            </div>

            <div className="d-flex align-items-center">
                <div>
                    <img src={imgSrc} alt="" className="h-dashboard-stats-widget-img" />
                </div>
                <div className="flex-1 ms-3">
                    <p className="text-muted mb-0 cstm-tx14">{title}</p>
                    <h4 className="mb-0">{content}</h4>
                </div>
            </div>

            {!!expandContent && (
                <button
                    type='button'
                    className={`h-dashboard-stats-widget-expand-btn ${isExpanded ? 'expanded' : ''}`}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <i className={`fi fi-rr-angle-small-down h-dashboard-stats-widget-expand-icon`}></i>
                </button>
            )}

            {isExpanded && (
                <div className='mt-2'>
                    {expandContent}
                </div>
            )}
        </div>
    );
}

/**
 * @param {Object} props
 * @param {string} props.label
 * @param {React.ReactNode} props.content
 */
function StatsCardExpandItem({ label, content }) {
    return (
        <p className="text-muted mb-0 h-dashboard-stats-widget-text-small">
            <span className="h-dashboard-stats-widget-text-label">{label}:</span>
            <span>{content}</span>
        </p>
    );
}
