import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../common/query-keys';
import webadminContentService from '../../Services/webadmin-content.service';

export function useGetHelperInfos() {
    const { data: helperInfosRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.WEBADMIN_CONTENT_HELPER_INFOS],
        queryFn: webadminContentService.getHelperInfos,
        staleTime: Infinity,
    });

    return {
        data: helperInfosRes?.data,
        isLoading: isLoading,
    };
}

export function useGetHelperInfoByCode(code) {
    const { data: helperInfos, isLoading } = useGetHelperInfos();
    const helperInfo = helperInfos?.find((item) => item.code === code);

    return {
        data: helperInfo,
        isLoading: isLoading,
    };
}
