import baseApi from "./base-api";

function getReportedMessages() {
    return baseApi.get("reported-messages");
}

/**
 * @param {string} reportedMessageId
 * @param {"thankyou" | "thankyou-reply" | "gift" | "gift-reply"} type
 */
function deleteReportedMessage(reportedMessageId, type) {
    return baseApi.deleteWithParams(`reported-messages/${reportedMessageId}`, {
        type: type,
    });
}

const reportedMessagesService = {
    getReportedMessages: getReportedMessages,
    deleteReportedMessage: deleteReportedMessage,
};

export default reportedMessagesService;
